import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function ImageIndicator({ image, setImage }) {
    return (
        <Box sx={{ position: "relative", marginRight: "8px" }}>
            <Box
                component="img"
                src={image.downloadURL}
                sx={{
                    width: 60, // Adjust the size of the image as needed
                    height: 60, // Adjust the size of the image as needed
                    borderRadius: "6px"
                }}
            />
            <IconButton
                onClick={() => setImage(null)}
                size="small"
                sx={{
                    position: "absolute",
                    top: -10,
                    right: -10,
                    color: "red",
                    backgroundColor: "white", // Add background color to make it stand out
                    "&:hover": {
                        backgroundColor: "white" // Maintain background color on hover
                    }
                }}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </Box>
    );
}
