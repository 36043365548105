import { Drawer } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { FloatingButton } from "../components/FloatingButton";
import FloatingToggle from "../components/FloatingToggle";
import FullDialog from "../components/FullDialog";
import { LeadsFlexBox } from "../components/LeadsFlexBox";
import QuickLeadAdd from "../components/QuickLeadAdd";
import { SideBar } from "../components/SideBar";
import { AuthContext } from "../context/AuthContext";
import { LeadsContext } from "../context/LeadsContext";
import { StoreContext } from "../context/StoreContext";
import getLeads from "../functions/getLeads";
import loadFolders from "../functions/loadFolders";
import getAssignList from "../utils-functions/getAssignList";

const now = new Date();
const END_DATE = new Date(now.setHours(23, 59, 59));
const START_DATE = new Date(now.setMonth(now.getMonth() - 36));
START_DATE.setHours(0, 0, 1);

const LEADS_MODE = ["ASSIGNED_LEADS", "SOURCE_LEADS"];

export default function LeadsPage() {
    const { user } = useContext(AuthContext);
    const { sourceLeads, setSourceLeads, unsubscribeSourceLeads, setUnsubscribeSourceLeads } = useContext(LeadsContext);
    const { folderLeads, setFolderLeads, unsubscribeFolderLeads, setUnsubscribeFolderLeads } = useContext(LeadsContext);
    const { maxNumberSourceLeads: maxNumber, setMaxNumberSourceLeads: setMaxNumber } = useContext(LeadsContext);
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(START_DATE);
    const [endDate, setEndDate] = useState(END_DATE);

    const [assignList, setAssignList] = useState();
    const [leadsMode] = useState(LEADS_MODE[1]);
    const [openSideBar, setOpenSideBar] = useState(false);
    const { folders, setFolders, setUnsubscribeStore } = useContext(StoreContext);
    const [folder, setFolder] = useState();
    const [open, setOpen] = useState(true);
    const [isFirstMount, setIsFirstMount] = useState(true);
    //modified by wayne 9-11-2023
    //default is to false,modified to be reading setting value from fb settings.desktopView
    const [desktopView, setDesktopView] = useState(user.settings?.desktopView || false);
    const [openQuickAddLeadCard, setOpenQuickAddLeadCard] = useState(false);

    const leads = useMemo(() => {
        if (folder) {
            return folderLeads;
        }

        if (sourceLeads) {
            let displayLeads = [...sourceLeads];
            displayLeads = sourceLeads.filter((lead) => {
                if (lead.type) {
                    return lead.type !== "ASSIGNED_LEAD";
                }
                return true;
            });

            displayLeads.forEach((lead) => {
                const assignedLeads = sourceLeads.filter((lead2) => {
                    if (lead2.type) {
                        return lead2.type === "ASSIGNED_LEAD" && lead2.sourceLeadId === lead.id;
                    }
                    return false;
                });
                lead.assignedLeads = assignedLeads;
            });
            return displayLeads;
        }
    }, [sourceLeads, leadsMode, folder, folderLeads]);

    useEffect(() => {
        let unsubscribe = () => {};
        if (!folders) {
            unsubscribe = loadFolders(user, setFolders);
            setUnsubscribeStore((array) => {
                if (array) {
                    let newArray = [...array];
                    newArray.push(unsubscribe);
                    setUnsubscribeStore(newArray);
                } else {
                    setUnsubscribeStore([unsubscribe]);
                }
            });
        }
    }, []);

    //   Loading leads
    useEffect(() => {
        let unsubscribe = () => {};

        if (folder) {
            unsubscribeFolderLeads?.();
            unsubscribe = getData();
            setUnsubscribeFolderLeads(() => () => unsubscribe());
            return () => unsubscribe();
        }

        if (!sourceLeads) {
            unsubscribe = getData();
            setUnsubscribeSourceLeads(() => () => unsubscribe());
        }
    }, [leadsMode, folder]);

    useEffect(() => {
        if (isFirstMount) {
            setIsFirstMount(false);
            return;
        }

        unsubscribeSourceLeads?.();
        let unsubscribe = () => {};
        console.log(maxNumber);
        unsubscribe = getData();
        setUnsubscribeSourceLeads(() => () => unsubscribe());
    }, [maxNumber]);

    useEffect(() => {
        const assignList = getAssignList(sourceLeads);
        setAssignList(assignList);
    }, [sourceLeads]);

    const getData = () => {
        let setFunction = () => {};
        if (folder) {
            setFunction = setFolderLeads;
        } else {
            setFunction = setSourceLeads;
        }
        setLoading(true);
        return getLeads(user, setFunction, startDate, endDate, setLoading, leadsMode, folder, maxNumber);
    };

    const toggleDrawer = (open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setOpenSideBar(open);
    };

    const handleClickFolder = (folder) => {
        console.log(folder);
        setFolder((prevState) => {
            if (prevState?.id === folder?.id) {
                return prevState;
            } else {
                triggerSlide();
                return folder;
            }
        });
    };

    const triggerSlide = () => {
        setOpen((state) => !state);
        setTimeout(() => {
            setOpen((state) => !state);
        }, 100);
    };

    const title = "Source Leads";

    return (
        <React.Fragment>
            <FloatingButton
                toggleDrawer={toggleDrawer}
                folder={folder}
                setOpenQuickAddLeadCard={setOpenQuickAddLeadCard}
            />
            <FloatingToggle desktopView={desktopView} setDesktopView={setDesktopView} />

            <LeadsFlexBox
                leads={leads}
                folder={folder}
                setFolder={setFolder}
                folders={folders}
                title={title}
                setMaxNumber={setMaxNumber}
                setLoading={setLoading}
                loading={loading}
                maxNumber={maxNumber}
                leadsMode={leadsMode}
                triggerSlide={triggerSlide}
                desktopView={desktopView}
                setDesktopView={setDesktopView}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
            />

            <FullDialog
                open={openQuickAddLeadCard}
                handleClose={() => setOpenQuickAddLeadCard(false)}
                title={"Quick Lead Add Card"}
                Component={<QuickLeadAdd />}
            />

            <Drawer anchor={"left"} open={openSideBar} onClose={toggleDrawer(false)}>
                <SideBar
                    toggleDrawer={toggleDrawer}
                    folder={folder}
                    folders={folders}
                    setFolder={setFolder}
                    handleClickFolder={handleClickFolder}
                    triggerSlide={triggerSlide}
                    leadsMode={leadsMode}
                />
            </Drawer>
        </React.Fragment>
    );
}
