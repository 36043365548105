import { Box, Button, Dialog, DialogTitle, MenuItem, Select, Switch } from "@mui/material";
import { Name } from "../../themes/themes";

export default function SettingsDialog({
    open,
    setOpen,
    currentFlow,
    model,
    setModel,
    handleChangeAppointment,
    handleChangeDisableAI,
    handleChangeSaveLead,
    handleChangeSendAudio,
    user
}) {
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <Button variant="contained" size={"small"} onClick={() => setOpen(true)} disabled={!currentFlow.id}>
                Settings
            </Button>
            <Dialog
                onClose={handleClose}
                open={open}
                PaperProps={{
                    sx: {
                        width: {
                            xs: "95vw", // width for extra-small screens
                            sm: "300px" // width for medium screens and above
                        }
                    }
                }}
            >
                <DialogTitle>Whatsapp Flow Settings</DialogTitle>
                <Box m={"16px"}>
                    <Box display="flex" justifyContent={"space-between"}>
                        <Name>AI Model</Name>
                        <Select
                            disabled={!currentFlow.id}
                            value={model}
                            onChange={(e) => setModel(e.target.value)}
                            size="small"
                        >
                            <MenuItem value={"gpt-3.5-turbo"}>gpt-3.5-turbo</MenuItem>
                            {user.addOnItems?.includes("gpt-4") && <MenuItem value={"gpt-4"}>gpt-4</MenuItem>}
                            {user.addOnItems?.includes("gpt-4") && (
                                <MenuItem value={"gpt-4-turbo-preview"}>gpt-4-turbo-preview</MenuItem>
                            )}
                        </Select>
                    </Box>
                    <Box display="flex" justifyContent={"space-between"}>
                        <Name>Appointment</Name>
                        <Switch
                            disabled={currentFlow.id ? false : true}
                            onChange={handleChangeAppointment}
                            checked={currentFlow.appointment === undefined ? false : currentFlow.appointment}
                        />
                    </Box>
                    <Box display="flex" justifyContent={"space-between"}>
                        <Name>Enable AI</Name>
                        <Switch
                            disabled={currentFlow.id ? false : true}
                            onChange={handleChangeDisableAI}
                            checked={currentFlow.disableAI === undefined ? true : !currentFlow.disableAI}
                        />
                    </Box>
                    <Box display="flex" justifyContent={"space-between"}>
                        <Name>Save Leads</Name>
                        <Switch
                            disabled={currentFlow.id ? false : true}
                            onChange={handleChangeSaveLead}
                            checked={currentFlow.saveLead === undefined ? true : currentFlow.saveLead}
                        />
                    </Box>
                    <Box display="flex" justifyContent={"space-between"}>
                        <Name>Voice Reply</Name>
                        <Switch
                            disabled={currentFlow.id ? false : true}
                            onChange={handleChangeSendAudio}
                            checked={currentFlow.sendAudio === undefined ? false : currentFlow.sendAudio}
                        />
                    </Box>
                </Box>
            </Dialog>
        </>
    );
}
