import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

const addAppointmentV2 = async (date, time, custname) => {
    try {
      const docRef = await addDoc(collection(db, 'appointments'), {
        date: date,
        time: time,
        custname: custname,
      });
  
      return JSON.stringify({ date: date, time: time, custname: custname });
  
    } catch (error) {
      console.error('Error in addAppointment: ', error);
      // Handle the error or rethrow if needed
      return JSON.stringify({ date: '', time: '', custname: '' });
    }
  }

  export default addAppointmentV2;
