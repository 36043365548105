import React, { useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

function CounterButton() {
    // State to keep track of the counter value
    const [count, setCount] = useState(0);

    

    // Function to handle the button click
    const handleClick = () => {
        setCount(count + 1);
    };

    return (
        <Container
            sx={{
                display: "flex",
                flexDirection: "column",
                height: "100vh",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center"
            }}
        >
            <Button variant="contained" color="primary" onClick={handleClick}>
                Click me
            </Button>
            {count > 0 && (
                <Typography variant="h6" sx={{ mt: 2 }}>
                    Counter: {count}
                </Typography>
            )}
        </Container>
    );
}

export default CounterButton;
