import { Box, Button, CardMedia, Container, Dialog, LinearProgress, Typography } from "@mui/material";
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { collectionGroup, limit, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { useContext, useEffect, useMemo, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import convertDate from "../../functions/common-functions/convertDate";
import formatDate from "../../functions/common-functions/formatDate";
import { arrayIsEmpty } from "../../utils-functions/arrayIsEmpty";
import mapSnapshot from "../../utils-functions/mapSnapshot";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";
import AudioPlayer from "../../components/Audio/AudioPlayerComponent";

export default function ListWhatsappMessagesPage() {
    const [messages, setMessages] = useState([]);
    const [openImage, setOpenImage] = useState(false);
    const [message, setMessage] = useState({});
    const [videoError, setVideoError] = useState(false);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({
        id: false
    });
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [loading, setLoading] = useState(false);

    const { user } = useContext(AuthContext);

    const handleClickImage = (message) => {
        setMessage(message);
        setOpenImage(true);
    };

    const checkMedia = (message) => {
        if (message.type === "ptt") {
            return <AudioPlayer downloadUrl={message.downloadUrl} />;
        } else if (message.type === "image" || message.type === "sticker") {
            const size = () => {
                return message.type === "sticker" ? { width: "50px" } : { width: "100px" };
            };
            return (
                <CardMedia
                    component="img"
                    image={message.downloadUrl}
                    alt="file"
                    sx={{ width: size(), cursor: "pointer" }}
                    onClick={() => handleClickImage(message)}
                />
            );
        } else if (message.type === "story_mention" || message.type === "video" || message.type === "story_reply") {
            if (videoError) {
                return <Typography>Story has expired</Typography>;
            }
            return (
                <>
                    <video controls width="250" onError={() => setVideoError(true)}>
                        <source src={message.downloadUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <Box>
                        <Typography variant="caption">
                            {message.type === "story_mention"
                                ? "Story mentioned"
                                : message.type === "story_reply" && "Story reply"}
                        </Typography>
                    </Box>
                </>
            );
        } else {
            return <Typography>{message.downloadUrl}</Typography>;
        }
    };

    // Define the columns for the DataGrid
    const columns = useMemo(() => {
        if (arrayIsEmpty(messages)) {
            return [];
        }

        return [
            { field: "id", headerName: "ID", width: 250, align: "center", headerAlign: "center" },
            {
                field: "date",
                headerName: "Date",
                width: 100,
                align: "center",
                headerAlign: "center",
                renderCell: (params) => {
                    return <Box>{formatDate(convertDate(params.value), true)}</Box>;
                }
            },
            {
                field: "name",
                headerName: "Name",
                width: 150,
                align: "center",
                headerAlign: "center"
            },
            {
                field: "clientId",
                headerName: "Client ID",
                width: 150,
                align: "center",
                headerAlign: "center",
                renderCell: (params) => (
                    <Typography
                        variant="caption"
                        sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            wordBreak: "break-word" // Break long words
                        }}
                    >
                        {params.value}
                    </Typography>
                )
            },
            {
                field: "chatRoomId",
                headerName: "chatRoomId",
                width: 200,
                align: "center",
                headerAlign: "center",
                renderCell: (params) => (
                    <Typography
                        variant="caption"
                        onClick={() => console.log(params.row)}
                        sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            wordBreak: "break-word" // Break long words
                        }}
                    >
                        {params.value}
                    </Typography>
                )
            },
            {
                field: "body",
                headerName: "Mesage",
                width: 350,
                align: "left",
                headerAlign: "center",
                renderCell: (params) => (
                    <Typography
                        variant="caption"
                        sx={{
                            display: "-webkit-box",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            WebkitLineClamp: 20, // Limit to 10 lines
                            WebkitBoxOrient: "vertical",
                            lineHeight: "16px", // Adjust line height as needed
                            maxHeight: "220px" // Adjust max height based on line height (2 lines)
                        }}
                    >
                        {params.value}
                    </Typography>
                )
            },
            {
                field: "type",
                headerName: "Type",
                width: 100,
                align: "center",
                headerAlign: "center",
                renderCell: (params) => {
                    const isGroup = params.row.isGroup;
                    return (
                        <Box display="flex" gap={1}>
                            <Typography variant="caption">{isGroup && "Group"}</Typography>
                            <Typography variant="caption">{params.value}</Typography>
                        </Box>
                    );
                }
            },
            {
                field: "downloadUrl",
                headerName: "File",
                width: 250,
                align: "left",
                headerAlign: "center",
                renderCell: (params) => {
                    return (
                        <>
                            <Box>{checkMedia(params.row)}</Box>
                        </>
                    );
                }
            }
        ];
        //
    }, [messages]);

    useEffect(() => {
        if (user) {
            // fetch companies
            const collectionRef = collectionGroup(db, "messages");
            const q = query(
                collectionRef,
                orderBy("date", "desc"),
                where("date", ">=", new Date("2024-03-26")),
                where("date", "<=", new Date()),
                limit(1000)
            );
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                setLoading(true);
                const messages = mapSnapshot(querySnapshot);
                setMessages(messages);
                setLoading(false);
            });

            return unsubscribe;
        }
    }, [user]);

    return (
        <Container disableGutters maxWidth="none">
            {loading && (
                <Box width="100%">
                    <LinearProgress />
                </Box>
            )}

            <Box display="flex" alignItems="center" sx={{ height: "calc(100vh - 120px)", width: "100%" }}>
                <DataGrid
                    columns={columns}
                    rows={messages}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 100 } }
                    }}
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    checkboxSelection
                    getRowId={(row) => row.id}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                    getRowHeight={() => "auto"}
                    getEstimatedRowHeight={() => 200}
                    disableRowSelectionOnClick
                    slots={{
                        toolbar: () => (
                            <CustomToolbar
                                // onDelete={deleteLeads}
                                // onChange={handleChangeField}
                                disabled={rowSelectionModel.length === 0}
                            />
                        ),
                        noRowsOverlay: CustomNoRowsOverlay
                    }}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setRowSelectionModel(newRowSelectionModel);
                    }}
                    sx={{
                        "--DataGrid-overlayHeight": "300px",
                        "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                            py: "2px"
                        },
                        "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                            py: "8px"
                        },
                        "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                            py: "16px"
                        }
                    }}
                />
            </Box>
            <Dialog open={openImage} onClose={() => setOpenImage(false)}>
                <CardMedia
                    component="img"
                    image={message.downloadUrl}
                    alt="file"
                    sx={{
                        width: "100%", // Take the full width of the parent
                        height: "100%", // Take the full height of the parent
                        objectFit: "contain" // Adjust this based on your preference
                    }}
                />
            </Dialog>
        </Container>
    );
}

function CustomToolbar({ onDelete, disabled, onChange }) {
    //
    const { user } = useContext(AuthContext);

    return (
        <GridToolbarContainer>
            <GridToolbar />
            <GridToolbarQuickFilter />
            <Box display="flex" alignItems="center" pt="4px">
                <Button color="error" onClick={onDelete} disabled={disabled} size="small">
                    Delete
                </Button>
                {user.role === "Super Admin" && (
                    <Button color="warning" onClick={onChange} disabled={disabled} size="small">
                        Add/Edit Field
                    </Button>
                )}
            </Box>
        </GridToolbarContainer>
    );
}
