import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, CardMedia, Dialog, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { useState } from "react";
import extractNumbersOnly from "../../functions/common-functions/extractNumbersOnly";
import useColors from "../../hooks/useColors";
import useWindowSize from "../../hooks/useWindowSize";
import formatWhatsAppStyle from "../../pages/MessengerInboxPage/formatWhatsappStyle";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";
import convertDate from "../../utils-functions/convertDate";
import formatDate from "../../functions/common-functions/formatDate";

export default function ChatMessageComponent({ message, platform = "whatsapp" }) {
    const [openImage, setOpenImage] = useState(false);
    const [videoError, setVideoError] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const myNumber = message.chatRoomId.split("-")[0];
    const from = extractNumbersOnly(message.from);
    const fromMe = myNumber === from;

    const colors = useColors(platform);
    const windowSize = useWindowSize();

    const handleClickImage = (message) => {
        console.log(message);
        setOpenImage(true);
    };

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const checkMedia = (message) => {
        if (message.type === "ptt") {
            return (
                <audio controls>
                    <source src={message.downloadUrl} />
                    Your browser does not support the audio element.
                </audio>
            );
        } else if (message.type === "image" || message.type === "sticker") {
            const size = () => {
                return message.type === "sticker" ? { width: "80px" } : { width: "200px" };
            };
            return (
                <CardMedia
                    component="img"
                    image={message.downloadUrl}
                    alt="file"
                    sx={{ width: size(), cursor: "pointer" }}
                    onClick={() => handleClickImage(message)}
                />
            );
        } else if (message.type === "story_mention" || message.type === "video" || message.type === "story_reply") {
            if (videoError) {
                return <Typography>Story has expired</Typography>;
            }
            return (
                <>
                    <video controls width="250" onError={() => setVideoError(true)}>
                        <source src={message.downloadUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <Box>
                        <Typography variant="caption">
                            {message.type === "story_mention"
                                ? "Story mentioned"
                                : message.type === "story_reply" && "Story reply"}
                        </Typography>
                    </Box>
                </>
            );
        } else {
            return <Typography>{message.downloadUrl}</Typography>;
        }
    };

    const size = () => {
        if (windowSize.width < 600) return { width: "100%" };
        return { height: "90vh" };
    };

    const confirmation = useConfirmation();

    const handleClickDelete = async (id) => {
        const response = await confirmation("Delete message", "Are you sure you want to delete this message?");
        if (!response) return;

        const docRef = doc(db, "fbMessages", message.chatRoomId, "messages", id);
        await deleteDoc(docRef);
    };

    return (
        !message.is_deleted && (
            <Box display="flex" flexDirection={"column"}>
                <Box
                    p="8px"
                    display="flex"
                    justifyContent={fromMe ? "flex-end" : "flex-start"}
                    sx={{
                        paddingRight: { xs: "30px", md: "60px" },
                        paddingLeft: { xs: "8px", md: "20px" },
                        marginBottom: "8px"
                    }}
                >
                    <Box
                        onClick={() => console.log(message)}
                        sx={{
                            position: "relative",
                            background: fromMe ? colors.textBoxBackgroundMe : colors.textBoxBackgroundClient,
                            borderRadius: "8px",
                            maxWidth: "85%",
                            boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.1)"
                        }}
                        p="8px"
                    >
                        {message.downloadUrl && checkMedia(message)}
                        <Typography
                            sx={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
                            color={fromMe ? colors.textColorMe : colors.textColorClient}
                        >
                            {message.body ? formatWhatsAppStyle(message.body) : ""}
                        </Typography>
                        <IconButton
                            sx={{
                                position: "absolute",
                                top: "50%",
                                right: -30,
                                transform: "translateY(-50%)",
                                color: "gray"
                            }}
                            size="small"
                            onClick={handleClickMenu}
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <Typography
                            variant="caption"
                            color="textSecondary"
                            display={"flex"}
                            sx={{
                                position: "absolute",
                                justifyContent: fromMe ? "flex-end" : "flex-start",
                                width: "150px",
                                bottom: -17,
                                color: "gray",
                                left: fromMe ? "auto" : "0",
                                right: fromMe ? "0" : "auto",
                                px: "4px"
                            }}
                        >
                            {formatDate(convertDate(message.date), true)}
                        </Typography>
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleCloseMenu}
                            MenuListProps={{
                                "aria-labelledby": "basic-button"
                            }}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "left"
                            }}
                            transformOrigin={{
                                vertical: "bottom",
                                horizontal: "left"
                            }}
                        >
                            <MenuItem
                                onClick={() => {
                                    handleClickDelete(message.id);
                                    handleCloseMenu();
                                }}
                            >
                                <DeleteIcon sx={{ marginRight: "10px" }} color="error" />
                                Delete
                            </MenuItem>
                        </Menu>
                    </Box>

                    <Dialog open={openImage} onClose={() => setOpenImage(false)}>
                        <CardMedia
                            component="img"
                            image={message.downloadUrl}
                            alt="file"
                            sx={{
                                width: "100%", // Take the full width of the parent
                                height: "100%", // Take the full height of the parent
                                objectFit: "contain" // Adjust this based on your preference
                            }}
                        />
                    </Dialog>
                </Box>
            </Box>
        )
    );
}
