import { Container, TextField, Button, Box } from "@mui/material";
import { GridContainer, GridFlexBox, Title, SelectItem } from "../themes/themes";
import { useContext, useEffect, useState } from "react";
import { getUserPages } from "../functions/getUserPages";
import { AuthContext } from "../context/AuthContext";
import useNotification from "../context-utils/NotificationContext";
import useList from "../context-utils/ListContext";
import { useInput } from "../context-utils/InputContext";
import { savePage } from "../functions/savePage";
import moment from "moment";
import { notification } from "../utils-functions/notification";
import { addDoc, collection, getDoc, docRef, updateDoc, getDocs, query, where } from "@firebase/firestore";
import { db } from "../firebase/firebase-utils";

const QuickLeadAdd = () => {
    const [formData, setFormData] = useState({
        name: "",
        phoneNo: "",
        email: "",
        source: "",
        campaign: ""
    });
    const [showDetails, setShowDetails] = useState(false);
    const [pages, setPages] = useState();
    const [page, setPage] = useState();
    const { user } = useContext(AuthContext);
    const notify = useNotification();
    const list = useList();
    const getInput = useInput();
    const [errors, setErrors] = useState();

    //Fields validation function
    const isValidEmail = (email) => {
        // A simple regex for email validation
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(email);
    };

    const validateFormData = () => {
        let tempErrors = {};

        // Check for name
        if (!formData.name.trim()) tempErrors.name = "Name is required";

        // // Check for phone number
        // if (!formData.phoneNo.trim()) {
        //     tempErrors.phoneNo = "Phone number is required";
        // } else if (!/^\d+$/.test(formData.phoneNo)) {
        //     tempErrors.phoneNo = "Phone number should contain only numbers";
        // }

        // // Check for email
        // if (formData.email && !isValidEmail(formData.email)) {
        //     tempErrors.email = "Invalid email format";
        // }

        setErrors(tempErrors);
        console.log("Object.keys(tempErrors).length: ", Object.keys(tempErrors).length);
        console.log("Error occurs: ", JSON.stringify(Object.keys(tempErrors).length === 0));
        return Object.keys(tempErrors).length === 0;
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleToggleDetails = () => {
        setShowDetails(!showDetails);
    };

    const handleClickPage = async () => {
        console.log(pages);
        const selectedPage = await list(pages, "Select page", "name", "facebook", true, true, "");
        if (selectedPage === "addAccount") {
            const newPageName = await getInput(
                "Create new page",
                "Please enter a name for your new page",
                "page name",
                ""
            );
            const myPage = {
                date: new Date(),
                email: user.email,
                name: newPageName,
                pageName: newPageName,
                userName: user.displayName,
                admins: [user.email]
            };
            console.log(myPage);
            const response = await savePage(myPage, user);
            console.log(response);
            notify("New page created for you", "success");
            setPage({ ...myPage, id: response.id });
        } else {
            if (selectedPage) {
                console.log(selectedPage);
                setPage(selectedPage);
            }
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateFormData()) {
            console.log("Fields got error");
            return;
        }

        let admins;
        if (page) {
            admins = [...page.admins, user.email];
        } else {
            admins = [user.email];
        }
        admins = admins.filter((c, index) => admins.indexOf(c) === index);
        console.log("admins: ", admins);
        let date = new Date();
        let created_time = new Date(date);
        if (date === moment(new Date()).format("YYYY-MM-DD")) {
            created_time = new Date();
        }
        console.log("created_time: ", created_time);

        //This is the request body
        const lead = {
            created: new Date(),
            created_time: created_time,
            field_data: [
                { name: "full_name", values: [formData.name || ""] },
                { name: "phone_number", values: [formData.phoneNo || ""] },
                { name: "email", values: [formData.email || ""] }
            ],
            name: formData.name || "",
            phone: formData.phoneNo || "",
            email: formData.email || "",
            ownerEmail: user.email || "",
            pageName: page ? page.name : "",
            page_id: page ? page.id : "",
            source: formData.source || "",
            admins: admins,
            campaignName: formData.campaign || ""
        };

        console.log("Quick lead: ", lead);

        try {
            //*added by wayne 15-3-24 : add extra check on existing leads records to avoid duplicate leads.
            // const emailQuery = query(collection(db, "leads"), where("email", "==", lead.email));
            // const phoneQuery = query(collection(db, "leads"), where("phone", "==", lead.phone));
          
            // const [emailQuerySnapshot, phoneQuerySnapshot] = await Promise.all([
            //     getDocs(emailQuery),
            //     getDocs(phoneQuery)
            // ]);

            // if (emailQuerySnapshot.size > 0 || phoneQuerySnapshot.size > 0) {
            //     console.log("This lead already exists");
            //     notify("This lead already exists", "error");
            //     return;
            // }else{
            //     console.log("Proceed to add leads.");
            // }

            //*
            const collectionRef = collection(db, "leads");
            await addDoc(collectionRef, lead);
            console.log("Manual lead added into Firestore");
            notify("New lead added", "success");
        } catch (error) {
            console.log(error);
            notification("Opps", error.message, "danger");
        }
    };

    useEffect(() => {
        const unsubscribe = getUserPages(user, setPages);
        return unsubscribe;
    }, []);

    return (
        <Container maxWidth="md" disableGutters>
            <GridContainer>
                <GridFlexBox>
                    <Title>Quick Lead Add</Title>
                </GridFlexBox>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3, width: "70%" }} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        error={!!errors?.name}
                        helperText={errors?.name}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="phoneNo"
                        label="Phone No."
                        name="phoneNo"
                        value={formData.phoneNo}
                        onChange={handleChange}
                        error={!!errors?.phoneNo}
                        helperText={errors?.phoneNo}
                    />

                    {showDetails && (
                        <>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                type="email"
                                value={formData.email}
                                onChange={handleChange}
                                error={!!errors?.email}
                                helperText={errors?.email}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="source"
                                label="Source"
                                name="source"
                                value={formData.source}
                                onChange={handleChange}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="campaign"
                                label="Campaign"
                                name="campaign"
                                value={formData.campaign}
                                onChange={handleChange}
                                sx={{ marginBottom: "2%" }}
                            />
                            <SelectItem value={page?.name} label="Page" onClick={handleClickPage} />
                        </>
                    )}

                    <Button
                        type="button"
                        fullWidth
                        variant="text"
                        color="primary"
                        sx={{ mt: 3 }}
                        onClick={handleToggleDetails}
                    >
                        {showDetails ? "Less details" : "Provide more details"}
                    </Button>

                    <Button type="submit" fullWidth variant="contained" color="primary" sx={{ margin: "3% 0" }}>
                        Submit
                    </Button>
                </Box>
            </GridContainer>
        </Container>
    );
};

export default QuickLeadAdd;
