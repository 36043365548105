import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import mapDocSnapshot from "../utils-functions/mapDocSnapshot";
import { sendTelegramMessage } from "./sendTelegramMessage";
import { updateDoc, arrayUnion } from "firebase/firestore";

//added by wayne 8-11-2023
//this function is to add admin to db leads.admins
export default async function assignAdmins(user, assignTo, selectData, openNotification) {
    try {
   
        selectData.forEach(async(lead) => {         
            const leaddoc = doc(db, "leads", lead.id);
            await updateDoc(leaddoc, {admins: arrayUnion(assignTo.id)}, { merge: true });
        });

        // Send Telegram message
        // const friendRef = doc(db, "users", assignTo.id);
        // const friendSnap = await getDoc(friendRef);
        // const friend = mapDocSnapshot(friendSnap);
        // const { chatId } = friend;
        // if (chatId) {
        //     let telegramMessage = `You have been assigned ${selectData.length} leads by ${user.displayName}\n`;
        //     let displayData = selectData;
        //     if (displayData.length > 5) {
        //         displayData = displayData.slice(0, 5);
        //     }
        //     displayData.forEach((lead, i) => {
        //         telegramMessage += `\n--- ${i + 1} ---\n<b>Name: ${lead.name}</b> \nPhone: ${lead.phone}\nEmail: ${lead.email
        //             } \nSource: ${lead.source} \nCampaign: ${lead.campaignName} \n`;
        //     });

        //     await sendTelegramMessage(chatId, telegramMessage);
        //     console.log("Telegram message sent to " + friend.displayName);
        // }
    } catch (error) {
        console.log(error);
        openNotification(error.message, "error");
    }
}
