export const sampleLeads = [
    {
        source: "wasapp",
        created_time: {
            seconds: 1627383509,
            nanoseconds: 678000000
        },
        assignments: [
            {
                date: {
                    seconds: 1627383663,
                    nanoseconds: 460000000
                },
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    email: "janecwy11@gmail.com",
                    name: "Jane Chan"
                }
            },
            {
                date: {
                    seconds: 1627383707,
                    nanoseconds: 271000000
                },
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    name: "Poh fen Koh",
                    email: "kohpohfen@gmail.com"
                }
            }
        ],
        campaignName: "",
        labels: [
            {
                userEmail: "janecwy11@gmail.com",
                fontColor: "#ffffff",
                label: "Pass Back",
                color: "#7b7b7b"
            }
        ],
        page_id: "FsW77M3XQxU8p8ePFiAw",
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        ownerEmail: "kohpohfen@gmail.com",
        warriors: [],
        remark: null,
        created: {
            seconds: 1627383509,
            nanoseconds: 678000000
        },
        lastAction: {
            action: "Called",
            created: {
                seconds: 1627546935,
                nanoseconds: 461000000
            },
            user: "janecwy11@gmail.com",
            files: null,
            remark: "",
            apptm: null
        },
        pageName: "summer whatsapp lead",
        field_data: [
            {
                name: "full_name",
                values: ["Jin"]
            },
            {
                values: ["+6 0125544278"],
                name: "phone_number"
            },
            {
                name: "email",
                values: [null]
            }
        ],
        codeName: "dc3160",
        actionedIds: ["janecwy11@gmail.com"],
        id: "00E3Oz425UUQvCqVauf9",
        path: "leads/00E3Oz425UUQvCqVauf9"
    },
    {
        remark: "weekday",
        page_id: "UhUfzbEdf16LS5XCRfCO",
        field_data: [
            {
                name: "full_name",
                values: ["Sabinah"]
            },
            {
                values: [1222444],
                name: "phone_number"
            },
            {
                name: "email",
                values: ["invest"]
            }
        ],
        admins: ["alanlaiproperty88@gmail.com", "rehmance39b@gmail.com"],
        created: {
            seconds: 1648540414,
            nanoseconds: 916000000
        },
        created_time: {
            seconds: 1648540414,
            nanoseconds: 916000000
        },
        source: "Facebook Ads ",
        campaignName: "",
        ownerEmail: "alanlaiproperty88@gmail.com",
        pageName: "Alan Lai\n",
        id: "00RwcR7nqH0N1D7yf13y",
        path: "leads/00RwcR7nqH0N1D7yf13y"
    },
    {
        ownerEmail: "kohpohfen@gmail.com",
        pageName: "maven",
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        warriors: ["alex07359@gmail.com"],
        page_id: "qShbFrX1peemq0pwcTz1",
        created_time: {
            seconds: 1625650908,
            nanoseconds: 931000000
        },
        source: "summer ads dmsr",
        field_data: [
            {
                name: "full_name",
                values: ["Ju Bin"]
            },
            {
                name: "phone_number",
                values: [60147549633]
            },
            {
                values: ["jbin304@gmail.com"],
                name: "email"
            }
        ],
        created: {
            seconds: 1625650908,
            nanoseconds: 931000000
        },
        id: "011CgMiaXBMIZfEqMkI1",
        path: "leads/011CgMiaXBMIZfEqMkI1"
    },
    {
        created_time: {
            seconds: 1678689393,
            nanoseconds: 186000000
        },
        admins: ["janecwy11@gmail.com", "rehmance39b@gmail.com"],
        field_data: [
            {
                values: ["Kai Zhi"],
                name: "full_name"
            },
            {
                name: "phone_number",
                values: [60192216683]
            },
            {
                values: [null],
                name: "email"
            }
        ],
        ownerEmail: "janecwy11@gmail.com",
        campaignName: "12",
        source: "fb",
        created: {
            seconds: 1678689393,
            nanoseconds: 186000000
        },
        warriors: ["aidenlim950310@gmail.com", "gnelol8895@gmail.com"],
        assignments: [
            {
                assignBy: "janecwy11@gmail.com",
                assign: {
                    email: "aidenlim950310@gmail.com",
                    name: "Aiden  Lim"
                },
                date: {
                    seconds: 1678689481,
                    nanoseconds: 568000000
                }
            },
            {
                assignBy: "janecwy11@gmail.com",
                assign: {
                    name: "Gene Lee",
                    email: "gnelol8895@gmail.com"
                },
                date: {
                    seconds: 1678717281,
                    nanoseconds: 633000000
                }
            }
        ],
        pageName: "jane chan",
        page_id: "ssqgLTQIArfNanStRoP1",
        remark: "20/2 not interested anymore",
        id: "02GXPi8FEgKjLCz1rDD6",
        path: "leads/02GXPi8FEgKjLCz1rDD6"
    },
    {
        created_time: {
            seconds: 1627539933,
            nanoseconds: 417000000
        },
        field_data: [
            {
                name: "full_name",
                values: ["Tan Siew Peng"]
            },
            {
                values: [60137741870],
                name: "phone_number"
            },
            {
                values: ["honginmark@hotmail.com"],
                name: "email"
            }
        ],
        ownerEmail: "limzhengying98@hotmail.com",
        actionedIds: ["ngsinyi@hotmail.com"],
        lastAction: {
            created: {
                seconds: 1627651609,
                nanoseconds: 576000000
            },
            user: "ngsinyi@hotmail.com",
            action: "Called",
            remark: "",
            files: null,
            apptm: null
        },
        pageName: "马来西亚投资网 | Malaysia Investment Portal",
        created: {
            seconds: 1627539933,
            nanoseconds: 417000000
        },
        warriors: ["ngsinyi@hotmail.com"],
        admins: ["daveckw@gmail.com", "debbieleow.dl@gmail.com", "limzhengying98@hotmail.com", "rehmance39b@gmail.com"],
        labels: [
            {
                fontColor: "#ffffff",
                color: "#3A0000",
                label: "No potential",
                userEmail: "ngsinyi@hotmail.com"
            }
        ],
        campaignName: "Pj Leads",
        source: "Pj Leads",
        page_id: "295016180591184",
        assignments: [
            {
                assign: {
                    name: "Elsa  Ng",
                    email: "ngsinyi@hotmail.com"
                },
                date: {
                    seconds: 1627539957,
                    nanoseconds: 324000000
                },
                assignBy: "limzhengying98@hotmail.com"
            }
        ],
        remark: null,
        id: "02SkLENKVvXU7K3apnKt",
        path: "leads/02SkLENKVvXU7K3apnKt"
    },
    {
        warriors: [
            "zzhenghoong96@gmail.com",
            "iqi.jimmyng@gmail.com",
            "jeremytan.iqi@gmail.com",
            "xiiaosheng33096@gmail.com"
        ],
        ownerEmail: "limzhengying98@hotmail.com",
        created: {
            seconds: 1664954779,
            nanoseconds: 23000000
        },
        labels: [
            {
                userEmail: "zzhenghoong96@gmail.com",
                color: "#7b7b7b",
                label: "Pass Back",
                fontColor: "#ffffff"
            },
            {
                color: "#5cfaff",
                fontColor: "#000000",
                userEmail: "jeremytan.iqi@gmail.com",
                label: "Tier 2"
            },
            {
                color: "#5cfaff",
                userEmail: "iqi.jimmyng@gmail.com",
                label: "WhatsApp blasting ",
                fontColor: "#000000"
            },
            {
                fontColor: "#000000",
                color: "#47e2b8",
                label: "Details sent",
                userEmail: "xiiaosheng33096@gmail.com"
            }
        ],
        lastAction: {
            remark: "",
            apptm: null,
            created: {
                seconds: 1680240352,
                nanoseconds: 127000000
            },
            action: "Called",
            userName: "foo fangsheng",
            user: "xiiaosheng33096@gmail.com",
            leadId: "02UxA80zYQbZCjjQu4bk",
            files: null
        },
        admins: ["limzhengying98@hotmail.com", "rehmance39b@gmail.com"],
        campaignName: "Mossaz",
        remark: "",
        assignments: [
            {
                date: {
                    seconds: 1664955053,
                    nanoseconds: 380000000
                },
                assign: {
                    email: "zzhenghoong96@gmail.com",
                    name: "Zheng Hoong Tan"
                },
                assignBy: "limzhengying98@hotmail.com"
            },
            {
                assign: {
                    name: "Jimmy Ng ",
                    email: "iqi.jimmyng@gmail.com"
                },
                assignBy: "limzhengying98@hotmail.com",
                date: {
                    seconds: 1665052944,
                    nanoseconds: 445000000
                }
            },
            {
                assignBy: "limzhengying98@hotmail.com",
                date: {
                    seconds: 1666870897,
                    nanoseconds: 405000000
                },
                assign: {
                    email: "jeremytan.iqi@gmail.com",
                    name: "Jeremy Tan"
                }
            },
            {
                assignBy: "limzhengying98@hotmail.com",
                assign: {
                    email: "xiiaosheng33096@gmail.com",
                    name: "xiiaosheng33096"
                },
                date: {
                    seconds: 1678971190,
                    nanoseconds: 297000000
                }
            }
        ],
        actionedIds: [
            "Ot81EWTNSNOHWgjKGD67",
            "IyqoOY2NbMb8kyenpdVb",
            "TjyGqsTbfpgr6SVi53fM",
            "1NJU1eLEEDKdcioaqrEM",
            "B8uda54ygIXOrHucQBoC",
            "KNKpf5E9rWepGbaq4Txg",
            "JvEcJKitktiSaDQVc8kw",
            "xiiaosheng33096@gmail.com"
        ],
        field_data: [
            {
                name: "full_name",
                values: ["陳潔如"]
            },
            {
                name: "phone_number",
                values: ["60183859231"]
            },
            {
                values: ["chenmai0430@gmail.com"],
                name: "email"
            }
        ],
        page_id: "r715QcCyBLgPHwD1Jzvq",
        source: "",
        created_time: {
            seconds: 1664954779,
            nanoseconds: 23000000
        },
        pageName: "Ying's Property",
        id: "02UxA80zYQbZCjjQu4bk",
        path: "leads/02UxA80zYQbZCjjQu4bk"
    },
    {
        created: {
            seconds: 1648540390,
            nanoseconds: 402000000
        },
        pageName: "Alan Lai\n",
        source: "Facebook Ads ",
        page_id: "UhUfzbEdf16LS5XCRfCO",
        ownerEmail: "alanlaiproperty88@gmail.com",
        field_data: [
            {
                name: "full_name",
                values: ["Kenneth Arvind Jesudass"]
            },
            {
                name: "phone_number",
                values: [60182854744]
            },
            {
                name: "email",
                values: ["weekend"]
            }
        ],
        remark: "invest",
        admins: ["alanlaiproperty88@gmail.com", "rehmance39b@gmail.com"],
        created_time: {
            seconds: 1648540390,
            nanoseconds: 402000000
        },
        campaignName: "",
        id: "02oXY3OyT3VKl3n5YkrT",
        path: "leads/02oXY3OyT3VKl3n5YkrT"
    },
    {
        admins: ["daveckw@gmail.com", "rehmance39b@gmail.com"],
        title: "Message From daveckw.com",
        field_data: [
            {
                values: ["Brad Bartell"],
                name: "full_name"
            },
            {
                values: [],
                name: "phone_number"
            },
            {
                values: ["Dangelo386.Murazik_1989@alabamahomenetwoks.com"],
                name: "email"
            }
        ],
        pageName: "Events Registration",
        campaignName: "Message From daveckw.com",
        created_time: "2022-07-13T04:03:05Z",
        created: {
            seconds: 1657684985,
            nanoseconds: 0
        },
        source: "Zapier",
        ownerEmail: "daveckw@gmail.com",
        page_id: "GxZGl3fW7S1eR5xKdElm",
        id: "03BMXT0Q0rvUBKZV6n5f",
        path: "leads/03BMXT0Q0rvUBKZV6n5f"
    },
    {
        ownerEmail: "janecwy11@gmail.com",
        remark: null,
        page_id: "ssqgLTQIArfNanStRoP1",
        created_time: {
            seconds: 1678686935,
            nanoseconds: 43000000
        },
        source: "fb & ins",
        admins: ["janecwy11@gmail.com", "rehmance39b@gmail.com"],
        pageName: "jane chan",
        campaignName: "12",
        warriors: ["henryleeproperty12@gmail.com", "gnelol8895@gmail.com"],
        created: {
            seconds: 1678686935,
            nanoseconds: 43000000
        },
        field_data: [
            {
                name: "full_name",
                values: ["Shirley Ng"]
            },
            {
                name: "phone_number",
                values: [60122019585]
            },
            {
                values: [null],
                name: "email"
            }
        ],
        assignments: [
            {
                date: {
                    seconds: 1678687099,
                    nanoseconds: 500000000
                },
                assign: {
                    name: "Junsu Mok",
                    email: "imjunsu0420@gmail.com"
                },
                assignBy: "janecwy11@gmail.com"
            },
            {
                date: {
                    seconds: 1678687393,
                    nanoseconds: 881000000
                },
                assign: {
                    name: "henry lee",
                    email: "henryleeproperty12@gmail.com"
                },
                assignBy: "janecwy11@gmail.com"
            },
            {
                date: {
                    seconds: 1678718322,
                    nanoseconds: 272000000
                },
                assign: {
                    name: "Gene Lee",
                    email: "gnelol8895@gmail.com"
                },
                assignBy: "janecwy11@gmail.com"
            }
        ],
        id: "03HuhqARBiqC61wfW2op",
        path: "leads/03HuhqARBiqC61wfW2op"
    },
    {
        lastAction: {
            user: "hanst9803@gmail.com",
            apptm: null,
            action: "Research",
            remark: "",
            files: null,
            created: {
                seconds: 1630997809,
                nanoseconds: 227000000
            }
        },
        admins: ["waynelim.property@gmail.com", "rehmance39b@gmail.com"],
        source: "D Erica",
        field_data: [
            {
                name: "full_name",
                values: ["Wong Jor Ken"]
            },
            {
                values: [60162120689],
                name: "phone_number"
            },
            {
                values: ["ken90000@yahoo.com"],
                name: "email"
            }
        ],
        campaignName: "",
        page_id: "BDa0Cv6SE1hGqs6fdvrM",
        remark: "1000sqf_-_3_rooms_|_2_baths  investment",
        ownerEmail: "waynelim.property@gmail.com",
        assignments: [
            {
                assignBy: "waynelim.property@gmail.com",
                assign: {
                    name: "ST HAN",
                    email: "hanst9803@gmail.com"
                },
                date: {
                    seconds: 1630932891,
                    nanoseconds: 223000000
                }
            }
        ],
        actionedIds: ["hanst9803@gmail.com"],
        labels: [
            {
                color: "#3b93ff",
                fontColor: "#ffffff",
                userEmail: "hanst9803@gmail.com",
                label: "Follow up"
            }
        ],
        warriors: ["hanst9803@gmail.com"],
        pageName: "Manual upload",
        created: {
            seconds: 1630932863,
            nanoseconds: 315000000
        },
        created_time: {
            seconds: 1630932863,
            nanoseconds: 315000000
        },
        id: "03RRu86cqEM43mJVin74",
        path: "leads/03RRu86cqEM43mJVin74"
    },
    {
        campaignName: 44946.982256944444,
        created_time: {
            seconds: 1677595724,
            nanoseconds: 803000000
        },
        page_id: "VXsXOqRTUBGiGr97x5qO",
        source: "Chloe - Hugoz",
        remark: "7pm_-_9pm",
        created: {
            seconds: 1677595724,
            nanoseconds: 803000000
        },
        folders: ["ace.property.gs@gmail.com:p41v6ZPLkvHRqlrEVf1E"],
        admins: ["ace.property.gs@gmail.com", "rehmance39b@gmail.com"],
        ownerEmail: "ace.property.gs@gmail.com",
        pageName: "RW Page",
        assignments: [
            {
                date: {
                    seconds: 1680783607,
                    nanoseconds: 91000000
                },
                assignBy: "ace.property.gs@gmail.com",
                assign: {
                    email: "ace.property.gs@gmail.com",
                    name: "RUSSELL WONG"
                }
            }
        ],
        field_data: [
            {
                values: ["Priscilla William"],
                name: "full_name"
            },
            {
                values: ["60172789710"],
                name: "phone_number"
            },
            {
                name: "email",
                values: ["pris.cbe@gmail.com"]
            }
        ],
        id: "04PgNKw9UG2EkVBHd1Bj",
        path: "leads/04PgNKw9UG2EkVBHd1Bj"
    },
    {
        assignedLeads: [],
        id: "04PgNKw9UG2EkVBHd1Bj:ace.property.gs@gmail.com",
        admins: ["ace.property.gs@gmail.com", "rehmance39b@gmail.com"],
        sourceLeadId: "04PgNKw9UG2EkVBHd1Bj",
        type: "ASSIGNED_LEAD",
        created_time: {
            seconds: 1677595724,
            nanoseconds: 803000000
        },
        source: "Chloe - Hugoz",
        field_data: [
            {
                values: ["Priscilla William"],
                name: "full_name"
            },
            {
                name: "phone_number",
                values: ["60172789710"]
            },
            {
                name: "email",
                values: ["pris.cbe@gmail.com"]
            }
        ],
        warriors: ["ace.property.gs@gmail.com"],
        email: "pris.cbe@gmail.com",
        campaignName: 44946.982256944444,
        ownerEmail: "ace.property.gs@gmail.com",
        remark: "7pm_-_9pm",
        created: {
            seconds: 1677595724,
            nanoseconds: 803000000
        },
        pageName: "RW Page",
        folders: ["ace.property.gs@gmail.com:p41v6ZPLkvHRqlrEVf1E"],
        phone: "60172789710",
        name: "Priscilla William",
        assignments: [
            {
                date: {
                    seconds: 1680783607,
                    nanoseconds: 91000000
                },
                assign: {
                    name: "RUSSELL WONG",
                    email: "ace.property.gs@gmail.com"
                },
                assignBy: "ace.property.gs@gmail.com"
            }
        ],
        page_id: "VXsXOqRTUBGiGr97x5qO",
        assignedDate: {
            seconds: 1680783607,
            nanoseconds: 91000000
        },
        path: "leads/04PgNKw9UG2EkVBHd1Bj:ace.property.gs@gmail.com"
    },
    {
        actionedIds: ["vinzchau00@gmail.com", "zzhenghoong96@gmail.com", "iqi.jimmyng@gmail.com"],
        page_id: "295016180591184",
        pageName: "马来西亚投资网 | Malaysia Investment Portal",
        warriors: [
            "vinzchau00@gmail.com",
            "zzhenghoong96@gmail.com",
            "iqi.jimmyng@gmail.com",
            "wkrealestate@outlook.com"
        ],
        admins: [
            "daveckw@gmail.com",
            "debbieleow.dl@gmail.com",
            "limzhengying98@hotmail.com",
            "myra@eliteone.com.my",
            "rehmance39b@gmail.com"
        ],
        source: "",
        field_data: [
            {
                name: "full_name",
                values: ["Waiseley Loh"]
            },
            {
                values: ["60162805685"],
                name: "phone_number"
            },
            {
                name: "email",
                values: ["waiseleyloh@hotmail.com"]
            }
        ],
        ownerEmail: "limzhengying98@hotmail.com",
        lastAction: {
            remark: "",
            files: null,
            user: "iqi.jimmyng@gmail.com",
            action: "Whatsapped",
            apptm: null,
            created: {
                seconds: 1669979645,
                nanoseconds: 795000000
            }
        },
        campaignName: "PJ Leads",
        created_time: {
            seconds: 1664012658,
            nanoseconds: 810000000
        },
        assignments: [
            {
                assignBy: "limzhengying98@hotmail.com",
                assign: {
                    email: "vinzchau00@gmail.com",
                    name: "Vinz Chau"
                },
                date: {
                    seconds: 1664013870,
                    nanoseconds: 534000000
                }
            },
            {
                assignBy: "limzhengying98@hotmail.com",
                assign: {
                    email: "zzhenghoong96@gmail.com",
                    name: "Zheng Hoong Tan"
                },
                date: {
                    seconds: 1664773488,
                    nanoseconds: 252000000
                }
            },
            {
                assign: {
                    name: "Jimmy Ng ",
                    email: "iqi.jimmyng@gmail.com"
                },
                assignBy: "limzhengying98@hotmail.com",
                date: {
                    seconds: 1665654987,
                    nanoseconds: 922000000
                }
            },
            {
                assign: {
                    email: "wkrealestate@outlook.com",
                    name: "WONG WAI KEAT"
                },
                assignBy: "limzhengying98@hotmail.com",
                date: {
                    seconds: 1678971302,
                    nanoseconds: 991000000
                }
            }
        ],
        labels: [
            {
                color: "#5cfaff",
                fontColor: "#000000",
                label: "Call back ",
                userEmail: "vinzchau00@gmail.com"
            },
            {
                label: "Follow up",
                fontColor: "#ffffff",
                userEmail: "iqi.jimmyng@gmail.com",
                color: "#3b93ff"
            },
            {
                fontColor: "#000000",
                label: "WhatsApp blasting ",
                userEmail: "iqi.jimmyng@gmail.com",
                color: "#5cfaff"
            }
        ],
        created: {
            seconds: 1664012658,
            nanoseconds: 810000000
        },
        remark: "",
        id: "04gwgAhaFVtNGSQO3X4v",
        path: "leads/04gwgAhaFVtNGSQO3X4v"
    },
    {
        source: "iProperty",
        remark: "1970-06-12",
        lastAction: {
            action: "Whatsapped",
            files: null,
            remark: "",
            created: {
                seconds: 1628672972,
                nanoseconds: 964000000
            },
            apptm: null,
            user: "jasonmch02@gmail.com"
        },
        created_time: {
            seconds: 1627449803,
            nanoseconds: 221000000
        },
        created: {
            seconds: 1627449803,
            nanoseconds: 221000000
        },
        pageName: "iProperty",
        campaignName: "iProperty",
        field_data: [
            {
                name: "full_name",
                values: ["JULIE TAN LAI CHENG"]
            },
            {
                name: "phone_number",
                values: [60122028835]
            },
            {
                values: ["JULIETCLC@GMAIL.COM"],
                name: "email"
            }
        ],
        actionedIds: ["jasonmch02@gmail.com"],
        ownerEmail: "daveckw@gmail.com",
        assignments: [
            {
                assignBy: "daveckw@gmail.com",
                date: {
                    seconds: 1627449873,
                    nanoseconds: 958000000
                },
                assign: {
                    email: "jasonmch02@gmail.com",
                    name: "Jason Mok"
                }
            }
        ],
        admins: ["daveckw@gmail.com", "rehmance39b@gmail.com"],
        warriors: ["jasonmch02@gmail.com"],
        page_id: "0bv3FGR5hBLGUHZwnyZu",
        labels: [
            {
                color: "#3A0000",
                label: "No potential",
                fontColor: "#ffffff",
                userEmail: "jasonmch02@gmail.com"
            }
        ],
        id: "04j46l4WzNkGCgXkt7Es",
        path: "leads/04j46l4WzNkGCgXkt7Es"
    },
    {
        remark: null,
        actionedIds: ["vinzchau00@gmail.com"],
        assignments: [
            {
                assignBy: "teeseechen@hotmail.com",
                assign: {
                    name: "Melvin Lim",
                    email: "limzhengying98@hotmail.com"
                },
                date: {
                    seconds: 1667979952,
                    nanoseconds: 988000000
                }
            },
            {
                assign: {
                    name: "Vinz Chau",
                    email: "vinzchau00@gmail.com"
                },
                assignBy: "limzhengying98@hotmail.com",
                date: {
                    seconds: 1667983334,
                    nanoseconds: 484000000
                }
            }
        ],
        lastAction: {
            files: null,
            user: "vinzchau00@gmail.com",
            apptm: null,
            action: "Research",
            remark: "",
            created: {
                seconds: 1667996984,
                nanoseconds: 270000000
            }
        },
        admins: ["teeseechen@hotmail.com", "rehmance39b@gmail.com"],
        created: {
            seconds: 1667977578,
            nanoseconds: 284000000
        },
        deleted: true,
        created_time: {
            seconds: 1667977578,
            nanoseconds: 284000000
        },
        campaignName: "Bryant Investor Campaign",
        pageName: "下金蛋的公鸡",
        page_id: "esBtNT78EsHNushVYUdz",
        warriors: ["limzhengying98@hotmail.com", "vinzchau00@gmail.com"],
        labels: [
            {
                fontColor: "#ffffff",
                userEmail: "vinzchau00@gmail.com",
                label: "Pass Back",
                color: "#7b7b7b"
            }
        ],
        source: "Bryant Investor Data",
        ownerEmail: "teeseechen@hotmail.com",
        field_data: [
            {
                name: "full_name",
                values: ["Chong Siew Nyin"]
            },
            {
                values: [60127178875],
                name: "phone_number"
            },
            {
                values: [null],
                name: "email"
            }
        ],
        id: "05NEvpKPOpsISz1aE0xJ",
        path: "leads/05NEvpKPOpsISz1aE0xJ"
    },
    {
        source: "fb ads summer",
        ownerEmail: "kohpohfen@gmail.com",
        labels: [
            {
                label: "Details sent",
                fontColor: "#000000",
                color: "#47e2b8",
                userEmail: "charles951121@gmail.com"
            }
        ],
        lastAction: {
            action: "Whatsapped",
            apptm: null,
            user: "charles951121@gmail.com",
            created: {
                seconds: 1626421960,
                nanoseconds: 952000000
            },
            remark: "",
            files: null
        },
        actionedIds: ["charles951121@gmail.com"],
        warriors: ["charles951121@gmail.com", "cheekit19960823@gmail.com"],
        field_data: [
            {
                values: ["30june.Ck Liew劉峻銧"],
                name: "full_name"
            },
            {
                values: ["+60162702080"],
                name: "phone_number"
            },
            {
                name: "email",
                values: ["ckliew69@gmail.com"]
            }
        ],
        created: {
            seconds: 1625651884,
            nanoseconds: 520000000
        },
        codeName: "SK0030",
        created_time: {
            seconds: 1625651884,
            nanoseconds: 520000000
        },
        remark: "Lead Gen DMSR - KL 1",
        page_id: "qShbFrX1peemq0pwcTz1",
        pageName: "maven",
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        assignments: [
            {
                date: {
                    seconds: 1626058711,
                    nanoseconds: 32000000
                },
                assign: {
                    email: "charles951121@gmail.com",
                    name: "CY Yong"
                },
                assignBy: "kohpohfen@gmail.com"
            },
            {
                assign: {
                    name: "Leong Chee kit",
                    email: "cheekit19960823@gmail.com"
                },
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1637233503,
                    nanoseconds: 598000000
                }
            }
        ],
        id: "05g4yNzkjPcliW4E6Vj6",
        path: "leads/05g4yNzkjPcliW4E6Vj6"
    },
    {
        assignments: [
            {
                date: {
                    seconds: 1658930763,
                    nanoseconds: 940000000
                },
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    email: "wilsontan00@gmail.com",
                    name: "Wilson Tan"
                }
            },
            {
                date: {
                    seconds: 1659614123,
                    nanoseconds: 872000000
                },
                assign: {
                    email: "zzhenghoong96@gmail.com",
                    name: "zzhenghoong96"
                },
                assignBy: "kohpohfen@gmail.com"
            },
            {
                date: {
                    seconds: 1660278607,
                    nanoseconds: 56000000
                },
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    email: "cindy_pang91@hotmail.com",
                    name: "Pang  Yy"
                }
            },
            {
                assign: {
                    name: "Wilson Tan",
                    email: "wilsontan00@gmail.com"
                },
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1660282129,
                    nanoseconds: 539000000
                }
            }
        ],
        source: "",
        remark: "",
        lastAction: {
            remark: "",
            apptm: null,
            user: "zzhenghoong96@gmail.com",
            files: null,
            created: {
                seconds: 1669880897,
                nanoseconds: 654000000
            },
            action: "Whatsapped"
        },
        appointments: [
            {
                venue: "showroom",
                budget: "time not sure yet",
                time: "22:54",
                date: {
                    seconds: 1658448000,
                    nanoseconds: 0
                },
                user: "kohpohfen@gmail.com",
                intention: "mandarin"
            }
        ],
        pageName: "summer whatsapp lead",
        page_id: "FsW77M3XQxU8p8ePFiAw",
        ownerEmail: "kohpohfen@gmail.com",
        created: {
            seconds: 1658050944,
            nanoseconds: 582000000
        },
        warriors: ["wilsontan00@gmail.com", "zzhenghoong96@gmail.com", "cindy_pang91@hotmail.com"],
        created_time: {
            seconds: 1658050944,
            nanoseconds: 582000000
        },
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        actionedIds: [
            "kohpohfen@gmail.com",
            "wilsontan00@gmail.com",
            "zzhenghoong96@gmail.com",
            "cindy_pang91@hotmail.com"
        ],
        labels: [
            {
                fontColor: "#ffffff",
                label: "Potential",
                color: "#d5a65d",
                userEmail: "kohpohfen@gmail.com"
            },
            {
                userEmail: "cindy_pang91@hotmail.com",
                color: "#3A0000",
                label: "No potential",
                fontColor: "#ffffff"
            }
        ],
        campaignName: "",
        field_data: [
            {
                name: "full_name",
                values: ["Boon Jun"]
            },
            {
                name: "phone_number",
                values: ["+60 16-396 5097"]
            },
            {
                values: [""],
                name: "email"
            }
        ],
        id: "05jjPeD7Ol3W2912Jgzd",
        path: "leads/05jjPeD7Ol3W2912Jgzd"
    },
    {
        source: "PJ Leads",
        page_id: "295016180591184",
        admins: ["daveckw@gmail.com", "debbieleow.dl@gmail.com", "limzhengying98@hotmail.com", "rehmance39b@gmail.com"],
        ownerEmail: "limzhengying98@hotmail.com",
        lastAction: {
            action: "Whatsapped",
            files: null,
            apptm: null,
            remark: "",
            user: "msleow@gmail.com",
            created: {
                seconds: 1630426177,
                nanoseconds: 839000000
            }
        },
        pageName: "马来西亚投资网 | Malaysia Investment Portal",
        field_data: [
            {
                values: ["Tan Seh Lok"],
                name: "full_name"
            },
            {
                values: ["60166845246"],
                name: "phone_number"
            },
            {
                name: "email",
                values: ["sehlok@live.com"]
            }
        ],
        created_time: {
            seconds: 1624891582,
            nanoseconds: 978000000
        },
        actionedIds: ["ngsinyi@hotmail.com", "msleow@gmail.com"],
        created: {
            seconds: 1624891582,
            nanoseconds: 978000000
        },
        warriors: ["ngsinyi@hotmail.com", "msleow@gmail.com"],
        assignments: [
            {
                date: {
                    seconds: 1626231942,
                    nanoseconds: 421000000
                },
                assignBy: "limzhengying98@hotmail.com",
                assign: {
                    email: "msleow@gmail.com",
                    name: "Iris Leow"
                }
            }
        ],
        id: "05lE3r6nceMKX16qrJuw",
        path: "leads/05lE3r6nceMKX16qrJuw"
    },
    {
        remark: null,
        field_data: [
            {
                values: ["Jimmy Kor"],
                name: "full_name"
            },
            {
                values: ["p:+60123850558"],
                name: "phone_number"
            },
            {
                values: ["jimmykor@gmail.com"],
                name: "email"
            }
        ],
        pageName: "Manual upload",
        assignments: [
            {
                date: {
                    seconds: 1647236275,
                    nanoseconds: 263000000
                },
                assignBy: "waynelim.property@gmail.com",
                assign: {
                    name: "Alvin Mah",
                    email: "alvin.careline@gmail.com"
                }
            }
        ],
        ownerEmail: "waynelim.property@gmail.com",
        source: "Novum",
        page_id: "BDa0Cv6SE1hGqs6fdvrM",
        warriors: ["alvin.careline@gmail.com"],
        admins: ["waynelim.property@gmail.com", "rehmance39b@gmail.com"],
        campaignName: "Novum",
        created_time: {
            seconds: 1647236256,
            nanoseconds: 649000000
        },
        created: {
            seconds: 1647236256,
            nanoseconds: 649000000
        },
        id: "06agmOTo9cMkNTGKFd9p",
        path: "leads/06agmOTo9cMkNTGKFd9p"
    },
    {
        page_id: "ssqgLTQIArfNanStRoP1",
        remark: "Npu 8pm",
        admins: ["janecwy11@gmail.com", "rehmance39b@gmail.com"],
        source: "fb ins",
        assignments: [
            {
                assign: {
                    name: "Gene Lee",
                    email: "gnelol8895@gmail.com"
                },
                date: {
                    seconds: 1678697009,
                    nanoseconds: 744000000
                },
                assignBy: "janecwy11@gmail.com"
            }
        ],
        field_data: [
            {
                values: ["Nick Yap"],
                name: "full_name"
            },
            {
                name: "phone_number",
                values: [60167708663]
            },
            {
                name: "email",
                values: [null]
            }
        ],
        warriors: [],
        ownerEmail: "janecwy11@gmail.com",
        created_time: {
            seconds: 1678696535,
            nanoseconds: 923000000
        },
        created: {
            seconds: 1678696535,
            nanoseconds: 924000000
        },
        campaignName: "12",
        deleted: true,
        pageName: "jane chan",
        id: "06aqtsxyBDF3n3W9vQ4A",
        path: "leads/06aqtsxyBDF3n3W9vQ4A"
    },
    {
        campaignName: "",
        pageName: "summer whatsapp lead",
        assignments: [
            {
                assign: {
                    name: "Vernice Lee",
                    email: "vernicelee2621@gmail.com"
                },
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1634443213,
                    nanoseconds: 423000000
                }
            },
            {
                assign: {
                    email: "hanst9803@gmail.com",
                    name: "ST HAN"
                },
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1635252205,
                    nanoseconds: 199000000
                }
            },
            {
                assign: {
                    email: "irychang20@gmail.com",
                    name: "iry chang"
                },
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1635252293,
                    nanoseconds: 730000000
                }
            }
        ],
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        remark: "",
        field_data: [
            {
                values: ["Ms Khoo"],
                name: "full_name"
            },
            {
                values: ["+60 16-209 0337"],
                name: "phone_number"
            },
            {
                name: "email",
                values: [""]
            }
        ],
        labels: [
            {
                label: "Follow up",
                userEmail: "vernicelee2621@gmail.com",
                color: "#3b93ff",
                fontColor: "#ffffff"
            },
            {
                label: "Follow up",
                color: "#3b93ff",
                userEmail: "hanst9803@gmail.com",
                fontColor: "#ffffff"
            },
            {
                color: "#7b7b7b",
                fontColor: "#ffffff",
                userEmail: "irychang20@gmail.com",
                label: "Pass Back"
            }
        ],
        ownerEmail: "kohpohfen@gmail.com",
        created: {
            seconds: 1634435892,
            nanoseconds: 410000000
        },
        source: "",
        actionedIds: ["vernicelee2621@gmail.com", "irychang20@gmail.com", "hanst9803@gmail.com"],
        created_time: {
            seconds: 1634342400,
            nanoseconds: 0
        },
        warriors: ["vernicelee2621@gmail.com", "hanst9803@gmail.com", "irychang20@gmail.com"],
        lastAction: {
            action: "Research",
            user: "irychang20@gmail.com",
            created: {
                seconds: 1635835227,
                nanoseconds: 30000000
            },
            files: null,
            apptm: null,
            remark: ""
        },
        page_id: "FsW77M3XQxU8p8ePFiAw",
        id: "06cQVNlbT69gokwIUgyZ",
        path: "leads/06cQVNlbT69gokwIUgyZ"
    },
    {
        field_data: [
            {
                values: ["dc2912 Agnes wong"],
                name: "full_name"
            },
            {
                values: [60122035016],
                name: "phone_number"
            },
            {
                values: [null],
                name: "email"
            }
        ],
        ownerEmail: "kohpohfen@gmail.com",
        deleted: true,
        created: {
            seconds: 1625684253,
            nanoseconds: 470000000
        },
        page_id: "FsW77M3XQxU8p8ePFiAw",
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        created_time: {
            seconds: 1625684253,
            nanoseconds: 470000000
        },
        pageName: "summer whatsapp lead",
        campaignName: "",
        source: "whatsapp fb lead summer",
        remark: null,
        id: "06eiGldKKFdvE3n5Khna",
        path: "leads/06eiGldKKFdvE3n5Khna"
    },
    {
        source: "",
        warriors: [
            "mongny@hotmail.com",
            "munhong0909@gmail.com",
            "vinzchau00@gmail.com",
            "wilsontan00@gmail.com",
            "jeremytan.iqi@gmail.com",
            "caixuanlew@outlook.com"
        ],
        lastAction: {
            remark: "",
            created: {
                seconds: 1662463513,
                nanoseconds: 441000000
            },
            files: null,
            apptm: null,
            action: "Called",
            user: "caixuanlew@outlook.com"
        },
        field_data: [
            {
                name: "full_name",
                values: ["Voon"]
            },
            {
                name: "phone_number",
                values: ["+60 18-368 1969"]
            },
            {
                name: "email",
                values: [""]
            }
        ],
        remark: "",
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        campaignName: "",
        pageName: "summer whatsapp lead",
        ownerEmail: "kohpohfen@gmail.com",
        labels: [
            {
                color: "#7b7b7b",
                fontColor: "#ffffff",
                userEmail: "mongny@hotmail.com",
                label: "Pass Back"
            },
            {
                fontColor: "#ffffff",
                color: "#7b7b7b",
                label: "Pass Back",
                userEmail: "jeremytan.iqi@gmail.com"
            },
            {
                userEmail: "caixuanlew@outlook.com",
                color: "#3A0000",
                fontColor: "#ffffff",
                label: "No potential"
            },
            {
                label: "No potential",
                color: "#3A0000",
                fontColor: "#ffffff",
                userEmail: "wilsontan00@gmail.com"
            }
        ],
        created_time: {
            seconds: 1655729947,
            nanoseconds: 576000000
        },
        actionedIds: [
            "kohpohfen@gmail.com",
            "munhong0909@gmail.com",
            "mongny@hotmail.com",
            "vinzchau00@gmail.com",
            "wilsontan00@gmail.com",
            "jeremytan.iqi@gmail.com",
            "caixuanlew@outlook.com"
        ],
        created: {
            seconds: 1655729947,
            nanoseconds: 576000000
        },
        assignments: [
            {
                date: {
                    seconds: 1656134753,
                    nanoseconds: 928000000
                },
                assign: {
                    email: "mongny@hotmail.com",
                    name: "Jimmy ong"
                },
                assignBy: "kohpohfen@gmail.com"
            },
            {
                assign: {
                    email: "munhong0909@gmail.com",
                    name: "Mun Hong Yip"
                },
                date: {
                    seconds: 1656325041,
                    nanoseconds: 884000000
                },
                assignBy: "kohpohfen@gmail.com"
            },
            {
                date: {
                    seconds: 1656684035,
                    nanoseconds: 218000000
                },
                assign: {
                    email: "vinzchau00@gmail.com",
                    name: "vinzchau00"
                },
                assignBy: "kohpohfen@gmail.com"
            },
            {
                date: {
                    seconds: 1658571896,
                    nanoseconds: 991000000
                },
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    name: "Wilson Tan",
                    email: "wilsontan00@gmail.com"
                }
            },
            {
                date: {
                    seconds: 1659005263,
                    nanoseconds: 418000000
                },
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    email: "jeremytan.iqi@gmail.com",
                    name: "Jeremy Tan"
                }
            },
            {
                date: {
                    seconds: 1660058524,
                    nanoseconds: 279000000
                },
                assign: {
                    email: "caixuanlew@outlook.com",
                    name: "Chelsea Lew"
                },
                assignBy: "kohpohfen@gmail.com"
            }
        ],
        page_id: "FsW77M3XQxU8p8ePFiAw",
        id: "08S8uFE7KDf5uGZ2zVVe",
        path: "leads/08S8uFE7KDf5uGZ2zVVe"
    },
    {
        admins: ["daveckw@gmail.com", "debbieleow.dl@gmail.com", "limzhengying98@hotmail.com", "rehmance39b@gmail.com"],
        created: {
            seconds: 1630294746,
            nanoseconds: 39000000
        },
        source: "fb pm",
        assignments: [
            {
                assignBy: "limzhengying98@hotmail.com",
                date: {
                    seconds: 1630294774,
                    nanoseconds: 731000000
                },
                assign: {
                    email: "janecwy11@gmail.com",
                    name: "Jane Chan"
                }
            },
            {
                assignBy: "limzhengying98@hotmail.com",
                date: {
                    seconds: 1630894037,
                    nanoseconds: 271000000
                },
                assign: {
                    name: "Elsa  Ng",
                    email: "ngsinyi@hotmail.com"
                }
            },
            {
                assignBy: "limzhengying98@hotmail.com",
                date: {
                    seconds: 1631094809,
                    nanoseconds: 201000000
                },
                assign: {
                    email: "williamffk@gmail.com",
                    name: "William Fam"
                }
            },
            {
                date: {
                    seconds: 1632055883,
                    nanoseconds: 758000000
                },
                assignBy: "limzhengying98@hotmail.com",
                assign: {
                    email: "benedict7119@gmail.com",
                    name: "Benedict chan Jin hou"
                }
            },
            {
                assignBy: "limzhengying98@hotmail.com",
                date: {
                    seconds: 1657108656,
                    nanoseconds: 695000000
                },
                assign: {
                    email: "vinzchau00@gmail.com",
                    name: "Vinz Chau"
                }
            }
        ],
        page_id: "295016180591184",
        ownerEmail: "limzhengying98@hotmail.com",
        field_data: [
            {
                name: "full_name",
                values: ["Miko Chew"]
            },
            {
                values: ["601112278363"],
                name: "phone_number"
            },
            {
                name: "email",
                values: ["miko_chew@hotmail.co.uk"]
            }
        ],
        warriors: [
            "janecwy11@gmail.com",
            "ngsinyi@hotmail.com",
            "williamffk@gmail.com",
            "benedict7119@gmail.com",
            "vinzchau00@gmail.com"
        ],
        labels: [
            {
                label: "Potential",
                userEmail: "benedict7119@gmail.com",
                color: "#d5a65d",
                fontColor: "#ffffff"
            },
            {
                fontColor: "#ffffff",
                color: "#3b93ff",
                userEmail: "benedict7119@gmail.com",
                label: "Follow up"
            },
            {
                color: "#47e2b8",
                fontColor: "#000000",
                label: "Details sent",
                userEmail: "benedict7119@gmail.com"
            },
            {
                userEmail: "williamffk@gmail.com",
                color: "#7b7b7b",
                label: "Pass Back",
                fontColor: "#ffffff"
            },
            {
                fontColor: "#000000",
                color: "#5cfaff",
                userEmail: "janecwy11@gmail.com",
                label: "move to whatsapp blasting "
            }
        ],
        pageName: "马来西亚投资网 | Malaysia Investment Portal",
        campaignName: "PJ Leads",
        remark: "",
        lastAction: {
            remark: "",
            action: "Called",
            created: {
                seconds: 1642159467,
                nanoseconds: 713000000
            },
            apptm: null,
            files: null,
            user: "janecwy11@gmail.com"
        },
        actionedIds: ["janecwy11@gmail.com", "benedict7119@gmail.com", "ngsinyi@hotmail.com", "williamffk@gmail.com"],
        created_time: {
            seconds: 1630294746,
            nanoseconds: 39000000
        },
        id: "08wUd9Jrmydl2sOToonx",
        path: "leads/08wUd9Jrmydl2sOToonx"
    },
    {
        remark: "speak english. summer dated, cy zoom, didnt close d clover. ffg hill park appmt. budget 700-800k. stay australia, come malaysia every month. buy with bf. invest. stay sierramas, valencia, bangsar",
        pageName: "summer whatsapp lead",
        campaignName: "",
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        source: "whatsapp fb lead summer",
        page_id: "FsW77M3XQxU8p8ePFiAw",
        created_time: {
            seconds: 1625684253,
            nanoseconds: 468000000
        },
        field_data: [
            {
                values: ["Jane"],
                name: "full_name"
            },
            {
                name: "phone_number",
                values: ["61 406 498 939"]
            },
            {
                values: [null],
                name: "email"
            }
        ],
        ownerEmail: "kohpohfen@gmail.com",
        created: {
            seconds: 1625684253,
            nanoseconds: 468000000
        },
        deleted: true,
        id: "095cvQg6OFtGvHomt7QH",
        path: "leads/095cvQg6OFtGvHomt7QH"
    },
    {
        remark: "没有方向\n",
        assignments: [
            {
                date: {
                    seconds: 1653388341,
                    nanoseconds: 872000000
                },
                assignBy: "alanlaiproperty88@gmail.com",
                assign: {
                    name: "Alan Yap",
                    email: "alan_yap1121@hotmail.com"
                }
            }
        ],
        source: "BUKIT JALIL LISTING",
        created_time: {
            seconds: 1653388104,
            nanoseconds: 608000000
        },
        created: {
            seconds: 1653388104,
            nanoseconds: 609000000
        },
        field_data: [
            {
                name: "full_name",
                values: ["邓锦铉\n"]
            },
            {
                name: "phone_number",
                values: [60165938325]
            },
            {
                values: [null],
                name: "email"
            }
        ],
        admins: ["alanlaiproperty88@gmail.com", "rehmance39b@gmail.com"],
        actionedIds: ["alan_yap1121@hotmail.com"],
        campaignName: "",
        lastAction: {
            remark: "",
            apptm: null,
            action: "Research",
            files: null,
            created: {
                seconds: 1653390677,
                nanoseconds: 65000000
            },
            user: "alan_yap1121@hotmail.com"
        },
        ownerEmail: "alanlaiproperty88@gmail.com",
        pageName: "Alan Lai\n",
        warriors: ["alan_yap1121@hotmail.com"],
        page_id: "UhUfzbEdf16LS5XCRfCO",
        id: "096c2oqj87aUDQybTngU",
        path: "leads/096c2oqj87aUDQybTngU"
    },
    {
        campaignName: "",
        page_id: "FsW77M3XQxU8p8ePFiAw",
        warriors: ["alex07359@gmail.com", "imjunsu0420@gmail.com", "alextai0517@gmail.com"],
        created_time: {
            seconds: 1633710834,
            nanoseconds: 393000000
        },
        pageName: "summer whatsapp lead",
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        labels: [
            {
                fontColor: "#ffffff",
                userEmail: "alex07359@gmail.com",
                label: "Spam",
                color: "#353232"
            },
            {
                color: "#5cfaff",
                label: "N.P.U",
                fontColor: "#000000",
                userEmail: "imjunsu0420@gmail.com"
            },
            {
                fontColor: "#000000",
                userEmail: "imjunsu0420@gmail.com",
                label: "Whatsapp Blast",
                color: "#5cfaff"
            }
        ],
        assignments: [
            {
                assign: {
                    email: "alex07359@gmail.com",
                    name: "dylan  chong"
                },
                date: {
                    seconds: 1633777628,
                    nanoseconds: 158000000
                },
                assignBy: "kohpohfen@gmail.com"
            },
            {
                assign: {
                    email: "imjunsu0420@gmail.com",
                    name: "imjunsu0420"
                },
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1633947451,
                    nanoseconds: 559000000
                }
            },
            {
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1637310059,
                    nanoseconds: 789000000
                },
                assign: {
                    name: "TAI WAI LOON",
                    email: "alextai0517@gmail.com"
                }
            }
        ],
        remark: "",
        field_data: [
            {
                values: ["陈伟康"],
                name: "full_name"
            },
            {
                values: ["01155100675"],
                name: "phone_number"
            },
            {
                values: [""],
                name: "email"
            }
        ],
        lastAction: {
            remark: "",
            user: "alextai0517@gmail.com",
            apptm: null,
            action: "Whatsapped",
            created: {
                seconds: 1637488043,
                nanoseconds: 79000000
            },
            files: null
        },
        actionedIds: ["alex07359@gmail.com", "imjunsu0420@gmail.com", "alextai0517@gmail.com"],
        created: {
            seconds: 1633710834,
            nanoseconds: 393000000
        },
        source: "",
        ownerEmail: "kohpohfen@gmail.com",
        id: "098v71zKoRKj48t1eYAE",
        path: "leads/098v71zKoRKj48t1eYAE"
    },
    {
        field_data: [
            {
                name: "full_name",
                values: ["NavinNaidu"]
            },
            {
                values: [60123078921],
                name: "phone_number"
            },
            {
                name: "email",
                values: [null]
            }
        ],
        created_time: {
            seconds: 1632331962,
            nanoseconds: 44000000
        },
        actionedIds: ["vernicelee2621@gmail.com"],
        lastAction: {
            files: null,
            created: {
                seconds: 1632985450,
                nanoseconds: 415000000
            },
            remark: "",
            user: "vernicelee2621@gmail.com",
            apptm: null,
            action: "Whatsapped"
        },
        pageName: "summer whatsapp lead",
        warriors: ["vernicelee2621@gmail.com"],
        page_id: "FsW77M3XQxU8p8ePFiAw",
        ownerEmail: "kohpohfen@gmail.com",
        source: "ads",
        remark: null,
        campaignName: "",
        assignments: [
            {
                date: {
                    seconds: 1632660755,
                    nanoseconds: 143000000
                },
                assign: {
                    email: "vernicelee2621@gmail.com",
                    name: "Vernice Lee"
                },
                assignBy: "kohpohfen@gmail.com"
            }
        ],
        created: {
            seconds: 1632331962,
            nanoseconds: 44000000
        },
        labels: [
            {
                label: "Follow up",
                color: "#3b93ff",
                userEmail: "vernicelee2621@gmail.com",
                fontColor: "#ffffff"
            }
        ],
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        id: "09Vo2bqGS8Gs4qbr4oD1",
        path: "leads/09Vo2bqGS8Gs4qbr4oD1"
    },
    {
        remark: null,
        page_id: "BDa0Cv6SE1hGqs6fdvrM",
        pageName: "Manual upload",
        campaignName: "Novum",
        warriors: ["alvin.careline@gmail.com"],
        source: "Novum",
        ownerEmail: "waynelim.property@gmail.com",
        created: {
            seconds: 1647236256,
            nanoseconds: 651000000
        },
        created_time: {
            seconds: 1647236256,
            nanoseconds: 651000000
        },
        field_data: [
            {
                name: "full_name",
                values: ["Jennifer Lee"]
            },
            {
                name: "phone_number",
                values: ["p:+60129333082"]
            },
            {
                name: "email",
                values: ["vuntinglee@hotmail.com"]
            }
        ],
        admins: ["waynelim.property@gmail.com", "rehmance39b@gmail.com"],
        assignments: [
            {
                assignBy: "waynelim.property@gmail.com",
                assign: {
                    name: "Alvin Mah",
                    email: "alvin.careline@gmail.com"
                },
                date: {
                    seconds: 1647236275,
                    nanoseconds: 238000000
                }
            }
        ],
        id: "09YLKpiJmgKtHH2Nln5I",
        path: "leads/09YLKpiJmgKtHH2Nln5I"
    },
    {
        remark: "",
        pageName: "Start-Future",
        page_id: "zCfJ22Y0WfYk2vECChSZ",
        created: {
            seconds: 1636509192,
            nanoseconds: 784000000
        },
        campaignName: "",
        lastAction: {
            created: {
                seconds: 1636618448,
                nanoseconds: 38000000
            },
            remark: "",
            user: "imjunsu0420@gmail.com",
            action: "Called",
            apptm: null,
            files: null
        },
        field_data: [
            {
                values: ["Goodwin"],
                name: "full_name"
            },
            {
                values: ["01115086778"],
                name: "phone_number"
            },
            {
                name: "email",
                values: [""]
            }
        ],
        admins: ["imjunsu0420@gmail.com", "rehmance39b@gmail.com"],
        created_time: {
            seconds: 1636509192,
            nanoseconds: 784000000
        },
        actionedIds: ["imjunsu0420@gmail.com"],
        labels: [
            {
                color: "#5cfaff",
                userEmail: "imjunsu0420@gmail.com",
                fontColor: "#000000",
                label: "N.P.U"
            }
        ],
        source: "",
        ownerEmail: "imjunsu0420@gmail.com",
        id: "09bNlIYB6ISW6hs45FL4",
        path: "leads/09bNlIYB6ISW6hs45FL4"
    },
    {
        field_data: [
            {
                name: "full_name",
                values: ["Sam"]
            },
            {
                name: "phone_number",
                values: ["p:0125185660"]
            },
            {
                name: "email",
                values: ["tswong5828@gmail.com"]
            }
        ],
        actionedIds: ["kaiyegolf@gmail.com"],
        page_id: "1496113477304516",
        created_time: {
            seconds: 1646381529,
            nanoseconds: 621000000
        },
        assignments: [
            {
                assign: {
                    email: "kaiyegolf@gmail.com",
                    name: "James  On"
                },
                date: {
                    seconds: 1646381619,
                    nanoseconds: 485000000
                },
                assignBy: "waynelim.property@gmail.com"
            }
        ],
        remark: null,
        campaignName: "Novum",
        source: "Novum leads",
        created: {
            seconds: 1646381529,
            nanoseconds: 621000000
        },
        admins: ["waynelim.property@gmail.com", "rehmance39b@gmail.com"],
        ownerEmail: "waynelim.property@gmail.com",
        pageName: "MY Property 360",
        warriors: ["kaiyegolf@gmail.com"],
        labels: [
            {
                userEmail: "kaiyegolf@gmail.com",
                color: "#3A0000",
                fontColor: "#ffffff",
                label: "No potential"
            }
        ],
        lastAction: {
            remark: "",
            user: "kaiyegolf@gmail.com",
            created: {
                seconds: 1646454343,
                nanoseconds: 193000000
            },
            files: null,
            action: "Research",
            apptm: null
        },
        id: "09j9CQUkt2XmLrXNkwGp",
        path: "leads/09j9CQUkt2XmLrXNkwGp"
    },
    {
        campaignName: "",
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        warriors: ["alanlaiproperty88@gmail.com", "sookmun770@gmail.com", "munhong0909@gmail.com"],
        ownerEmail: "kohpohfen@gmail.com",
        assignments: [
            {
                assign: {
                    email: "alanlaiproperty88@gmail.com",
                    name: "Alan  Lai"
                },
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1664874603,
                    nanoseconds: 961000000
                }
            },
            {
                date: {
                    seconds: 1665230007,
                    nanoseconds: 707000000
                },
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    name: "Smun Lee",
                    email: "sookmun770@gmail.com"
                }
            },
            {
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    name: "Junsu Mok",
                    email: "imjunsu0420@gmail.com"
                },
                date: {
                    seconds: 1665584554,
                    nanoseconds: 91000000
                }
            },
            {
                date: {
                    seconds: 1666874267,
                    nanoseconds: 287000000
                },
                assign: {
                    name: "Mun Hong Yip",
                    email: "munhong0909@gmail.com"
                },
                assignBy: "kohpohfen@gmail.com"
            }
        ],
        remark: "",
        source: "",
        pageName: "summer whatsapp lead",
        lastAction: {
            apptm: null,
            created: {
                seconds: 1665212668,
                nanoseconds: 103000000
            },
            action: "Called",
            user: "alanlaiproperty88@gmail.com",
            remark: "",
            files: null
        },
        page_id: "FsW77M3XQxU8p8ePFiAw",
        actionedIds: ["kohpohfen@gmail.com", "alanlaiproperty88@gmail.com"],
        created_time: {
            seconds: 1664606063,
            nanoseconds: 825000000
        },
        field_data: [
            {
                values: ["Sharon See ( repeat)"],
                name: "full_name"
            },
            {
                values: ["+60 16-380 8549"],
                name: "phone_number"
            },
            {
                name: "email",
                values: [""]
            }
        ],
        created: {
            seconds: 1664606063,
            nanoseconds: 826000000
        },
        id: "0AOmygm7XrQNkFNNznHb",
        path: "leads/0AOmygm7XrQNkFNNznHb"
    },
    {
        campaignName: "",
        created_time: {
            seconds: 1673276918,
            nanoseconds: 204000000
        },
        page_id: "n1vxEdHMrUuund3hbyhM",
        created: {
            seconds: 1673276918,
            nanoseconds: 205000000
        },
        lastAction: {
            remark: "",
            userName: "Ting Seong Shew",
            created: {
                seconds: 1679564086,
                nanoseconds: 611000000
            },
            user: "seong31993199@gmail.com",
            files: null,
            action: "Whatsapped",
            leadId: "0B1DtPH3DERdCOcjTY27",
            apptm: null
        },
        remark: "",
        ownerEmail: "kohpohfen@gmail.com",
        actionedIds: ["kohpohfen@gmail.com", "jonathanchin0614@gmail.com", "seong31993199@gmail.com"],
        source: "",
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        field_data: [
            {
                name: "full_name",
                values: ["Lim"]
            },
            {
                name: "phone_number",
                values: ["+60 12-658 1283"]
            },
            {
                name: "email",
                values: [""]
            }
        ],
        labels: [
            {
                fontColor: "#000000",
                userEmail: "kohpohfen@gmail.com",
                color: "#fef23a",
                label: "Appointment"
            }
        ],
        assignments: [
            {
                assign: {
                    name: "henry lee",
                    email: "henryleeproperty12@gmail.com"
                },
                date: {
                    seconds: 1677126733,
                    nanoseconds: 308000000
                },
                assignBy: "kohpohfen@gmail.com"
            },
            {
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    name: "henry lee",
                    email: "henryleeproperty12@gmail.com"
                },
                date: {
                    seconds: 1677126819,
                    nanoseconds: 699000000
                }
            },
            {
                assign: {
                    name: "Smun Lee",
                    email: "sookmun770@gmail.com"
                },
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1677407347,
                    nanoseconds: 151000000
                }
            },
            {
                assign: {
                    email: "jonathanchin0614@gmail.com",
                    name: "Jonathan Chin"
                },
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1678457455,
                    nanoseconds: 114000000
                }
            },
            {
                assign: {
                    email: "seong31993199@gmail.com",
                    name: "Ting Seong Shew"
                },
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1679293951,
                    nanoseconds: 271000000
                }
            }
        ],
        pageName: "Fiddlewoodz",
        warriors: [
            "henryleeproperty12@gmail.com",
            "sookmun770@gmail.com",
            "jonathanchin0614@gmail.com",
            "seong31993199@gmail.com"
        ],
        id: "0B1DtPH3DERdCOcjTY27",
        path: "leads/0B1DtPH3DERdCOcjTY27"
    },
    {
        assignments: [
            {
                date: {
                    seconds: 1650353147,
                    nanoseconds: 496000000
                },
                assign: {
                    email: "munhong0909@gmail.com",
                    name: "Mun Hong Yip"
                },
                assignBy: "kohpohfen@gmail.com"
            }
        ],
        pageName: "Summer Koh 房产甜心",
        field_data: [
            {
                name: "full_name",
                values: ["Gunasekar Murugaiah"]
            },
            {
                values: ["+60192813493"],
                name: "phone_number"
            },
            {
                values: ["lens00700@yahoo.com"],
                name: "email"
            }
        ],
        warriors: ["munhong0909@gmail.com"],
        created: {
            seconds: 1642241004,
            nanoseconds: 468000000
        },
        remark: null,
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        ownerEmail: "kohpohfen@gmail.com",
        page_id: "425840764829395",
        created_time: {
            seconds: 1642241004,
            nanoseconds: 468000000
        },
        campaignName: "",
        source: "ads lead gen",
        id: "0BP4tHhKLkHEzHJ3EB2u",
        path: "leads/0BP4tHhKLkHEzHJ3EB2u"
    },
    {
        created: {
            seconds: 1649233834,
            nanoseconds: 112000000
        },
        assignments: [
            {
                date: {
                    seconds: 1649562571,
                    nanoseconds: 285000000
                },
                assign: {
                    name: "Alan Yap",
                    email: "alan_yap1121@hotmail.com"
                },
                assignBy: "kohpohfen@gmail.com"
            },
            {
                date: {
                    seconds: 1649762674,
                    nanoseconds: 145000000
                },
                assign: {
                    name: "Yen Shen Tan",
                    email: "shanetanrealestate@gmail.com"
                },
                assignBy: "kohpohfen@gmail.com"
            },
            {
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1651822326,
                    nanoseconds: 776000000
                },
                assign: {
                    name: "eunicemin0729",
                    email: "eunicemin0729@gmail.com"
                }
            },
            {
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1651830089,
                    nanoseconds: 520000000
                },
                assign: {
                    email: "alexchia320@gmail.com",
                    name: "Alexander Chia"
                }
            },
            {
                date: {
                    seconds: 1659951883,
                    nanoseconds: 261000000
                },
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    name: "Chelsea Lew",
                    email: "caixuanlew@outlook.com"
                }
            },
            {
                date: {
                    seconds: 1660190142,
                    nanoseconds: 616000000
                },
                assign: {
                    name: "Jimmy ong",
                    email: "mongny@hotmail.com"
                },
                assignBy: "kohpohfen@gmail.com"
            },
            {
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1663642925,
                    nanoseconds: 405000000
                },
                assign: {
                    name: "desmondchongproperty21",
                    email: "desmondchongproperty21@gmail.com"
                }
            }
        ],
        page_id: "FsW77M3XQxU8p8ePFiAw",
        lastAction: {
            remark: "",
            files: null,
            action: "Called",
            apptm: null,
            created: {
                seconds: 1663850612,
                nanoseconds: 810000000
            },
            user: "desmondchongproperty21@gmail.com"
        },
        field_data: [
            {
                name: "full_name",
                values: ["Karin"]
            },
            {
                name: "phone_number",
                values: ["+60 14-663 1136"]
            },
            {
                name: "email",
                values: [""]
            }
        ],
        warriors: [
            "alan_yap1121@hotmail.com",
            "shanetanrealestate@gmail.com",
            "eunicemin0729@gmail.com",
            "alexchia320@gmail.com",
            "caixuanlew@outlook.com",
            "mongny@hotmail.com",
            "desmondchongproperty21@gmail.com"
        ],
        labels: [
            {
                userEmail: "alan_yap1121@hotmail.com",
                label: "Potential",
                fontColor: "#ffffff",
                color: "#d5a65d"
            }
        ],
        remark: "",
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        actionedIds: [
            "kohpohfen@gmail.com",
            "alan_yap1121@hotmail.com",
            "eunicemin0729@gmail.com",
            "alexchia320@gmail.com",
            "mongny@hotmail.com",
            "caixuanlew@outlook.com",
            "desmondchongproperty21@gmail.com"
        ],
        pageName: "summer whatsapp lead",
        campaignName: "",
        created_time: {
            seconds: 1649233834,
            nanoseconds: 112000000
        },
        source: "",
        ownerEmail: "kohpohfen@gmail.com",
        id: "0ByLfnQQaxB6P9yQenBd",
        path: "leads/0ByLfnQQaxB6P9yQenBd"
    },
    {
        actionedIds: [
            "kohpohfen@gmail.com",
            "wilsontan00@gmail.com",
            "vinzchau00@gmail.com",
            "seong31993199@gmail.com",
            "jonathanchin0614@gmail.com"
        ],
        pageName: "Fiddlewoodz",
        ownerEmail: "kohpohfen@gmail.com",
        created: {
            seconds: 1670150317,
            nanoseconds: 282000000
        },
        labels: [
            {
                fontColor: "#000000",
                label: "Appointment",
                color: "#fef23a",
                userEmail: "kohpohfen@gmail.com"
            }
        ],
        lastAction: {
            remark: "",
            leadId: "0CHVMg9roNBOGqTW2AcM",
            userName: "Ting Seong Shew",
            user: "seong31993199@gmail.com",
            created: {
                seconds: 1680324548,
                nanoseconds: 177000000
            },
            apptm: null,
            files: null,
            action: "Called"
        },
        remark: "Fiddlewoodz and klcc",
        appointments: [
            {
                user: "kohpohfen@gmail.com",
                time: "21:00",
                venue: " Zoom",
                date: {
                    seconds: 1671321600,
                    nanoseconds: 0
                }
            }
        ],
        campaignName: "",
        assignments: [
            {
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    name: "Wilson Tan",
                    email: "wilsontan00@gmail.com"
                },
                date: {
                    seconds: 1670228347,
                    nanoseconds: 798000000
                }
            },
            {
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    name: "vinzchau00",
                    email: "vinzchau00@gmail.com"
                },
                date: {
                    seconds: 1670482953,
                    nanoseconds: 812000000
                }
            },
            {
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    email: "fedinandiqi@gmail.com",
                    name: "Ferdinand  Poon"
                },
                date: {
                    seconds: 1675168679,
                    nanoseconds: 276000000
                }
            },
            {
                date: {
                    seconds: 1675688283,
                    nanoseconds: 589000000
                },
                assign: {
                    email: "janecwy11@gmail.com",
                    name: "Jane Chan"
                },
                assignBy: "kohpohfen@gmail.com"
            },
            {
                date: {
                    seconds: 1678457517,
                    nanoseconds: 167000000
                },
                assign: {
                    email: "jonathanchin0614@gmail.com",
                    name: "Jonathan Chin"
                },
                assignBy: "kohpohfen@gmail.com"
            },
            {
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    name: "Ting Seong Shew",
                    email: "seong31993199@gmail.com"
                },
                date: {
                    seconds: 1679294061,
                    nanoseconds: 879000000
                }
            }
        ],
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        field_data: [
            {
                values: ["Alan"],
                name: "full_name"
            },
            {
                values: ["+60 16-988 7805"],
                name: "phone_number"
            },
            {
                values: [""],
                name: "email"
            }
        ],
        page_id: "n1vxEdHMrUuund3hbyhM",
        source: "",
        warriors: [
            "fedinandiqi@gmail.com",
            "janecwy11@gmail.com",
            "jonathanchin0614@gmail.com",
            "seong31993199@gmail.com"
        ],
        created_time: {
            seconds: 1670150317,
            nanoseconds: 282000000
        },
        id: "0CHVMg9roNBOGqTW2AcM",
        path: "leads/0CHVMg9roNBOGqTW2AcM"
    },
    {
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        labels: [
            {
                color: "#7b7b7b",
                fontColor: "#ffffff",
                userEmail: "vernicelee2621@gmail.com",
                label: "Pass Back"
            },
            {
                label: "No potential",
                color: "#3A0000",
                userEmail: "kaiyegolf@gmail.com",
                fontColor: "#ffffff"
            },
            {
                userEmail: "irychang20@gmail.com",
                fontColor: "#ffffff",
                label: "Pass Back",
                color: "#7b7b7b"
            }
        ],
        lastAction: {
            created: {
                seconds: 1635426970,
                nanoseconds: 378000000
            },
            id: "P1EghgjV8dCdtj8AGAKz",
            files: null,
            remark: "no ans",
            action: "Called",
            apptm: null,
            user: "hanst9803@gmail.com"
        },
        campaignName: "",
        created_time: {
            seconds: 1634256000,
            nanoseconds: 0
        },
        assignments: [
            {
                assign: {
                    email: "kaiyegolf@gmail.com",
                    name: "James  On"
                },
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1634356547,
                    nanoseconds: 881000000
                }
            },
            {
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    name: "Vernice Lee",
                    email: "vernicelee2621@gmail.com"
                },
                date: {
                    seconds: 1634443213,
                    nanoseconds: 432000000
                }
            },
            {
                assign: {
                    name: "ST HAN",
                    email: "hanst9803@gmail.com"
                },
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1635252208,
                    nanoseconds: 333000000
                }
            },
            {
                assign: {
                    name: "iry chang",
                    email: "irychang20@gmail.com"
                },
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1635252299,
                    nanoseconds: 426000000
                }
            },
            {
                assign: {
                    name: "Gina  Lim",
                    email: "jgandco18@gmail.com"
                },
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1641568409,
                    nanoseconds: 242000000
                }
            }
        ],
        source: "",
        remark: "",
        page_id: "FsW77M3XQxU8p8ePFiAw",
        pageName: "summer whatsapp lead",
        actionedIds: ["P1EghgjV8dCdtj8AGAKz", "0kLN3WZadSrZtUmkgw4c", "HxjEA9yxDatzMla47Jsk", "uqvX1YqLB5PFtOLX9KLw"],
        created: {
            seconds: 1634353472,
            nanoseconds: 706000000
        },
        ownerEmail: "kohpohfen@gmail.com",
        field_data: [
            {
                name: "full_name",
                values: ["Song Jian Hoe"]
            },
            {
                values: ["+60 16-201 3118"],
                name: "phone_number"
            },
            {
                name: "email",
                values: [""]
            }
        ],
        warriors: [
            "kaiyegolf@gmail.com",
            "vernicelee2621@gmail.com",
            "hanst9803@gmail.com",
            "irychang20@gmail.com",
            "jgandco18@gmail.com"
        ],
        id: "0CMadHzJEE9zPFBHaD0O",
        path: "leads/0CMadHzJEE9zPFBHaD0O"
    },
    {
        created_time: {
            seconds: 1667976981,
            nanoseconds: 783000000
        },
        deleted: true,
        source: "Bryant Investor Lead",
        page_id: "esBtNT78EsHNushVYUdz",
        field_data: [
            {
                name: "full_name",
                values: ["Nicole Nicole"]
            },
            {
                values: [60167258019],
                name: "phone_number"
            },
            {
                values: [null],
                name: "email"
            }
        ],
        campaignName: "Bryant Investor Campaign",
        admins: ["teeseechen@hotmail.com", "rehmance39b@gmail.com"],
        created: {
            seconds: 1667976981,
            nanoseconds: 783000000
        },
        pageName: "下金蛋的公鸡",
        remark: null,
        ownerEmail: "teeseechen@hotmail.com",
        id: "0D0MJH2SzpzypTY0kwzw",
        path: "leads/0D0MJH2SzpzypTY0kwzw"
    },
    {
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        actionedIds: ["kaycee.eliteone@gmail.com", "kohpohfen@gmail.com"],
        created: {
            seconds: 1664029264,
            nanoseconds: 605000000
        },
        remark: "",
        lastAction: {
            created: {
                seconds: 1668328779,
                nanoseconds: 731000000
            },
            user: "kohpohfen@gmail.com",
            files: null,
            remark: "",
            apptm: null,
            action: "Research"
        },
        pageName: "summer whatsapp lead",
        deleted: true,
        source: "",
        ownerEmail: "kohpohfen@gmail.com",
        assignments: [
            {
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1664256921,
                    nanoseconds: 330000000
                },
                assign: {
                    email: "mongny@hotmail.com",
                    name: "Jimmy ong"
                }
            },
            {
                assign: {
                    email: "desmondchongproperty21@gmail.com",
                    name: "desmondchongproperty21"
                },
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1664444296,
                    nanoseconds: 526000000
                }
            },
            {
                date: {
                    seconds: 1664608901,
                    nanoseconds: 752000000
                },
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    name: "G J",
                    email: "junntproperties@gmail.com"
                }
            },
            {
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    name: "Smun Lee",
                    email: "sookmun770@gmail.com"
                },
                date: {
                    seconds: 1664622236,
                    nanoseconds: 404000000
                }
            },
            {
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1665653085,
                    nanoseconds: 20000000
                },
                assign: {
                    email: "kaycee.eliteone@gmail.com",
                    name: "Kaycee Chong"
                }
            }
        ],
        field_data: [
            {
                values: ["VIP"],
                name: "full_name"
            },
            {
                name: "phone_number",
                values: ["+60 11-2128 8879"]
            },
            {
                values: [""],
                name: "email"
            }
        ],
        campaignName: "",
        created_time: {
            seconds: 1664029264,
            nanoseconds: 604000000
        },
        page_id: "FsW77M3XQxU8p8ePFiAw",
        warriors: [
            "mongny@hotmail.com",
            "desmondchongproperty21@gmail.com",
            "junntproperties@gmail.com",
            "sookmun770@gmail.com",
            "kaycee.eliteone@gmail.com"
        ],
        id: "0DYrJmmLmvehup5ir6Zf",
        path: "leads/0DYrJmmLmvehup5ir6Zf"
    },
    {
        ownerEmail: "kohpohfen@gmail.com",
        source: "",
        lastAction: {
            leadId: "0DfAJC57aHB7xQcGS8Sy",
            userName: "Poh fen Koh",
            remark: "",
            apptm: null,
            action: "Research",
            created: {
                seconds: 1683360687,
                nanoseconds: 640000000
            },
            files: null,
            user: "kohpohfen@gmail.com"
        },
        labels: [
            {
                label: "ppotential",
                userEmail: "kohpohfen@gmail.com",
                fontColor: "#000000",
                color: "#5cfaff"
            }
        ],
        actionedIds: [
            "jonathanchin0614@gmail.com",
            "henryleeproperty12@gmail.com",
            "seong31993199@gmail.com",
            "kellywongyikei@gmail.com",
            "kohpohfen@gmail.com"
        ],
        remark: "D ivo, dmsr, bukit jalil",
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        created_time: {
            seconds: 1675740707,
            nanoseconds: 538000000
        },
        field_data: [
            {
                values: ["JW"],
                name: "full_name"
            },
            {
                values: ["+60 13-716 1223"],
                name: "phone_number"
            },
            {
                name: "email",
                values: [""]
            }
        ],
        pageName: "summer whatsapp lead",
        assignments: [
            {
                date: {
                    seconds: 1675768455,
                    nanoseconds: 477000000
                },
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    name: "Gene Lee",
                    email: "gnelol8895@gmail.com"
                }
            },
            {
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    name: "Jane Chan",
                    email: "janecwy11@gmail.com"
                },
                date: {
                    seconds: 1676031919,
                    nanoseconds: 831000000
                }
            },
            {
                date: {
                    seconds: 1676398104,
                    nanoseconds: 274000000
                },
                assign: {
                    email: "jonathanchin0614@gmail.com",
                    name: "Jonathan Chin"
                },
                assignBy: "kohpohfen@gmail.com"
            },
            {
                date: {
                    seconds: 1677054042,
                    nanoseconds: 491000000
                },
                assign: {
                    email: "henryleeproperty12@gmail.com",
                    name: "henry lee"
                },
                assignBy: "kohpohfen@gmail.com"
            },
            {
                assign: {
                    name: "Ting Seong Shew",
                    email: "seong31993199@gmail.com"
                },
                date: {
                    seconds: 1678868410,
                    nanoseconds: 744000000
                },
                assignBy: "kohpohfen@gmail.com"
            },
            {
                date: {
                    seconds: 1680410537,
                    nanoseconds: 927000000
                },
                assign: {
                    name: "kellywongyikei",
                    email: "kellywongyikei@gmail.com"
                },
                assignBy: "kohpohfen@gmail.com"
            }
        ],
        campaignName: "",
        page_id: "FsW77M3XQxU8p8ePFiAw",
        warriors: [
            "janecwy11@gmail.com",
            "jonathanchin0614@gmail.com",
            "henryleeproperty12@gmail.com",
            "seong31993199@gmail.com",
            "kellywongyikei@gmail.com"
        ],
        created: {
            seconds: 1675740707,
            nanoseconds: 539000000
        },
        id: "0DfAJC57aHB7xQcGS8Sy",
        path: "leads/0DfAJC57aHB7xQcGS8Sy"
    },
    {
        created: {
            seconds: 1636974334,
            nanoseconds: 798000000
        },
        pageName: "summer whatsapp lead",
        campaignName: "",
        ownerEmail: "kohpohfen@gmail.com",
        created_time: {
            seconds: 1636974334,
            nanoseconds: 798000000
        },
        field_data: [
            {
                values: ["Kelvin Chin"],
                name: "full_name"
            },
            {
                values: ["+60 17-332 2629"],
                name: "phone_number"
            },
            {
                values: [""],
                name: "email"
            }
        ],
        deleted: true,
        source: "",
        page_id: "FsW77M3XQxU8p8ePFiAw",
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        remark: "",
        id: "0F10bu0K1uyZEtEdaDMr",
        path: "leads/0F10bu0K1uyZEtEdaDMr"
    },
    {
        email: null,
        phone: 60166080535,
        warriors: ["fedinandiqi@gmail.com"],
        admins: ["janecwy11@gmail.com", "rehmance39b@gmail.com"],
        name: "Tidus Lim",
        campaignName: "pj leads (Feb-Mar 2023) ",
        page_id: "ssqgLTQIArfNanStRoP1",
        id: "0FfrASEXi89X1Hrju7ZF",
        created: {
            seconds: 1678797675,
            nanoseconds: 78000000
        },
        actionedIds: ["fedinandiqi@gmail.com"],
        pageName: "jane chan",
        lastAction: {
            leadId: "0FfrASEXi89X1Hrju7ZF",
            files: null,
            action: "Called",
            apptm: null,
            remark: "",
            userName: "Ferdinand  Poon",
            created: {
                seconds: 1682411511,
                nanoseconds: 366000000
            },
            user: "fedinandiqi@gmail.com"
        },
        ownerEmail: "janecwy11@gmail.com",
        created_time: {
            seconds: 1678797675,
            nanoseconds: 78000000
        },
        field_data: [
            {
                name: "full_name",
                values: ["Tidus Lim"]
            },
            {
                values: [60166080535],
                name: "phone_number"
            },
            {
                name: "email",
                values: [null]
            }
        ],
        labels: [
            {
                fontColor: "white",
                userEmail: "fedinandiqi@gmail.com",
                name: "No Pick Up",
                label: "No Pick Up",
                color: "#2196f3"
            }
        ],
        assignments: [
            {
                assignBy: "janecwy11@gmail.com",
                assign: {
                    email: "fedinandiqi@gmail.com",
                    name: "Ferdinand  Poon"
                },
                date: {
                    seconds: 1678935997,
                    nanoseconds: 550000000
                }
            }
        ],
        source: "fb in ins ",
        remark: null,
        path: "leads/0FfrASEXi89X1Hrju7ZF"
    },
    {
        actionedIds: [
            "gB2eOqzx32fYQTBDc6TP",
            "yZ95aP94sp70qR1P7DTg",
            "WUgj0NYBUmjr7jImwa0M",
            "EqXfsLtEWXJIINz3iwxR",
            "Gwyqdkg3pY0FYyCkqkYC",
            "jeremytan.iqi@gmail.com",
            "huiyingang96@hotmail.com",
            "iqi.jimmyng@gmail.com",
            "xiiaosheng33096@gmail.com"
        ],
        labels: [
            {
                label: "Potential",
                fontColor: "#ffffff",
                color: "#d5a65d",
                userEmail: "huiyingang96@hotmail.com"
            },
            {
                label: "Pass Back",
                color: "#7b7b7b",
                fontColor: "#ffffff",
                userEmail: "iqi.jimmyng@gmail.com"
            },
            {
                userEmail: "xiiaosheng33096@gmail.com",
                label: "Details sent",
                color: "#47e2b8",
                fontColor: "#000000"
            },
            {
                label: "Pass Back",
                color: "#7b7b7b",
                fontColor: "#ffffff",
                userEmail: "jeremytan.iqi@gmail.com"
            }
        ],
        created_time: {
            seconds: 1663605074,
            nanoseconds: 363000000
        },
        warriors: [
            "huiyingang96@hotmail.com",
            "jeremytan.iqi@gmail.com",
            "iqi.jimmyng@gmail.com",
            "xiiaosheng33096@gmail.com"
        ],
        admins: ["limzhengying98@hotmail.com", "rehmance39b@gmail.com"],
        field_data: [
            {
                name: "full_name",
                values: ["TK\t"]
            },
            {
                values: ["60166719611\t"],
                name: "phone_number"
            },
            {
                name: "email",
                values: ["torresyong94@gmail.com"]
            }
        ],
        pageName: "Jessica.Prop",
        created: {
            seconds: 1663605074,
            nanoseconds: 363000000
        },
        source: "",
        remark: "",
        lastAction: {
            apptm: null,
            user: "jeremytan.iqi@gmail.com",
            remark: "",
            userName: "Jeremy Tan",
            created: {
                seconds: 1683624077,
                nanoseconds: 542000000
            },
            action: "Research",
            files: null,
            leadId: "0G7OpTMpt99Md0qZIvc8"
        },
        page_id: "Rql66McauGVhEqKAkUBs",
        ownerEmail: "limzhengying98@hotmail.com",
        campaignName: "CPD",
        assignments: [
            {
                assign: {
                    email: "charles951121@gmail.com",
                    name: "CY Yong"
                },
                date: {
                    seconds: 1663605562,
                    nanoseconds: 223000000
                },
                assignBy: "limzhengying98@hotmail.com"
            },
            {
                assign: {
                    name: "leona ang",
                    email: "huiyingang96@hotmail.com"
                },
                date: {
                    seconds: 1663608310,
                    nanoseconds: 796000000
                },
                assignBy: "limzhengying98@hotmail.com"
            },
            {
                assign: {
                    name: "Jeremy Tan",
                    email: "jeremytan.iqi@gmail.com"
                },
                date: {
                    seconds: 1663846954,
                    nanoseconds: 425000000
                },
                assignBy: "limzhengying98@hotmail.com"
            },
            {
                assignBy: "limzhengying98@hotmail.com",
                date: {
                    seconds: 1665655749,
                    nanoseconds: 421000000
                },
                assign: {
                    name: "Jimmy Ng ",
                    email: "iqi.jimmyng@gmail.com"
                }
            },
            {
                date: {
                    seconds: 1678971363,
                    nanoseconds: 909000000
                },
                assign: {
                    name: "xiiaosheng33096",
                    email: "xiiaosheng33096@gmail.com"
                },
                assignBy: "limzhengying98@hotmail.com"
            }
        ],
        id: "0G7OpTMpt99Md0qZIvc8",
        path: "leads/0G7OpTMpt99Md0qZIvc8"
    },
    {
        lastAction: {
            files: null,
            remark: "",
            created: {
                seconds: 1650350926,
                nanoseconds: 80000000
            },
            apptm: null,
            user: "kaiyegolf@gmail.com",
            action: "Research"
        },
        pageName: "MY Property 360",
        labels: [
            {
                fontColor: "#ffffff",
                userEmail: "edwingoh.kokchai@gmail.com",
                label: "Pass Back",
                color: "#7b7b7b"
            },
            {
                fontColor: "#ffffff",
                label: "No potential",
                userEmail: "kaiyegolf@gmail.com",
                color: "#3A0000"
            }
        ],
        ownerEmail: "waynelim.property@gmail.com",
        source: "Mossaz",
        page_id: "1496113477304516",
        warriors: ["edwingoh.kokchai@gmail.com", "kaiyegolf@gmail.com"],
        assignments: [
            {
                date: {
                    seconds: 1648459316,
                    nanoseconds: 82000000
                },
                assignBy: "waynelim.property@gmail.com",
                assign: {
                    email: "edwingoh.kokchai@gmail.com",
                    name: "Edwin Goh"
                }
            },
            {
                assignBy: "waynelim.property@gmail.com",
                assign: {
                    name: "James  On",
                    email: "kaiyegolf@gmail.com"
                },
                date: {
                    seconds: 1650206043,
                    nanoseconds: 316000000
                }
            }
        ],
        created: {
            seconds: 1648459308,
            nanoseconds: 293000000
        },
        created_time: {
            seconds: 1648459308,
            nanoseconds: 293000000
        },
        field_data: [
            {
                values: ["Irene Khoo"],
                name: "full_name"
            },
            {
                name: "phone_number",
                values: ["+60102363361"]
            },
            {
                name: "email",
                values: ["pinkmudgee@yahoo.com"]
            }
        ],
        campaignName: "Mossaz KL Sel",
        remark: "studio",
        actionedIds: ["edwingoh.kokchai@gmail.com", "kaiyegolf@gmail.com"],
        admins: ["waynelim.property@gmail.com", "rehmance39b@gmail.com"],
        id: "0G8HPqPhrumZccLUx540",
        path: "leads/0G8HPqPhrumZccLUx540"
    },
    {
        admins: ["janecwy11@gmail.com", "rehmance39b@gmail.com"],
        page_id: "ssqgLTQIArfNanStRoP1",
        created: {
            seconds: 1648115028,
            nanoseconds: 70000000
        },
        ownerEmail: "janecwy11@gmail.com",
        remark: null,
        created_time: {
            seconds: 1648115028,
            nanoseconds: 70000000
        },
        campaignName: "",
        field_data: [
            {
                name: "full_name",
                values: ["Jivan"]
            },
            {
                values: [60167455317],
                name: "phone_number"
            },
            {
                values: [null],
                name: "email"
            }
        ],
        pageName: "jane chan",
        source: "fb/ig",
        id: "0GDDCgCmyacTmY3q8fzM",
        path: "leads/0GDDCgCmyacTmY3q8fzM"
    },
    {
        lastAction: {
            action: "Called",
            apptm: null,
            files: null,
            user: "kaycee.eliteone@gmail.com",
            remark: "",
            created: {
                seconds: 1665983065,
                nanoseconds: 119000000
            }
        },
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        actionedIds: ["cindy_pang91@hotmail.com", "desmondchongproperty21@gmail.com", "kaycee.eliteone@gmail.com"],
        source: "",
        warriors: [
            "mongny@hotmail.com",
            "cindy_pang91@hotmail.com",
            "desmondchongproperty21@gmail.com",
            "sookmun770@gmail.com",
            "kaycee.eliteone@gmail.com"
        ],
        assignments: [
            {
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1664256921,
                    nanoseconds: 337000000
                },
                assign: {
                    email: "mongny@hotmail.com",
                    name: "Jimmy ong"
                }
            },
            {
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    email: "cindy_pang91@hotmail.com",
                    name: "Pang  Yy"
                },
                date: {
                    seconds: 1664366913,
                    nanoseconds: 912000000
                }
            },
            {
                assign: {
                    email: "desmondchongproperty21@gmail.com",
                    name: "desmondchongproperty21"
                },
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1664444296,
                    nanoseconds: 549000000
                }
            },
            {
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    name: "Smun Lee",
                    email: "sookmun770@gmail.com"
                },
                date: {
                    seconds: 1664622236,
                    nanoseconds: 424000000
                }
            },
            {
                date: {
                    seconds: 1665653085,
                    nanoseconds: 37000000
                },
                assign: {
                    email: "kaycee.eliteone@gmail.com",
                    name: "Kaycee Chong"
                },
                assignBy: "kohpohfen@gmail.com"
            }
        ],
        labels: [],
        created: {
            seconds: 1664007746,
            nanoseconds: 988000000
        },
        pageName: "summer whatsapp lead",
        remark: "",
        field_data: [
            {
                values: ["Koay"],
                name: "full_name"
            },
            {
                values: ["+60 12-392 3596"],
                name: "phone_number"
            },
            {
                name: "email",
                values: [""]
            }
        ],
        ownerEmail: "kohpohfen@gmail.com",
        page_id: "FsW77M3XQxU8p8ePFiAw",
        campaignName: "",
        created_time: {
            seconds: 1664007746,
            nanoseconds: 987000000
        },
        id: "0GrdJbNFdtlnXI2TwpbC",
        path: "leads/0GrdJbNFdtlnXI2TwpbC"
    },
    {
        lastAction: {
            remark: "",
            action: "Whatsapped",
            created: {
                seconds: 1668171734,
                nanoseconds: 470000000
            },
            apptm: null,
            files: null,
            user: "iqi.jimmyng@gmail.com"
        },
        labels: [
            {
                label: "Follow up",
                userEmail: "aya_raine@yahoo.com.au",
                color: "#3b93ff",
                fontColor: "#ffffff"
            },
            {
                label: "No Looking any more",
                fontColor: "#000000",
                color: "#5cfaff",
                userEmail: "bingjiee96@gmail.com"
            },
            {
                fontColor: "#ffffff",
                label: "Pass Back",
                color: "#7b7b7b",
                userEmail: "imjunsu0420@gmail.com"
            },
            {
                userEmail: "imjunsu0420@gmail.com",
                label: "Mont Kiara",
                color: "#5cfaff",
                fontColor: "#000000"
            },
            {
                fontColor: "#000000",
                label: "WhatsApp blasting ",
                color: "#5cfaff",
                userEmail: "iqi.jimmyng@gmail.com"
            }
        ],
        remark: "",
        admins: [
            "daveckw@gmail.com",
            "debbieleow.dl@gmail.com",
            "limzhengying98@hotmail.com",
            "myra@eliteone.com.my",
            "rehmance39b@gmail.com"
        ],
        ownerEmail: "limzhengying98@hotmail.com",
        actionedIds: [
            "AkuVhF8dryWkyQC6ZmEK",
            "OcB4Cc2QTZp31kkto6K3",
            "XfdBbLOWVTZxl6SbasKx",
            "Vn3SmTrt8AyMk8ljaAT0",
            "9s6ItZh8J50Qp1ImC4uv",
            "EZfmFRAfekGLVyuRYtz1",
            "1q2vVwJxXITsAaA8upLX",
            "stEwYiD3HqSvhla3cznC",
            "z0kahfSFDQ9AWQAYKEzb",
            "AwT1S20CwOCUvndLpp60",
            "n2SS9j0VEEYnGfntVTyG",
            "8bwONZatO2oPI1GqRp9P",
            "wWeV7G54y08CzckiYy8X",
            "CwB9A2hfA7DNTVwJPaUK",
            "8y4b7fNAqzmZCsYLg2jB",
            "iqi.jimmyng@gmail.com"
        ],
        created: {
            seconds: 1647829017,
            nanoseconds: 781000000
        },
        pageName: "马来西亚投资网 | Malaysia Investment Portal",
        assignments: [
            {
                date: {
                    seconds: 1647829435,
                    nanoseconds: 199000000
                },
                assign: {
                    email: "aya_raine@yahoo.com.au",
                    name: "Lorraine Lai"
                },
                assignBy: "limzhengying98@hotmail.com"
            },
            {
                assign: {
                    email: "williamffk@gmail.com",
                    name: "William Fam"
                },
                assignBy: "limzhengying98@hotmail.com",
                date: {
                    seconds: 1648701210,
                    nanoseconds: 523000000
                }
            },
            {
                assignBy: "limzhengying98@hotmail.com",
                date: {
                    seconds: 1648818219,
                    nanoseconds: 126000000
                },
                assign: {
                    email: "bingjiee96@gmail.com",
                    name: "Keith Yap"
                }
            },
            {
                assignBy: "limzhengying98@hotmail.com",
                date: {
                    seconds: 1649017357,
                    nanoseconds: 95000000
                },
                assign: {
                    email: "meijunkapo_1014@hotmail.com",
                    name: "MJ CHEAH"
                }
            },
            {
                assign: {
                    name: "CY Yong",
                    email: "charles951121@gmail.com"
                },
                assignBy: "limzhengying98@hotmail.com",
                date: {
                    seconds: 1649904067,
                    nanoseconds: 234000000
                }
            },
            {
                assignBy: "limzhengying98@hotmail.com",
                date: {
                    seconds: 1651330614,
                    nanoseconds: 571000000
                },
                assign: {
                    name: "Junsu Mok",
                    email: "imjunsu0420@gmail.com"
                }
            },
            {
                date: {
                    seconds: 1660311971,
                    nanoseconds: 887000000
                },
                assign: {
                    email: "iqi.jimmyng@gmail.com",
                    name: "Jimmy Ng "
                },
                assignBy: "limzhengying98@hotmail.com"
            }
        ],
        source: "Fiddlewoodz",
        field_data: [
            {
                name: "full_name",
                values: ["Daphne Lau"]
            },
            {
                name: "phone_number",
                values: ["60123123981"]
            },
            {
                values: ["laushdaphne@yahoo.com"],
                name: "email"
            }
        ],
        campaignName: "",
        page_id: "295016180591184",
        warriors: [
            "aya_raine@yahoo.com.au",
            "williamffk@gmail.com",
            "bingjiee96@gmail.com",
            "meijunkapo_1014@hotmail.com",
            "charles951121@gmail.com",
            "imjunsu0420@gmail.com",
            "iqi.jimmyng@gmail.com"
        ],
        created_time: {
            seconds: 1647829017,
            nanoseconds: 781000000
        },
        id: "0Gwuab5g27f8XIJOeqV4",
        path: "leads/0Gwuab5g27f8XIJOeqV4"
    },
    {
        page_id: "ssqgLTQIArfNanStRoP1",
        remark: null,
        warriors: ["gnelol8895@gmail.com"],
        ownerEmail: "janecwy11@gmail.com",
        campaignName: "pj leads (Feb-Mar 2023) ",
        assignments: [
            {
                assignBy: "janecwy11@gmail.com",
                assign: {
                    email: "gnelol8895@gmail.com",
                    name: "Gene Lee"
                },
                date: {
                    seconds: 1679396133,
                    nanoseconds: 752000000
                }
            }
        ],
        field_data: [
            {
                values: ["Cherie Lee"],
                name: "full_name"
            },
            {
                values: [60122669817],
                name: "phone_number"
            },
            {
                name: "email",
                values: [null]
            }
        ],
        source: "fb in ins ",
        admins: ["janecwy11@gmail.com", "rehmance39b@gmail.com"],
        created_time: {
            seconds: 1678797675,
            nanoseconds: 79000000
        },
        created: {
            seconds: 1678797675,
            nanoseconds: 79000000
        },
        pageName: "jane chan",
        id: "0H1k6vz0oWhCkzGDY1ga",
        path: "leads/0H1k6vz0oWhCkzGDY1ga"
    },
    {
        source: "PJ Airbnb Whatsapp Lead",
        campaignName: "",
        lastAction: {
            leadId: "0HGcFk7VXpDtg4hB5q1W",
            remark: "",
            created: {
                seconds: 1677652190,
                nanoseconds: 295000000
            },
            user: "xiiaosheng33096@gmail.com",
            action: "Called",
            apptm: null,
            userName: "foo fangsheng",
            files: null
        },
        created: {
            seconds: 1675900525,
            nanoseconds: 760000000
        },
        assignments: [
            {
                assign: {
                    email: "xiiaosheng33096@gmail.com",
                    name: "xiiaosheng33096"
                },
                date: {
                    seconds: 1677053311,
                    nanoseconds: 416000000
                },
                assignBy: "limzhengying98@hotmail.com"
            },
            {
                date: {
                    seconds: 1679376359,
                    nanoseconds: 618000000
                },
                assignBy: "limzhengying98@hotmail.com",
                assign: {
                    email: "sookmun770@gmail.com",
                    name: "Smun Lee"
                }
            }
        ],
        ownerEmail: "charles951121@gmail.com",
        pageName: "CML",
        page_id: "WFjWAGvhg9lf6FyQwFKz",
        remark: null,
        admins: ["charles951121@gmail.com", "limzhengying98@hotmail.com", "rehmance39b@gmail.com"],
        field_data: [
            {
                values: ["Carmen Hair Saloon"],
                name: "full_name"
            },
            {
                name: "phone_number",
                values: [60189118920]
            },
            {
                name: "email",
                values: [null]
            }
        ],
        created_time: {
            seconds: 1675900525,
            nanoseconds: 760000000
        },
        actionedIds: ["xiiaosheng33096@gmail.com"],
        warriors: ["xiiaosheng33096@gmail.com", "sookmun770@gmail.com"],
        id: "0HGcFk7VXpDtg4hB5q1W",
        path: "leads/0HGcFk7VXpDtg4hB5q1W"
    },
    {
        lastAction: {
            apptm: null,
            files: null,
            created: {
                seconds: 1660117549,
                nanoseconds: 325000000
            },
            user: "cindy_pang91@hotmail.com",
            remark: "",
            action: "Whatsapped"
        },
        field_data: [
            {
                values: ["PANG YUK MING"],
                name: "full_name"
            },
            {
                values: ["60 17-810 1166"],
                name: "phone_number"
            },
            {
                values: [null],
                name: "email"
            }
        ],
        created_time: {
            seconds: 1625685074,
            nanoseconds: 348000000
        },
        pageName: "summer whatsapp lead",
        source: "whatsapp ",
        campaignName: "",
        labels: [
            {
                fontColor: "#ffffff",
                color: "#3b93ff",
                userEmail: "vernicelee2621@gmail.com",
                label: "Follow up"
            },
            {
                color: "#7b7b7b",
                fontColor: "#ffffff",
                userEmail: "christai8798@gmail.com",
                label: "Pass Back"
            },
            {
                color: "#3A0000",
                fontColor: "#ffffff",
                label: "No potential",
                userEmail: "cindy_pang91@hotmail.com"
            }
        ],
        actionedIds: ["vernicelee2621@gmail.com", "christai8798@gmail.com", "cindy_pang91@hotmail.com"],
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        remark: null,
        codeName: "dc2954",
        assignments: [
            {
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    email: "kohpohfen@gmail.com",
                    name: "Poh fen Koh"
                },
                date: {
                    seconds: 1626507280,
                    nanoseconds: 885000000
                }
            },
            {
                date: {
                    seconds: 1633002739,
                    nanoseconds: 622000000
                },
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    name: "Vernice Lee",
                    email: "vernicelee2621@gmail.com"
                }
            },
            {
                date: {
                    seconds: 1649761576,
                    nanoseconds: 380000000
                },
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    name: "Chris  Tai ",
                    email: "christai8798@gmail.com"
                }
            },
            {
                date: {
                    seconds: 1658901505,
                    nanoseconds: 509000000
                },
                assign: {
                    email: "cindy_pang91@hotmail.com",
                    name: "Pang  Yy"
                },
                assignBy: "kohpohfen@gmail.com"
            },
            {
                assign: {
                    email: "yukil960823@gmail.com",
                    name: "yukil960823"
                },
                date: {
                    seconds: 1682696095,
                    nanoseconds: 310000000
                },
                assignBy: "kohpohfen@gmail.com"
            }
        ],
        ownerEmail: "kohpohfen@gmail.com",
        created: {
            seconds: 1625685074,
            nanoseconds: 348000000
        },
        page_id: "FsW77M3XQxU8p8ePFiAw",
        warriors: [
            "kohpohfen@gmail.com",
            "vernicelee2621@gmail.com",
            "christai8798@gmail.com",
            "cindy_pang91@hotmail.com",
            "yukil960823@gmail.com"
        ],
        id: "0HKiEC28x1GUctMLR58S",
        path: "leads/0HKiEC28x1GUctMLR58S"
    },
    {
        assignments: [
            {
                date: {
                    seconds: 1626231579,
                    nanoseconds: 465000000
                },
                assign: {
                    name: "Elsa  Ng",
                    email: "ngsinyi@hotmail.com"
                },
                assignBy: "limzhengying98@hotmail.com"
            }
        ],
        admins: ["daveckw@gmail.com", "debbieleow.dl@gmail.com", "limzhengying98@hotmail.com", "rehmance39b@gmail.com"],
        source: "PJ Leads",
        warriors: ["msleow@gmail.com", "ngsinyi@hotmail.com"],
        ownerEmail: "limzhengying98@hotmail.com",
        field_data: [
            {
                values: ["May Er"],
                name: "full_name"
            },
            {
                name: "phone_number",
                values: ["60176981880"]
            },
            {
                values: [""],
                name: "email"
            }
        ],
        page_id: "295016180591184",
        lastAction: {
            remark: "",
            created: {
                seconds: 1626759092,
                nanoseconds: 193000000
            },
            apptm: null,
            user: "ngsinyi@hotmail.com",
            action: "Called",
            files: null
        },
        created_time: {
            seconds: 1624941893,
            nanoseconds: 848000000
        },
        labels: [
            {
                color: "#47e2b8",
                label: "Details sent",
                userEmail: "msleow@gmail.com",
                fontColor: "#000000"
            },
            {
                label: "Spam",
                fontColor: "#ffffff",
                color: "#353232",
                userEmail: "ngsinyi@hotmail.com"
            }
        ],
        created: {
            seconds: 1624941893,
            nanoseconds: 848000000
        },
        actionedIds: ["msleow@gmail.com", "ngsinyi@hotmail.com"],
        pageName: "马来西亚投资网 | Malaysia Investment Portal",
        id: "0HtZeXy19FNeHSBKh5EN",
        path: "leads/0HtZeXy19FNeHSBKh5EN"
    },
    {
        field_data: [
            {
                values: ["MR. ERIC WONG JIN-SUN"],
                name: "full_name"
            },
            {
                name: "phone_number",
                values: ["60122781822"]
            },
            {
                values: ["saint.savant@hotmail.com"],
                name: "email"
            }
        ],
        assignments: [
            {
                date: {
                    seconds: 1648868287,
                    nanoseconds: 989000000
                },
                assignBy: "daveckw@gmail.com",
                assign: {
                    email: "bingjiee96@gmail.com",
                    name: "Keith Yap"
                }
            }
        ],
        created: {
            seconds: 1627449803,
            nanoseconds: 205000000
        },
        page_id: "0bv3FGR5hBLGUHZwnyZu",
        pageName: "iProperty",
        source: "iProperty",
        campaignName: "iProperty",
        admins: ["daveckw@gmail.com", "rehmance39b@gmail.com"],
        remark: "1987-08-06",
        warriors: ["bingjiee96@gmail.com"],
        ownerEmail: "daveckw@gmail.com",
        created_time: {
            seconds: 1627449803,
            nanoseconds: 205000000
        },
        id: "0HxK9pZU1tBNqUVQzTs0",
        path: "leads/0HxK9pZU1tBNqUVQzTs0"
    },
    {
        remark: "16/2 blocked clash lead",
        campaignName: "12",
        created: {
            seconds: 1678686935,
            nanoseconds: 40000000
        },
        ownerEmail: "janecwy11@gmail.com",
        admins: ["janecwy11@gmail.com", "rehmance39b@gmail.com"],
        created_time: {
            seconds: 1678686935,
            nanoseconds: 40000000
        },
        pageName: "jane chan",
        actionedIds: ["henryleeproperty12@gmail.com"],
        field_data: [
            {
                values: ["沈佳辉,\r\nShen Jiahui"],
                name: "full_name"
            },
            {
                name: "phone_number",
                values: [60127219846]
            },
            {
                values: [null],
                name: "email"
            }
        ],
        source: "fb & ins",
        page_id: "ssqgLTQIArfNanStRoP1",
        warriors: ["henryleeproperty12@gmail.com"],
        assignments: [
            {
                assign: {
                    email: "imjunsu0420@gmail.com",
                    name: "Junsu Mok"
                },
                assignBy: "janecwy11@gmail.com",
                date: {
                    seconds: 1678687099,
                    nanoseconds: 522000000
                }
            },
            {
                assignBy: "janecwy11@gmail.com",
                date: {
                    seconds: 1678687393,
                    nanoseconds: 915000000
                },
                assign: {
                    email: "henryleeproperty12@gmail.com",
                    name: "henry lee"
                }
            }
        ],
        lastAction: {
            leadId: "0I1i3rbdkZ3oi2XpEk72",
            remark: "",
            apptm: null,
            userName: "henry lee",
            created: {
                seconds: 1679561953,
                nanoseconds: 703000000
            },
            action: "Called",
            user: "henryleeproperty12@gmail.com",
            files: null
        },
        id: "0I1i3rbdkZ3oi2XpEk72",
        path: "leads/0I1i3rbdkZ3oi2XpEk72"
    },
    {
        source: "",
        lastAction: {
            action: "Called",
            user: "aidenlim950310@gmail.com",
            leadId: "0Iaa2mftqB1zCcMz8SBb",
            remark: "",
            userName: "Aiden  Lim",
            created: {
                seconds: 1680159233,
                nanoseconds: 766000000
            },
            files: null,
            apptm: null
        },
        ownerEmail: "kohpohfen@gmail.com",
        page_id: "n1vxEdHMrUuund3hbyhM",
        remark: "",
        pageName: "Fiddlewoodz",
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        created_time: {
            seconds: 1677407936,
            nanoseconds: 792000000
        },
        warriors: [
            "jonathanchin0614@gmail.com",
            "aidenlim950310@gmail.com",
            "williamffk@gmail.com",
            "seong31993199@gmail.com"
        ],
        field_data: [
            {
                name: "full_name",
                values: ["Joe"]
            },
            {
                values: ["+60 18-770 6766"],
                name: "phone_number"
            },
            {
                values: [""],
                name: "email"
            }
        ],
        campaignName: "",
        assignments: [
            {
                date: {
                    seconds: 1677589681,
                    nanoseconds: 647000000
                },
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    name: "Jonathan Chin",
                    email: "jonathanchin0614@gmail.com"
                }
            },
            {
                date: {
                    seconds: 1677663768,
                    nanoseconds: 145000000
                },
                assign: {
                    email: "aidenlim950310@gmail.com",
                    name: "Aiden  Lim"
                },
                assignBy: "kohpohfen@gmail.com"
            },
            {
                assign: {
                    name: "William Fam",
                    email: "williamffk@gmail.com"
                },
                date: {
                    seconds: 1678255845,
                    nanoseconds: 167000000
                },
                assignBy: "kohpohfen@gmail.com"
            },
            {
                date: {
                    seconds: 1678525815,
                    nanoseconds: 71000000
                },
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    name: "Ting Seong Shew",
                    email: "seong31993199@gmail.com"
                }
            },
            {
                assign: {
                    email: "williamffk@gmail.com",
                    name: "William Fam"
                },
                date: {
                    seconds: 1683984458,
                    nanoseconds: 230000000
                },
                assignBy: "kohpohfen@gmail.com"
            }
        ],
        actionedIds: [
            "5xNmR5AbmLBi3H70maqX",
            "sDQILwwiuVq7tmlykf2B",
            "uJfPluabWp624huhSnYU",
            "qdFT1nt59ULUPyWdQRyo",
            "fGBfD28Tqrvoi7C0iBZx",
            "VZLQLkncJvWlw3ymgZxZ",
            "zWcDJL6YknGAGMx6g1Ip",
            "aidenlim950310@gmail.com"
        ],
        created: {
            seconds: 1677407936,
            nanoseconds: 793000000
        },
        id: "0Iaa2mftqB1zCcMz8SBb",
        path: "leads/0Iaa2mftqB1zCcMz8SBb"
    },
    {
        page_id: "UhUfzbEdf16LS5XCRfCO",
        field_data: [
            {
                name: "full_name",
                values: ["weekend"]
            },
            {
                values: ["ownstay"],
                name: "phone_number"
            },
            {
                name: "email",
                values: [60179757126]
            }
        ],
        ownerEmail: "alanlaiproperty88@gmail.com",
        created_time: {
            seconds: 1648540390,
            nanoseconds: 464000000
        },
        remark: "Yih Ying",
        created: {
            seconds: 1648540390,
            nanoseconds: 464000000
        },
        pageName: "Alan Lai\n",
        source: "Facebook Ads ",
        admins: ["alanlaiproperty88@gmail.com", "rehmance39b@gmail.com"],
        campaignName: "",
        id: "0IxIvqPdqOgcaEYO53Es",
        path: "leads/0IxIvqPdqOgcaEYO53Es"
    },
    {
        pageName: "Alan Lai\n",
        admins: ["alanlaiproperty88@gmail.com", "rehmance39b@gmail.com"],
        created_time: {
            seconds: 1648540390,
            nanoseconds: 477000000
        },
        remark: "own_stay",
        source: "Facebook Ads ",
        campaignName: "",
        field_data: [
            {
                name: "full_name",
                values: ["3 C F / 1 E J 3"]
            },
            {
                name: "phone_number",
                values: [1123204881]
            },
            {
                values: ["weekday"],
                name: "email"
            }
        ],
        created: {
            seconds: 1648540390,
            nanoseconds: 478000000
        },
        page_id: "UhUfzbEdf16LS5XCRfCO",
        ownerEmail: "alanlaiproperty88@gmail.com",
        id: "0JPDSo9hYgAcqCdBcjjF",
        path: "leads/0JPDSo9hYgAcqCdBcjjF"
    },
    {
        labels: [
            {
                userEmail: "vernicelee2621@gmail.com",
                fontColor: "#ffffff",
                color: "#7b7b7b",
                label: "Pass Back"
            },
            {
                userEmail: "imjunsu0420@gmail.com",
                fontColor: "#ffffff",
                label: "Pass Back",
                color: "#7b7b7b"
            }
        ],
        assignments: [
            {
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    email: "vernicelee2621@gmail.com",
                    name: "Vernice Lee"
                },
                date: {
                    seconds: 1632466570,
                    nanoseconds: 808000000
                }
            },
            {
                date: {
                    seconds: 1633518216,
                    nanoseconds: 336000000
                },
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    name: "imjunsu0420",
                    email: "imjunsu0420@gmail.com"
                }
            },
            {
                assign: {
                    email: "imjunsu0420@gmail.com",
                    name: "imjunsu0420"
                },
                date: {
                    seconds: 1634200553,
                    nanoseconds: 376000000
                },
                assignBy: "kohpohfen@gmail.com"
            }
        ],
        lastAction: {
            created: {
                seconds: 1633928146,
                nanoseconds: 423000000
            },
            user: "imjunsu0420@gmail.com",
            apptm: null,
            action: "Called",
            files: null,
            remark: ""
        },
        ownerEmail: "kohpohfen@gmail.com",
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        source: "ads",
        remark: null,
        created: {
            seconds: 1632331962,
            nanoseconds: 52000000
        },
        created_time: {
            seconds: 1632331962,
            nanoseconds: 52000000
        },
        page_id: "FsW77M3XQxU8p8ePFiAw",
        warriors: ["vernicelee2621@gmail.com", "imjunsu0420@gmail.com"],
        field_data: [
            {
                name: "full_name",
                values: ["小元 xiao yuan"]
            },
            {
                name: "phone_number",
                values: ["60177902322"]
            },
            {
                values: [null],
                name: "email"
            }
        ],
        actionedIds: ["vernicelee2621@gmail.com", "imjunsu0420@gmail.com"],
        campaignName: "",
        pageName: "summer whatsapp lead",
        id: "0JVKYNaAdul1dEuz0OSq",
        path: "leads/0JVKYNaAdul1dEuz0OSq"
    },
    {
        field_data: [
            {
                values: ["Vincent Tham"],
                name: "full_name"
            },
            {
                name: "phone_number",
                values: [60187791038]
            },
            {
                values: [null],
                name: "email"
            }
        ],
        source: "fb ins",
        admins: ["janecwy11@gmail.com", "rehmance39b@gmail.com"],
        created_time: {
            seconds: 1678696535,
            nanoseconds: 927000000
        },
        warriors: [],
        campaignName: "12",
        ownerEmail: "janecwy11@gmail.com",
        assignments: [
            {
                assignBy: "janecwy11@gmail.com",
                date: {
                    seconds: 1678697009,
                    nanoseconds: 688000000
                },
                assign: {
                    name: "Gene Lee",
                    email: "gnelol8895@gmail.com"
                }
            }
        ],
        remark: null,
        page_id: "ssqgLTQIArfNanStRoP1",
        created: {
            seconds: 1678696535,
            nanoseconds: 927000000
        },
        pageName: "jane chan",
        id: "0JvlQBSMOAf0ADYq2PIL",
        path: "leads/0JvlQBSMOAf0ADYq2PIL"
    },
    {
        remark: null,
        field_data: [
            {
                name: "full_name",
                values: ["Wong KH"]
            },
            {
                name: "phone_number",
                values: ["p:+60192227846"]
            },
            {
                values: ["wongkh.sys@gmail.com"],
                name: "email"
            }
        ],
        assignments: [
            {
                assignBy: "waynelim.property@gmail.com",
                assign: {
                    email: "kaiyegolf@gmail.com",
                    name: "James  On"
                },
                date: {
                    seconds: 1647160288,
                    nanoseconds: 101000000
                }
            }
        ],
        pageName: "Manual upload",
        warriors: ["kaiyegolf@gmail.com"],
        created_time: {
            seconds: 1647160259,
            nanoseconds: 894000000
        },
        campaignName: "",
        admins: ["waynelim.property@gmail.com", "rehmance39b@gmail.com"],
        page_id: "BDa0Cv6SE1hGqs6fdvrM",
        created: {
            seconds: 1647160259,
            nanoseconds: 894000000
        },
        source: "Novum leads",
        ownerEmail: "waynelim.property@gmail.com",
        id: "0JwLnSnNADP9JF7amqN1",
        path: "leads/0JwLnSnNADP9JF7amqN1"
    },
    {
        ownerEmail: "kohpohfen@gmail.com",
        actionedIds: ["mongny@hotmail.com", "munhong0909@gmail.com"],
        created_time: {
            seconds: 1662780840,
            nanoseconds: 310000000
        },
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        labels: [
            {
                color: "#3A0000",
                userEmail: "munhong0909@gmail.com",
                label: "No potential",
                fontColor: "#ffffff"
            }
        ],
        campaignName: "",
        source: "",
        remark: "",
        lastAction: {
            created: {
                seconds: 1664441444,
                nanoseconds: 958000000
            },
            user: "munhong0909@gmail.com",
            remark: "",
            apptm: null,
            files: null,
            action: "Called"
        },
        warriors: ["mongny@hotmail.com", "munhong0909@gmail.com", "sookmun770@gmail.com"],
        created: {
            seconds: 1662780840,
            nanoseconds: 311000000
        },
        pageName: "summer whatsapp lead",
        page_id: "FsW77M3XQxU8p8ePFiAw",
        assignments: [
            {
                date: {
                    seconds: 1662866608,
                    nanoseconds: 414000000
                },
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    name: "Jimmy ong",
                    email: "mongny@hotmail.com"
                }
            },
            {
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    email: "munhong0909@gmail.com",
                    name: "Mun Hong Yip"
                },
                date: {
                    seconds: 1663160552,
                    nanoseconds: 584000000
                }
            },
            {
                date: {
                    seconds: 1663417698,
                    nanoseconds: 152000000
                },
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    email: "sookmun770@gmail.com",
                    name: "Smun Lee"
                }
            }
        ],
        field_data: [
            {
                name: "full_name",
                values: ["Lance"]
            },
            {
                values: ["+60 12-389 3996"],
                name: "phone_number"
            },
            {
                values: [""],
                name: "email"
            }
        ],
        id: "0KD1cQKI5eM3yvwbAwQ7",
        path: "leads/0KD1cQKI5eM3yvwbAwQ7"
    },
    {
        source: "",
        admins: ["limzhengying98@hotmail.com", "rehmance39b@gmail.com"],
        remark: "",
        page_id: "Rql66McauGVhEqKAkUBs",
        ownerEmail: "limzhengying98@hotmail.com",
        created: {
            seconds: 1666704121,
            nanoseconds: 522000000
        },
        pageName: "Jessica.Prop",
        created_time: {
            seconds: 1666704121,
            nanoseconds: 522000000
        },
        campaignName: "D'IVO",
        actionedIds: ["gnelol8895@gmail.com", "zzhenghoong96@gmail.com"],
        field_data: [
            {
                name: "full_name",
                values: ["Desmond"]
            },
            {
                name: "phone_number",
                values: ["0126819186"]
            },
            {
                name: "email",
                values: [""]
            }
        ],
        lastAction: {
            action: "Whatsapped",
            apptm: null,
            remark: "",
            files: null,
            created: {
                seconds: 1668684491,
                nanoseconds: 469000000
            },
            user: "zzhenghoong96@gmail.com"
        },
        assignments: [
            {
                date: {
                    seconds: 1666704221,
                    nanoseconds: 665000000
                },
                assign: {
                    name: "Gene Lee",
                    email: "gnelol8895@gmail.com"
                },
                assignBy: "limzhengying98@hotmail.com"
            },
            {
                assign: {
                    email: "zzhenghoong96@gmail.com",
                    name: "Zheng Hoong Tan"
                },
                date: {
                    seconds: 1667808854,
                    nanoseconds: 349000000
                },
                assignBy: "limzhengying98@hotmail.com"
            },
            {
                assign: {
                    email: "sookmun770@gmail.com",
                    name: "Smun Lee"
                },
                assignBy: "limzhengying98@hotmail.com",
                date: {
                    seconds: 1667913555,
                    nanoseconds: 808000000
                }
            }
        ],
        warriors: ["gnelol8895@gmail.com", "zzhenghoong96@gmail.com", "sookmun770@gmail.com"],
        id: "0Kv7Yx5NKKGmsVKXyuIa",
        path: "leads/0Kv7Yx5NKKGmsVKXyuIa"
    },
    {
        assignments: [
            {
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1664256921,
                    nanoseconds: 327000000
                },
                assign: {
                    name: "Jimmy ong",
                    email: "mongny@hotmail.com"
                }
            },
            {
                assign: {
                    email: "desmondchongproperty21@gmail.com",
                    name: "desmondchongproperty21"
                },
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1664444296,
                    nanoseconds: 518000000
                }
            },
            {
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    name: "G J",
                    email: "junntproperties@gmail.com"
                },
                date: {
                    seconds: 1664608901,
                    nanoseconds: 757000000
                }
            },
            {
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1664622236,
                    nanoseconds: 388000000
                },
                assign: {
                    email: "sookmun770@gmail.com",
                    name: "Smun Lee"
                }
            },
            {
                date: {
                    seconds: 1665653085,
                    nanoseconds: 9000000
                },
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    name: "Kaycee Chong",
                    email: "kaycee.eliteone@gmail.com"
                }
            }
        ],
        ownerEmail: "kohpohfen@gmail.com",
        campaignName: "",
        lastAction: {
            created: {
                seconds: 1665982798,
                nanoseconds: 937000000
            },
            action: "Called",
            remark: "",
            user: "kaycee.eliteone@gmail.com",
            apptm: null,
            files: null
        },
        warriors: [
            "mongny@hotmail.com",
            "desmondchongproperty21@gmail.com",
            "junntproperties@gmail.com",
            "sookmun770@gmail.com",
            "kaycee.eliteone@gmail.com"
        ],
        pageName: "summer whatsapp lead",
        actionedIds: ["desmondchongproperty21@gmail.com", "kaycee.eliteone@gmail.com"],
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        source: "",
        created_time: {
            seconds: 1664030940,
            nanoseconds: 275000000
        },
        field_data: [
            {
                name: "full_name",
                values: ["Rica"]
            },
            {
                values: ["+60 11-1143 9766"],
                name: "phone_number"
            },
            {
                values: [""],
                name: "email"
            }
        ],
        remark: "",
        created: {
            seconds: 1664030940,
            nanoseconds: 275000000
        },
        page_id: "FsW77M3XQxU8p8ePFiAw",
        id: "0LbpX0upTQQAbTcpT86p",
        path: "leads/0LbpX0upTQQAbTcpT86p"
    },
    {
        created_time: {
            seconds: 1647160259,
            nanoseconds: 897000000
        },
        admins: ["waynelim.property@gmail.com", "rehmance39b@gmail.com"],
        page_id: "BDa0Cv6SE1hGqs6fdvrM",
        warriors: ["kaiyegolf@gmail.com"],
        pageName: "Manual upload",
        lastAction: {
            created: {
                seconds: 1647408234,
                nanoseconds: 382000000
            },
            action: "Research",
            files: null,
            user: "kaiyegolf@gmail.com",
            apptm: null,
            remark: ""
        },
        remark: null,
        created: {
            seconds: 1647160259,
            nanoseconds: 897000000
        },
        campaignName: "",
        assignments: [
            {
                date: {
                    seconds: 1647160288,
                    nanoseconds: 73000000
                },
                assign: {
                    name: "James  On",
                    email: "kaiyegolf@gmail.com"
                },
                assignBy: "waynelim.property@gmail.com"
            }
        ],
        ownerEmail: "waynelim.property@gmail.com",
        field_data: [
            {
                name: "full_name",
                values: ["Yit Piau Lim"]
            },
            {
                name: "phone_number",
                values: ["p:+60129899600"]
            },
            {
                values: ["ypbert@yahoo.com"],
                name: "email"
            }
        ],
        source: "Novum leads",
        actionedIds: ["kaiyegolf@gmail.com"],
        id: "0LdlzD65v13hKrWfABz2",
        path: "leads/0LdlzD65v13hKrWfABz2"
    },
    {
        source: "Fiddlewoodz email",
        admins: ["waynelim.property@gmail.com", "rehmance39b@gmail.com"],
        created_time: {
            seconds: 1663830660,
            nanoseconds: 181000000
        },
        created: {
            seconds: 1663830660,
            nanoseconds: 182000000
        },
        ownerEmail: "waynelim.property@gmail.com",
        remark: "3 Bedrooms",
        campaignName: "Fiddlewoodz",
        field_data: [
            {
                values: ["vidya"],
                name: "full_name"
            },
            {
                name: "phone_number",
                values: ["0173255613"]
            },
            {
                values: ["vidyameenal@yahoo.com"],
                name: "email"
            }
        ],
        pageName: "MY Property 360",
        page_id: "1496113477304516",
        deleted: true,
        id: "0Lhc0gfkG9kTBek03d9z",
        path: "leads/0Lhc0gfkG9kTBek03d9z"
    },
    {
        campaignName: "",
        page_id: "BDa0Cv6SE1hGqs6fdvrM",
        lastAction: {
            action: "Whatsapped",
            remark: "",
            files: null,
            apptm: null,
            user: "hengch88@yahoo.com.my",
            created: {
                seconds: 1629164047,
                nanoseconds: 570000000
            }
        },
        deleted: true,
        created: {
            seconds: 1627541110,
            nanoseconds: 666000000
        },
        remark: "1300sqf_-_3_bedrooms_|_3_bath  own_stay",
        field_data: [
            {
                name: "full_name",
                values: ["Zulkifli Kasim"]
            },
            {
                name: "phone_number",
                values: [60123320200]
            },
            {
                values: ["zkmsg@yahoo.com"],
                name: "email"
            }
        ],
        created_time: {
            seconds: 1627541110,
            nanoseconds: 666000000
        },
        codeName: "4",
        source: "Fiddlewoodz",
        ownerEmail: "waynelim.property@gmail.com",
        admins: ["waynelim.property@gmail.com", "rehmance39b@gmail.com"],
        labels: [
            {
                color: "#5cfaff",
                label: "Subsales Condo",
                fontColor: "#000000",
                userEmail: "hengch88@yahoo.com.my"
            },
            {
                fontColor: "#ffffff",
                label: "Pass Back",
                userEmail: "hengch88@yahoo.com.my",
                color: "#7b7b7b"
            }
        ],
        actionedIds: ["hengch88@yahoo.com.my"],
        assignments: [
            {
                date: {
                    seconds: 1627541144,
                    nanoseconds: 631000000
                },
                assign: {
                    name: "Henry Heng",
                    email: "hengch88@yahoo.com.my"
                },
                assignBy: "waynelim.property@gmail.com"
            }
        ],
        warriors: ["hengch88@yahoo.com.my"],
        pageName: "Manual upload",
        id: "0LpwGJeZqKiYDYRGjNV6",
        path: "leads/0LpwGJeZqKiYDYRGjNV6"
    },
    {
        assignments: [
            {
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    email: "jeremytan.iqi@gmail.com",
                    name: "Jeremy Tan"
                },
                date: {
                    seconds: 1656503800,
                    nanoseconds: 349000000
                }
            },
            {
                date: {
                    seconds: 1657012738,
                    nanoseconds: 491000000
                },
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    email: "alan_yap1121@hotmail.com",
                    name: "Alan Yap"
                }
            },
            {
                assign: {
                    name: "vinzchau00",
                    email: "vinzchau00@gmail.com"
                },
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1658237804,
                    nanoseconds: 290000000
                }
            },
            {
                date: {
                    seconds: 1658558411,
                    nanoseconds: 897000000
                },
                assign: {
                    name: "Pang  Yy",
                    email: "cindy_pang91@hotmail.com"
                },
                assignBy: "kohpohfen@gmail.com"
            },
            {
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    email: "cindy_pang91@hotmail.com",
                    name: "Pang  Yy"
                },
                date: {
                    seconds: 1660278702,
                    nanoseconds: 188000000
                }
            }
        ],
        created_time: {
            seconds: 1656492791,
            nanoseconds: 271000000
        },
        warriors: [
            "jeremytan.iqi@gmail.com",
            "alan_yap1121@hotmail.com",
            "vinzchau00@gmail.com",
            "cindy_pang91@hotmail.com"
        ],
        actionedIds: [
            "TCadZ1ikhm2tIV3UjlQ5",
            "uKhyo3RLtFD5Yc6KcEhc",
            "FHRmi6xOS7OkPoggfcG3",
            "HGgwEjEiYLscMkgILGnR",
            "JIe4tHeD0j8aYcGtJnxN",
            "eZviy7EPQR8w346lShW1",
            "arIL0GSpbKzQi0daZQT6",
            "yo3nLrBesB0ZW1KEYbPp",
            "KFq8EqMU2ATtAfzEbRMd",
            "UtZAaSfxFQsL9yDXmwBs",
            "cindy_pang91@hotmail.com",
            "jeremytan.iqi@gmail.com"
        ],
        labels: [
            {
                color: "#3A0000",
                label: "No potential",
                fontColor: "#ffffff",
                userEmail: "kohpohfen@gmail.com"
            },
            {
                userEmail: "jeremytan.iqi@gmail.com",
                fontColor: "#000000",
                label: "Finding project for client ",
                color: "#5cfaff"
            },
            {
                fontColor: "#ffffff",
                label: "No potential",
                userEmail: "alan_yap1121@hotmail.com",
                color: "#3A0000"
            },
            {
                color: "#3A0000",
                label: "No potential",
                fontColor: "#ffffff",
                userEmail: "cindy_pang91@hotmail.com"
            }
        ],
        pageName: "summer whatsapp lead",
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        source: "",
        remark: "",
        lastAction: {
            user: "jeremytan.iqi@gmail.com",
            files: null,
            action: "Whatsapped",
            created: {
                seconds: 1669881996,
                nanoseconds: 475000000
            },
            remark: "",
            apptm: null
        },
        campaignName: "",
        field_data: [
            {
                values: ["VIP"],
                name: "full_name"
            },
            {
                name: "phone_number",
                values: ["+60 16-521 9198"]
            },
            {
                name: "email",
                values: [""]
            }
        ],
        ownerEmail: "kohpohfen@gmail.com",
        page_id: "FsW77M3XQxU8p8ePFiAw",
        created: {
            seconds: 1656492791,
            nanoseconds: 271000000
        },
        id: "0NCnYxOP6V2Kvu2r9XHL",
        path: "leads/0NCnYxOP6V2Kvu2r9XHL"
    },
    {
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        field_data: [
            {
                values: ["Marianne"],
                name: "full_name"
            },
            {
                values: ["+6001112621363"],
                name: "phone_number"
            },
            {
                name: "email",
                values: ["Mgray1166@gmail.com"]
            }
        ],
        page_id: "qShbFrX1peemq0pwcTz1",
        created_time: {
            seconds: 1625631179,
            nanoseconds: 939000000
        },
        pageName: "maven",
        warriors: ["williamffk@gmail.com"],
        ownerEmail: "kohpohfen@gmail.com",
        created: {
            seconds: 1625631179,
            nanoseconds: 939000000
        },
        source: "summer fb ads",
        id: "0NE80jH0H83tHLc636oR",
        path: "leads/0NE80jH0H83tHLc636oR"
    },
    {
        source: "",
        labels: [
            {
                label: "Pass Back",
                userEmail: "williamffk@gmail.com",
                fontColor: "#ffffff",
                color: "#7b7b7b"
            }
        ],
        assignments: [
            {
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    name: "Jonathan Chin",
                    email: "jonathanchin0614@gmail.com"
                },
                date: {
                    seconds: 1677825155,
                    nanoseconds: 834000000
                }
            },
            {
                assign: {
                    email: "sookmun770@gmail.com",
                    name: "Smun Lee"
                },
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1677924848,
                    nanoseconds: 684000000
                }
            },
            {
                assign: {
                    name: "Aiden  Lim",
                    email: "aidenlim950310@gmail.com"
                },
                date: {
                    seconds: 1678109770,
                    nanoseconds: 505000000
                },
                assignBy: "kohpohfen@gmail.com"
            },
            {
                date: {
                    seconds: 1678255843,
                    nanoseconds: 441000000
                },
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    name: "William Fam",
                    email: "williamffk@gmail.com"
                }
            },
            {
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1678525815,
                    nanoseconds: 56000000
                },
                assign: {
                    name: "Ting Seong Shew",
                    email: "seong31993199@gmail.com"
                }
            },
            {
                date: {
                    seconds: 1683984458,
                    nanoseconds: 218000000
                },
                assign: {
                    name: "William Fam",
                    email: "williamffk@gmail.com"
                },
                assignBy: "kohpohfen@gmail.com"
            }
        ],
        warriors: [
            "jonathanchin0614@gmail.com",
            "sookmun770@gmail.com",
            "aidenlim950310@gmail.com",
            "williamffk@gmail.com",
            "seong31993199@gmail.com"
        ],
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        ownerEmail: "kohpohfen@gmail.com",
        remark: "Hugoz n fiddle",
        pageName: "Hugoz",
        campaignName: "",
        page_id: "JLJjE7tktAqFcjvr1eVL",
        field_data: [
            {
                name: "full_name",
                values: ["Mr Liang"]
            },
            {
                name: "phone_number",
                values: ["+60 16-216 7896"]
            },
            {
                name: "email",
                values: [""]
            }
        ],
        lastAction: {
            remark: "",
            leadId: "0NNFLU46N8I2Q79q447C",
            files: null,
            created: {
                seconds: 1678605911,
                nanoseconds: 284000000
            },
            apptm: null,
            user: "sookmun770@gmail.com",
            userName: "Smun Lee",
            action: "Research"
        },
        created_time: {
            seconds: 1677765184,
            nanoseconds: 470000000
        },
        created: {
            seconds: 1677765184,
            nanoseconds: 470000000
        },
        actionedIds: [
            "Uh7bgRnrhi4awPjKbwE2",
            "kohpohfen@gmail.com",
            "sookmun770@gmail.com",
            "aidenlim950310@gmail.com",
            "williamffk@gmail.com",
            "seong31993199@gmail.com"
        ],
        id: "0NNFLU46N8I2Q79q447C",
        path: "leads/0NNFLU46N8I2Q79q447C"
    },
    {
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        codeName: "Summer",
        created_time: {
            seconds: 1661096908,
            nanoseconds: 970000000
        },
        pageName: "summer whatsapp lead",
        warriors: [
            "alanlaiproperty88@gmail.com",
            "iqi.jimmyng@gmail.com",
            "desmondchongproperty21@gmail.com",
            "mongny@hotmail.com",
            "cindy_pang91@hotmail.com"
        ],
        actionedIds: [
            "9X02dQpTBrQB64SwAcIG",
            "NrkA5TW6EqQhhNZuy7gB",
            "P8DJGTeJGaoRspmApLIT",
            "QD4ZjoxXaLP8RszRrS4D",
            "RIST2wYb3v9PZyXgCXcL",
            "2j0jolszBcND5KVq2Sos",
            "V0rEqi9ZoC2Da6SjaCbh",
            "KGBRwYnlYIriyDkZLJeq",
            "XbctxvMtUwITXM39So29",
            "paeJLjpb0sQ2q6XA1DOT",
            "rLLngUq2cDSGwqtLypFB",
            "fGwMaNIS1q7NqlfgaSMI",
            "XP5gpJzCeMWphnPr2IGN",
            "krQxwveZQNAVecZ2Hxy2",
            "yyIMckNoLyjAjyvnm8wP",
            "McnJk0nQTIBervf942av",
            "SS0qLA35kSI42LEKizVB",
            "Ft8X9XACbewN24g5RT52",
            "TzVB03J3wgSLkxY80DpX",
            "EYDY79lbBjER99ErPeHZ",
            "CAFbulBmIOoV3rfVOdY4",
            "AQLEkxtIEUoyHcOuJfbc",
            "tm9OK5i2yu6Wwl3P4zaB",
            "UZ5uFg6sEm5hCih2iqXe",
            "OErEhfGEjjEshMVVPMtP",
            "fwLEqQWHoab5qalFr6hM",
            "iqUDWY7vjLrwnNYSp8ZP",
            "puMUNalRjM7xv7IQXFXQ",
            "iqi.jimmyng@gmail.com"
        ],
        created: {
            seconds: 1661096908,
            nanoseconds: 970000000
        },
        labels: [
            {
                label: "Follow up",
                fontColor: "#ffffff",
                color: "#3b93ff",
                userEmail: "alanlaiproperty88@gmail.com"
            },
            {
                userEmail: "iqi.jimmyng@gmail.com",
                fontColor: "#000000",
                label: "WhatsApp blasting ",
                color: "#5cfaff"
            },
            {
                fontColor: "#ffffff",
                userEmail: "cindy_pang91@hotmail.com",
                label: "No potential",
                color: "#3A0000"
            },
            {
                label: "Call Back",
                fontColor: "#000000",
                userEmail: "iqi.jimmyng@gmail.com",
                color: "#5cfaff"
            }
        ],
        remark: "",
        page_id: "FsW77M3XQxU8p8ePFiAw",
        assignments: [
            {
                date: {
                    seconds: 1661100575,
                    nanoseconds: 728000000
                },
                assign: {
                    email: "alanlaiproperty88@gmail.com",
                    name: "Alan  Lai"
                },
                assignBy: "kohpohfen@gmail.com"
            },
            {
                date: {
                    seconds: 1661154999,
                    nanoseconds: 712000000
                },
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    email: "iqi.jimmyng@gmail.com",
                    name: "Jimmy Ng "
                }
            },
            {
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    email: "desmondchongproperty21@gmail.com",
                    name: "desmondchongproperty21"
                },
                date: {
                    seconds: 1661436371,
                    nanoseconds: 16000000
                }
            },
            {
                assign: {
                    email: "mongny@hotmail.com",
                    name: "Jimmy ong"
                },
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1661756302,
                    nanoseconds: 515000000
                }
            },
            {
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1663139588,
                    nanoseconds: 419000000
                },
                assign: {
                    email: "cindy_pang91@hotmail.com",
                    name: "Pang  Yy"
                }
            }
        ],
        ownerEmail: "kohpohfen@gmail.com",
        campaignName: "",
        lastAction: {
            created: {
                seconds: 1668170646,
                nanoseconds: 821000000
            },
            user: "iqi.jimmyng@gmail.com",
            remark: "",
            apptm: null,
            action: "Whatsapped",
            files: null
        },
        source: "",
        field_data: [
            {
                name: "full_name",
                values: ["VIP"]
            },
            {
                values: ["+60 12-308 1316"],
                name: "phone_number"
            },
            {
                name: "email",
                values: [""]
            }
        ],
        id: "0NRicE29adugZN6VkQ1g",
        path: "leads/0NRicE29adugZN6VkQ1g"
    },
    {
        warriors: ["bingjiee96@gmail.com"],
        pageName: "iProperty",
        created: {
            seconds: 1627449803,
            nanoseconds: 204000000
        },
        assignments: [
            {
                assignBy: "daveckw@gmail.com",
                assign: {
                    name: "Keith Yap",
                    email: "bingjiee96@gmail.com"
                },
                date: {
                    seconds: 1648868288,
                    nanoseconds: 878000000
                }
            }
        ],
        ownerEmail: "daveckw@gmail.com",
        campaignName: "iProperty",
        remark: "1973-11-24",
        field_data: [
            {
                values: ["CHARLIE LEE KHENG HUI"],
                name: "full_name"
            },
            {
                name: "phone_number",
                values: [60177612128]
            },
            {
                values: ["SAGI73@YAHOO.COM"],
                name: "email"
            }
        ],
        admins: ["daveckw@gmail.com", "rehmance39b@gmail.com"],
        source: "iProperty",
        created_time: {
            seconds: 1627449803,
            nanoseconds: 204000000
        },
        page_id: "0bv3FGR5hBLGUHZwnyZu",
        id: "0NWsZJQsWpExNsCoruso",
        path: "leads/0NWsZJQsWpExNsCoruso"
    },
    {
        created_time: {
            seconds: 1636877202,
            nanoseconds: 147000000
        },
        actionedIds: ["hanst9803@gmail.com"],
        page_id: "zCfJ22Y0WfYk2vECChSZ",
        pageName: "Start-Future",
        admins: ["imjunsu0420@gmail.com", "rehmance39b@gmail.com"],
        created: {
            seconds: 1636877202,
            nanoseconds: 147000000
        },
        remark: "PJ Lead",
        ownerEmail: "imjunsu0420@gmail.com",
        labels: [
            {
                color: "#3A0000",
                userEmail: "hanst9803@gmail.com",
                label: "No potential",
                fontColor: "#ffffff"
            }
        ],
        source: "",
        assignments: [
            {
                date: {
                    seconds: 1636963510,
                    nanoseconds: 178000000
                },
                assignBy: "imjunsu0420@gmail.com",
                assign: {
                    email: "hanst9803@gmail.com",
                    name: "ST HAN"
                }
            }
        ],
        campaignName: "Lead Gen",
        field_data: [
            {
                name: "full_name",
                values: ["双荣"]
            },
            {
                name: "phone_number",
                values: [" +60182768468"]
            },
            {
                name: "email",
                values: [""]
            }
        ],
        lastAction: {
            user: "hanst9803@gmail.com",
            created: {
                seconds: 1636980432,
                nanoseconds: 822000000
            },
            remark: "",
            action: "Called",
            files: null,
            apptm: null
        },
        warriors: ["hanst9803@gmail.com"],
        id: "0NdHIWcYhjHtw1BKR2U9",
        path: "leads/0NdHIWcYhjHtw1BKR2U9"
    },
    {
        field_data: [
            {
                values: ["shuyi yip"],
                name: "full_name"
            },
            {
                values: ["0174081932"],
                name: "phone_number"
            },
            {
                name: "email",
                values: ["shuyiyip@outlook.com"]
            }
        ],
        page_id: "qShbFrX1peemq0pwcTz1",
        ownerEmail: "kohpohfen@gmail.com",
        warriors: ["williamffk@gmail.com"],
        pageName: "maven",
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        created: {
            seconds: 1625631179,
            nanoseconds: 960000000
        },
        created_time: {
            seconds: 1625631179,
            nanoseconds: 960000000
        },
        source: "summer fb ads",
        id: "0Nmq5RG1OZXSN7B5LJcJ",
        path: "leads/0Nmq5RG1OZXSN7B5LJcJ"
    },
    {
        remark: "",
        campaignName: "",
        page_id: "n1vxEdHMrUuund3hbyhM",
        created: {
            seconds: 1676746496,
            nanoseconds: 913000000
        },
        pageName: "Fiddlewoodz",
        source: "",
        ownerEmail: "kohpohfen@gmail.com",
        deleted: true,
        field_data: [
            {
                values: ["VIP"],
                name: "full_name"
            },
            {
                values: ["+60 10-218 2148"],
                name: "phone_number"
            },
            {
                values: [""],
                name: "email"
            }
        ],
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        created_time: {
            seconds: 1676746496,
            nanoseconds: 913000000
        },
        id: "0NqesxLgtrVG05S2QqhO",
        path: "leads/0NqesxLgtrVG05S2QqhO"
    },
    {
        admins: ["janecwy11@gmail.com", "rehmance39b@gmail.com"],
        ownerEmail: "janecwy11@gmail.com",
        created: {
            seconds: 1648115014,
            nanoseconds: 58000000
        },
        campaignName: "",
        pageName: "jane chan",
        remark: null,
        created_time: {
            seconds: 1648115014,
            nanoseconds: 58000000
        },
        page_id: "ssqgLTQIArfNanStRoP1",
        source: "fb/ig",
        field_data: [
            {
                name: "full_name",
                values: ["Charly Chok"]
            },
            {
                name: "phone_number",
                values: [60132222866]
            },
            {
                name: "email",
                values: [null]
            }
        ],
        id: "0O9MQo0ioRMDH2aD8YLd",
        path: "leads/0O9MQo0ioRMDH2aD8YLd"
    },
    {
        pageName: "Hugoz",
        campaignName: "",
        warriors: ["janecwy11@gmail.com", "sookmun770@gmail.com", "kellywongyikei@gmail.com"],
        created_time: {
            seconds: 1675744686,
            nanoseconds: 948000000
        },
        page_id: "JLJjE7tktAqFcjvr1eVL",
        ownerEmail: "kohpohfen@gmail.com",
        actionedIds: ["kohpohfen@gmail.com", "sookmun770@gmail.com", "kellywongyikei@gmail.com"],
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        lastAction: {
            leadId: "0OLIFCTlonhhAhaFShco",
            apptm: null,
            remark: "",
            files: null,
            user: "kellywongyikei@gmail.com",
            created: {
                seconds: 1680866995,
                nanoseconds: 317000000
            },
            action: "Called",
            userName: "Kelly Wong"
        },
        labels: [
            {
                label: "Appointment",
                color: "#fef23a",
                fontColor: "#000000",
                userEmail: "kohpohfen@gmail.com"
            }
        ],
        assignments: [
            {
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1676031919,
                    nanoseconds: 805000000
                },
                assign: {
                    name: "Jane Chan",
                    email: "janecwy11@gmail.com"
                }
            },
            {
                assign: {
                    name: "Smun Lee",
                    email: "sookmun770@gmail.com"
                },
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1677212258,
                    nanoseconds: 422000000
                }
            },
            {
                assign: {
                    email: "kellywongyikei@gmail.com",
                    name: "kellywongyikei"
                },
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1680410537,
                    nanoseconds: 925000000
                }
            }
        ],
        remark: "",
        created: {
            seconds: 1675744686,
            nanoseconds: 948000000
        },
        source: "",
        appointments: [
            {
                date: {
                    seconds: 1675900800,
                    nanoseconds: 0
                },
                time: "10:00",
                venue: "Zoom arte",
                user: "kohpohfen@gmail.com"
            }
        ],
        field_data: [
            {
                name: "full_name",
                values: ["Victor"]
            },
            {
                name: "phone_number",
                values: ["+60 18-662 4353"]
            },
            {
                name: "email",
                values: [""]
            }
        ],
        id: "0OLIFCTlonhhAhaFShco",
        path: "leads/0OLIFCTlonhhAhaFShco"
    },
    {
        remark: "",
        pageName: "summer whatsapp lead",
        deleted: true,
        created_time: {
            seconds: 1658073595,
            nanoseconds: 302000000
        },
        actionedIds: ["kohpohfen@gmail.com"],
        lastAction: {
            user: "kohpohfen@gmail.com",
            apptm: null,
            files: null,
            created: {
                seconds: 1658233761,
                nanoseconds: 395000000
            },
            remark: "",
            action: "Whatsapped"
        },
        source: "",
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        created: {
            seconds: 1658073595,
            nanoseconds: 302000000
        },
        campaignName: "",
        ownerEmail: "kohpohfen@gmail.com",
        page_id: "FsW77M3XQxU8p8ePFiAw",
        field_data: [
            {
                name: "full_name",
                values: ["Julian"]
            },
            {
                values: ["+60 19-210 0021"],
                name: "phone_number"
            },
            {
                name: "email",
                values: [""]
            }
        ],
        id: "0ONam4cYp2ryNSaDy10c",
        path: "leads/0ONam4cYp2ryNSaDy10c"
    },
    {
        assignments: [
            {
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1636267841,
                    nanoseconds: 593000000
                },
                assign: {
                    name: "TAI WAI LOON",
                    email: "alextai0517@gmail.com"
                }
            },
            {
                assign: {
                    email: "vernicelee2621@gmail.com",
                    name: "Vernice Lee"
                },
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1636820959,
                    nanoseconds: 715000000
                }
            },
            {
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1638630940,
                    nanoseconds: 447000000
                },
                assign: {
                    name: "Choon Kit Mui",
                    email: "ckkey08181@gmail.com"
                }
            }
        ],
        page_id: "FsW77M3XQxU8p8ePFiAw",
        actionedIds: ["alextai0517@gmail.com", "vernicelee2621@gmail.com", "ckkey08181@gmail.com"],
        ownerEmail: "kohpohfen@gmail.com",
        pageName: "summer whatsapp lead",
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        created: {
            seconds: 1636217836,
            nanoseconds: 167000000
        },
        campaignName: "",
        source: "",
        remark: "",
        lastAction: {
            apptm: null,
            action: "Called",
            files: null,
            user: "ckkey08181@gmail.com",
            created: {
                seconds: 1638970255,
                nanoseconds: 136000000
            },
            remark: ""
        },
        field_data: [
            {
                values: ["Chen KS"],
                name: "full_name"
            },
            {
                values: ["+60 17-620 0986"],
                name: "phone_number"
            },
            {
                name: "email",
                values: [""]
            }
        ],
        labels: [
            {
                color: "#5cfaff",
                userEmail: "vernicelee2621@gmail.com",
                fontColor: "#000000",
                label: "Bought"
            }
        ],
        created_time: {
            seconds: 1636156800,
            nanoseconds: 0
        },
        warriors: ["alextai0517@gmail.com", "vernicelee2621@gmail.com", "ckkey08181@gmail.com"],
        id: "0ONpfBiQrwuBq3F6yMiw",
        path: "leads/0ONpfBiQrwuBq3F6yMiw"
    },
    {
        campaignName: "",
        remark: "",
        lastAction: {
            files: null,
            action: "Whatsapped",
            apptm: null,
            remark: "",
            user: "zzhenghoong96@gmail.com",
            created: {
                seconds: 1669868507,
                nanoseconds: 778000000
            }
        },
        field_data: [
            {
                values: ["VIP"],
                name: "full_name"
            },
            {
                values: ["6018-371 7433"],
                name: "phone_number"
            },
            {
                values: [""],
                name: "email"
            }
        ],
        actionedIds: [
            "kohpohfen@gmail.com",
            "alan_yap1121@hotmail.com",
            "alexchia320@gmail.com",
            "zzhenghoong96@gmail.com"
        ],
        source: "",
        ownerEmail: "kohpohfen@gmail.com",
        pageName: "summer whatsapp lead",
        created_time: {
            seconds: 1652095350,
            nanoseconds: 283000000
        },
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        warriors: ["mongny@hotmail.com", "zzhenghoong96@gmail.com", "cheam127@gmail.com"],
        appointments: [
            {
                date: {
                    seconds: 1653091200,
                    nanoseconds: 0
                },
                time: "18:00",
                intention: "millerz",
                language: "chinese",
                user: "kohpohfen@gmail.com",
                venue: "actual"
            }
        ],
        created: {
            seconds: 1652095350,
            nanoseconds: 284000000
        },
        labels: [
            {
                label: "Appointment",
                userEmail: "kohpohfen@gmail.com",
                color: "#fef23a",
                fontColor: "#000000"
            },
            {
                color: "#7b7b7b",
                label: "Pass Back",
                fontColor: "#ffffff",
                userEmail: "zzhenghoong96@gmail.com"
            }
        ],
        assignments: [
            {
                assign: {
                    email: "shanetanrealestate@gmail.com",
                    name: "Yen Shen Tan"
                },
                date: {
                    seconds: 1652152458,
                    nanoseconds: 838000000
                },
                assignBy: "kohpohfen@gmail.com"
            },
            {
                date: {
                    seconds: 1652189126,
                    nanoseconds: 668000000
                },
                assign: {
                    email: "jasonng0103@gmail.com",
                    name: "JAY SOON NG"
                },
                assignBy: "kohpohfen@gmail.com"
            },
            {
                date: {
                    seconds: 1652253790,
                    nanoseconds: 959000000
                },
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    email: "alan_yap1121@hotmail.com",
                    name: "Alan Yap"
                }
            },
            {
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    email: "alexchia320@gmail.com",
                    name: "Alexander Chia"
                },
                date: {
                    seconds: 1652501612,
                    nanoseconds: 506000000
                }
            },
            {
                assign: {
                    email: "mongny@hotmail.com",
                    name: "Jimmy ong"
                },
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1655095672,
                    nanoseconds: 536000000
                }
            },
            {
                date: {
                    seconds: 1655470242,
                    nanoseconds: 969000000
                },
                assign: {
                    name: "zzhenghoong96",
                    email: "zzhenghoong96@gmail.com"
                },
                assignBy: "kohpohfen@gmail.com"
            },
            {
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1659267241,
                    nanoseconds: 772000000
                },
                assign: {
                    name: "DavidCheam David Cheam",
                    email: "cheam127@gmail.com"
                }
            }
        ],
        page_id: "FsW77M3XQxU8p8ePFiAw",
        id: "0ORPzcjXXS1H8Vgp9KkK",
        path: "leads/0ORPzcjXXS1H8Vgp9KkK"
    },
    {
        source: "Facebook Ads ",
        campaignName: "",
        remark: "weekend",
        field_data: [
            {
                values: ["ARUEN"],
                name: "full_name"
            },
            {
                name: "phone_number",
                values: [60122262484]
            },
            {
                values: ["ownstay"],
                name: "email"
            }
        ],
        created_time: {
            seconds: 1648540414,
            nanoseconds: 915000000
        },
        admins: ["alanlaiproperty88@gmail.com", "rehmance39b@gmail.com"],
        ownerEmail: "alanlaiproperty88@gmail.com",
        pageName: "Alan Lai\n",
        created: {
            seconds: 1648540414,
            nanoseconds: 915000000
        },
        page_id: "UhUfzbEdf16LS5XCRfCO",
        id: "0P70HpzXWUz60yfza7c4",
        path: "leads/0P70HpzXWUz60yfza7c4"
    },
    {
        assignments: [
            {
                assign: {
                    name: "Smun Lee",
                    email: "sookmun770@gmail.com"
                },
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1672645482,
                    nanoseconds: 384000000
                }
            },
            {
                date: {
                    seconds: 1672821144,
                    nanoseconds: 453000000
                },
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    name: "Gene Lee",
                    email: "gnelol8895@gmail.com"
                }
            },
            {
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1672905756,
                    nanoseconds: 466000000
                },
                assign: {
                    name: "Wilson Tan",
                    email: "wilsontan00@gmail.com"
                }
            },
            {
                date: {
                    seconds: 1673012274,
                    nanoseconds: 638000000
                },
                assign: {
                    email: "janecwy11@gmail.com",
                    name: "Jane Chan"
                },
                assignBy: "kohpohfen@gmail.com"
            },
            {
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1675166821,
                    nanoseconds: 658000000
                },
                assign: {
                    email: "fedinandiqi@gmail.com",
                    name: "Ferdinand  Poon"
                }
            },
            {
                assignBy: "limzhengying98@hotmail.com",
                assign: {
                    email: "jeremytan.iqi@gmail.com",
                    name: "Jeremy Tan"
                },
                date: {
                    seconds: 1676439587,
                    nanoseconds: 120000000
                }
            }
        ],
        actionedIds: [
            "Ulgx6qdLTAzQjql89gci",
            "irK7GAEYfg4mNStFXClf",
            "3BdAU0QYzJjo1w5mlup4",
            "N8MnQaGHY0zIkB2IomBf",
            "jeremytan.iqi@gmail.com",
            "sookmun770@gmail.com"
        ],
        ownerEmail: "kohpohfen@gmail.com",
        field_data: [
            {
                values: ["Mr Ong"],
                name: "full_name"
            },
            {
                name: "phone_number",
                values: ["+60 16-921 5823"]
            },
            {
                name: "email",
                values: [""]
            }
        ],
        source: "",
        campaignName: "",
        warriors: [
            "sookmun770@gmail.com",
            "gnelol8895@gmail.com",
            "wilsontan00@gmail.com",
            "janecwy11@gmail.com",
            "fedinandiqi@gmail.com",
            "jeremytan.iqi@gmail.com"
        ],
        remark: "",
        pageName: "summer M",
        created_time: {
            seconds: 1672415942,
            nanoseconds: 197000000
        },
        lastAction: {
            remark: "",
            action: "Research",
            files: null,
            leadId: "0PNpbo11K6yjKJXZZV3T",
            apptm: null,
            userName: "Smun Lee",
            created: {
                seconds: 1678610446,
                nanoseconds: 857000000
            },
            user: "sookmun770@gmail.com"
        },
        page_id: "W4G8PO7RQJYxhrosjVKW",
        admins: ["kohpohfen@gmail.com", "limzhengying98@hotmail.com", "rehmance39b@gmail.com"],
        created: {
            seconds: 1672415942,
            nanoseconds: 197000000
        },
        id: "0PNpbo11K6yjKJXZZV3T",
        path: "leads/0PNpbo11K6yjKJXZZV3T"
    },
    {
        campaignName: "",
        assignments: [
            {
                assign: {
                    name: "Jimmy ong",
                    email: "mongny@hotmail.com"
                },
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1664256921,
                    nanoseconds: 325000000
                }
            },
            {
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1664444296,
                    nanoseconds: 514000000
                },
                assign: {
                    name: "desmondchongproperty21",
                    email: "desmondchongproperty21@gmail.com"
                }
            },
            {
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    name: "G J",
                    email: "junntproperties@gmail.com"
                },
                date: {
                    seconds: 1664608902,
                    nanoseconds: 409000000
                }
            },
            {
                date: {
                    seconds: 1664622236,
                    nanoseconds: 383000000
                },
                assign: {
                    name: "Smun Lee",
                    email: "sookmun770@gmail.com"
                },
                assignBy: "kohpohfen@gmail.com"
            },
            {
                assign: {
                    email: "kaycee.eliteone@gmail.com",
                    name: "Kaycee Chong"
                },
                date: {
                    seconds: 1665653085,
                    nanoseconds: 5000000
                },
                assignBy: "kohpohfen@gmail.com"
            }
        ],
        remark: "",
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        page_id: "FsW77M3XQxU8p8ePFiAw",
        source: "",
        warriors: [
            "mongny@hotmail.com",
            "desmondchongproperty21@gmail.com",
            "junntproperties@gmail.com",
            "sookmun770@gmail.com",
            "kaycee.eliteone@gmail.com"
        ],
        pageName: "summer whatsapp lead",
        created: {
            seconds: 1664031536,
            nanoseconds: 944000000
        },
        ownerEmail: "kohpohfen@gmail.com",
        actionedIds: ["kaycee.eliteone@gmail.com"],
        lastAction: {
            files: null,
            remark: "",
            created: {
                seconds: 1665662437,
                nanoseconds: 319000000
            },
            apptm: null,
            action: "Called",
            user: "kaycee.eliteone@gmail.com"
        },
        created_time: {
            seconds: 1664031536,
            nanoseconds: 944000000
        },
        field_data: [
            {
                values: ["Kiew"],
                name: "full_name"
            },
            {
                values: ["+60 12-312 1352"],
                name: "phone_number"
            },
            {
                name: "email",
                values: [""]
            }
        ],
        id: "0PR9RJ4hGiC4Lg6HihDE",
        path: "leads/0PR9RJ4hGiC4Lg6HihDE"
    },
    {
        admins: ["janecwy11@gmail.com", "rehmance39b@gmail.com"],
        pageName: "jane chan",
        ownerEmail: "janecwy11@gmail.com",
        created_time: {
            seconds: 1678696535,
            nanoseconds: 917000000
        },
        created: {
            seconds: 1678696535,
            nanoseconds: 917000000
        },
        remark: null,
        page_id: "ssqgLTQIArfNanStRoP1",
        campaignName: "12",
        field_data: [
            {
                name: "full_name",
                values: ["Tan Yong Ling"]
            },
            {
                name: "phone_number",
                values: [60146846312]
            },
            {
                name: "email",
                values: [null]
            }
        ],
        source: "fb ins",
        id: "0PkhUkamOS9mQFl0iuPC",
        path: "leads/0PkhUkamOS9mQFl0iuPC"
    },
    {
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        lastAction: {
            userName: "Ting Seong Shew",
            action: "Whatsapped",
            user: "seong31993199@gmail.com",
            created: {
                seconds: 1680324722,
                nanoseconds: 902000000
            },
            leadId: "0QXqpkyKAnmnLxx36C4R",
            apptm: null,
            files: null,
            remark: ""
        },
        warriors: [
            "sookmun770@gmail.com",
            "wilsontan00@gmail.com",
            "vinzchau00@gmail.com",
            "fedinandiqi@gmail.com",
            "jonathanchin0614@gmail.com",
            "seong31993199@gmail.com"
        ],
        assignments: [
            {
                assign: {
                    email: "sookmun770@gmail.com",
                    name: "Smun Lee"
                },
                date: {
                    seconds: 1670133457,
                    nanoseconds: 546000000
                },
                assignBy: "kohpohfen@gmail.com"
            },
            {
                date: {
                    seconds: 1670228347,
                    nanoseconds: 805000000
                },
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    email: "wilsontan00@gmail.com",
                    name: "Wilson Tan"
                }
            },
            {
                assign: {
                    email: "vinzchau00@gmail.com",
                    name: "vinzchau00"
                },
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1670482953,
                    nanoseconds: 818000000
                }
            },
            {
                assign: {
                    email: "fedinandiqi@gmail.com",
                    name: "Ferdinand  Poon"
                },
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1675168679,
                    nanoseconds: 289000000
                }
            },
            {
                assign: {
                    email: "jonathanchin0614@gmail.com",
                    name: "Jonathan Chin"
                },
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1678457517,
                    nanoseconds: 192000000
                }
            },
            {
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    email: "seong31993199@gmail.com",
                    name: "Ting Seong Shew"
                },
                date: {
                    seconds: 1679294061,
                    nanoseconds: 883000000
                }
            }
        ],
        ownerEmail: "kohpohfen@gmail.com",
        pageName: "Fiddlewoodz",
        source: "",
        actionedIds: [
            "pFfanq1hlQpdCOP89dKp",
            "LefSXzKLVojMNhA9dYj3",
            "t2DIwd83LWTTbECLwTYE",
            "1IVSbMH1hAz8B06UJYfq",
            "gyhaTul0ILzUWNjt9z5w",
            "FLHUl4G5RXHZsgVL1EfE",
            "seong31993199@gmail.com"
        ],
        created: {
            seconds: 1670133377,
            nanoseconds: 698000000
        },
        campaignName: "",
        field_data: [
            {
                values: ["Ms Yeong"],
                name: "full_name"
            },
            {
                name: "phone_number",
                values: ["+60 18-288 0793"]
            },
            {
                values: [""],
                name: "email"
            }
        ],
        page_id: "n1vxEdHMrUuund3hbyhM",
        created_time: {
            seconds: 1670133377,
            nanoseconds: 697000000
        },
        remark: "",
        id: "0QXqpkyKAnmnLxx36C4R",
        path: "leads/0QXqpkyKAnmnLxx36C4R"
    },
    {
        pageName: "MY Property 360",
        actionedIds: ["kaiyegolf@gmail.com"],
        created_time: {
            seconds: 1648458810,
            nanoseconds: 552000000
        },
        ownerEmail: "waynelim.property@gmail.com",
        page_id: "1496113477304516",
        created: {
            seconds: 1648458810,
            nanoseconds: 553000000
        },
        admins: ["waynelim.property@gmail.com", "rehmance39b@gmail.com"],
        warriors: ["kaiyegolf@gmail.com"],
        assignments: [
            {
                assignBy: "waynelim.property@gmail.com",
                date: {
                    seconds: 1648458923,
                    nanoseconds: 308000000
                },
                assign: {
                    name: "James  On",
                    email: "kaiyegolf@gmail.com"
                }
            }
        ],
        source: "Agile",
        remark: null,
        lastAction: {
            created: {
                seconds: 1648703133,
                nanoseconds: 355000000
            },
            user: "kaiyegolf@gmail.com",
            action: "Research",
            apptm: null,
            remark: "",
            files: null
        },
        field_data: [
            {
                values: ["Summer Lim"],
                name: "full_name"
            },
            {
                values: [60124297155],
                name: "phone_number"
            },
            {
                values: [null],
                name: "email"
            }
        ],
        campaignName: "Agile MK",
        id: "0QphxShX23h0s3DtBaO6",
        path: "leads/0QphxShX23h0s3DtBaO6"
    },
    {
        admins: ["alanlaiproperty88@gmail.com", "rehmance39b@gmail.com"],
        source: "Facebook Ads ",
        field_data: [
            {
                name: "full_name",
                values: ["weekend"]
            },
            {
                name: "phone_number",
                values: ["ownstay"]
            },
            {
                name: "email",
                values: [60123727191]
            }
        ],
        actionedIds: ["munhong0909@gmail.com"],
        campaignName: "",
        created_time: {
            seconds: 1648540414,
            nanoseconds: 958000000
        },
        ownerEmail: "alanlaiproperty88@gmail.com",
        remark: "Lydia Chen",
        created: {
            seconds: 1648540414,
            nanoseconds: 958000000
        },
        assignments: [
            {
                date: {
                    seconds: 1650535519,
                    nanoseconds: 626000000
                },
                assign: {
                    name: "Mun Hong Yip",
                    email: "munhong0909@gmail.com"
                },
                assignBy: "alanlaiproperty88@gmail.com"
            }
        ],
        warriors: ["munhong0909@gmail.com"],
        lastAction: {
            files: null,
            action: "Emailed",
            user: "munhong0909@gmail.com",
            created: {
                seconds: 1650613396,
                nanoseconds: 250000000
            },
            remark: "",
            apptm: null
        },
        pageName: "Alan Lai\n",
        page_id: "UhUfzbEdf16LS5XCRfCO",
        id: "0RAEf8mx4DlLLGB9uNpd",
        path: "leads/0RAEf8mx4DlLLGB9uNpd"
    },
    {
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        field_data: [
            {
                values: ["Melvin"],
                name: "full_name"
            },
            {
                name: "phone_number",
                values: ["+60 16-533 6448"]
            },
            {
                name: "email",
                values: [""]
            }
        ],
        created_time: {
            seconds: 1634803687,
            nanoseconds: 676000000
        },
        created: {
            seconds: 1634803687,
            nanoseconds: 676000000
        },
        campaignName: "",
        remark: "",
        pageName: "summer whatsapp lead",
        page_id: "FsW77M3XQxU8p8ePFiAw",
        deleted: true,
        source: "",
        ownerEmail: "kohpohfen@gmail.com",
        id: "0RLHNc9iS989MT0QIAg2",
        path: "leads/0RLHNc9iS989MT0QIAg2"
    },
    {
        ownerEmail: "janecwy11@gmail.com",
        page_id: "ssqgLTQIArfNanStRoP1",
        source: "fb ins",
        campaignName: "12",
        created_time: {
            seconds: 1678696535,
            nanoseconds: 895000000
        },
        created: {
            seconds: 1678696535,
            nanoseconds: 895000000
        },
        admins: ["janecwy11@gmail.com", "rehmance39b@gmail.com"],
        remark: null,
        field_data: [
            {
                values: ["Janice Liam"],
                name: "full_name"
            },
            {
                values: [60129886566],
                name: "phone_number"
            },
            {
                values: [null],
                name: "email"
            }
        ],
        pageName: "jane chan",
        id: "0SMOILF9NkFQqJK4n2zv",
        path: "leads/0SMOILF9NkFQqJK4n2zv"
    },
    {
        created_time: {
            seconds: 1682683646,
            nanoseconds: 582000000
        },
        actionedIds: ["aidenlim950310@gmail.com", "sookmun770@gmail.com"],
        ownerEmail: "janecwy11@gmail.com",
        field_data: [
            {
                values: ["Lee Chean Wey"],
                name: "full_name"
            },
            {
                name: "phone_number",
                values: [60126564665]
            },
            {
                name: "email",
                values: [null]
            }
        ],
        admins: ["janecwy11@gmail.com", "rehmance39b@gmail.com"],
        pageName: "jane chan",
        lastAction: {
            apptm: null,
            created: {
                seconds: 1683290258,
                nanoseconds: 860000000
            },
            user: "sookmun770@gmail.com",
            leadId: "0T3et7BnaRj7M7VOnudJ",
            action: "Called",
            userName: "Smun Lee",
            files: null,
            remark: ""
        },
        page_id: "ssqgLTQIArfNanStRoP1",
        assignments: [
            {
                assign: {
                    email: "aidenlim950310@gmail.com",
                    name: "Aiden  Lim"
                },
                assignBy: "janecwy11@gmail.com",
                date: {
                    seconds: 1682683667,
                    nanoseconds: 758000000
                }
            },
            {
                assignBy: "janecwy11@gmail.com",
                date: {
                    seconds: 1683088708,
                    nanoseconds: 498000000
                },
                assign: {
                    name: "Smun Lee",
                    email: "sookmun770@gmail.com"
                }
            }
        ],
        remark: null,
        campaignName: "pj leads mar - apr ",
        source: "fb and ins ",
        created: {
            seconds: 1682683646,
            nanoseconds: 582000000
        },
        warriors: ["aidenlim950310@gmail.com", "sookmun770@gmail.com"],
        id: "0T3et7BnaRj7M7VOnudJ",
        path: "leads/0T3et7BnaRj7M7VOnudJ"
    },
    {
        ownerEmail: "kohpohfen@gmail.com",
        created: {
            seconds: 1663140617,
            nanoseconds: 16000000
        },
        source: "",
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        warriors: ["cindy_pang91@hotmail.com", "mongny@hotmail.com", "alanlaiproperty88@gmail.com"],
        campaignName: "",
        lastAction: {
            files: null,
            action: "Research",
            created: {
                seconds: 1666356041,
                nanoseconds: 58000000
            },
            apptm: null,
            remark: "",
            user: "cindy_pang91@hotmail.com"
        },
        field_data: [
            {
                name: "full_name",
                values: ["Jenny"]
            },
            {
                name: "phone_number",
                values: ["+60 11-2321 6461"]
            },
            {
                values: [""],
                name: "email"
            }
        ],
        created_time: {
            seconds: 1663140617,
            nanoseconds: 16000000
        },
        actionedIds: ["cindy_pang91@hotmail.com", "mongny@hotmail.com", "alanlaiproperty88@gmail.com"],
        assignments: [
            {
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    name: "Pang  Yy",
                    email: "cindy_pang91@hotmail.com"
                },
                date: {
                    seconds: 1663157237,
                    nanoseconds: 293000000
                }
            },
            {
                assign: {
                    email: "mongny@hotmail.com",
                    name: "Jimmy ong"
                },
                date: {
                    seconds: 1663406922,
                    nanoseconds: 682000000
                },
                assignBy: "kohpohfen@gmail.com"
            },
            {
                assign: {
                    email: "cindy_pang91@hotmail.com",
                    name: "Pang  Yy"
                },
                date: {
                    seconds: 1663654531,
                    nanoseconds: 263000000
                },
                assignBy: "kohpohfen@gmail.com"
            },
            {
                date: {
                    seconds: 1665653286,
                    nanoseconds: 567000000
                },
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    email: "alanlaiproperty88@gmail.com",
                    name: "Alan  Lai"
                }
            }
        ],
        page_id: "FsW77M3XQxU8p8ePFiAw",
        pageName: "summer whatsapp lead",
        remark: "",
        id: "0T6vASWjyZGPUg3GzYvU",
        path: "leads/0T6vASWjyZGPUg3GzYvU"
    },
    {
        created_time: {
            seconds: 1636628887,
            nanoseconds: 973000000
        },
        actionedIds: [
            "imjunsu0420@gmail.com",
            "hanst9803@gmail.com",
            "vernicelee2621@gmail.com",
            "ckkey08181@gmail.com"
        ],
        created: {
            seconds: 1636628887,
            nanoseconds: 973000000
        },
        page_id: "lhANOIMaHDWINLYwiXKC",
        source: "ads",
        assignments: [
            {
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    email: "imjunsu0420@gmail.com",
                    name: "imjunsu0420"
                },
                date: {
                    seconds: 1636628941,
                    nanoseconds: 801000000
                }
            },
            {
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1636820794,
                    nanoseconds: 727000000
                },
                assign: {
                    name: "Vernice Lee",
                    email: "vernicelee2621@gmail.com"
                }
            },
            {
                date: {
                    seconds: 1636963348,
                    nanoseconds: 197000000
                },
                assignBy: "imjunsu0420@gmail.com",
                assign: {
                    email: "hanst9803@gmail.com",
                    name: "ST HAN"
                }
            },
            {
                date: {
                    seconds: 1639144023,
                    nanoseconds: 226000000
                },
                assign: {
                    email: "ckkey08181@gmail.com",
                    name: "Choon Kit Mui"
                },
                assignBy: "kohpohfen@gmail.com"
            }
        ],
        field_data: [
            {
                values: ["SUm May"],
                name: "full_name"
            },
            {
                values: [60163475864],
                name: "phone_number"
            },
            {
                name: "email",
                values: [null]
            }
        ],
        warriors: ["imjunsu0420@gmail.com", "vernicelee2621@gmail.com", "hanst9803@gmail.com", "ckkey08181@gmail.com"],
        labels: [
            {
                fontColor: "#000000",
                color: "#5cfaff",
                userEmail: "imjunsu0420@gmail.com",
                label: "N.P.U"
            },
            {
                color: "#5cfaff",
                fontColor: "#000000",
                userEmail: "vernicelee2621@gmail.com",
                label: "no pickup, WhatsApp text"
            }
        ],
        pageName: "Alan (Junsu face)",
        remark: null,
        lastAction: {
            files: null,
            apptm: null,
            action: "Whatsapped",
            remark: "",
            created: {
                seconds: 1640956899,
                nanoseconds: 137000000
            },
            user: "ckkey08181@gmail.com"
        },
        campaignName: "",
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        ownerEmail: "kohpohfen@gmail.com",
        id: "0TR1Um2FyscOJZ0Aqdb1",
        path: "leads/0TR1Um2FyscOJZ0Aqdb1"
    },
    {
        lastAction: {
            apptm: null,
            action: "Research",
            created: {
                seconds: 1647438295,
                nanoseconds: 64000000
            },
            files: null,
            remark: "",
            user: "eunicemin0729@gmail.com"
        },
        created: {
            seconds: 1642241067,
            nanoseconds: 62000000
        },
        source: "ads lead gen",
        assignments: [
            {
                date: {
                    seconds: 1642258360,
                    nanoseconds: 440000000
                },
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    email: "eunicemin0729@gmail.com",
                    name: "eunicemin0729"
                }
            },
            {
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1645519668,
                    nanoseconds: 367000000
                },
                assign: {
                    name: "Gene Lee",
                    email: "gnelol8895@gmail.com"
                }
            },
            {
                date: {
                    seconds: 1645519866,
                    nanoseconds: 376000000
                },
                assign: {
                    name: "Chia  Wei lun",
                    email: "alan_0219@hotmail.com"
                },
                assignBy: "kohpohfen@gmail.com"
            },
            {
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1646213895,
                    nanoseconds: 954000000
                },
                assign: {
                    name: "Yen Shen Tan",
                    email: "shanetanrealestate@gmail.com"
                }
            },
            {
                assign: {
                    email: "bingjiee96@gmail.com",
                    name: "Keith Yap"
                },
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1651658424,
                    nanoseconds: 733000000
                }
            }
        ],
        page_id: "425840764829395",
        pageName: "Summer Koh 房产甜心",
        campaignName: "",
        ownerEmail: "kohpohfen@gmail.com",
        field_data: [
            {
                name: "full_name",
                values: ["Jess Tff"]
            },
            {
                values: ["+60172277123"],
                name: "phone_number"
            },
            {
                name: "email",
                values: ["jesstff@yahoo.com"]
            }
        ],
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        actionedIds: ["eunicemin0729@gmail.com", "gnelol8895@gmail.com", "alan_0219@hotmail.com"],
        labels: [
            {
                userEmail: "eunicemin0729@gmail.com",
                color: "#5cfaff",
                fontColor: "#000000",
                label: "Called"
            },
            {
                fontColor: "#ffffff",
                label: "Follow up",
                color: "#3b93ff",
                userEmail: "alan_0219@hotmail.com"
            },
            {
                fontColor: "#ffffff",
                color: "#3b93ff",
                userEmail: "eunicemin0729@gmail.com",
                label: "Follow up"
            }
        ],
        warriors: [
            "eunicemin0729@gmail.com",
            "gnelol8895@gmail.com",
            "alan_0219@hotmail.com",
            "shanetanrealestate@gmail.com",
            "bingjiee96@gmail.com"
        ],
        created_time: {
            seconds: 1642241067,
            nanoseconds: 62000000
        },
        remark: null,
        id: "0UPtFUQaOhz1jv9qBP8q",
        path: "leads/0UPtFUQaOhz1jv9qBP8q"
    },
    {
        assignments: [
            {
                date: {
                    seconds: 1647759821,
                    nanoseconds: 747000000
                },
                assign: {
                    name: "Smun Lee",
                    email: "sookmun770@gmail.com"
                },
                assignBy: "kohpohfen@gmail.com"
            },
            {
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1647928380,
                    nanoseconds: 391000000
                },
                assign: {
                    name: "Chia  Wei lun",
                    email: "alan_0219@hotmail.com"
                }
            },
            {
                date: {
                    seconds: 1648125712,
                    nanoseconds: 105000000
                },
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    email: "eunicemin0729@gmail.com",
                    name: "eunicemin0729"
                }
            },
            {
                assign: {
                    email: "eunicemin0729@gmail.com",
                    name: "eunicemin0729"
                },
                date: {
                    seconds: 1650020702,
                    nanoseconds: 239000000
                },
                assignBy: "kohpohfen@gmail.com"
            },
            {
                date: {
                    seconds: 1651889803,
                    nanoseconds: 173000000
                },
                assign: {
                    email: "alexchia320@gmail.com",
                    name: "Alexander Chia"
                },
                assignBy: "kohpohfen@gmail.com"
            }
        ],
        field_data: [
            {
                name: "full_name",
                values: ["Stephanie Tan"]
            },
            {
                name: "phone_number",
                values: ["+60 12-268 9739"]
            },
            {
                name: "email",
                values: [""]
            }
        ],
        page_id: "FsW77M3XQxU8p8ePFiAw",
        pageName: "summer whatsapp lead",
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        labels: [
            {
                label: "Follow up",
                color: "#3b93ff",
                userEmail: "alan_0219@hotmail.com",
                fontColor: "#ffffff"
            },
            {
                userEmail: "eunicemin0729@gmail.com",
                fontColor: "#000000",
                label: "No pick up",
                color: "#5cfaff"
            }
        ],
        remark: "",
        warriors: ["sookmun770@gmail.com", "alan_0219@hotmail.com", "eunicemin0729@gmail.com", "alexchia320@gmail.com"],
        campaignName: "",
        created_time: {
            seconds: 1647698814,
            nanoseconds: 894000000
        },
        lastAction: {
            user: "alexchia320@gmail.com",
            remark: "",
            created: {
                seconds: 1652276092,
                nanoseconds: 344000000
            },
            action: "Called",
            apptm: null,
            files: null
        },
        created: {
            seconds: 1647698814,
            nanoseconds: 894000000
        },
        actionedIds: [
            "sookmun770@gmail.com",
            "alan_0219@hotmail.com",
            "eunicemin0729@gmail.com",
            "alexchia320@gmail.com"
        ],
        source: "",
        ownerEmail: "kohpohfen@gmail.com",
        id: "0UR5BtCeTMHQMh30Yi9b",
        path: "leads/0UR5BtCeTMHQMh30Yi9b"
    },
    {
        pageName: "jane chan",
        campaignName: "12",
        remark: null,
        created_time: {
            seconds: 1678696535,
            nanoseconds: 927000000
        },
        created: {
            seconds: 1678696535,
            nanoseconds: 927000000
        },
        ownerEmail: "janecwy11@gmail.com",
        assignments: [
            {
                assign: {
                    name: "Gene Lee",
                    email: "gnelol8895@gmail.com"
                },
                assignBy: "janecwy11@gmail.com",
                date: {
                    seconds: 1678697009,
                    nanoseconds: 687000000
                }
            }
        ],
        page_id: "ssqgLTQIArfNanStRoP1",
        source: "fb ins",
        field_data: [
            {
                values: ["Chew Chai Yap"],
                name: "full_name"
            },
            {
                name: "phone_number",
                values: [6421491443]
            },
            {
                name: "email",
                values: [null]
            }
        ],
        admins: ["janecwy11@gmail.com", "rehmance39b@gmail.com"],
        warriors: [],
        id: "0W26tugS40pfbirEvDwu",
        path: "leads/0W26tugS40pfbirEvDwu"
    },
    {
        lastAction: {
            files: null,
            apptm: null,
            action: "Called",
            created: {
                seconds: 1669966792,
                nanoseconds: 626000000
            },
            remark: "",
            user: "iqi.jimmyng@gmail.com"
        },
        warriors: ["charles951121@gmail.com", "iqi.jimmyng@gmail.com"],
        assignments: [
            {
                date: {
                    seconds: 1662881434,
                    nanoseconds: 677000000
                },
                assign: {
                    name: "leona ang",
                    email: "huiyingang96@hotmail.com"
                },
                assignBy: "limzhengying98@hotmail.com"
            },
            {
                date: {
                    seconds: 1662994062,
                    nanoseconds: 208000000
                },
                assign: {
                    name: "CY Yong",
                    email: "charles951121@gmail.com"
                },
                assignBy: "limzhengying98@hotmail.com"
            },
            {
                assignBy: "limzhengying98@hotmail.com",
                date: {
                    seconds: 1665656141,
                    nanoseconds: 255000000
                },
                assign: {
                    email: "iqi.jimmyng@gmail.com",
                    name: "Jimmy Ng "
                }
            }
        ],
        remark: "",
        actionedIds: [
            "7hUE5MtveFro06ZSlFJT",
            "GOcT2F9FpBEIEwe31urr",
            "07lvufOv6a5fdrlvtP8H",
            "VbBbMkZWNaJrdFbrsH4H",
            "Ypqa8G9EciMWEIDtn4y7",
            "id2HkkasuBOyjjnFheTu",
            "qT9Y92lfrf7suWoNfPkJ",
            "vwIifIVVolD8VEqlGdvV",
            "PR7xVogljOxZgxOsdZLE",
            "iqi.jimmyng@gmail.com"
        ],
        labels: [
            {
                label: "Follow up",
                userEmail: "huiyingang96@hotmail.com",
                color: "#3b93ff",
                fontColor: "#ffffff"
            },
            {
                fontColor: "#000000",
                userEmail: "charles951121@gmail.com",
                label: "Appointment",
                color: "#fef23a"
            },
            {
                userEmail: "iqi.jimmyng@gmail.com",
                color: "#5cfaff",
                fontColor: "#000000",
                label: "WhatsApp blasting "
            }
        ],
        campaignName: "CPD",
        page_id: "295016180591184",
        source: "",
        admins: [
            "daveckw@gmail.com",
            "debbieleow.dl@gmail.com",
            "limzhengying98@hotmail.com",
            "myra@eliteone.com.my",
            "rehmance39b@gmail.com"
        ],
        pageName: "马来西亚投资网 | Malaysia Investment Portal",
        appointments: [
            {
                user: "charles951121@gmail.com",
                intention: "Both for 2-3 rooms unit",
                time: "12:00",
                budget: "600k",
                date: {
                    seconds: 1663459200,
                    nanoseconds: 0
                },
                language: "Chinese",
                venue: "Atera Residence "
            }
        ],
        created: {
            seconds: 1662881344,
            nanoseconds: 848000000
        },
        field_data: [
            {
                values: ["Ann"],
                name: "full_name"
            },
            {
                name: "phone_number",
                values: ["60124291926"]
            },
            {
                name: "email",
                values: ["yayi_annie@live.com"]
            }
        ],
        created_time: {
            seconds: 1662881344,
            nanoseconds: 848000000
        },
        ownerEmail: "limzhengying98@hotmail.com",
        id: "0WNI4pjjx6bTIXNzKsSN",
        path: "leads/0WNI4pjjx6bTIXNzKsSN"
    },
    {
        ownerEmail: "alanlaiproperty88@gmail.com",
        page_id: "UhUfzbEdf16LS5XCRfCO",
        field_data: [
            {
                values: ["JOANNE"],
                name: "full_name"
            },
            {
                values: [60162881268],
                name: "phone_number"
            },
            {
                values: ["weekday"],
                name: "email"
            }
        ],
        pageName: "Alan Lai\n",
        created_time: {
            seconds: 1648540390,
            nanoseconds: 406000000
        },
        campaignName: "",
        admins: ["alanlaiproperty88@gmail.com", "rehmance39b@gmail.com"],
        remark: "own_stay",
        source: "Facebook Ads ",
        created: {
            seconds: 1648540390,
            nanoseconds: 406000000
        },
        id: "0WqhMEOSQZaj76UsOzWE",
        path: "leads/0WqhMEOSQZaj76UsOzWE"
    },
    {
        campaignName: "",
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        actionedIds: ["jeremytan.iqi@gmail.com", "desmondchongproperty21@gmail.com", "cindy_pang91@hotmail.com"],
        created: {
            seconds: 1656497602,
            nanoseconds: 277000000
        },
        assignments: [
            {
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1656503800,
                    nanoseconds: 329000000
                },
                assign: {
                    email: "jeremytan.iqi@gmail.com",
                    name: "Jeremy Tan"
                }
            },
            {
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    name: "desmondchongproperty21",
                    email: "desmondchongproperty21@gmail.com"
                },
                date: {
                    seconds: 1657012351,
                    nanoseconds: 947000000
                }
            },
            {
                date: {
                    seconds: 1658233434,
                    nanoseconds: 50000000
                },
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    name: "Jeremy Tan",
                    email: "jeremytan.iqi@gmail.com"
                }
            },
            {
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    email: "vinzchau00@gmail.com",
                    name: "vinzchau00"
                },
                date: {
                    seconds: 1658237804,
                    nanoseconds: 275000000
                }
            },
            {
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    email: "cindy_pang91@hotmail.com",
                    name: "Pang  Yy"
                },
                date: {
                    seconds: 1658558411,
                    nanoseconds: 887000000
                }
            },
            {
                date: {
                    seconds: 1660278702,
                    nanoseconds: 180000000
                },
                assign: {
                    name: "Pang  Yy",
                    email: "cindy_pang91@hotmail.com"
                },
                assignBy: "kohpohfen@gmail.com"
            }
        ],
        lastAction: {
            user: "cindy_pang91@hotmail.com",
            remark: "",
            apptm: null,
            files: null,
            created: {
                seconds: 1660615659,
                nanoseconds: 16000000
            },
            action: "Research"
        },
        remark: "",
        created_time: {
            seconds: 1656497602,
            nanoseconds: 276000000
        },
        ownerEmail: "kohpohfen@gmail.com",
        page_id: "FsW77M3XQxU8p8ePFiAw",
        warriors: [
            "jeremytan.iqi@gmail.com",
            "desmondchongproperty21@gmail.com",
            "vinzchau00@gmail.com",
            "cindy_pang91@hotmail.com"
        ],
        pageName: "summer whatsapp lead",
        field_data: [
            {
                name: "full_name",
                values: ["VIP"]
            },
            {
                name: "phone_number",
                values: ["+60 19-216 4164"]
            },
            {
                name: "email",
                values: [""]
            }
        ],
        labels: [
            {
                color: "#7b7b7b",
                fontColor: "#ffffff",
                label: "Pass Back",
                userEmail: "jeremytan.iqi@gmail.com"
            },
            {
                color: "#3A0000",
                fontColor: "#ffffff",
                userEmail: "cindy_pang91@hotmail.com",
                label: "No potential"
            }
        ],
        source: "",
        id: "0X1Zizhf5A5MGOpV7gNu",
        path: "leads/0X1Zizhf5A5MGOpV7gNu"
    },
    {
        lastAction: {
            created: {
                seconds: 1648541220,
                nanoseconds: 663000000
            },
            user: "alan_0219@hotmail.com",
            remark: "",
            action: "Research",
            apptm: null,
            files: null
        },
        created: {
            seconds: 1647939410,
            nanoseconds: 518000000
        },
        remark: "",
        actionedIds: ["KaN0RT3dbSFn7eFxk9Dc", "liTQdsqpe1rt2BplpSgG", "Q7fgxj0u35TiADTGrcmM", "alan_0219@hotmail.com"],
        pageName: "arcuz",
        field_data: [
            {
                values: ["Nicole"],
                name: "full_name"
            },
            {
                values: ["+60 19-216 0302"],
                name: "phone_number"
            },
            {
                name: "email",
                values: [""]
            }
        ],
        ownerEmail: "kohpohfen@gmail.com",
        campaignName: "",
        labels: [
            {
                userEmail: "alan_0219@hotmail.com",
                color: "#7b7b7b",
                label: "Pass Back",
                fontColor: "#ffffff"
            },
            {
                label: "Pass Back",
                color: "#7b7b7b",
                fontColor: "#ffffff",
                userEmail: "eunicemin0729@gmail.com"
            }
        ],
        source: "",
        warriors: ["sookmun770@gmail.com", "alan_0219@hotmail.com", "eunicemin0729@gmail.com"],
        page_id: "2GLEXYd06sRYhuzkQZF6",
        deleted: true,
        created_time: {
            seconds: 1647939410,
            nanoseconds: 518000000
        },
        admins: ["kohpohfen@gmail.com", "alanlaiproperty88@gmail.com", "rehmance39b@gmail.com"],
        assignments: [
            {
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1648010895,
                    nanoseconds: 239000000
                },
                assign: {
                    email: "sookmun770@gmail.com",
                    name: "Smun Lee"
                }
            },
            {
                date: {
                    seconds: 1648043374,
                    nanoseconds: 557000000
                },
                assign: {
                    email: "alan_0219@hotmail.com",
                    name: "Chia  Wei lun"
                },
                assignBy: "kohpohfen@gmail.com"
            },
            {
                assign: {
                    email: "eunicemin0729@gmail.com",
                    name: "eunicemin0729"
                },
                date: {
                    seconds: 1648125712,
                    nanoseconds: 185000000
                },
                assignBy: "kohpohfen@gmail.com"
            }
        ],
        id: "0XDzeiOvrmIbqH3uhMvX",
        path: "leads/0XDzeiOvrmIbqH3uhMvX"
    },
    {
        page_id: "BDa0Cv6SE1hGqs6fdvrM",
        field_data: [
            {
                name: "full_name",
                values: ["Devin Rasiah"]
            },
            {
                name: "phone_number",
                values: ["p:0126252782"]
            },
            {
                values: ["devinrasiah@gmail.com"],
                name: "email"
            }
        ],
        warriors: ["hanst9803@gmail.com", "steven100880@gmail.com"],
        ownerEmail: "waynelim.property@gmail.com",
        assignments: [
            {
                assign: {
                    name: "ST HAN",
                    email: "hanst9803@gmail.com"
                },
                assignBy: "waynelim.property@gmail.com",
                date: {
                    seconds: 1647236321,
                    nanoseconds: 994000000
                }
            },
            {
                date: {
                    seconds: 1653633547,
                    nanoseconds: 696000000
                },
                assignBy: "waynelim.property@gmail.com",
                assign: {
                    email: "steven100880@gmail.com",
                    name: "steven100880"
                }
            }
        ],
        source: "Novum",
        created_time: {
            seconds: 1647236305,
            nanoseconds: 107000000
        },
        admins: ["waynelim.property@gmail.com", "rehmance39b@gmail.com"],
        created: {
            seconds: 1647236305,
            nanoseconds: 107000000
        },
        campaignName: "Novum",
        pageName: "Manual upload",
        remark: null,
        id: "0XSYU8dC4UXelFxetR7H",
        path: "leads/0XSYU8dC4UXelFxetR7H"
    },
    {
        lastAction: {
            created: {
                seconds: 1663065453,
                nanoseconds: 231000000
            },
            user: "imjunsu0420@gmail.com",
            apptm: null,
            action: "Called",
            remark: "",
            files: null
        },
        page_id: "FsW77M3XQxU8p8ePFiAw",
        campaignName: "",
        ownerEmail: "kohpohfen@gmail.com",
        created_time: {
            seconds: 1655135708,
            nanoseconds: 877000000
        },
        created: {
            seconds: 1655135708,
            nanoseconds: 878000000
        },
        labels: [
            {
                userEmail: "kohpohfen@gmail.com",
                label: "Booked",
                fontColor: "#000000",
                color: "#5eff17"
            },
            {
                color: "#7b7b7b",
                label: "Pass Back",
                userEmail: "mongny@hotmail.com",
                fontColor: "#ffffff"
            },
            {
                label: "Pass Back",
                color: "#7b7b7b",
                userEmail: "imjunsu0420@gmail.com",
                fontColor: "#ffffff"
            }
        ],
        assignments: [
            {
                assign: {
                    email: "mongny@hotmail.com",
                    name: "Jimmy ong"
                },
                date: {
                    seconds: 1658928996,
                    nanoseconds: 252000000
                },
                assignBy: "kohpohfen@gmail.com"
            },
            {
                assign: {
                    email: "imjunsu0420@gmail.com",
                    name: "Junsu Mok"
                },
                date: {
                    seconds: 1660293602,
                    nanoseconds: 988000000
                },
                assignBy: "kohpohfen@gmail.com"
            }
        ],
        pageName: "summer whatsapp lead",
        field_data: [
            {
                name: "full_name",
                values: ["Horis"]
            },
            {
                name: "phone_number",
                values: ["+60 16-378 4211"]
            },
            {
                name: "email",
                values: [""]
            }
        ],
        actionedIds: ["kohpohfen@gmail.com", "mongny@hotmail.com", "imjunsu0420@gmail.com"],
        remark: "",
        source: "",
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        appointments: [
            {
                venue: "Dmsr showroom",
                time: "17:00",
                user: "kohpohfen@gmail.com",
                date: {
                    seconds: 1655510400,
                    nanoseconds: 0
                }
            }
        ],
        warriors: ["mongny@hotmail.com", "imjunsu0420@gmail.com"],
        id: "0XqMV3laTYpiu1rm9eCa",
        path: "leads/0XqMV3laTYpiu1rm9eCa"
    },
    {
        field_data: [
            {
                values: ["Zayden"],
                name: "full_name"
            },
            {
                values: ["+60 11-3962 5739"],
                name: "phone_number"
            },
            {
                values: [""],
                name: "email"
            }
        ],
        ownerEmail: "kohpohfen@gmail.com",
        actionedIds: [
            "2zSyr05dyJYjJvtF3qHQ",
            "TVSPRFm5y44FLvoISW6m",
            "z3s8XYf1icJPUBE6jxD4",
            "YPJ62d9EWyStaBrcnurS",
            "SxiVwoouBQoBRxKeb0rI",
            "AXNQdCCn4FZSnTVKBgl7",
            "qgfM91Qtmnn0y2JNyyP6",
            "AAiH3E2Ly18bh0goj0YE",
            "xOedYuEYw8CWfU96CyQB",
            "sSkAysv6L91Cme1Q8iVS",
            "seong31993199@gmail.com"
        ],
        admins: ["kohpohfen@gmail.com", "rehmance39b@gmail.com"],
        warriors: [
            "henryleeproperty12@gmail.com",
            "jonathanchin0614@gmail.com",
            "kellywongyikei@gmail.com",
            "munhong0909@gmail.com",
            "seong31993199@gmail.com",
            "sookmun770@gmail.com",
            "williamffk@gmail.com"
        ],
        created: {
            seconds: 1678875695,
            nanoseconds: 188000000
        },
        page_id: "n1vxEdHMrUuund3hbyhM",
        labels: [
            {
                label: "Potential",
                userEmail: "kohpohfen@gmail.com",
                color: "#d5a65d",
                fontColor: "#ffffff"
            },
            {
                label: "Details sent",
                userEmail: "henryleeproperty12@gmail.com",
                color: "#47e2b8",
                name: "Details sent",
                fontColor: "#000000"
            }
        ],
        pageName: "Fiddlewoodz",
        remark: "",
        lastAction: {
            userName: "Ting Seong Shew",
            files: null,
            remark: "",
            created: {
                seconds: 1682411380,
                nanoseconds: 911000000
            },
            user: "seong31993199@gmail.com",
            apptm: null,
            leadId: "0Y3YiRN8Mwl7crrgHzKq",
            action: "Whatsapped"
        },
        campaignName: "",
        assignments: [
            {
                assign: {
                    name: "henry lee",
                    email: "henryleeproperty12@gmail.com"
                },
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1678937103,
                    nanoseconds: 143000000
                }
            },
            {
                assign: {
                    email: "jonathanchin0614@gmail.com",
                    name: "Jonathan Chin"
                },
                assignBy: "kohpohfen@gmail.com",
                date: {
                    seconds: 1679225515,
                    nanoseconds: 35000000
                }
            },
            {
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    name: "kellywongyikei",
                    email: "kellywongyikei@gmail.com"
                },
                date: {
                    seconds: 1680072123,
                    nanoseconds: 715000000
                }
            },
            {
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    email: "munhong0909@gmail.com",
                    name: "Mun Hong Yip"
                },
                date: {
                    seconds: 1680875842,
                    nanoseconds: 92000000
                }
            },
            {
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    name: "Ting Seong Shew",
                    email: "seong31993199@gmail.com"
                },
                date: {
                    seconds: 1681036287,
                    nanoseconds: 565000000
                }
            },
            {
                assignBy: "kohpohfen@gmail.com",
                assign: {
                    name: "Smun Lee",
                    email: "sookmun770@gmail.com"
                },
                date: {
                    seconds: 1681210089,
                    nanoseconds: 500000000
                }
            },
            {
                assign: {
                    name: "William Fam",
                    email: "williamffk@gmail.com"
                },
                date: {
                    seconds: 1683984108,
                    nanoseconds: 376000000
                },
                assignBy: "kohpohfen@gmail.com"
            }
        ],
        source: "",
        phone: "+60 11-3962 5739",
        id: "0Y3YiRN8Mwl7crrgHzKq",
        email: "",
        name: "Zayden",
        created_time: {
            seconds: 1678875695,
            nanoseconds: 188000000
        },
        path: "leads/0Y3YiRN8Mwl7crrgHzKq"
    }
];
