export const convertStringDate = (stringDate) => {
    // dd/mm/yyyy
    if (/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(stringDate)) {
        let day = stringDate.split("/")[0];
        let month = stringDate.split("/")[1];
        let year = stringDate.split("/")[2];

        if (day.length === 1) {
            day = "0" + day;
        }

        if (month.length === 1) {
            day = "0" + month;
        }

        const date = year + "-" + month + "-" + day;
        return date;
    }

    if (/^\d{1,2}-\d{1,2}-\d{4}$/.test(stringDate)) {
        let day = stringDate.split("-")[0];
        let month = stringDate.split("-")[1];
        let year = stringDate.split("-")[2];

        if (day.length === 1) {
            day = "0" + day;
        }

        if (month.length === 1) {
            day = "0" + month;
        }

        const date = year + "-" + month + "-" + day;
        return date;
    }

    return stringDate;
};

export default function convertDate(date) {
    let newDate = date;
    if (typeof date !== "object") {
        if (typeof date == "number") {
            newDate = new Date(date * 1000); //change Unix time
        }
        if (typeof date == "string") {
            const stringDate = convertStringDate(date);
            newDate = new Date(stringDate);
        }
    } else {
        newDate = new Date(date.seconds * 1000);
    }

    if (date instanceof Date) newDate = date;
    var d = new Date(newDate);

    if (isNaN(d)) return "";

    return d;
}
