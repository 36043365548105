export default async function streamData(data, setMessages, messages) {
    const reader = data.getReader();
    const decoder = new TextDecoder("utf-8");
    let done = false;

    let text = "";
    let r;
    while (!done) {
        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        const chunkValue = decoder.decode(value);

        // Split chunkValue by the string "data: "
        const parts = chunkValue.split("data: ");

        // Remove the first element if it is empty
        if (parts[0].trim() === "") {
            parts.shift();
        }

        // Parse each part as a JSON object
        const jsonObjectArray = parts
            .map((part) => {
                // Check if the part is "[DONE]" and skip processing it
                if (part.trim() === "[DONE]") {
                    return null;
                }

                try {
                    return JSON.parse(part);
                } catch (error) {
                    console.error("Failed to parse JSON:", part, error);
                    return null;
                }
            })
            .filter((obj) => obj !== null);

        jsonObjectArray.forEach((jsonObj) => {
            const content = jsonObj.choices[0].delta?.content || "";
            text += content;
        });

        let newMessages = [...messages, { name: "DJC AI", content: text, role: "assistant" }];
        setMessages(newMessages);
        r = newMessages;
    }
    return r;
}
