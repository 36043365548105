import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";
import mapSnapshot from "../../functions/common-functions/mapSnapshot";

export default function loadWhatsappClients(setWhatsappClients, setLoading) {
    try {
        const collectionRef = collection(db, "whatsappClients");
        const q = query(collectionRef, orderBy("date", "desc"));
        setLoading(true);
        return onSnapshot(q, (snapshot) => {
            const clients = mapSnapshot(snapshot);
            setWhatsappClients(clients);
            setLoading(false);
        });
    } catch (err) {
        console.log(err);
        setLoading(false);
    }
}
