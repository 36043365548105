import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import EventIcon from "@mui/icons-material/Event";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { IconButton, Tooltip } from "@mui/material";
import { blue, cyan, green, orange, yellow } from "@mui/material/colors";
import React, { useContext, useState } from "react";
import { InputContext } from "../context-utils/InputContext";
import { AuthContext } from "../context/AuthContext";
import { addAction } from "../functions/addAction";
import { addRemark } from "../functions/addRemark";
import { getWhatsappNumber } from "../utils-functions/getWhatsappNumber";
import FullDialog from "./FullDialog";
import ManageFollowUp from "./ManageFollowUp";
import ManageAppointments from "./ManageAppointments";
import PaidIcon from "@mui/icons-material/Paid";
import BookedSale from "./BookedSale";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import UploadFilesButton from "./UploadFile/UploadFilesButton";

export function ActionsPanel({ lead }) {
    const { user } = useContext(AuthContext);
    const { openInputDialog } = useContext(InputContext);
    const [openFollowUp, setOpenFollowUp] = React.useState(false);
    const [openApptm, setOpenApptm] = React.useState(false);
    const [openBookedSale, setOpenBookedSale] = useState(false);

    const handleClickAction = async (action, lead, remark = "") => {
        // Follow up
        if (action === "Follow up") {
            setOpenFollowUp(true);
            return;
        }

        // Set Appointment
        if (action === "Appointment") {
            setOpenApptm(true);
            return;
        }

        // Set Booked Sale
        if (action === "Booked Sale") {
            setOpenBookedSale(true);
            return;
        }

        // Whatsapp and Call
        if (action === "Whatsapped") {
            const phone = getWhatsappNumber(lead.phone);
            const whatsappLink = `https://wa.me/${phone}?text=Hi ${lead.name}`;
            const win = window.open(whatsappLink, "_blank");
            win.focus();
        }
        if (action === "Called") {
            const phone = "+" + getWhatsappNumber(lead.phone);
            const phoneLink = `tel:${phone}`;
            const win = window.open(phoneLink, "_blank");
            win.focus();
        }
        const actionId = await addAction(lead, action, remark, user);

        const response = await openInputDialog("Add remarks", "", "Remarks", "");
        await addRemark({ id: actionId }, lead, response);
    };

    const handleFollowUp = async (response) => {
        setOpenFollowUp(false);
        console.log(response);
        if (!response) return;

        let { date, followUpId, remarks } = response;
        console.log("handleFollowUp");
        await addAction(lead, `Follow up on ${date}`, remarks, user, "", "", { followUpId, followUpDate: date });
    };

    const handleApptm = (appointment) => {
        console.log(appointment);
        setOpenApptm(false);
    };

    const handleBookedSale = (bookedSale) => {
        console.log("bookedSale: ", bookedSale);
        setOpenBookedSale(false);
    };

    const handleAddAttachment = async (files) => {
        await addAction(lead, "Attachment", "", user, files, "");
        console.log(files);
    };

    return (
        <React.Fragment>
            <Tooltip title="Whatsapp">
                <IconButton style={{ padding: "0 2px 0 2px" }} onClick={() => handleClickAction("Whatsapped", lead)}>
                    <WhatsAppIcon sx={{ color: green[500] }} />
                </IconButton>
            </Tooltip>

            <Tooltip title="Call">
                <IconButton style={{ padding: "0 2px 0 2px" }} onClick={() => handleClickAction("Called", lead)}>
                    <PhoneIcon sx={{ color: orange[500] }} />
                </IconButton>
            </Tooltip>

            <Tooltip title="Remark">
                <IconButton style={{ padding: "0 2px 0 2px" }} onClick={() => handleClickAction("Remarked", lead)}>
                    <ChatBubbleOutlineOutlinedIcon sx={{ color: blue[500] }} />
                </IconButton>
            </Tooltip>

            <Tooltip title="Follow up">
                <IconButton style={{ padding: "0 2px 0 2px" }} onClick={() => handleClickAction("Follow up", lead)}>
                    <EventIcon sx={{ color: cyan[500] }} />
                </IconButton>
            </Tooltip>

            <Tooltip title="Set Appointment">
                <IconButton style={{ padding: "0 2px 0 2px" }} onClick={() => handleClickAction("Appointment", lead)}>
                    <EventAvailableIcon sx={{ color: yellow[800] }} />
                </IconButton>
            </Tooltip>

            <Tooltip title="Booked Sale">
                <IconButton style={{ padding: "0 2px 0 2px" }} onClick={() => handleClickAction("Booked Sale", lead)}>
                    <PaidIcon sx={{ color: orange[800] }} />
                </IconButton>
            </Tooltip>

            <UploadFilesButton
                toolTip="Attach File"
                icon={<AttachFileIcon sx={{ color: blue[800] }} />}
                handleFile={handleAddAttachment}
            />

            <FullDialog
                open={openFollowUp}
                handleClose={() => setOpenFollowUp(false)}
                title={"Follow Up Manager"}
                Component={<ManageFollowUp lead={lead} handleClose={handleFollowUp} />}
            />

            <FullDialog
                open={openApptm}
                handleClose={() => setOpenApptm(false)}
                title={"Appointments Manager"}
                Component={<ManageAppointments lead={lead} handleClose={handleApptm} />}
            />

            <FullDialog
                open={openBookedSale}
                handleClose={() => setOpenBookedSale(false)}
                title={"Booked Sale"}
                Component={<BookedSale lead={lead} handleClose={handleBookedSale} />}
            />
        </React.Fragment>
    );
}
