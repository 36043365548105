import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export default async function unassignLeads(leads, unassignFrom) {
    try {
  
        leads.forEach(async (lead) => {
            console.log('leadlead:',lead);
            if (lead.warriors) {
                const warriors = [...lead.warriors];
                const index = warriors.findIndex((w) => w === unassignFrom);
                if (index !== -1) {
                    warriors.splice(index, 1);
                    const docRef = doc(db, "leads", lead.id);
                    await updateDoc(docRef, { warriors });
                    console.log("Unassign " + unassignFrom + " from leadId " + lead.id);
                }
                
            } 

            // Delete created assignedLead
            const assignedLeadDocRef = doc(db, "leads", lead.id + ":" + unassignFrom);
            await deleteDoc(assignedLeadDocRef);
            console.log("Delete assignedLead " + lead.id + ":" + unassignFrom);

            //3-1-24 added by wayne :
            //delete selected assignments array in lead.id.assignment to remove selected array of assignment.
            console.log(lead.id , "=",unassignFrom);
            const newAssignmentArray = lead.assignments.filter((obj) => obj.assign.email !== unassignFrom);
            const docRef = doc(db, "leads", lead.id);
            await updateDoc(docRef, { assignments: newAssignmentArray });

            console.log("Unassign " + unassignFrom + " from leadId " + lead.id);
            //end add
        });
    } catch (error) {
        console.log(error);
    }
}
