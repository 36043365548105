export default function convertDate(date) {
    if (!date) return null;
    if (isNumeric(date)) {
        date = parseFloat(date);
    }

    let newDate = date;
    if (typeof date !== "object") {
        if (typeof date == "number") {
            newDate = new Date(date * 1000); //change Unix time
        }
        if (typeof date == "string") {
            newDate = new Date(date);
        }
    } else {
        newDate = new Date(date.seconds * 1000);
    }

    if (date instanceof Date) newDate = date;
    var d = new Date(newDate);

    return d;
}

function isNumeric(str) {
    if (typeof str != "string") return false; // we only process strings!
    return (
        !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str))
    );
}
