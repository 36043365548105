import { collection, limit, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export default function getLeads(
    user,
    setLeads,
    startDate,
    endDate,
    setLoading,
    leadsMode,
    folder,
    maxNumber,
    setOpen
) {
    setLoading(true);
    try {
        let folderName = "";
        if (folder) {
            folderName = user.id + ":" + folder.id;
        }
        const collectionRef = collection(db, "leads");
        let q;
      
        if (leadsMode === "ASSIGNED_LEADS" && !folder) {
            q = query(
                collectionRef,
                where("warriors", "array-contains", user.email),
                where("created", ">=", startDate),
                where("created", "<=", endDate),
                orderBy("created", "desc"),
                limit(maxNumber)
            );
        } else if (leadsMode === "SOURCE_LEADS" && !folder) {
            // Leads Mode === "SOURCE_LEADS"

            //added by wayne 2 nov 2023
            //if maxNumber===1, retrieve all records
            if (maxNumber === 1) {
                q = query(collectionRef, where("admins", "array-contains", user.email), orderBy("created", "desc"));
            } else {
                q = query(
                    collectionRef,
                    where("admins", "array-contains", user.email),
                    where("created", ">=", startDate),
                    where("created", "<=", endDate),
                    orderBy("created", "desc"),
                    limit(maxNumber)
                );
            }
        } else if (folder) {
            console.log(folderName);
            q = query(collectionRef, where("folders", "array-contains", folderName), orderBy("created", "desc"));
        }

        return onSnapshot(q, (leadsSnap) => {
            if (!leadsSnap.empty) {
                let leads = leadsSnap.docs.map((doc) => {
                    let data = {};
                    try {
                        if (doc.data().field_data) {
                            doc.data().field_data.forEach((item) => {
                                if (item.name?.includes("name") || item.name?.includes("全名"))
                                    data = { ...data, name: item.values[0] };
                                else if (item.name?.includes("email") || item.name?.includes("邮箱"))
                                    data = { ...data, email: item.values[0] };
                                else if (item.name?.includes("phone") || item.name?.includes("手机号"))
                                    data = { ...data, phone: item.values[0] };
                                else {
                                    data = { ...data, [item.name]: item.values[0] };
                                }
                            });
                        }
                        const found = doc.data().assignments?.find((item) => item.assign?.email === user.id);
                        const assignBy = found ? found.assignBy : "";
                        return {
                            ...doc.data(),
                            ...data,
                            assignBy,
                            id: doc.id
                        };
                    } catch (err) {
                        console.log(err);
                    }
                });

                // remove those leads that are not assigned to the user
                if (folder && leadsMode === "ASSIGNED_LEADS") {
                    leads = leads.filter((lead) => lead.warriors.includes(user.id));
                }
                console.log("leads", leads);
                setLeads(leads);
                setLoading(false);
            } else {
                console.log("heheh");
                setLeads();
                setLoading(false);
            }
        });
    } catch (error) {
        console.log(error);
    }
}
