import { doc, updateDoc } from "firebase/firestore";
import { arrayIsEmpty } from "../utils-functions/arrayIsEmpty";
import { db } from "../firebase/firebase-utils";

export default async function addLabelToLead(lead, user, label) {
    try {
        const newLabels = lead.labels ? [...lead.labels] : [];
        if (!arrayIsEmpty(newLabels)) {
            const index = newLabels.findIndex((l) => l.label === label.label && l.userEmail === user.id);
            if (index !== -1) {
                newLabels[index] = label;
            } else {
                newLabels.push(label);
            }
        } else {
            newLabels.push(label);
        }

        const docRef = doc(db, "leads", lead.id);
        await updateDoc(docRef, { labels: newLabels });
        console.log("Updated labels in lead ", lead.id);
    } catch (error) {
        console.log(error);
    }
}
