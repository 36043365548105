import { AppBar, Box, Dialog, IconButton, Slide, Toolbar, Typography } from "@mui/material";
import { forwardRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@emotion/react";
import { tokens } from "../theme";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullDialog({ open, handleClose, title = "Dialog", Component }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar position={"fixed"} style={{ backgroundColor: colors.grey[500] }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => handleClose()} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6">{title}</Typography>
                    </Toolbar>
                </AppBar>
                <Box pt={9}>{Component}</Box>
            </Dialog>
        </Box>
    );
}
