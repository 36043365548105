export default async function sendMessageToNames(
    names,
    message,
    from,
    setNames,
    delayTime = 1000,
    isTerminated,
    files,
    apiAddress,
    secret,
    pollObj,
    user
) {
    try {
        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        console.log(from);
        var statusArray = [];
        for (const [i, item] of names.entries()) {
            if (isTerminated.current) {
                break;
            }
            const newMessage = message.replace("{name}", item.name);

            if (!files.length && !item.file) {
                var response = await sendMessage(item.phone, newMessage, from, apiAddress, secret, user);
            } else if (item.file) {
                response = await sendAttachments(item.phone, item.file, from, newMessage, apiAddress, secret, 2);
            } else {
                response = await sendAttachments(item.phone, files, from, newMessage, apiAddress, secret);
            }

            //added by wayne : 23-2-24 to add in poll object on whatsappbroadcast
            // let pollResponse;
            // console.log(pollObj);
            // if (pollObj?.pollOptions?.length !== 0) {
            //     pollResponse = await sendPoll(item.phone, from, apiAddress, secret, pollObj);
            //     console.log("pollResponse:", pollResponse);
            // }

            //********************************//

            console.log(response.status);
            let status;

            // if (pollResponse.status) status = "sent"; //added by wayne : 23-2-24 to add in poll object on whatsappbroadcast
            // if (!pollResponse.status) status = "failed"; //added by wayne : 23-2-24 to add in poll object on whatsappbroadcast
            // if (!pollResponse.status && pollResponse.message === "The number is not registered") status = "bad number"; //added by wayne : 23-2-24 to add in poll object on whatsappbroadcast

            if (response.status) {
                status = "sent";
            } else {
                status = "failed";
            }

            if (!response.status && response.message === "The number is not registered") status = "bad number";
            statusArray.push(status);
            setNames((prevState) => {
                const newNames = [...prevState];
                newNames[i].status = status;
                return newNames;
            });

            if (i < names.length - 1) {
                await delay(delayTime);
            } // Add a delay before sending the message
        }

        return Promise.resolve(statusArray);
    } catch (err) {
        console.log(err);
    }
}

//added by wayne : 23-2-24 to add in poll object on whatsappbroadcast
const sendPoll = async (number, from, apiAddress, secret, pollObj) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    console.log("pollObj:", pollObj);

    const requestBody = JSON.stringify({
        number: formatPhoneNumber(number),
        // message: message,
        from: from,
        secret,
        pollName: pollObj.pollName,
        pollOptions: pollObj.pollOptions
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestBody,
        redirect: "follow"
    };

    try {
        // const response = await fetch(`${apiAddress}send-message`, requestOptions);
        const response = await fetch(`${apiAddress}send-poll-message`, requestOptions);
        const result = await response.json();
        return result;
    } catch (error) {
        console.log("error", error);
        return { status: false };
    }
};
//********************************************************//

async function sendMessage(number, message, from, apiAddress, secret, user) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const metaData = { userId: user.id || "", userName: user.displayName || "" };
    console.log("metaData:", metaData);

    const requestBody = JSON.stringify({
        number: formatPhoneNumber(number),
        message: message,
        from: from,
        secret,
        metaData
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestBody,
        redirect: "follow"
    };

    try {
        const response = await fetch(`${apiAddress}send-message`, requestOptions);
        const result = await response.json();
        return result;
    } catch (error) {
        console.log("error", error);
        return { status: false };
    }
}

const sendAttachments = async (number, files, from, caption, apiAddress, secret, type = 1) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestBody;
    if (type === 2) {
        requestBody = JSON.stringify({
            number: formatPhoneNumber(number),
            caption: caption,
            from: from,
            downloadURL: files,
            secret
        });
    } else {
        requestBody = JSON.stringify({
            number: formatPhoneNumber(number),
            caption: caption,
            from: from,
            downloadURL: files[0].downloadURL,
            filename: files[0].name,
            secret
        });
    }

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestBody,
        redirect: "follow"
    };

    try {
        const response = await fetch(`${apiAddress}send-image-url-message`, requestOptions);
        const result = await response.json();
        return result;
    } catch (error) {
        console.log("error", error);
        return { status: false };
    }
};

export { sendMessage, sendAttachments };

function formatPhoneNumber(phone) {
    // Group number
    if (phone.includes("@g.us")) {
        return phone;
    }

    let p = phone;
    if (!p) return "";
    if (typeof p === "number") p = p.toString();
    let cleanedPhone = p.replace(/[^0-9]/g, "");

    if (cleanedPhone[0] === "0") {
        cleanedPhone = "6" + cleanedPhone;
    }
    return cleanedPhone;
}
