import { collection, getDocs, limit, orderBy, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";
import convertDate from "./../../utils-functions/convertDate";

const getLeadsByPageId = async (pageId, user, loadAll = false) => {
    const collectionRef = collection(db, "leads");
    let q;
    if (pageId === "all") {
        if (loadAll) {
            q = query(collectionRef, where("admins", "array-contains", user.id), orderBy("created", "desc"));
        } else {
            q = query(
                collectionRef,
                where("admins", "array-contains", user.id),
                orderBy("created", "desc"),
                limit(250)
            );
        }
    } else {
        if (loadAll) {
            q = query(
                collectionRef,
                where("page_id", "==", pageId),
                where("admins", "array-contains", user.id),
                orderBy("created", "desc")
            );
        } else {
            q = query(
                collectionRef,
                where("page_id", "==", pageId),
                where("admins", "array-contains", user.id),
                orderBy("created", "desc"),
                limit(250)
            );
        }
    }
    const snapshot = await getDocs(q);
    if (!snapshot.empty) {
        let leads = snapshot.docs.map((doc) => {
            let data = {};
            try {
                if (doc.data().field_data) {
                    doc.data().field_data.forEach((item) => {
                        if (item.name?.includes("name") || item.name?.includes("全名"))
                            data = { ...data, name: item.values[0] };
                        else if (item.name?.includes("email") || item.name?.includes("邮箱"))
                            data = { ...data, email: item.values[0] };
                        else if (item.name?.includes("phone") || item.name?.includes("手机号"))
                            data = { ...data, phone: item.values[0] };
                        else {
                            data = { ...data, [item.name]: item.values[0] };
                        }
                    });
                }
                let assignBy = doc.data().assignments?.[0]
                    ? doc.data().assignments?.[0]?.assignBy?.split("@")?.[0]
                    : "";
                let date = convertDate(doc.data().created);
                if (doc.data().type === "ASSIGNED_LEAD") {
                    date.setSeconds(date.getSeconds() - 1);
                } else {
                    assignBy = "";
                }
                return {
                    ...doc.data(),
                    ...data,
                    created: date,
                    assignBy,
                    id: doc.id
                };
            } catch (err) {
                console.log(err);
            }
        });
        leads.sort((a, b) => b.created - a.created);
        return leads;
    } else {
        return [];
    }
};

export default getLeadsByPageId;
