import { doc, getDoc, writeBatch } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import mapDocSnapshot from "../utils-functions/mapDocSnapshot";
import { sendTelegramMessage } from "./sendTelegramMessage";

export default async function assignLeads(user, assignTo, selectData, openNotification) {
    try {
        const batch = writeBatch(db);
        selectData.forEach((lead) => {
            let warriors = [assignTo.id];

            const assignment = {
                assign: { email: assignTo.id, name: assignTo.displayName },
                assignBy: user.id,
                date: new Date()
            };
            const assignments = [assignment];

            const leadId = lead.id + ":" + assignTo.id;
            const sourceLeadId = lead.id;
            const type = "ASSIGNED_LEAD";
            const newLead = {
                ...lead,
                id: leadId,
                assignedDate: new Date(),
                sourceLeadId,
                warriors,
                assignments,
                type,
                assignedLeads: []
            };

            const docRef = doc(db, "leads", leadId);
            batch.set(docRef, newLead, { merge: true });

            //Source Assignments
            let sourceAssignments = lead.assignments ? lead.assignments : [];
            sourceAssignments.push(assignment);
            const sourceDocRef = doc(db, "leads", sourceLeadId);
            batch.update(sourceDocRef, { assignments: sourceAssignments });
        });

        await batch.commit();
        console.log("Assignments completed");
        openNotification("Assignments done", "success");

        // Send Telegram message
        const friendRef = doc(db, "users", assignTo.id);
        const friendSnap = await getDoc(friendRef);
        const friend = mapDocSnapshot(friendSnap);
        const { chatId } = friend;
        if (chatId) {
            let telegramMessage = `You have been assigned ${selectData.length} leads by ${user.displayName}\n`;
            let displayData = selectData;
            if (displayData.length > 5) {
                displayData = displayData.slice(0, 5);
            }
            displayData.forEach((lead, i) => {
                telegramMessage += `\n--- ${i + 1} ---\n<b>Name: ${lead.name}</b> \nPhone: ${lead.phone}\nEmail: ${
                    lead.email
                } \nSource: ${lead.source} \nCampaign: ${lead.campaignName} \n`;
            });

            await sendTelegramMessage(chatId, telegramMessage);
            console.log("Telegram message sent to " + friend.displayName);
        }
    } catch (error) {
        console.log(error);
        openNotification(error.message, "error");
    }
}
