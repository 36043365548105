import { CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { loadActions } from "../functions/loadActions";
import { AuthContext } from "../context/AuthContext";
import { blue, green } from "@mui/material/colors";
import { Box } from "@mui/system";
import { GridContainer, GridFlexBox, IconButtonC, Name } from "../themes/themes";
import deleteAction from "../functions/deleteAction";
import UndoComponent from "./UndoComponent";
import { InputContext } from "../context-utils/InputContext";
import { addRemark } from "../functions/addRemark";

export default function LeadActions({ lead, leadsMode, limit, setOpenCard, setLead }) {
    const [actions, setActions] = useState([]);
    const [loading, setLoading] = useState(false);
    const { user } = useContext(AuthContext);
    const [undoAction, setUndoAction] = useState();

    const { openInputDialog } = useContext(InputContext);

    useEffect(() => {
        const unsubscribe = loadActions(lead, setActions, setLoading);
        return unsubscribe;
    }, []);

    const displayAction = (lead, action) => {
        if (leadsMode === "SOURCE_LEADS") {
            return true;
        }
        if (action.user === user.email) return true;
        if (lead.closers) {
            lead.closers.forEach((closer) => {
                if (closer === user.email) {
                    return true;
                }
            });
        }
    };

    const handleDelete = async (action) => {
        await deleteAction(lead, action);
        setUndoAction(action);
    };

    const handleAddRemark = async (action) => {
        const response = await openInputDialog("Remark", "Add remarks", "", "");
        await addRemark(action, lead, response);
    };

    const decideColor = (action) => {
        if (action.action === "Appointment") {
            return green[500];
        } else {
            return blue[500];
        }
    };

    const canDelete = (action, user) => {
        if (action.user === user.id) return true;
        if (user.role === "Super Admin") return true;
    };

    const [displayActions, moreActions] = useMemo(() => {
        let array = [];
        actions.forEach((action) => {
            if (displayAction(lead, action)) {
                array.push(action);
            }
        });
        let moreActions = false;
        if (array.length > limit) {
            moreActions = array.length - limit;
        }
        return [array, moreActions];
    }, [actions, limit]);

    const handleClickMoreActions = () => {
        setLead(lead);
        setOpenCard(true);
    };

    return (
        <Box>
            {loading && (
                <Box m={1}>
                    <CircularProgress size="15px" />
                </Box>
            )}
            {displayActions.slice(0, limit).map((action, i) => (
                <Grid
                    item
                    container
                    display={"flex"}
                    key={i}
                    onClick={() => {
                        console.log(actions);
                    }}
                >
                    <Grid item xs={4} md={4} display={"flex"}>
                        <Box>
                            <Typography variant="subtitle2" style={{ fontWeight: "bold", color: decideColor(action) }}>
                                {action.userName}
                            </Typography>
                            <Typography variant="subtitle2" style={{ color: decideColor(action) }}>
                                {moment(action.created).format("DD-MMM LT")}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={8} md>
                        <Box display={"flex"} alignItems={"center"}>
                            <Typography variant="subtitle2" style={{ fontWeight: "bold", color: decideColor(action) }}>
                                {action.action}
                            </Typography>
                            {canDelete(action, user) && (
                                <Box display={"flex"} alignItems={"center"}>
                                    <IconButtonC onClick={() => handleDelete(action)}>clear</IconButtonC>
                                    <IconButtonC onClick={() => handleAddRemark(action)}>edit</IconButtonC>
                                </Box>
                            )}
                        </Box>

                        <Typography variant="subtitle2" style={{ whiteSpace: "normal", color: decideColor(action) }}>
                            {action.remark}
                        </Typography>
                        {action.files &&
                            action.files.map((file) => (
                                <React.Fragment key={file.name}>
                                    <a
                                        href={file.downloadURL}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ textDecoration: "none" }} // Optional: Remove the underline styling from the link
                                    >
                                        <Typography variant="subtitle2" style={{ whiteSpace: "normal" }}>
                                            {file.name}
                                        </Typography>
                                    </a>
                                </React.Fragment>
                            ))}
                    </Grid>
                </Grid>
            ))}
            {moreActions && (
                <GridContainer>
                    <GridFlexBox fs>
                        <IconButton style={{ padding: 0 }} size="small" onClick={handleClickMoreActions}>
                            <Name color={blue[500]}>{`${moreActions} more actions...`}</Name>
                        </IconButton>
                    </GridFlexBox>
                </GridContainer>
            )}

            <UndoComponent lead={lead} undoAction={undoAction} setUndoAction={setUndoAction} />
        </Box>
    );
}
