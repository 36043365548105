import DeleteIcon from "@mui/icons-material/Delete";
import { Icon, MenuItem, Select } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Container } from "@mui/system";
import { collection, deleteDoc, doc, getDoc, getDocs, orderBy, query, setDoc } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DataFields from "../components/DataFields";
import { ListContext } from "../context-utils/ListContext";
import { AuthContext } from "../context/AuthContext";
import { LeadsContext } from "../context/LeadsContext";
import { StateContext } from "../context/StateContext";
import { StoreContext } from "../context/StoreContext";
import { UsersContext } from "../context/UsersContext";
import { db } from "../firebase/firebase-utils";
import loadUsersRT from "../functions/loadUsersRT";
import { ButtonC, GridContainer, GridDivider, GridFlexBox, GridItem, Loading, Title } from "../themes/themes";
import addState from "../utils-functions/addState";
import mapDocSnapshot from "../utils-functions/mapDocSnapshot";
import mapSnapshot from "../utils-functions/mapSnapshot";
import { arrayIsEmpty } from "./../utils-functions/arrayIsEmpty";

export default function UserCardPage() {
    const { user, setUser, setSignInAsUser } = useContext(AuthContext);
    const { users, setUsers, setUnsubscribeUsers } = useContext(UsersContext);
    const { openGlobalList } = useContext(ListContext);
    const { state, setState } = useContext(StateContext);
    const { quickAccesses, setQuickAccesses, clearStore } = useContext(StoreContext);
    const { setSourceLeads, unsubscribeSourceLeads, setAssignedLeads, unsubscribeLeads } = useContext(LeadsContext);

    const [signInAs, setSignInAs] = useState();
    const [displayUser, setDisplayUser] = useState();
    const [loading, setLoading] = useState(false);

    const { param } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) return;
        setDisplayUser(user);
    }, [user]);

    useEffect(() => {
        if (users) return;
        if (user.role === "Super Admin") {
            addState("authUser", user, state, setState);
            if (!users) loadData();
        } else {
            LoadAccessUsers();
        }

        if (param) {
            if (users) {
                const fUser = users.find((user) => user.id === param);
                if (fUser) setDisplayUser(fUser);
            }
        } else {
            setDisplayUser(user);
        }
    }, [user, users]);

    useEffect(() => {
        if (!state?.authUser) return;
        const loadData = async (authUser) => {
            const collectionRef = collection(db, "users", authUser.email, "quickAccesses");
            const q = query(collectionRef, orderBy("lastAccessed", "desc"));
            const snapshot = await getDocs(q);
            const quickAccesses = mapSnapshot(snapshot);
            setQuickAccesses(quickAccesses);
        };
        if (state.authUser) {
            loadData(state.authUser);
        }
    }, [state?.authUser]);

    const updateQuickAccess = (user) => {
        const newQuickAccesses = quickAccesses.filter((item) => item.id !== user.id);
        setQuickAccesses([user, ...newQuickAccesses]);
    };

    const LoadAccessUsers = async () => {
        let users = [];
        const accessToIds = user.accessToIds || [];
        console.log("accessToIds", accessToIds);
        await Promise.all(
            accessToIds.map(async (id) => {
                const userRef = doc(db, "users", id);
                const snapshot = await getDoc(userRef);
                const tempUser = mapDocSnapshot(snapshot);
                users.push(tempUser);
            })
        );
        console.log("users", users);
        if (!arrayIsEmpty(users)) {
            setUsers([user, ...users]);
        }
    };

    const loadData = () => {
        loadUsersRT(user, setUsers, setUnsubscribeUsers, setLoading);
    };

    const handleClick = async (quickAccess) => {
        console.log(quickAccess);
        if (quickAccess) {
            signInAsFn(quickAccess);
            return;
        }

        const response = await openGlobalList(users, "Sign in as", "displayName", "face", false, true, "email");
        if (response) {
            signInAsFn(response);
        }
    };

    const signInAsFn = async (member) => {
        if (!member?.id) return;

        // pull the latest user data
        const userRef = doc(db, "users", member.id);
        const snapshot = await getDoc(userRef);
        const signInUser = mapDocSnapshot(snapshot);

        // Save to quick access
        // user is the original master user
        // member is the user to sign in as
        const docRef = doc(db, "users", user.id, "quickAccesses", member.id);
        await setDoc(docRef, { ...signInUser, lastAccessed: new Date() });
        console.log("member saved to quick access", member);
        updateQuickAccess(signInUser);

        setDisplayUser(signInUser);
        setSignInAs(signInUser);
        setSignInAsUser(signInUser);
        setSourceLeads();
        setAssignedLeads();
        clearStore();
        unsubscribeSourceLeads?.();
        unsubscribeLeads?.forEach((fn) => fn?.());
    };

    const handleUpdate = (object, key, value) => {
        if (object.id === user.id) {
            const newUser = { ...user, [key]: value };
            setUser(newUser);
        }
    };

    const handleDeleteQuickAccess = async (id) => {
        const docRef = doc(db, "users", user.id, "quickAccesses", id);
        await deleteDoc(docRef);
        const newQuickAccesses = quickAccesses.filter((item) => item.id !== id);
        setQuickAccesses(newQuickAccesses);
    };

    return (
        <Container maxWidth="sm">
            <GridContainer>
                <GridFlexBox>
                    <Title>User Card</Title>
                </GridFlexBox>
                <Loading loading={loading} />
                <GridDivider />
                <GridFlexBox w="10%" fs>
                    <Icon fontSize="large">face</Icon>
                </GridFlexBox>
                <GridItem w="90%" fs fs20>
                    {displayUser && displayUser.displayName}
                </GridItem>
                <GridDivider />
                <DataFields object={displayUser} collectionId={"users"} handleUpdate={handleUpdate} state={state} />
                <SignInAsMember
                    state={state}
                    signInAs={signInAs}
                    handleClick={handleClick}
                    quickAccesses={quickAccesses}
                    handleClickQuickAccess={handleClick}
                    handleDeleteQuickAccess={handleDeleteQuickAccess}
                />
                <GridFlexBox fs w="50%">
                    <ButtonC color="warning" onClick={() => navigate(-1)} small>
                        Back
                    </ButtonC>
                </GridFlexBox>
                <GridFlexBox fe w="50%">
                    <ButtonC color="error" onClick={() => navigate("/sign-out")} small>
                        Sign Out
                    </ButtonC>
                </GridFlexBox>
                <GridDivider />
            </GridContainer>
        </Container>
    );
}

function SignInAsMember(props) {
    const { signInAs, handleClick, quickAccesses, handleClickQuickAccess, handleDeleteQuickAccess } = props;
    const [selectedQuickAccess, setSelectedQuickAccess] = React.useState("");

    const handleQuickAccessChange = (event) => {
        setSelectedQuickAccess(event.target.value);
        handleClickQuickAccess(event.target.value);
        console.log(event.target.value);
    };

    return (
        <React.Fragment>
            <GridItem fs fs20 w="30%">
                Sign in as:
            </GridItem>
            <GridFlexBox fs w="70%">
                <ButtonC small onClick={() => handleClick()}>
                    {signInAs ? signInAs.displayName : "Select member"}
                </ButtonC>
                <Select
                    value={selectedQuickAccess}
                    onChange={handleQuickAccessChange}
                    displayEmpty
                    size="small"
                    renderValue={(selected) => {
                        if (selected === "") {
                            return <em style={{ color: "#888" }}>Quick Access</em>;
                        }
                        return selected.email;
                    }}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {quickAccesses.map((access) => (
                        <MenuItem key={access.id} value={access}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "100%"
                                }}
                            >
                                {access.email}
                                <IconButton
                                    size="small"
                                    onClick={(event) => {
                                        event.stopPropagation(); // Prevent the select menu from closing
                                        handleDeleteQuickAccess(access.id);
                                    }}
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </div>
                        </MenuItem>
                    ))}
                </Select>
            </GridFlexBox>
            <GridDivider />
        </React.Fragment>
    );
}
