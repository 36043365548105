import { useCallback, useState } from "react";

import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";

// ----------------------------------------------------------------------

export default function FullScreenOption() {
    const [fullscreen, setFullscreen] = useState(false);

    const onToggleFullScreen = useCallback(() => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
            setFullscreen(true);
        } else if (document.exitFullscreen) {
            document.exitFullscreen();
            setFullscreen(false);
        }
    }, []);

    return (
        <Box sx={{ p: 1 }}>
            <ButtonBase
                onClick={onToggleFullScreen}
                sx={{
                    width: 1,
                    height: 48,
                    borderRadius: 1,
                    color: "text.disabled",
                    typography: "subtitle2"
                }}
            >
                {fullscreen ? <FullscreenExitIcon fontSize="small" /> : <FullscreenIcon fontSize="small" />}
            </ButtonBase>
        </Box>
    );
}
