import React, { useState, Fragment } from "react";
import { IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import UploadFilesButton from "../UploadFile/UploadFilesButton";
import { toast } from "react-hot-toast";
import ImageIcon from "@mui/icons-material/Image";

export default function AddItemsMenu({ image, setImage }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleFile = (files) => {
        setAnchorEl(null);
        console.log(files);
        if (!files[0].type.includes("image")) {
            toast.error("This is not an image file");
            return;
        }

        setImage(files[0]);
    };

    return (
        <Fragment>
            <IconButton onClick={handleMenuClick}>
                <AddIcon fontSize="large" />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
            >
                <MenuItem>
                    <ListItemIcon>
                        <ImageIcon />
                    </ListItemIcon>
                    <ListItemText>
                        <UploadFilesButton handleFile={handleFile} label="Add Image" variant="none" hover={false} />
                    </ListItemText>
                </MenuItem>
                {/* Add more menu items here */}
            </Menu>
        </Fragment>
    );
}
