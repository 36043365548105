import React from "react";
import { Button, IconButton, Tooltip } from "@mui/material";
import FullDialog from "../../components/FullDialog";
import UploadListingDocument from "../ListingPage/UploadListingDocument";

import { addDoc, collection,doc,setDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";

export const UploadListingFilesButton = ({    
    handleListingFile,
    label = "Upload",
    icon = null,
    currentListing,
    toolTip = ""
}) => {
    const [open, setOpen] = React.useState(false);
  
    const handleClose = (files) => {
        setOpen(false);
        saveFiles(files);
        // handleListingFile(files);
    };

    const handleClickExistingFile = (files) => {
        // setOpen(false);
        handleListingFile(files);
    };

    const saveFiles = async (files) => {
        try {
            const collectionRef = collection(db, "listings", currentListing.id, "files");
            console.log('UploadListingFilesButton:');
    
            // Use Promise.all to wait for all addDoc operations to complete
            const docRefs = await Promise.all(files.map(file => addDoc(collectionRef, file)));
            let newImageId = docRefs.map(docRef => docRef.id);
            console.log('newImageId:', newImageId);
    
            // Add the newImageId to currentListing and update in Firestore
            const listingsCollectionRef = doc(db, 'listings', currentListing.id);
            currentListing.propertyImg = [...(currentListing.propertyImg || []), ...newImageId];
    
            await setDoc(listingsCollectionRef, currentListing, { merge: true });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <React.Fragment>
            {icon ? (
                <Tooltip title={toolTip}>
                    <IconButton style={{ padding: "0 2px 0 2px" }} onClick={() => setOpen(true)}>
                        {icon}
                    </IconButton>
                </Tooltip>
            ) : (
                <Button variant="contained" onClick={() => setOpen(true)}>
                    {label}
                </Button>
            )}

            <FullDialog
                title={"Upload Files"}
                open={open}
                handleClose={() => setOpen(false)}
                Component={
                    <UploadListingDocument handleFile={handleClose} handleClickExistingFile={handleClickExistingFile} currentListing={currentListing}/>
                }
            />
        </React.Fragment>
    );
}
