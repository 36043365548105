import {
    BaseEdge,
    EdgeLabelRenderer,
    getStraightPath,
    getBezierPath,
    useReactFlow,
    MarkerType,
    getSimpleBezierPath
  } from 'reactflow';
  import { useTheme } from "@emotion/react";
  import { tokens } from '../../theme';
  import Button from '@mui/material/Button';
  import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
  import { useConfirmation } from '../../context-utils/ConfirmationContext';

 // CustomEdge component
 export default function CustomEdge({ id, sourceX, sourceY, targetX, targetY, onDelete }) {
    const confirmation = useConfirmation();
    const theme = useTheme();
  
    // Calculate the path and label position
    const [edgePath, labelX, labelY] = getBezierPath({
      sourceX,
      sourceY,
      targetX,
      targetY,
    });
  
    const edgeStyle = {
      labelBgPadding: [8, 4],
      labelBgBorderRadius: 4,
      labelBgStyle: { fill: "transparent" },
      labelStyle: { fill: "#fff" },
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 20,
        height: 20,
        color: "#FF0072",
      },
      style: {
        strokeWidth: 3,
        stroke: "#FF0072",
      },
      animated: true,
    };
  
    const handleConfirmDelete = async () => {
      console.log('id:',id);
      const response = await confirmation("Confirmed Delete?", "Are you sure you want to delete this edge?");
      if (response) {
        onDelete(id); // Use the passed onDelete function
      }
    };
  
    return (
      <>
        <BaseEdge
          id={id}
          path={edgePath}
          style={edgeStyle.style}
          markerEnd={edgeStyle.markerEnd}
          animated={edgeStyle.animated}
        />
        <EdgeLabelRenderer
          style={{
            ...edgeStyle.labelStyle,
            background: edgeStyle.labelBgStyle.fill,
            padding: `${edgeStyle.labelBgPadding[0]}px ${edgeStyle.labelBgPadding[1]}px`,
            borderRadius: edgeStyle.labelBgBorderRadius,
          }}
        >
          <Button
            variant="outlined"
            style={{
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              pointerEvents: 'all',
            }}
            sx={{
                backgroundColor: 'rgba(255, 59, 91, 0.5)',
                color: theme.palette.mode==='light'?'black':'white'
            }}
            className="nodrag nopan"
            onClick={handleConfirmDelete}
          >
            <DeleteForeverIcon />
          </Button>
        </EdgeLabelRenderer>
      </>
    );
  }
  
  