import { Button, Container, Icon, IconButton, InputAdornment } from "@mui/material";
import {
    collection,
    collectionGroup,
    doc,
    getDoc,
    getDocs,
    limit,
    orderBy,
    query,
    setDoc,
    startAfter,
    updateDoc,
    where
} from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { UsersContext } from "../context/UsersContext";
import { db } from "../firebase/firebase-utils";
import loadUsers from "../functions/loadUsers";
import { ButtonC, GridContainer, GridDivider, GridFlexBox, TextFieldC, Title } from "../themes/themes";
import mapSnapshot from "../utils-functions/mapSnapshot";

export default function TestPage() {
    const { users, setUsers } = useContext(UsersContext);
    const { user } = useContext(AuthContext);

    const [value, setValue] = useState();
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState("");

    useEffect(() => {
        if (!users) {
            loadData();
        }
    }, []);

    const loadData = async () => {
        const users = await loadUsers(user);
        setUsers(users);
        console.log(users);
        return users;
    };

    const handleClick = () => {};

    const handleAddClientIds = async () => {
        const collectionRef = collection(db, "users");
        const snapshot = await getDocs(collectionRef);
        const users = mapSnapshot(snapshot);
        console.log(users);
    };

    const handleAddCreatedTime = async () => {
        const collectionRef = collection(db, "leads");
        const q = query(collectionRef, orderBy("created", "desc"), limit(1000));
        const snapshot = await getDocs(q);
        const leads = mapSnapshot(snapshot);
        console.log(leads);
        leads.forEach(async (lead) => {
            if (!lead.created_time) {
                console.log(lead.id, " has not created_time");
                const docRef = doc(db, "leads", lead.id);
                await updateDoc(docRef, { created_time: lead.created });
                console.log("updated", lead.id);
            }
        });
    };

    const mapLeads = async () => {
        let lastVisible = null;
        let firstBatch = true;
        let continueFetching = true;

        while (continueFetching) {
            const collectionRef = collection(db, "leads");
            let q;
            console.log("lastVisible", lastVisible);

            if (firstBatch) {
                // Get the snapshot for the specific document
                const specificDocRef = doc(db, "leads", "pSWn2ZxfWOWlm86IBFfY");
                lastVisible = await getDoc(specificDocRef);
                if (!lastVisible.exists()) {
                    console.log("No such document!");
                    break; // Exit if the specific document does not exist
                }
                firstBatch = false;
            }

            if (lastVisible) {
                q = query(collectionRef, orderBy("created", "desc"), startAfter(lastVisible), limit(500));
            } else {
                q = query(collectionRef, orderBy("created", "desc"), limit(500));
            }
            const snapshot = await getDocs(q);

            if (!snapshot.docs.length) {
                continueFetching = false; // Stop if there are no documents
                break;
            }

            lastVisible = snapshot.docs[snapshot.docs.length - 1]; // Get the last document

            const leads = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            console.log(leads);

            // Process leads in parallel using Promise.all
            await Promise.all(
                leads.map(async (lead) => {
                    const docRef = doc(db, "leads", lead.id);
                    let data = {};

                    if (lead.field_data) {
                        lead.field_data.forEach((item) => {
                            if (item.name?.includes("name") || item.name?.includes("全名"))
                                data = { ...data, name: item.values[0] || "" };
                            else if (item.name?.includes("email") || item.name?.includes("邮箱"))
                                data = { ...data, email: item.values[0] || "" };
                            else if (item.name?.includes("phone") || item.name?.includes("手机号"))
                                data = { ...data, phone: item.values[0] || "" };
                        });
                    }

                    // await updateDoc(docRef, { ...data });
                    console.log("updated", lead.id);
                })
            );

            console.log(`Batch processed. Last processed lead ID: ${lastVisible.id}`);
        }
    };

    const handleSearch = async () => {
        console.log(search);
        let searchParameters = {
            q: search,
            query_by: "name"
        };

        const results = await typesenseClient.collections("leads").documents().search(searchParameters);
        console.log(results);
    };

    return (
        <Container>
            <GridContainer>
                <GridFlexBox>
                    <Title>Test Page</Title>
                </GridFlexBox>
                <GridDivider />
                <TextFieldC value={value} onChange={(e) => setValue(e.target.value)} />
                <GridFlexBox>
                    <ButtonC onClick={handleClick}>Change</ButtonC>
                </GridFlexBox>
                {[0, 1, 2].map((index) => (
                    <GridFlexBox key={index} index={index}>
                        Page {page + 1}
                    </GridFlexBox>
                ))}
                <GridFlexBox>
                    <IconButton onClick={() => setPage((page) => page - 1)}>
                        <Icon>remove</Icon>
                    </IconButton>
                    <IconButton onClick={() => setPage((page) => page + 1)}>
                        <Icon>add</Icon>
                    </IconButton>
                </GridFlexBox>
                <InputDisplay />
                <GridFlexBox>
                    <Button onClick={handleDownloadLeads}>Download Leads</Button>
                </GridFlexBox>
                <GridFlexBox>
                    <Button onClick={handleSeedLeads} variant="contained">
                        Seed Leads
                    </Button>
                </GridFlexBox>
                <GridFlexBox>
                    <Button onClick={handleSeedActions} variant="contained">
                        Seed Actions
                    </Button>
                </GridFlexBox>
                <GridFlexBox>
                    <Button onClick={handleAddCreatedTime} variant="contained">
                        Add created_time
                    </Button>
                </GridFlexBox>
                <GridFlexBox>
                    <Button variant="contained" onClick={() => mapLeads()}>
                        Map Leads
                    </Button>
                </GridFlexBox>
                <GridFlexBox m3>
                    <TextField
                        label="Search leads"
                        size={"small"}
                        value={search}
                        autoComplete="off"
                        onChange={(e) => setSearch(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleSearch} size="small">
                                        <Icon>search</Icon>
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </GridFlexBox>
            </GridContainer>
        </Container>
    );
}

const handleDownloadLeads = async () => {
    const collectionRef = collection(db, "leads");
    const q = query(collectionRef, limit(100));
    const snapshot = await getDocs(q);
    let leads = mapSnapshot(snapshot);
    let mainActions = [];
    await Promise.all(
        leads.map(async (lead) => {
            const actionsRef = collection(db, "leads", lead.id, "actions");
            const actionsSnapshot = await getDocs(actionsRef);
            let actions = mapSnapshot(actionsSnapshot);
            actions.forEach((action) => {
                action = { ...action, leadId: lead.id };
                mainActions.push(action);
            });
            const newAdmins = [...lead.admins, "rehmance39b@gmail.com"];
            lead.admins = newAdmins;
            return Promise.resolve(null);
        })
    );
    console.log(leads);
    console.log(mainActions);
};

const handleSeedLeads = async () => {
    sampleLeads.forEach(async (lead) => {
        const docRef = doc(db, "leads", lead.id);
        convertSecondsToDates(lead);
        await setDoc(docRef, { ...lead, admins: [...lead.admins, "daveckw@gmail.com"] }, { merge: true });
        console.log(lead.id + " updated");
    });
};

const handleSeedActions = async () => {
    sampleActions.forEach(async (action) => {
        const docRef = doc(db, "leads", action.leadId, "actions", action.id);
        await setDoc(docRef, action, { merge: true });
        console.log(action.id + " updated");
    });
};

const loadAllActions = async (users) => {
    try {
        if (!users) return;
        const q = query(
            collectionGroup(db, "actions"),
            where("created", ">=", new Date("2023-01-01")),
            where("created", "<=", new Date("2023-02-31"))
        );
        const snapshot = await getDocs(q);
        const actions = mapSnapshot(snapshot);
        console.log(actions);
        actions.forEach(async (action) => {
            const leadId = action.path.split("/")[1];
            const user = users.find((u) => u.id === action.user);
            const userName = user.displayName;
            const docRef = doc(db, "leads", leadId, "actions", action.id);
            await updateDoc(docRef, { userName: userName });
            console.log(`ActionId: ${action.id} for lead ${leadId} updated | userName: ${userName}`);
        });
        return actions;
    } catch (error) {
        console.log(error);
    }
};

import { TextField, Typography } from "@mui/material";
import { sampleActions } from "./sampleActions";
import { sampleLeads } from "./sampleLeads";
import { set } from "lodash";
import typesenseClient from "../typesense/typesense";

function InputDisplay() {
    const [userInput, setUserInput] = useState("");

    function handleInputChange(event) {
        setUserInput(event.target.value);
    }

    return (
        <div>
            <TextField label="Enter your input" variant="outlined" onChange={handleInputChange} fullWidth />
            <Typography variant="body1" gutterBottom>
                {userInput}
            </Typography>
        </div>
    );
}

function convertSecondsToDates(obj) {
    for (let prop in obj) {
        if (typeof obj[prop] === "object" && obj[prop] !== null) {
            if ("seconds" in obj[prop] && "nanoseconds" in obj[prop]) {
                let date = new Date(obj[prop]["seconds"] * 1000);
                obj[prop] = date;
            } else {
                convertSecondsToDates(obj[prop]);
            }
        }
    }
}
