import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DJCLogoGrey from "../assets/DJCLogoGrey.png";
import DJCLogoWhite from "../assets/DJCLogoWhite.png";
import MenuDropdown from "./MenuDropdown";
import EliteAlpha from "../assets/EliteAlpha.png";
import SimplyNiceLogo from "../assets/SimplyNiceLogoWhite.png";

import { useTheme } from "@mui/material";
import { MENU_ITEMS, MENU_ITEMS_SIGNED_OUT } from "../settings/settings";
import { ColorModeContext, tokens } from "../theme";
import FullScreenOption from "./FullScreenFullScreenOption";

export default function Menu({ user }) {
    const [anchorEl, setAnchorEl] = useState(false);
    const [menu, setMenu] = useState(MENU_ITEMS);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);

    const navigate = useNavigate();

    const host = window.location.hostname;

    if (host === "elitealpha.tech") {
        var logo = EliteAlpha;
        var logoWhite = EliteAlpha;
    } else if (host === "simplynice.ai") {
        logo = SimplyNiceLogo;
        logoWhite = SimplyNiceLogo;
    } else {
        logo = DJCLogoGrey;
        logoWhite = DJCLogoWhite;
    }

    useEffect(() => {
        if (!user) {
            setMenu(MENU_ITEMS_SIGNED_OUT);
        } else {
            setMenu(MENU_ITEMS);
        }
    }, [user]);

    const handleClick = (event) => {
        if (anchorEl) {
            setAnchorEl(null);
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = (event) => {
        const action = event.currentTarget.getAttribute("data-index");
        console.log(action);
        setAnchorEl(null);
        menu.every((item) => {
            if (item.action === action) {
                navigate(item.url);
                return false;
            }
            return true;
        });
    };

    return (
        <Box sx={{ flexGrow: 1 }} pt={"55px"}>
            <AppBar position="fixed" style={{ backgroundColor: colors.primary[400], height: "55px" }}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        aria-label="menu"
                        sx={{ color: colors.grey[100] }}
                        onClick={handleClick}
                    >
                        <MenuIcon />
                    </IconButton>
                    <MenuDropdown
                        anchorEl={anchorEl}
                        handleClose={handleClose}
                        handleClick={handleClick}
                        menuDropDown={menu}
                        user={user}
                    />

                    <Typography
                        variant="body"
                        sx={{ flexGrow: 1 }}
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate("/")}
                    >
                        <img
                            width="100px"
                            alt="logo"
                            src={theme.palette.mode === "dark" ? logoWhite : logo}
                            style={{ paddingBottom: "8px" }}
                        />
                    </Typography>
                    <FullScreenOption />
                    <IconButton onClick={() => colorMode.toggleColorMode(user)}>
                        {theme.palette.mode === "dark" ? <DarkModeOutlinedIcon /> : <LightModeOutlinedIcon />}
                    </IconButton>
                    {user && (
                        <Button color="primary" onClick={() => navigate("/user-card-page")}>
                            <Typography variant="h7" sx={{ flexGrow: 1, color: colors.grey[100] }}>
                                {user.displayName || ""}
                            </Typography>
                        </Button>
                    )}
                </Toolbar>
            </AppBar>
        </Box>
    );
}
