import {
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Checkbox,
    Paper,
    Grid,
    Button,
    TextField,
    IconButton,
    Autocomplete,
    Typography
} from "@mui/material";
import { useMemo, useState } from "react";
import { GridDivider, Loading } from "../themes/themes";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SearchIcon from "@mui/icons-material/Search";
import { arrayIsEmpty } from "../utils-functions/arrayIsEmpty";

const SavedContactList = ({
    names,
    setNames,
    contactList,
    setContactList,
    loadTag,
    setLoadTag,
    loadingTag,
    setLoadingTag,
    loadUserSavedContact,
    user
}) => {
    const [checked, setChecked] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    const filteredContacts = useMemo(() => {
        if (arrayIsEmpty(contactList)) {
            return [];
        }
        return contactList.filter((contact) => {
            const nameMatch = contact.name.toLowerCase().includes(searchQuery.toLowerCase());
            const phoneMatch = contact.phone.toLowerCase().includes(searchQuery.toLowerCase());
            const tagsMatch = contact.tags.some((tag) => tag.toLowerCase().includes(searchQuery.toLowerCase()));

            return nameMatch || phoneMatch || tagsMatch;
        });
    }, [contactList, searchQuery]);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleToggleAll = () => {
        if (checked.length === filteredContacts.length) {
            setChecked([]);
        } else {
            setChecked(filteredContacts);
        }
    };

    const handleAddName = () => {
        const contactNames = checked.map((item) => {
            return { name: item.name, phone: item.phone, status: "" };
        });

        if (names.length > 0) {
            setNames([...names, ...contactNames]);
        } else {
            setNames(contactNames);
        }
    };

    const handleBackClick = () => {
        setLoadTag("");
        setContactList(null);
    };

    return (
        <Paper sx={{ height: "45vh", width: "100%", padding: "8px", overflow: "auto" }}>
            <Grid container display="flex" sx={{ flexDirection: "column", justifyContent: "flex-start" }}>
                {filteredContacts.length > 0 ? (
                    <Grid item xs={12} sx={{ width: "100%" }}>
                        <IconButton size="small" onClick={handleBackClick}>
                            <ArrowBackIosIcon />
                        </IconButton>
                        <TextField
                            label="Search"
                            variant="outlined"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            size="small"
                        />
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={10}>
                                <Autocomplete
                                    options={user.userClientTags || []}
                                    value={loadTag}
                                    onChange={(event, newValue) => {
                                        setLoadTag(newValue);
                                    }}
                                    freeSolo
                                    renderInput={(params) => (
                                        <TextField {...params} label="Load tags" variant="outlined" size="small" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <IconButton onClick={loadUserSavedContact}>
                                    <SearchIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                <GridDivider />
                {contactList && loadTag && (
                    <Grid item xs={12}>
                        <Typography variant="body2" sx={{ fontStyle: "italic", color: "grey", fontSize: "small" }}>
                            Tags: {loadTag.toString() || ""}
                        </Typography>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color={"primary"}
                        size="small"
                        startIcon={<AddIcon />}
                        disableElevation
                        onClick={handleAddName}
                    >
                        Add ({checked.length})
                    </Button>
                </Grid>
                <GridDivider />
                <Grid item xs={12}>
                    <List
                        sx={{
                            width: "100%",
                            bgcolor: "background.paper",
                            height: "inherit",
                            overflowY: "auto",
                            paddingTop: "0"
                        }}
                    >
                        <ListItem
                            sx={{
                                position: "sticky",
                                top: 0,
                                zIndex: 99,
                                backgroundColor: "background.paper",
                                boxShadow:
                                    "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
                            }}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={
                                        checked.length === filteredContacts.length && filteredContacts.length !== 0
                                    }
                                    indeterminate={checked.length > 0 && checked.length < contactList.length}
                                    tabIndex={-1}
                                    disableRipple
                                    onClick={handleToggleAll}
                                />
                            </ListItemIcon>
                            <ListItemText primary="Select All" />
                        </ListItem>
                        <Loading loading={loadingTag} />
                        {filteredContacts &&
                            filteredContacts.map((contact) => {
                                const labelId = `checkbox-list-label-${contact.id}`;

                                return (
                                    <ListItem key={contact.id} disablePadding>
                                        <ListItemButton role={undefined} onClick={handleToggle(contact)} dense>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={checked.indexOf(contact) !== -1}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ "aria-labelledby": labelId }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText
                                                primaryTypographyProps={{
                                                    style: {
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis"
                                                    }
                                                }}
                                                id={labelId}
                                                primary={contact.name}
                                                secondary={`${contact.phone} | ${contact.tags.join(",")}`}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })}
                    </List>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default SavedContactList;
