import { Box, Container, FormControl, FormControlLabel, FormGroup, Grid, Switch } from "@mui/material";
import { blue, green, red, grey } from "@mui/material/colors";
import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import { addDoc, collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import { GridDivider, Loading, Name, Title } from "../../themes/themes";
import { arrayIsEmpty } from "../../utils-functions/arrayIsEmpty";
import convertDate from "../../utils-functions/convertDate";
import formatDate from "./../../utils-functions/formatDate";
import formatNumber from "./../../utils-functions/formatNumber";
import getSubscription from "./getSubscription";
import getTransfers from "./getTransfers";
import MoneyIcon from "@mui/icons-material/Money";
import { useInput } from "../../context-utils/InputContext";
import { toast } from "react-hot-toast";

export default function CommissionPage() {
    const { user } = useContext(AuthContext);
    const [referees, setReferees] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tableView, setTableView] = useState(false);
    const [referrals, setReferrals] = useState([]);
    const [paymentLoadings, setPaymentLoadings] = useState([]);
    const [paymentStatus, setPaymentStatus] = useState([]);

    useEffect(() => {
        const getData = async () => {
            const collectionRef = collection(db, "users");
            let q;
            if (user.role === "Super Admin") {
                q = query(collectionRef);
            } else {
                q = query(collectionRef, where("referral", "==", user.id));
            }
            setLoading(true);
            const snapshot = await getDocs(q);
            let referees = await Promise.all(
                snapshot.docs.map(async (doc) => {
                    const subscription = await getSubscription(doc.id);
                    return {
                        id: doc.id,
                        ...doc.data(),
                        ...subscription
                    };
                })
            );
            referees = referees.filter((referee) => referee.price);
            referees.sort((a, b) => convertDate(b.date) - convertDate(a.date));
            console.log(referees);
            setReferees(referees);
            setLoading(false);
        };

        getData();
    }, []);

    useEffect(() => {
        if (arrayIsEmpty(referees)) return;
        const getData = async () => {
            let referralsObj = {};
            referees.forEach((referee) => {
                let totalPayments = 0;
                if (!referee.referral) referee.referral = "daveckw@gmail.com";
                referee.payments?.forEach((payment) => {
                    if (payment) {
                        totalPayments += payment.amount_received / 100;
                    }
                });

                // Check if the object for this referral exists, if not, initialize it
                if (!referralsObj[referee.referral]) {
                    referralsObj[referee.referral] = { totalPayments: 0 };
                }

                // Now safely update the totalPayments
                referralsObj[referee.referral].totalPayments += totalPayments;
            });

            await Promise.all(
                Object.keys(referralsObj).map(async (referral) => {
                    const docRef = doc(db, "users", referral);
                    const snapshot = await getDoc(docRef);
                    const displayName = snapshot.data().displayName;
                    const connectedStripeId = snapshot.data().connectedStripeId;
                    const phone = snapshot.data().phone;
                    referralsObj[referral].displayName = displayName;
                    referralsObj[referral].connectedStripeId = connectedStripeId;
                    referralsObj[referral].phone = phone;

                    const totalCommissionPaid = await getTransfers({ connectedStripeId });
                    referralsObj[referral].totalCommissionPaid = totalCommissionPaid;

                    // Code to calulate balance here
                    const balance =
                        referralsObj[referral].totalPayments * 0.2 - referralsObj[referral].totalCommissionPaid;
                    referralsObj[referral].balance = balance;
                })
            );

            console.log(referralsObj);
            let referralsArray = [];
            Object.keys(referralsObj).forEach((referral) => {
                referralsArray.push({
                    id: referral,
                    ...referralsObj[referral]
                });
            });
            referralsArray.sort((a, b) => b.totalPayments - a.totalPayments);
            console.log(referralsArray);
            setReferrals(referralsArray);
        };
        getData();
    }, [referees]);

    const totalMrr = useMemo(() => {
        return referees.reduce((acc, referee) => {
            if (referee.subscriptionStatus === "active" || referee.subscriptionStatus === "trialing") {
                return acc + (referee.mrr || 0);
            } else {
                return acc;
            }
        }, 0);
    }, [referees]);

    const input = useInput();

    const sendMoney = async (row) => {
        console.log(row);
        const response = await input("Amount", "Amount to send", "Amount", row.balance);
        if (response) {
            const collectionRef = collection(db, "stripeCreatePayments");
            if (!row.connectedStripeId) {
                toast.error("No connected stripe account");
                return;
            }
            const ref = await addDoc(collectionRef, {
                amount: parseFloat(response),
                connectedStripeId: row.connectedStripeId,
                date: new Date(),
                currency: "myr",
                displayName: row.displayName,
                email: row.id
            });

            const newPaymentLoadings = [...paymentLoadings, row.id];
            setPaymentLoadings(newPaymentLoadings);
            const newPaymentStatus = [...paymentStatus, { id: ref.id, rowId: row.id, status: "processing" }];
            setPaymentStatus(newPaymentStatus);
        }
    };

    const columns = [
        { field: "id", headerName: "id", width: 200 },
        { field: "displayName", headerName: "Name", width: 150 },
        { field: "phone", headerName: "Phone", width: 150 },
        {
            field: "totalPayments",
            headerName: "Total Payments Received",
            width: 150,
            align: "right", // Right align the cell content
            headerAlign: "right", // Right align the header content
            renderCell: (params) => formatNumber(params.value, 2)
        },
        {
            field: "totalCommissionPaid",
            headerName: "Total Commission Paid",
            width: 150,
            align: "right", // Right align the cell content
            headerAlign: "right", // Right align the header content
            renderCell: (params) => formatNumber(params.value, 2)
        },
        {
            field: "balance",
            headerName: "Balance",
            align: "right", // Right align the cell content
            headerAlign: "right", // Right align the header content
            renderCell: (params) => (
                <Name color={params.value > 0.1 ? green[500] : grey[500]}>{formatNumber(params.value, 2)}</Name>
            )
        },
        {
            field: "connectedStripeId",
            headerName: "Stripe Id",
            width: 80
        },
        {
            field: "sendMoney",
            role: "Super Admin",
            type: "actions",
            width: 50,
            getActions: (params) => [
                <GridActionsCellItem
                    disabled={paymentLoadings.includes(params.id)}
                    key={params.id}
                    icon={<MoneyIcon />}
                    label="Delete"
                    onClick={() => sendMoney(params.row)}
                />
            ]
        }
    ];

    const columnsToDisplay = useMemo(() => {
        if (user.role === "Super Admin") {
            return columns;
        } else {
            return columns.filter((column) => column.role !== "Super Admin");
        }
    }, [user]);

    const totalPayments = useMemo(() => {
        return referees.reduce((acc, referee) => {
            if (referee.payments) {
                return (
                    acc +
                    referee.payments.reduce((acc, payment) => {
                        if (payment) {
                            return acc + payment.amount_received / 100;
                        } else {
                            return acc;
                        }
                    }, 0)
                );
            } else {
                return acc;
            }
        }, 0);
    }, [referees]);

    const handleChange = (event) => {
        setTableView(event.target.checked);
    };

    return (
        <Container maxWidth="lg">
            <Grid container>
                <Grid item xs={12}>
                    <Box display="flex">
                        <Title>Commission Page</Title>
                        <FormControl component="fieldset" variant="standard" sx={{ marginLeft: "8px" }}>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Switch checked={tableView} onChange={handleChange} />}
                                    label="Table View"
                                />
                            </FormGroup>
                        </FormControl>
                    </Box>
                </Grid>
                <Loading loading={loading} />
                <GridDivider />
                {tableView ? (
                    // Table view
                    <>
                        <DataGrid
                            columns={columnsToDisplay}
                            rows={referrals}
                            slots={{ toolbar: () => <GridToolbar /> }}
                        />
                    </>
                ) : (
                    // Flexbox View
                    <>
                        {referees.map((referee) => {
                            if (referee.price)
                                return (
                                    <React.Fragment key={referee.id}>
                                        <Grid item display={"flex"} xs={2}>
                                            <Name>{formatDate(convertDate(referee.date))}</Name>
                                        </Grid>
                                        <Grid item display={"flex"} xs={2}>
                                            <Name nowrap>{referee.displayName}</Name>
                                        </Grid>
                                        <Grid item display={"flex"} xs={2}>
                                            <Name nowrap>{referee.referral || ""}</Name>
                                        </Grid>
                                        <Grid item display={"flex"} xs={2} justifyContent={"center"}>
                                            <Name nowrap color={checkColor(referee.subscriptionStatus)}>
                                                {referee.subscriptionStatus}
                                            </Name>
                                        </Grid>
                                        <Grid item display={"flex"} xs={3} justifyContent={"flex-end"}>
                                            <Box display={"flex"} alignItems={"center"} gap="8px">
                                                <Name>{formatNumber(referee.price || 0, 0)}</Name>
                                                <Name>{referee.paymentInterval}</Name>
                                            </Box>
                                        </Grid>
                                        <Grid item display={"flex"} xs={1} justifyContent={"flex-end"}>
                                            <Name>{formatNumber(referee.mrr, 0)}</Name>
                                        </Grid>
                                        {referee.payments?.map((payment, i) => {
                                            return (
                                                <React.Fragment key={i}>
                                                    {payment && (
                                                        <>
                                                            <Grid
                                                                item
                                                                display={"flex"}
                                                                xs={2}
                                                                justifyContent={"flex-start"}
                                                            >
                                                                <Name color={blue[500]}>
                                                                    {formatDate(convertDate(payment.date))}
                                                                </Name>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                display={"flex"}
                                                                xs={2}
                                                                justifyContent={"flex-start"}
                                                            >
                                                                <Name color={blue[500]}>Received</Name>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                display={"flex"}
                                                                xs={8}
                                                                justifyContent={"flex-end"}
                                                            >
                                                                <Name color={blue[500]}>
                                                                    {formatNumber(payment?.amount_received / 100, 0)}
                                                                </Name>
                                                            </Grid>
                                                        </>
                                                    )}
                                                </React.Fragment>
                                            );
                                        })}

                                        <GridDivider />
                                    </React.Fragment>
                                );
                        })}
                    </>
                )}

                <Grid item display={"flex"} xs={12} justifyContent={"flex-end"}>
                    <Name color={green[500]}>Total estimated MRR: {formatNumber(totalMrr, 2)}</Name>
                </Grid>
                <Grid item display={"flex"} xs={12} justifyContent={"flex-end"}>
                    <Name color={green[500]}>Total estimated MRC: {formatNumber(totalMrr * 0.2, 2)}</Name>
                </Grid>
                <Grid item display={"flex"} xs={12} justifyContent={"flex-end"}>
                    <Name color={blue[500]}>Total payments received: {formatNumber(totalPayments, 2)}</Name>
                </Grid>
            </Grid>
        </Container>
    );
}

const checkColor = (status) => {
    switch (status) {
        case "active":
            return green[500];
        case "canceled":
            return red[500];
        case "trialing":
            return blue[500];
        default:
            return "black";
    }
};
