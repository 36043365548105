import { Box, Grid, IconButton, Slide } from "@mui/material";
import { ChipC, GridFlexBox, Name, Title } from "../themes/themes";
import { useTheme } from "@emotion/react";
import { tokens } from "../theme";
import React, { useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { arrayIsEmpty } from "../utils-functions/arrayIsEmpty";

export default function SearchBar({ searchAssign, searchLabel, setSearchAssign, setSearchLabel }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [open, setOpen] = useState(false);

    useEffect(() => {
        console.log(searchLabel);
        if (searchAssign || searchLabel) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [searchAssign, searchLabel]);

    const handleClearLabel = (label) => {
        setSearchLabel((prevState) => {
            if (prevState) {
                const newState = prevState.filter(
                    (l) => l.label.trim().toLowerCase() !== label.label.trim().toLowerCase()
                );
                if (arrayIsEmpty(newState)) return null;
                return newState;
            }
        });
    };

    return (
        <Grid
            container
            display={open ? "flex" : "none"}
            position={"fixed"}
            bottom={"5px"}
            right={"10px"}
            width={"78vw"}
            height="120px"
            alignItems={"center"}
            style={{ zIndex: 10000000 }}
        >
            <Slide direction="right" in={open}>
                <Grid
                    container
                    border={"solid 1px"}
                    borderRadius={"10px"}
                    style={{ backgroundColor: colors.primary[900] }}
                    padding={"8px"}
                    alignItems={"center"}
                >
                    <GridFlexBox xs={2} fs>
                        <Name>Search</Name>
                    </GridFlexBox>
                    <GridFlexBox xs={4} fs>
                        {searchAssign && (
                            <>
                                <ChipC color="green">{searchAssign}</ChipC>
                                <IconButton size="small" style={{ paddingLeft: 0 }} onClick={() => setSearchAssign("")}>
                                    <ClearIcon />
                                </IconButton>
                            </>
                        )}
                    </GridFlexBox>
                    <GridFlexBox xs={6} fs fw>
                        {searchLabel?.map((sLabel) => (
                            <React.Fragment key={sLabel.label}>
                                <ChipC bgcolor={sLabel.color} fontColor={sLabel.fontColor}>
                                    {sLabel.label}
                                </ChipC>
                                <IconButton
                                    size="small"
                                    style={{ paddingLeft: 0 }}
                                    onClick={() => handleClearLabel(sLabel)}
                                >
                                    <ClearIcon />
                                </IconButton>
                            </React.Fragment>
                        ))}
                    </GridFlexBox>
                </Grid>
            </Slide>
        </Grid>
    );
}
