import { addDoc, collection } from "firebase/firestore";
import app, { db } from "../firebase/firebase-utils";

export default async function addAppointment(user, appointment, reminderId) {
    try {
        console.log(appointment);
        const collectionRef = collection(db, "users", user.id, "appointments");
        const { id } = await addDoc(collectionRef, { ...appointment, reminderId });
        console.log("Appointment added into Firestore", id);
        return id;
    } catch (error) {
        console.log(error);
    }
}
