import { Box, Button, Container, Icon, IconButton, LinearProgress, Typography } from "@mui/material";
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import {
    arrayRemove,
    arrayUnion,
    collection,
    doc,
    // limit,
    onSnapshot,
    orderBy,
    query,
    updateDoc,
    where
} from "firebase/firestore";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import useChooseFriend from "../../hooks/useChooseFriend";
import { arrayIsEmpty } from "../../utils-functions/arrayIsEmpty";
import mapSnapshot from "../../utils-functions/mapSnapshot";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";
import convertDate from "../../functions/common-functions/convertDate";
import formatDate from "../../functions/common-functions/formatDate";

export default function ListScheduledMessagesPage() {
    const [scheduledTasks, setScheduledTasks] = useState([]);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({
        id: false
    });
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [loading, setLoading] = useState(false);

    const { user } = useContext(AuthContext);

    const chooseContact = useChooseFriend();

    const handleAddAdmin = async (event, company) => {
        event.stopPropagation();
        setLoading(true);
        const response = await chooseContact();
        console.log("response", response);
        if (response) {
            const docRef = doc(db, "companies", company.id);
            await updateDoc(docRef, { admins: arrayUnion(response.id) });
            console.log("Admin added");
        }
        setLoading(false);
    };

    const confirmation = useConfirmation();

    const handleDeleteAdmin = async (event, company, admin) => {
        console.log(company, admin);
        event.stopPropagation();
        const response = await confirmation("Remove admin", "Are you sure you want to delete this admin?");
        if (response) {
            const docRef = doc(db, "companies", company.id);
            await updateDoc(docRef, { admins: arrayRemove(admin) });
            console.log("Admin deleted");
        }
    };

    // eslint-disable-next-line
    const renderAdmins = (params) => {
        const { value } = params;
        return (
            <Box>
                {value.map((admin) => (
                    <Box key={admin} display="flex" alignItems="center" gap={2} justifyContent="space-between">
                        <Box>
                            <Icon icon="ri:admin-fill" size="small" />
                        </Box>
                        <Box display="flex" justifyContent="flex-start" flexGrow={1}>
                            <Typography variant="caption">{admin}</Typography>
                        </Box>
                        <IconButton
                            size="small"
                            onClick={(event) => handleDeleteAdmin(event, params.row, admin)}
                            color="error"
                        >
                            <Icon fontSize="small">delete</Icon>
                        </IconButton>
                    </Box>
                ))}
                <IconButton size="small" onClick={(event) => handleAddAdmin(event, params.row)}>
                    <Icon>add</Icon>
                </IconButton>
            </Box>
        );
    };

    // Define the columns for the DataGrid
    const columns = useMemo(() => {
        if (arrayIsEmpty(scheduledTasks)) {
            return [];
        }

        return [
            { field: "id", headerName: "ID", width: 250, align: "center", headerAlign: "center" },
            {
                field: "scheduledTime",
                headerName: "Date",
                width: 150,
                align: "center",
                headerAlign: "center",
                renderCell: (params) => {
                    return <Box>{formatDate(convertDate(params.value), true)}</Box>;
                }
            },
            {
                field: "createdBy",
                headerName: "Created By",
                width: 250,
                align: "center",
                headerAlign: "center"
            },
            {
                field: "params",
                headerName: "Params",
                width: 300,
                align: "center",
                headerAlign: "center",
                renderCell: (params) => {
                    const { value } = params;
                    return (
                        <>
                            <Box display="flex" alignItems={"center"} gap={1}>
                                <Typography variant="caption">Message: </Typography>
                                <Typography variant="caption">{value.message}</Typography>
                            </Box>
                            <Box display="flex" alignItems={"center"} gap={1}>
                                <Typography variant="caption">Files: </Typography>
                            </Box>
                            {value.files?.map((file, i) => (
                                <React.Fragment key={`${file.downloadURL || ""}${i}`}>
                                    <Box>
                                        <a href={`${file.downloadURL}`} target="_blank" rel="noreferrer">
                                            {file.name || ""}
                                        </a>
                                    </Box>
                                    <Box>{file.type}</Box>
                                </React.Fragment>
                            ))}
                        </>
                    );
                }
            },
            {
                field: "message",
                headerName: "Status Mesage",
                width: 100,
                align: "center",
                headerAlign: "center"
            }
        ];
        //
    }, [scheduledTasks]);

    useEffect(() => {
        if (user) {
            // fetch companies
            const collectionRef = collection(db, "scheduledTasks");
            const q = query(
                collectionRef,
                orderBy("scheduledTime", "desc"),
                where("scheduledTime", ">=", new Date("2024-03-26")),
                where("scheduledTime", "<=", new Date())
            );
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                setLoading(true);
                const scheduledTasks = mapSnapshot(querySnapshot);
                setScheduledTasks(scheduledTasks);
                setLoading(false);
            });

            return unsubscribe;
        }
    }, [user]);

    return (
        <Container>
            {loading && (
                <Box width="100%">
                    <LinearProgress />
                </Box>
            )}

            <Box display="flex" alignItems="center" sx={{ height: "calc(100vh - 120px)", width: "100%" }}>
                <DataGrid
                    columns={columns}
                    rows={scheduledTasks}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 10 } }
                    }}
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    checkboxSelection
                    getRowId={(row) => row.id}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                    getRowHeight={() => "auto"}
                    getEstimatedRowHeight={() => 200}
                    disableRowSelectionOnClick
                    slots={{
                        toolbar: () => (
                            <CustomToolbar
                                // onDelete={deleteLeads}
                                // onChange={handleChangeField}
                                disabled={rowSelectionModel.length === 0}
                            />
                        ),
                        noRowsOverlay: CustomNoRowsOverlay
                    }}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setRowSelectionModel(newRowSelectionModel);
                    }}
                    sx={{
                        "--DataGrid-overlayHeight": "300px",
                        "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                            py: "2px"
                        },
                        "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                            py: "8px"
                        },
                        "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                            py: "16px"
                        }
                    }}
                />
            </Box>
        </Container>
    );
}

function CustomToolbar({ onDelete, disabled, onChange }) {
    //
    const { user } = useContext(AuthContext);

    return (
        <GridToolbarContainer>
            <GridToolbar />
            <GridToolbarQuickFilter />
            <Box display="flex" alignItems="center" pt="4px">
                <Button color="error" onClick={onDelete} disabled={disabled} size="small">
                    Delete
                </Button>
                {user.role === "Super Admin" && (
                    <Button color="warning" onClick={onChange} disabled={disabled} size="small">
                        Add/Edit Field
                    </Button>
                )}
            </Box>
        </GridToolbarContainer>
    );
}
