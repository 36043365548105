import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import ScheduleIcon from "@mui/icons-material/Schedule";
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from "@mui/icons-material/Send";
import { Avatar, Box, CircularProgress, IconButton, Input, InputAdornment, Typography } from "@mui/material";
import { collection, doc, limit, onSnapshot, orderBy, query, updateDoc, where } from "firebase/firestore";
import { useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";

import ChatMessageComponent from "../../components/ChatInbox/ChatMessageComponent";
import ImageIndicator from "../../components/ChatInbox/ImageIndicator";
import AddItemsMenu from "../../components/Menus/AddItemsMenu";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import removeSpecialChars from "../../functions/common-functions/removeSpecialChars";
import useColors from "../../hooks/useColors";
import useWindowSize from "../../hooks/useWindowSize";
import mapSnapshot from "../../utils-functions/mapSnapshot";
import { sendAttachments, sendMessage } from "../WhatsappWebPage/sendMessageToNames";
import AccountsDialog from "./AccountsDialog";
import { ChatRoomComponent } from "./ChatRoomComponent";
import SortDialog from "./FilterDialog";
import MenuComponent from "./MenuComponent";
import WhatsappConnectionComponent from "./WhatsappConnectionComponent";

export default function ChatInboxPage() {
    const [clientId, setClientId] = useState(null);
    const [chatRooms, setChatRooms] = useState([]);
    const [currentChatRoom, setCurrentChatRoom] = useState(null);
    const [messages, setMessages] = useState([]);
    const [selectChatRoom, setSelectChatRoom] = useState(true);
    const [apiAddress, setApiAddress] = useState("");
    const [whatsappSession, setWhatsappSession] = useState("");
    const [whatsappPhone, setWhatsappPhone] = useState("");
    const [secret, setSecret] = useState("");
    const [message, setMessage] = useState("");
    const [chatRoomsLimit, setChatRoomsLimit] = useState(20);
    const [messagesLimit, setMessagesLimit] = useState(20);
    const [sort, setSort] = useState("date"); // Add sort state here
    const [openAccount, setOpenAccount] = useState(false);
    const [whatsappUser, setWhatsappUser] = useState("");
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);

    const { user } = useContext(AuthContext);

    useEffect(() => {
        if (!user) return;
        const clientId = removeSpecialChars(user.email);
        setClientId(clientId);
        setWhatsappUser(user);
    }, [user]);

    // Load ChatRooms
    useEffect(() => {
        if (!clientId) return;
        const collectionRef = collection(db, "whatsappMessages");
        const q = query(collectionRef, where("clientId", "==", clientId), limit(chatRoomsLimit), orderBy(sort, "desc"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const chatRooms = mapSnapshot(querySnapshot);
            setChatRooms(chatRooms);
        });
        return () => unsubscribe(); // Cleanup subscription on unmount
    }, [clientId, chatRoomsLimit, sort]); // Add chatRoomsLimit as a dependency

    const handleScroll = (e) => {
        // Check if the user is within 10 pixels of the bottom
        const nearBottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10;

        if (nearBottom) {
            setChatRoomsLimit((prevLimit) => prevLimit + 20);
        }
    };

    const handleMessagesScroll = (e) => {
        // Check if the user is within 10 pixels of the top
        const nearTop = e.target.scrollHeight - e.target.clientHeight + e.target.scrollTop < 10;
        const nearBottom = e.target.scrollTop < 10;

        if (nearTop) {
            setMessagesLimit((prevLimit) => prevLimit + 20);
        }

        if (nearBottom) {
            const docRef = doc(db, "whatsappMessages", currentChatRoom.id);
            updateDoc(docRef, {
                unread: 0,
                unreadAI: 0
            });
        }
    };

    // Load Messages
    useEffect(() => {
        if (!currentChatRoom) return;
        const collectionRef = collection(db, "whatsappMessages", currentChatRoom.id, "messages");
        const q = query(collectionRef, limit(messagesLimit), orderBy("date", "desc"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const messages = mapSnapshot(querySnapshot);
            setMessages(messages);
        });
        return unsubscribe;
    }, [currentChatRoom, messagesLimit]);

    const handleClickChatRoom = (chatRoom) => {
        console.log(chatRoom);
        setCurrentChatRoom(chatRoom);
        setSelectChatRoom(false);
    };

    const windowSize = useWindowSize();

    const boxWidth = useMemo(() => {
        if (windowSize.width < 600) {
            // Mobile View
            if (selectChatRoom) {
                const leftWidth = "100%";
                const rightWidth = 0;
                return { leftWidth, rightWidth };
            } else {
                const leftWidth = 0;
                const rightWidth = "100%";
                return { leftWidth, rightWidth };
            }
        } else {
            // Desktop View
            const leftWidth = "30%";
            const rightWidth = "70%";
            return { leftWidth, rightWidth };
        }
    }, [windowSize, selectChatRoom]);

    const colors = useColors();

    const handleSendMessage = async () => {
        console.log("Send message");
        if (!message && !image) {
            toast.error("No message");
            return;
        }
        if (!apiAddress) {
            toast.error("API Not ready");
            return;
        }

        if (!whatsappSession) {
            toast.error("Whatsapp not connected");
            return;
        }
        let number = currentChatRoom.id.split("-")[1];
        const from = currentChatRoom.clientId;

        // It is a group message
        if (currentChatRoom.isGroup) {
            if (currentChatRoom.from?.includes("@g.us")) {
                number = currentChatRoom.from;
            } else {
                number = currentChatRoom.to;
            }
        }

        setLoading(true);
        if (image) {
            console.log("Sending image . . .");
            const response = await sendAttachments(number, [image], from, message, apiAddress, secret);
            console.log(response);
            setImage(null);
            setMessage("");
            setLoading(false);
            return;
        }

        const response = await sendMessage(number, message, from, apiAddress, secret, user);
        setLoading(false);
        setMessage("");
        console.log(response);
    };

    const handleClickAccount = () => {
        setOpenAccount(true);
    };

    return (
        <Box display="flex" width="100%" height={"calc(100vh - 55px)"}>
            {/* Left Panel */}
            <Box
                display={!boxWidth.leftWidth ? "none" : "flex"}
                width={boxWidth.leftWidth}
                flexDirection={"column"}
                height={"100%"}
            >
                <Box
                    p={"8px"}
                    width={"100%"}
                    display={"flex"}
                    alignItems={"center"}
                    gap="8px"
                    justifyContent={"space-between"}
                    sx={{ background: colors.topBar, borderRight: `1px solid ${colors.topBarLine}` }}
                >
                    <Avatar />
                    <Box display="flex" flexDirection={"column"}>
                        <Typography>{clientId}</Typography>
                        <WhatsappConnectionComponent
                            whatsappUser={whatsappUser}
                            whatsappSession={whatsappSession}
                            setApiAddress={setApiAddress}
                            setWhatsappSession={setWhatsappSession}
                            setWhatsappPhone={setWhatsappPhone}
                            setSecret={setSecret}
                        />
                    </Box>

                    <AccountsDialog
                        open={openAccount}
                        setOpen={setOpenAccount}
                        setClientId={setClientId}
                        setWhatsappUser={setWhatsappUser}
                    />
                </Box>
                <Box
                    display="flex"
                    width={"100%"}
                    p="8px"
                    gap="8px"
                    sx={{ borderBottom: `1px solid ${colors.topBarLine}` }}
                >
                    <Input
                        disableUnderline
                        fullWidth
                        sx={{
                            background: colors.searchBox,
                            borderRadius: "6px",
                            height: "35px",
                            padding: "0 8px 0 8px"
                        }}
                        startAdornment={
                            <InputAdornment position="start">
                                <IconButton>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <SortDialog sort={sort} setSort={setSort} />
                </Box>
                <Box
                    flexGrow={1}
                    display="flex"
                    flexDirection={"column"}
                    width="100%"
                    sx={{ overflowY: "auto" }}
                    onScroll={handleScroll} // Add the scroll handler here
                >
                    {chatRooms.map((chatRoom) => (
                        <Box key={chatRoom.id} width={"100%"}>
                            <ChatRoomComponent chatRoom={chatRoom} handleClick={handleClickChatRoom} />
                        </Box>
                    ))}
                </Box>
            </Box>

            {/* Right Panel */}
            <Box
                width={boxWidth.rightWidth}
                display={!boxWidth.rightWidth ? "none" : "flex"}
                flexDirection={"column"}
                alignContent={"center"}
                height={"100%"}
            >
                <Box
                    display={"flex"}
                    width={"100%"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    p={"8px"}
                    alignItems={"center"}
                    sx={{ background: colors.topBar }}
                >
                    <Box display="flex" alignItems={"center"} gap="8px">
                        {boxWidth.leftWidth === 0 && (
                            <IconButton onClick={() => setSelectChatRoom(true)}>
                                <ArrowBackIcon />
                            </IconButton>
                        )}
                        <Avatar />
                        <Typography variant="body1">{currentChatRoom?.name || ""}</Typography>
                    </Box>
                    <Box display="flex" alignItems={"center"} gap="8px">
                        <IconButton>
                            <SearchIcon />
                        </IconButton>
                        <MenuComponent menuItems={["Contact Info", "Select Messages"]} />
                    </Box>
                </Box>
                <Box
                    flex={1}
                    display="flex"
                    flexDirection={"column-reverse"}
                    width="100%"
                    height="100%"
                    onScroll={handleMessagesScroll} // Add the scroll handler here
                    sx={{
                        backgroundImage: `url(${colors.whatsappBackground})`,
                        backgroundSize: "cover", // to cover the entire Box area
                        backgroundPosition: "center",
                        overflowY: "auto"
                    }}
                >
                    {messages.map((message) => (
                        <ChatMessageComponent key={message.id} message={message} />
                    ))}
                </Box>
                <Box
                    width={"100%"}
                    display="flex"
                    alignItems={"center"}
                    flexDirection={"row"}
                    sx={{ background: colors.topBar }}
                >
                    <Box p="4px" width={"100%"} alignItems={"center"} display={currentChatRoom ? "flex" : "none"}>
                        <IconButton>
                            <InsertEmoticonIcon fontSize="large" />
                        </IconButton>
                        <AddItemsMenu image={image} setImage={setImage} />
                        {image && <ImageIndicator image={image} setImage={setImage} />}
                        <Input
                            fullWidth
                            disabled={!currentChatRoom || !whatsappSession}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            disableUnderline
                            multiline
                            maxRows={6}
                            sx={{
                                background: "white",
                                borderRadius: "6px",
                                padding: "0px 8px 0 8px",
                                minHeight: "40px",
                                backgroundColor: colors.textInput
                            }}
                        />
                        <IconButton
                            onClick={handleSendMessage}
                            disabled={(!currentChatRoom && !image) || loading || !whatsappSession}
                        >
                            {loading ? <CircularProgress size={24} /> : <SendIcon fontSize="large" />}
                        </IconButton>
                        <IconButton>
                            <ScheduleIcon color="#54656f" />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
