import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import Tooltip from "@mui/material/Tooltip";
import { collection, doc, getDocs, setDoc, updateDoc, onSnapshot } from "firebase/firestore";
import { useCallback, useContext, useEffect, useRef, useState, useMemo } from "react";
import ReactFlow, {
    Background,
    Controls,
    MarkerType,
    MiniMap,
    ReactFlowProvider,
    addEdge,
    applyNodeChanges,
    useEdgesState,
    useNodesState,
    useReactFlow
} from "reactflow";
import "reactflow/dist/style.css";
import useNotification from "../../context-utils/NotificationContext";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import mapSnapshot from "../../utils-functions/mapSnapshot";
import BlockNode from "./BlockNode";
import FlowContext from "./FlowContext";
import PollNode from "./PollNode";
import CustomEdge from "./CustomEdge";

import {
    addBlockRelation,
    readBlockRelation,
    removeBlockRelation,
    updateAllSequence999,
    updateBlock,
    removeBlockRelationById,
    readBlock,readBlockRelationById
} from "./firebaseCall.js";

const initialNodes = [];

// the styling of edge
const edgeLabelStyle = {
    labelBgPadding: [8, 4],
    labelBgBorderRadius: 4,
    labelBgStyle: { fill: "transparent" },
    labelStyle: { fill: "#fff" },
    markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 20,
        height: 20,
        color: "#FF0072"
    },
    style: {
        strokeWidth: 3,
        stroke: "#FF0072"
    },
    animated: true
};

// const edgeTypes = {
//     'custom-edge': CustomEdge,
//   };

// const edgeTypes = {
//     'custom-edge': (props) => <CustomEdge {...props} onDelete={handleEdgeDelete} />,
// };

const initialEdges = [];
const nodeTypes = { BlockNode: BlockNode, PollNode: PollNode };
const collectionName = "whatsappFlows";

function Flow({ flow, updateTrigger, setBlocks }) {
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const [refresh, setRefresh] = useState(0);
    const { user } = useContext(AuthContext);
    const notify = useNotification();
    const reactFlowInstance = useReactFlow();
    const [firstNode, setFirstNode] = useState("");

    const onConnect = useCallback(
        async (params) => {
            const { source, target, sourceHandle, targetHandle } = params;

            // Find the connected nodes
            const sourceNode = nodes.find((el) => el.id === source);
            const targetNode = nodes.find((el) => el.id === target);

            if (sourceNode === targetNode) {
                notify("Looping the same node is not allowed.", "error");
                return;
            }
            console.log("connect");
            console.log("edges:", edges);
            const edgeExists = edges.some((edge) => edge.source === source && edge.target === target);
            if (edgeExists) {
                notify("This connection already exists.", "error");
                return;
            }

            let relationObj = {
                sourceNodeId: sourceNode.id,
                targetNodeId: targetNode.id,
                sourceHandleId: sourceHandle,
                sourceBlockType: sourceNode.blockType ? sourceNode.blockType : "", //to cater if block is from previous record
                flowId: flow.id
            };

            // Add edge for 2 nodes once connect
            const newBlkRelationId = await addBlockRelation(user, flow, relationObj);
            //the program should check for current sourceNode.id's targetNode and retrieve its array value.if targetNode is not exist, then create a new array and set to targetNode. if value already existed , get the current array value and push the new targetNode.id to append in the end of array.
            const block = await readBlock(user, flow, sourceNode.id);
            let arrTargetNodeIds = [];
            console.log("block:", block);
            if (block && block.targetNodeId) {
                arrTargetNodeIds = [...block.targetNodeId]; // Clone the array to prevent direct mutation
                arrTargetNodeIds.push(targetNode.id);
            } else {
                arrTargetNodeIds = [targetNode.id];
            }

            let blockObj = {
                targetNodeId: arrTargetNodeIds
            };

            await updateBlock(user, flow, sourceNode.id, blockObj);

            // Create a new edge with custom styling and the new block relation ID
            const newEdge = {
                ...params,
                id: newBlkRelationId, // Set the edge's ID to the new block relation ID
                type: "custom-edge",
                ...edgeLabelStyle
            };

            // Add the new edge with custom styling and ID
            setEdges((eds) => addEdge(newEdge, eds));
        },
        [setEdges, nodes, edges, user, flow]
    );

    const handleCreateNode = async () => {
        // when create, all name to set as Node Name. after that user will need to 1 by 1 reenter the name for the node.
        try {
            if (!reactFlowInstance) {
                console.error("React Flow instance is not available");
                return;
            }
            //set the initial location of new node. this have to run first cause need to save into new node database.
            const viewPortCenter = reactFlowInstance.screenToFlowPosition({
                x: window.innerWidth / 2,
                y: window.innerHeight / 2
            });

            const collectionRef = collection(db, "users", user.id, collectionName, flow.id, "blocks");

            //to check if inside blocks there is no record.this part is to cater system migrating where previous system is using orderId for sequencing and 0 means that the block is the first item in the flow.
            const snapshot = await getDocs(collectionRef);
            let tempOrderId = snapshot.empty ? 0 : 999;
            ///need to add checker where if this is the first node, set orderId = 0
            const docRef = doc(collectionRef);
            await setDoc(
                docRef,
                {
                    id: docRef.id,
                    blockId: docRef.id, // Save the document ID
                    name: "New Node Name",
                    createdBy: user.id,
                    status: "active",
                    flowId: flow.id,
                    count: 0,
                    time: 0,
                    timeUnit: "minutes",
                    orderId: tempOrderId,
                    message: "",
                    position: {
                        x: viewPortCenter.x,
                        y: viewPortCenter.y
                    },
                    updatedDate: new Date(),
                    date: new Date(),
                    type: "BlockNode",
                    functionItems: [],
                    arrPollItems: [],
                    reactFlowStatus: true
                },
                { merge: true }
            );

            const data = {
                id: docRef.id,
                blockId: docRef.id,
                type: "BlockNode",
                position: {
                    x: viewPortCenter.x,
                    y: viewPortCenter.y
                },
                data: {
                    name: "New Node Name",
                    id: docRef.id,
                    blockId: docRef.id,
                    createdBy: user.id,
                    status: "active",
                    flowId: flow.id,
                    count: 0,
                    time: 0,
                    timeUnit: "minutes",
                    orderId: tempOrderId,
                    message: "",
                    updatedDate: new Date(),
                    blockType: "sequential",
                    date: new Date(),
                    functionItems: [],
                    arrPollItems: [],
                    reactFlowStatus: true
                }
            };

            console.log("handleCreateNode x:", viewPortCenter.x);
            console.log("handleCreateNode y:", viewPortCenter.y);

            setNodes((nds) => [...nds, data]);
            // setRerender(!rerender);
        } catch (err) {
            console.log("ReactFlowPage.jsx:", err);
        }
    };

    function toDate(firestoreTimestamp) {
        if (
            !firestoreTimestamp ||
            typeof firestoreTimestamp.seconds !== "number" ||
            typeof firestoreTimestamp.nanoseconds !== "number"
        ) {
            // Return a default date (e.g., the current date) if the timestamp is invalid
            // Alternatively, you could return null and handle it in the sorting logic
            return new Date();
        }
        return new Date(firestoreTimestamp.seconds * 1000 + firestoreTimestamp.nanoseconds / 1000000);
    }

    const fetchNodes = async () => {
        if (!reactFlowInstance) {
            console.error("React Flow instance is not available.");
            return;
        }
        //set the initial location of new node. this have to run first cause need to save into new node database.
        const viewPortCenter = reactFlowInstance.screenToFlowPosition({
            x: window.innerWidth / 2,
            y: window.innerHeight / 2
        });

        console.log("fetchNodes x:", viewPortCenter.x);
        console.log("fetchNodes y:", viewPortCenter.y);

        const collectionRef = collection(db, "users", user.id, collectionName, flow.id, "blocks");
        try {
            const snapshot = await getDocs(collectionRef);

            let increment = -15;
            if (!snapshot.empty) {
                let allBlocks = mapSnapshot(snapshot).map((item) => {
                    //add extra field for block from previous system
                    if (item.reactFlowStatus === undefined) {
                        increment += 15;

                        //update block that is from previous system.
                        let itemObj = {
                            type: "BlockNode",
                            blockType: "sequential",
                            position: {
                                x: viewPortCenter.x + increment,
                                y: viewPortCenter.y + increment
                            },
                            reactFlowStatus: true
                        };
                        updateBlock(user, flow, item.id, itemObj);

                        //**********need to update all existing edge to react flow system*******//

                        return {
                            ...item,
                            data: {
                                ...item,
                                type: "BlockNode",
                                blockType: "sequential",
                                position: {
                                    x: viewPortCenter.x + increment,
                                    y: viewPortCenter.y + increment
                                },
                                reactFlowStatus: true
                            }, //customize node format. data need to pass into a field name 'data'
                            type: "BlockNode",
                            blockType: "sequential",
                            position: {
                                x: viewPortCenter.x + increment,
                                y: viewPortCenter.y + increment
                            },
                            reactFlowStatus: true
                        };
                    } else {
                        return {
                            ...item,
                            data: { ...item } //customize node format. data need to pass into a field name 'data'
                        };
                    }
                });

                allBlocks.sort((a, b) => {
                    const dateA = toDate(a.updatedDate); // Use updatedDate instead of createdDate
                    const dateB = toDate(b.updatedDate);

                    // Assuming you still want to prioritize orderId === 0 at the start
                    if (a.orderId === 0) return -1;
                    if (b.orderId === 0) return 1;

                    // If different types, and you still want BlockNode before PollNode
                    if (a.type !== b.type) {
                        return a.type === "BlockNode" ? -1 : 1;
                    }

                    // Since you want recently updated items to appear at the end, sort by updatedDate ascending
                    return dateA - dateB; // For ascending, swap dateA and dateB for descending
                });

                setFirstNode(allBlocks[0].blockId);
                console.table("blocks22:", allBlocks);
                // setBlocks([...allBlocks]);
                setNodes([...allBlocks]);
            } else {
                console.log("There is 0 block found.");
            }
        } catch (error) {
            console.error("Error fetching nodes:", error);
        }
    };

    const fetchEdge = async () => {
        const allEdges = await readBlockRelation(user, flow);
        if (!allEdges || allEdges.length === 0) {
            updateAllSequence999(user, flow);
            console.log("No records, or allEdges is null or empty");
            // Handle the case when allEdges is null, empty or has no records
            return;
        }

        let loadEdges = allEdges.map((edge) => {
            return {
                id: edge.id,
                source: edge.sourceNodeId,
                target: edge.targetNodeId,
                sourceHandle: edge.sourceHandleId,
                type: edge.type || "custom-edge",
                ...edgeLabelStyle
            };
        });
        // console.log("loadEdges:", loadEdges);
        setEdges(loadEdges);
    };

    const edgeUpdateSuccessful = useRef(true);
    const onEdgeUpdateStart = useCallback(() => {
        edgeUpdateSuccessful.current = false;
    }, []);

    const onEdgeUpdate = useCallback((oldEdge, newConnection) => {
        edgeUpdateSuccessful.current = true;

        let relationObj = {
            sourceNodeId: oldEdge.source,
            targetNodeId: oldEdge.target
        };

        //remove relation in firebase
        removeBlockRelation(user, flow, relationObj);

        //add relation from new node
        let newRelationFromNewNodeObj = {
            sourceNodeId: newConnection.target,
            targetNodeId: oldEdge.target,
            sourceHandleId: oldEdge.sourceHandle,
            flowId: flow.id
        };
        addBlockRelation(user, flow, newRelationFromNewNodeObj);

        //add relation to new node
        let newRelationToNodeObj = {
            sourceNodeId: oldEdge.source,
            targetNodeId: newConnection.target,
            sourceHandleId: oldEdge.sourceHandle,
            flowId: flow.id
        };
        addBlockRelation(user, flow, newRelationToNodeObj);

        //update the sequences of remaining nodes
        console.log("onEdgeUpdate");
        // updateBlockSequence(user, flow, firstNode);
        fetchEdge();
        // setEdges((els) => updateEdge(oldEdge, newConnection, els));
        // setRefresh(!refresh);
    }, []);

    const onEdgeUpdateEnd = useCallback(
        (_, edge) => {
            if (!edgeUpdateSuccessful.current) {
                setEdges((eds) => eds.filter((e) => e.id !== edge.id));

                let relationObj = {
                    sourceNodeId: edge.source,
                    targetNodeId: edge.target
                };
                //remove relation in firebase
                removeBlockRelation(user, flow, relationObj);

                
                // updateBlockSequence(user, flow, firstNode);
                setRefresh(!refresh);
            }
            edgeUpdateSuccessful.current = true;
        },
        [setEdges]
    );

    // const handleEdgeDelete = useCallback(
    //     (edgeId) => {
    //         // Remove edge from React state
    //         setEdges((currentEdges) => currentEdges.filter((e) => e.id !== edgeId));
    //         console.log("edgeId:", edgeId);

    //         //use the edgeID to search blockRelation collection, get the sourceNodeId, then use the sourceNodeId to readBlock and get the targetNodeId array. if the sourceNodeId exist in the array, remove it.
    //         readBlockRelationById(user, flow, edgeId)
    //         .then(async (edgeData) => {
    //           if (!edgeData) {
    //             console.error("No edge data found for the given edge ID:", edgeId);
    //             return;
    //           }
          
    //           const { sourceNodeId, targetNodeId } = edgeData;
    //           console.log("Edge Data:", edgeData);
          
    //           // Assuming readBlock is defined to fetch a block by its ID
    //           const sourceBlock = await readBlock(user, flow, sourceNodeId);
          
    //           if (!sourceBlock || !Array.isArray(sourceBlock.targetNodeId)) {
    //             console.error("Source block not found or it doesn't have a targetNodeId array.");
    //             return;
    //           }
          
    //           // Filter out the targetNodeId to remove it
    //           const updatedTargetNodeIds = sourceBlock.targetNodeId.filter(id => id !== targetNodeId);
          
    //           // Assuming updateBlock is defined to update a block with new data
    //           // Update the source block with the new array of targetNodeIds
    //           await updateBlock(user, flow, sourceNodeId, { targetNodeId: updatedTargetNodeIds })
    //             .then(() => console.log(`Removed targetNodeId: ${targetNodeId} from sourceBlock: ${sourceNodeId}`))
    //             .catch(error => console.error("Error updating block:", error));
    //         })
    //         .catch(error => console.error("Error fetching edge data:", error));
          

    //         removeBlockRelationById(user, flow, edgeId)
    //             .then(() => {
    //                 console.log("Edge removed from Firebase");
    //                 // Optionally, trigger any refresh or additional state updates needed
    //                 setRefresh((prev) => !prev);
    //             })
    //             .catch((error) => {
    //                 console.error("Failed to remove edge from Firebase:", error);
    //             });
            
            
    //     },
    //     [setEdges, user, flow, setRefresh]
    // );

    const handleEdgeDelete = useCallback(
        async (edgeId) => {
          // Remove edge from React state
          setEdges((currentEdges) => currentEdges.filter((e) => e.id !== edgeId));
          console.log("edgeId:", edgeId);
      
          try {
            const edgeData = await readBlockRelationById(user, flow, edgeId);
            if (!edgeData) {
              console.error("No edge data found for the given edge ID:", edgeId);
              return;
            }
      
            const { sourceNodeId, targetNodeId } = edgeData;
            console.log("Edge Data:", edgeData);
      
            const sourceBlock = await readBlock(user, flow, sourceNodeId);
            if (!sourceBlock || !Array.isArray(sourceBlock.targetNodeId)) {
              console.error("Source block not found or it doesn't have a targetNodeId array.");
              return;
            }
      
            // Filter out the targetNodeId to remove it
            const updatedTargetNodeIds = sourceBlock.targetNodeId.filter((id) => id !== targetNodeId);
      
            // Update the source block with the new array of targetNodeIds
            await updateBlock(user, flow, sourceNodeId, { targetNodeId: updatedTargetNodeIds });
            console.log(`Removed targetNodeId: ${targetNodeId} from sourceBlock: ${sourceNodeId}`);
      
            // Remove the edge from Firebase
            await removeBlockRelationById(user, flow, edgeId);
            console.log("Edge removed from Firebase");
      
            // Optionally, trigger any refresh or additional state updates needed
            setRefresh((prev) => !prev);
          } catch (error) {
            console.error("Error processing edge deletion:", error);
          }
        },
        [setEdges, user, flow, setRefresh]
      );
      

    const edgeTypes = useMemo(
        () => ({
            "custom-edge": (props) => <CustomEdge {...props} onDelete={handleEdgeDelete} />
        }),
        [handleEdgeDelete]
    ); // handleEdgeDelete is stable and changes only if its dependencies change

    const updateNodePositionInFirestore = async (lastNodePos) => {
        try {
            const nodeRef = doc(db, "users", user.id, collectionName, flow.id, "blocks", lastNodePos.id);
            await updateDoc(nodeRef, {
                position: {
                    x: lastNodePos.x,
                    y: lastNodePos.y
                },
                updatedDate: new Date()
            });

            // Update the position in the local React state
            setNodes((currentNodes) => {
                return currentNodes.map((node) => {
                    if (node.id === lastNodePos.id) {
                        return { ...node, position: { x: lastNodePos.x, y: lastNodePos.y } };
                    }
                    return node;
                });
            });
        } catch (error) {
            console.error("Error updating node position in Firestore:", error);
        }
    };

    let lastNodePos;

    const modifiedOnNodesChange = useCallback(
        (changes) => {
            setNodes((nds) => applyNodeChanges(changes, nds));

            changes.forEach((change) => {
                if (change.type === "position" && change.dragging === true) {
                    // This is where the node was dropped

                    lastNodePos = change.position;
                    lastNodePos = { ...lastNodePos, id: change.id };
                } else {
                    if (lastNodePos) {
                        updateNodePositionInFirestore(lastNodePos);
                    }
                }
            });
        },
        [setNodes] // No need to include onNodesChange in dependencies
    );

    useEffect(() => {
        if (!flow.id || !user.id) return;
        fetchNodes(); // Fetch nodes again when updateTrigger changes
        fetchEdge();
        console.log("Node and edge refreshed.first node :", firstNode);
    }, [flow, updateTrigger, refresh]);

    //added this part by wayne 20-2-24 to make force refresh to user.
    useEffect(() => {
        const checkForUpdates = async () => {
            try {
                // Assume you have a version endpoint or a static version file

                const response = await fetch("/version.json");

                const data = await response.json();

                const currentVersion = data.version;

                const localVersion = localStorage.getItem("appVersion");

                if (!localVersion || localVersion !== currentVersion) {
                    console.log("Updating to new version...");
                    localStorage.setItem("appVersion", currentVersion);
                    window.location.reload(true); // Force reload to update to the new version
                }
            } catch (error) {
                console.error("Failed to check for updates:", error);
            }
        };

        checkForUpdates();
    }, []);

    const proOptions = { hideAttribution: true };

    return (
        <>
            <div style={{ width: "100vw", height: "85vh", position: "relative" }}>
                <Box sx={{ position: "absolute", top: 10, right: 20, "& > :not(style)": { m: 1 } }}>
                    <Tooltip title="Add a new node" placement="top">
                        <Fab color="primary" onClick={handleCreateNode}>
                            <AddCircleOutlineIcon fontSize="large" />
                        </Fab>
                    </Tooltip>
                </Box>
                <ReactFlow
                    proOptions={proOptions} //to remove the 'reactflow' text in background
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={modifiedOnNodesChange} // Use the modified onNodesChange here
                    fitView
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    nodeTypes={nodeTypes}
                    edgeTypes={edgeTypes}
                    onEdgeUpdate={onEdgeUpdate}
                    onEdgeUpdateStart={onEdgeUpdateStart}
                    onEdgeUpdateEnd={onEdgeUpdateEnd}
                    minZoom={0.001} // Allows zooming out to 0.1% of the original size
                    maxZoom={3}
                >
                    <Controls />
                    <MiniMap zoomable pannable />
                    <Background variant="dots" gap={12} size={1} />
                </ReactFlow>
            </div>
        </>
    );
}

export default function OverviewFlowPage({ flow }) {
    const [updateTrigger, setUpdateTrigger] = useState(0);

    return (
        <ReactFlowProvider>
            <FlowContext.Provider value={{ flow, setUpdateTrigger }}>
                <Flow flow={flow} updateTrigger={updateTrigger} setUpdateTrigger={setUpdateTrigger} />
            </FlowContext.Provider>
        </ReactFlowProvider>
    );
}
