export default function generateSecret(input, secretCounter) {
    if (!input || !secretCounter) return null;
    const code = input
        .toLowerCase()
        .split("")
        .map((char) => (char.charCodeAt(0) - "a".charCodeAt(0) + 1) * 2)
        .join("");

    let generatedCode = parseFloat(code.substring(0, 10)) * parseFloat(secretCounter + 2);
    generatedCode = generatedCode.toString();
    generatedCode = generatedCode.split("").reverse().join("");

    const counter = secretCounter.toString().padStart(3, "0");

    const transformedCode = code.substring(0, 10) + counter + generatedCode;
    return transformedCode;
}
