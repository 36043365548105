import AssistantIcon from "@mui/icons-material/Assistant";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import RedeemIcon from "@mui/icons-material/Redeem";
import ListAltIcon from "@mui/icons-material/ListAlt";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";

import { blue, green, orange, pink, purple, red, teal } from "@mui/material/colors";

export const MENU_ITEMS = [
    { action: "Home", url: "/", icon: "home" },
    { action: "Divider", url: "/", icon: "" },

    { action: "Connect Facebook", url: "/facebook-connect-page", icon: "facebook" },
    { action: "Facebook Forms", url: "/leads-gen-form", icon: "facebook" },
    { action: "Connect Telegram", url: "/telegram-connect-page", icon: "telegram" },
    { action: "Divider", url: "/", icon: "" },

    { action: "Assigned Leads", url: "/assigned-leads-page", icon: "grading" },
    { action: "Follow Up Leads", url: "/follow-up-leads-page", icon: "grading" },
    { action: "Source Leads", url: "/source-leads-page", icon: "grading" },
    { action: "Source Leads Table v3", url: "/leads-table-page-v3", icon: "grading" },
    { action: "Leads Dashboard", url: "/leads-dashboard-page", icon: "grading" },
    { action: "Folders Management", url: "/folders-management-page", icon: "snippet_folder" },
    { action: "Divider", url: "/", icon: "" },

    { action: "Clients Page", url: "/clients-page", icon: <AccessibilityNewIcon /> },
    { action: "Divider", url: "/", icon: "" },

    { action: "Calendar Page", url: "/calendar-page", icon: "calendar_month" },
    { action: "Divider", url: "/", icon: "" },

    { action: "Upload Data Page", url: "/upload-data-page", icon: "cloud_upload_outlined" },
    { action: "Divider", url: "/", icon: "" },

    { action: "Create Form Page", url: "/create-form-page", icon: <ListAltIcon /> },
    { action: "Affiliate Submissions Page", url: "/form/affiliate-submissions-page", icon: <FormatListNumberedIcon /> },
    { action: "Divider", url: "/", icon: "" },

    { action: "Whatsapp Broadcast", url: "/whatsapp-broadcast-page", icon: <WhatsAppIcon />, hide: "Test" },
    { action: "Whatsapp Flow", url: "/whatsapp-flow-page", icon: <WhatsAppIcon />, hide: "Test" },
    { action: "Webhook Generator", url: "/webhook-generator-page", icon: <WhatsAppIcon />, hide: "Test" },
    { action: "Chat Inbox", url: "/chat-inbox-page", icon: <WhatsAppIcon />, hide: "Test" },
    { action: "Divider", url: "/", icon: "", hide: "Test" },

    { action: "Affiliate Page", url: "/affiliate", icon: "face" },
    { action: "Commission Page", url: "/commission-page", icon: <RedeemIcon /> },
    { action: "Payments Page", url: "/payments-page", icon: <RedeemIcon /> },
    { action: "Divider", url: "/", icon: "" },

    { action: "Subscription", url: "/stripe-checkout-page", icon: <AssistantIcon /> },
    { action: "Divider", url: "/", icon: "" },

    { action: "Users Page", url: "/users-page", icon: "face" },
    { action: "Divider", url: "/", icon: "" },

    { action: "Sign Out", url: "/sign-out", icon: "logout", color: red[700] },
    { action: "Divider", url: "/", icon: "" },

    { action: "Update Affiliate Page", url: "/update-affiliates-page", icon: "face", role: "Super Admin" },
    { action: "Telegram Webhook Generator", url: "/telegram-webhook-generator-page", icon: "face", role: "Super Admin" },
    { action: "Whatsapp Backend", url: "/backend-dashboard-page", icon: "biotech", role: "Super Admin", color: green[700] },
    { action: "Playground", url: "/playground-page", icon: "biotech", role: "Super Admin", color: green[700] },
    { action: "Google AI Playground", url: "/google-ai-page", icon: "biotech", role: "Super Admin", color: green[700] },
    { action: "Whatsapp Web", url: "/whatsapp-web-page", icon: "biotech", role: "Super Admin", color: orange[700] },
    { action: "Whatsapp Flow Usage", url: "/whatsapp-flow-usage-page", icon: "face", role: "Super Admin", color: orange[700] },
    { action: "Overview Flow", url: "/overview-flow-page", icon: "biotech", role: "Super Admin", color: orange[700] },
    { action: "Test", url: "/test-page", icon: "biotech", role: "Super Admin", color: orange[500] },
    { action: "Chat Inbox", url: "/chat-inbox-page", icon: "biotech", role: "Super Admin", color: orange[500] },
    { action: "Messenger Inbox", url: "/messenger-inbox-page", icon: "biotech", role: "Super Admin", color: orange[500] },
    { action: "List Scheduled Messages", url: "/list-scheduled-messages-page", icon: "biotech", role: "Super Admin", color: orange[500] },
    { action: "List Whatsapp Messages", url: "/list-whatsapp-messages-page", icon: "biotech", role: "Super Admin", color: orange[500] }
];

export const MENU_ITEMS_SIGNED_OUT = [
    { action: "Home", url: "/", icon: "home" },
    { action: "Divider", url: "/", icon: "" },
    { action: "Sign In", url: "/sign-in", icon: "login" }
];

export const COPYRIGHT_LINK = "https://www.djcsystem.com";

export const WEBSITE_NAME = "www.djcsystem.com";

export const APP_TITLE = "DJC System";

export const SECRET_CODE = "djc123";

export const HOMEPAGE_ITEMS = [
    { description: "title", title: "Leads Management", color: blue[500] },
    { description: "View Assigned Leads", title: "Assigned Leads", color: blue[500], link: "/assigned-leads-page" },
    { description: "View Follow Up leads", title: "Follow Up Leads", color: pink[500], link: "/follow-up-leads-page" },
    { description: "View Source Leads for Leads Owner", title: "Source Leads", color: orange[500], link: "/source-leads-page" },
    { description: "Leads Dashboard", title: "Leads Dashboard", color: blue["A700"], link: "/leads-dashboard-page" },
    { description: "divider" },

    { description: "title", title: "Whatsapp Management", color: blue[500], hide: "Test" },
    { description: "Whatsapp Broadcast", title: "Whatsapp Broadcast", color: green[600], link: "/whatsapp-broadcast-page", hide: "Test" },
    { description: "Whatsapp AI Flow Design", title: "Whatsapp Flow Design", color: blue[700], link: "/whatsapp-flow-page", hide: "Test" },
    { description: "Whatsapp Chat Inbox", title: "Whatsapp Chat Inbox v2", color: orange[800], link: "/chat-inbox-page", hide: "Test" },
    { description: "divider", hide: "Test" },

    { description: "title", title: "Facebook and Instagram Messages", color: blue[500] },
    { description: "Messenger Inbox", title: "Messenger Inbox", color: purple[400], link: "/messenger-inbox-page" },
    { description: "divider" },

    { description: "title", title: "DJC Funnels", color: blue[500] },
    { description: "DJC Funnels", title: "DJC Funnels", color: teal[500], link: "/djc-funnel-page" },
    { description: "divider" },

    { description: "title", title: "Property Listing", color: blue[500], addOn: "whatsappCloud" },
    {
        description: "Property Listing",
        title: "Property Listing",
        color: teal[900],
        link: "/listing-page",
        addOn: "whatsappCloud",
        toolTip: "You need a Premium account to use this feature. Click Subscription to subscribe"
    },
    { description: "divider", addOn: "whatsappCloud" },

    { description: "title", title: " Tutorials", color: blue[500] },
    { description: "Tutorials", title: "Tutorials", color: purple[900], link: "/tutorials-page" },
    { description: "divider" },

    { description: "title", title: "Settings", color: blue[500] },
    { description: "Connect Facebook", title: "Connect Facebook", color: blue[700], link: "/facebook-connect-page" },
    { description: "Connect Facebook", title: "Connect Telegram", color: purple[700], link: "/telegram-connect-page" },
    { description: "Subscription", title: "Subscription", color: orange[800], link: "/stripe-checkout-page" },
    { description: "divider" }
    // { description: "Admin Page", title: "Admin Page", color: orange[900], link: "/admin-page" },
];

export const LEAD_CARD = "LEAD_CARD";
export const HOME = "HOME";
export const ROWS_PER_PAGE = 50;
export const SOURCE_LEADS = "SOURCE_LEADS";
export const EMPTY = "**empty**";

export const LEADS_HEADER = [
    { id: "created", label: "Date", width: "100px", type: "date" },
    { id: "name", label: "Name", width: "100px" },
    { id: "phone", label: "Phone", width: "100px" },
    { id: "email", label: "Email", width: "100px" },
    { id: "labels", label: "Labels", width: "100px", type: "labels" },
    { id: "actions", label: "Actions", width: "320px", type: "actions" },
    { id: "color", label: "Color", width: "100px", type: "color" },
    { id: "remark", label: "Remark", width: "100px" },
    { id: "pageName", label: "Page", width: "100px" },
    { id: "campaignName", label: "Campaign", width: "100px" },
    { id: "source", label: "Source", width: "100px" },
    { id: "assignBy", label: "Assigned by", width: "100px" },
    { id: "assignments", label: "Assigned to", width: "100px", type: "assignment" },
    //added by wayne
    //8-11-2023
    //add in additional field to display admins of current lead.
    { id: "admins", label: "Admins", width: "100px", type: "admins" }
];

export const WHATSAPPCLOUD_PRODUCT_ID = "prod_O76rXX7S9fBnC4";
