import Typesense from "typesense";

const apiKey = process.env.REACT_APP_TYPESENSE_API_KEY;

let typesenseClient = new Typesense.Client({
    nodes: [
        {
            host: "typesense.djc.ai",
            port: "443", // For Typesense Cloud use 443
            protocol: "https"
        }
    ],
    apiKey: apiKey,
    connectionTimeoutSeconds: 2
});

export default typesenseClient;
