import axios from "axios";

export default function getState(clientId, setStatus, secret, setSize) {
    console.log(clientId);
    try {
        let data = JSON.stringify({
            clientId: clientId,
            from: clientId,
            secret
        });

        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: "https://api.djc.ai/check-state",
            headers: {
                "Content-Type": "application/json"
            },
            data: data
        };

        let config2 = {
            method: "post",
            maxBodyLength: Infinity,
            url: "https://api.djc.ai/get-directory-size",
            headers: {
                "Content-Type": "application/json"
            },
            data: data
        };

        axios
            .request(config)
            .then((response) => {
                if (response.data.status === true) {
                    setStatus("ready");
                } else {
                    setStatus("disconnected");
                }
            })
            .catch((error) => {
                console.log(error);
                setStatus("disconnected");
                console.log(error);
            });

        axios
            .request(config2)
            .then((response) => {
                console.log(response);
                setSize(response.data.size);
            })
            .catch((error) => {
                console.log(error);
            });
    } catch (err) {
        console.log(err);
        setStatus("error");
        return "error";
    }
}
