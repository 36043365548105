import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography
} from "@mui/material";
import { addDoc, collection, deleteDoc, doc, getDocs, updateDoc } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import { useConfirmation } from "../../context-utils/ConfirmationContext";

export default function TutorialsPage() {
    const [open, setOpen] = useState(false);
    const [tutorials, setTutorials] = useState([]);
    const [formData, setFormData] = useState({ title: "", description: "", link: "", thumbnail: "" });
    const [videoDialogOpen, setVideoDialogOpen] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState("");
    const [isEditing, setIsEditing] = useState(false); // New state to track editing mode

    const { user } = useContext(AuthContext);

    useEffect(() => {
        const getTutorials = async () => {
            const tutorialsRef = collection(db, "tutorials");
            const tutorialsSnapshot = await getDocs(tutorialsRef);
            const tutorialsList = [];
            tutorialsSnapshot.forEach((doc) => {
                tutorialsList.push({ ...doc.data(), id: doc.id });
            });
            setTutorials(tutorialsList);
        };
        getTutorials();
    }, []);

    const handleClickOpen = () => {
        setOpen(true);
        setIsEditing(false);
        setFormData({ title: "", description: "", link: "", thumbnail: "" }); // Reset form data
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async () => {
        let updatedTutorials = [...tutorials];
        if (isEditing) {
            // Update the tutorial
            const tutorialRef = doc(db, "tutorials", formData.id);
            const updatedTutorial = { ...formData, date: new Date(), uploadedBy: user.id, access: "public" };
            delete updatedTutorial.id; // Remove the id property before updating
            await updateDoc(tutorialRef, updatedTutorial);
            toast.success("Tutorial updated");

            // Update the tutorial in the local state
            updatedTutorials = tutorials.map((tutorial) =>
                tutorial.id === formData.id ? { ...updatedTutorial, id: formData.id } : tutorial
            );
        } else {
            // Add a new tutorial
            const collectionRef = collection(db, "tutorials");
            const tutorial = { ...formData, date: new Date(), uploadedBy: user.id, access: "public" };
            const docRef = await addDoc(collectionRef, tutorial);
            toast.success("Tutorial added");

            // Add the new tutorial to the local state
            updatedTutorials.push({ ...tutorial, id: docRef.id });
        }

        setTutorials(updatedTutorials);
        setFormData({ title: "", description: "", link: "", thumbnail: "" });
        setOpen(false);
        setIsEditing(false);
    };

    const handleVideoClick = (videoUrl) => {
        setSelectedVideo(videoUrl);
        setVideoDialogOpen(true);
    };

    const handleVideoDialogClose = () => {
        setVideoDialogOpen(false);
    };

    const handleEditClick = (tutorial) => {
        setFormData(tutorial); // Populate the form with the tutorial data
        setOpen(true);
        setIsEditing(true); // Set editing mode to true
    };

    const confirmation = useConfirmation();

    const handleDeleteClick = async (tutorial) => {
        const response = await confirmation("Delete tutorial", "Click ok to delete");
        if (!response) return;

        const updatedTutorials = tutorials.filter((t) => t.id !== tutorial.id);
        setTutorials(updatedTutorials);
        const tutorialRef = doc(db, "tutorials", tutorial.id);
        const deleteTutorial = async () => {
            await deleteDoc(tutorialRef);
            toast.success("Tutorial deleted");
        };
        deleteTutorial();
    };

    return (
        <div>
            {(user?.role === "Super Admin" || user?.role === "Admin") && (
                <Box m="16px">
                    <Button variant="contained" onClick={handleClickOpen}>
                        Add Tutorial
                    </Button>
                </Box>
            )}

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{isEditing ? "Edit Tutorial" : "Add a Tutorial"}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="title"
                        label="Title"
                        multiline
                        type="text"
                        fullWidth
                        variant="standard"
                        value={formData.title}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="description"
                        label="Description"
                        type="text"
                        multiline
                        rows={4}
                        fullWidth
                        variant="standard"
                        value={formData.description}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="link"
                        label="Link"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={formData.link}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="thumbnail"
                        label="Thumbnail URL"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={formData.thumbnail}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSubmit}>{isEditing ? "Update" : "Add"}</Button>
                </DialogActions>
            </Dialog>
            <Grid container spacing={1} sx={{ padding: 2 }}>
                {tutorials.map((tutorial, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index} onClick={() => handleVideoClick(tutorial.link)}>
                        <Card sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                            <CardMedia
                                component="img"
                                image={tutorial.thumbnail}
                                alt={tutorial.title}
                                sx={{ cursor: "pointer" }}
                            />
                            <CardContent sx={{ flexGrow: 1 }}>
                                <Typography variant="h6">{tutorial.title}</Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {tutorial.description}
                                </Typography>
                            </CardContent>
                            {(user?.role === "Super Admin" || user?.role === "Admin") && (
                                <CardActions>
                                    <Box>
                                        <Button
                                            size="small"
                                            onClick={(e) => {
                                                e.stopPropagation(); // Prevent triggering the video click when editing
                                                handleEditClick(tutorial);
                                            }}
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            color="error"
                                            size="small"
                                            onClick={(e) => {
                                                e.stopPropagation(); // Prevent triggering the video click when editing
                                                handleDeleteClick(tutorial);
                                            }}
                                        >
                                            Delete
                                        </Button>
                                    </Box>
                                </CardActions>
                            )}
                        </Card>
                    </Grid>
                ))}
            </Grid>

            <Dialog open={videoDialogOpen} onClose={handleVideoDialogClose} maxWidth="lg" fullWidth sx={{ padding: 0 }}>
                <DialogContent sx={{ padding: "4px" }}>
                    <iframe
                        width="100%"
                        height="500"
                        src={selectedVideo}
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                        title="Video"
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
}
