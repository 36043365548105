import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Avatar, Box, IconButton, Typography, Chip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import convertDate from "../../functions/common-functions/convertDate";
import formatDate from "../../functions/common-functions/formatDate";
import formatWhatsAppStyle from "./formatWhatsappStyle";
import useColors from "../../hooks/useColors";

export function ChatRoomComponent({ chatRoom = {}, handleClick }) {
    const [typographyWidth, setTypographyWidth] = useState("auto");

    const number = chatRoom?.id?.split("-")[0] || "";

    const containerRef = useRef(null);

    const colors = useColors();

    useEffect(() => {
        const calculateWidth = () => {
            if (containerRef.current) {
                const containerWidth = containerRef.current.offsetWidth;
                const iconButtonWidth = 52; // Adjust this based on actual IconButton size
                const calculatedWidth = containerWidth - iconButtonWidth - 8 * 2; // Subtract IconButton width and margins
                setTypographyWidth(calculatedWidth);
            }
        };

        // Calculate on mount and window resize
        calculateWidth();
        window.addEventListener("resize", calculateWidth);

        // Cleanup
        return () => window.removeEventListener("resize", calculateWidth);
    });

    const messageToDisplay = () => {
        if (chatRoom?.lastMessageType === "ptt") return "Voice message 🎤";
        if (chatRoom?.lastMessageType === "image") return "Image 📷";

        const lastMessage = chatRoom?.lastMessage;
        if (!lastMessage) return "";

        return lastMessage ? formatWhatsAppStyle(lastMessage) : "";
    };

    const handleClickMore = (event, chatRoom) => {
        console.log("clicked more", chatRoom);
        event.stopPropagation();
    };

    return (
        <Box
            display={"flex"}
            alignItems={"center"}
            width={"100%"}
            onClick={() => handleClick(chatRoom)}
            justifyContent={"space-between"}
            sx={{
                borderBottom: `1px solid ${colors.topBarLine}`,
                "&:hover": {
                    backgroundColor: colors.boxHighlight // Replace 'primary.main' with your desired color
                },
                cursor: "pointer"
            }}
            p="4px"
        >
            <Box p="8px" display="flex" width={"100%"} ref={containerRef}>
                <Box>
                    <Avatar sx={{ width: "45px", height: "45px" }} />
                </Box>
                <Box display="flex" flexDirection={"column"} p="0 8px 0 8px" width={"100%"}>
                    <Box
                        display="flex"
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        width={`${typographyWidth}px`}
                    >
                        <Typography
                            variant="body1"
                            flex={1}
                            noWrap
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                wordBreak: "break-word" // Break long words
                            }}
                        >
                            {chatRoom?.name || number}
                        </Typography>
                        <Typography variant="caption" color="#71818a">
                            {chatRoom?.date ? formatDate(convertDate(chatRoom.date), true) : ""}
                        </Typography>
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width={`${typographyWidth}px`} // Set width to calculated width because nowrap only works with fixed width
                    >
                        <Typography
                            variant="body2"
                            flex={1}
                            noWrap
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                wordBreak: "break-word" // Break long words
                            }}
                        >
                            {messageToDisplay()}
                        </Typography>
                        {Boolean(chatRoom.unreadAI) && <Chip label={chatRoom.unreadAI} color="error" />}
                        {Boolean(chatRoom.unread) && <Chip label={chatRoom.unread} color="primary" />}
                        <IconButton size="small" onClick={(event) => handleClickMore(event, chatRoom)}>
                            <ExpandMoreIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
