import DeleteIcon from "@mui/icons-material/Delete";
import {
    Box,
    Button,
    Container,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Tabs,
    Tab,
    Typography,
    IconButton
} from "@mui/material";
import { DataGrid, GridToolbar, GridToolbarContainer } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import { collection, deleteDoc, doc, getDocs, query, where } from "firebase/firestore";
import { useContext, useEffect, useMemo, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import { GridContainer, GridFlexBox, Loading, Name } from "../../themes/themes";
import formatDate from "../../utils-functions/formatDate";
import mapSnapshot from "../../utils-functions/mapSnapshot";
import convertDate from "./../../utils-functions/convertDate";
import getLeadsByPageId from "./getLeadsByPageId";
import { arrayIsEmpty } from "./../../utils-functions/arrayIsEmpty";
import { LeadsContext } from "../../context/LeadsContext";
import formatNumber from "./../../utils-functions/formatNumber";
import LeadActionsComponent from "./LeadActionsComponent";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import FullDialog from "../../components/FullDialog";
import ManageLabels from "./ManageLabels";
import { render } from "@testing-library/react";
import LeadCardPage from "../LeadCardPage";

export default function LeadsTablePageV3() {
    const { user } = useContext(AuthContext);
    const { sourceLeads, setSourceLeads, reload, setReload } = useContext(LeadsContext);
    const [pages, setPages] = useState([]);
    const [currentPageId, setCurrentPageId] = useState("");
    const [loading, setLoading] = useState(false);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [leadsType, setLeadsType] = useState(0);
    const [loadAll, setLoadAll] = useState(false);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false, email: false });
    const [currentLead, setCurrentLead] = useState(null);
    const [openLabel, setOpenLabel] = useState(false);
    const [openCard, setOpenCard] = useState(false);

    const type = useMemo(() => {
        if (leadsType === 0) {
            return "SOURCE_LEADS";
        } else {
            return "ASSIGNED_LEADS";
        }
    }, [leadsType]);

    const columns = [
        { field: "id", headerName: "ID" },
        {
            field: "created",
            headerName: "Date",
            width: 100,
            valueGetter: (params) => convertDate(params.value),
            renderCell: (params) => {
                const dateTime = formatDate(convertDate(params.row.created), true);
                const date = dateTime.split(" ")[0];
                const time = dateTime.split(" ")[1];
                return (
                    <Box>
                        <Typography fontSize="11px">{date}</Typography>
                        <Typography fontSize={"10px"}>{time}</Typography>
                    </Box>
                );
            }
        },
        {
            field: "name",
            headerName: "Name",
            width: 150,
            renderCell: (params) => (
                <Name
                    onClick={() => {
                        console.log(params.row);
                        setCurrentLead(params.row);
                        setOpenCard(true);
                    }}
                >
                    {params.value}
                </Name>
            )
        },
        { field: "email", headerName: "Email", width: 180 },
        { field: "phone", headerName: "Phone", width: 150 },
        {
            field: "labels",
            headerName: "Labels",
            width: 180,
            valueGetter: (params) => {
                if (params.value) {
                    let labelsString = "";
                    params.value.forEach((label) => {
                        labelsString += label.label + ", ";
                    });
                    return labelsString;
                }
            },
            renderCell: (params) => (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: "flex-start",
                        alignItems: "center"
                    }}
                >
                    {!arrayIsEmpty(params.row.labels) &&
                        params.row.labels.map((label, i) => (
                            <Box
                                sx={{
                                    backgroundColor: label.color,

                                    borderRadius: "5px",
                                    padding: "2px 5px",
                                    margin: "2px"
                                }}
                                key={`${label.label || ""}${i}`}
                            >
                                <Name
                                    fs10
                                    color={label.fontColor}
                                    onClick={() => {
                                        setCurrentLead(params.row);
                                        setOpenLabel(true);
                                    }}
                                    cp
                                >
                                    {label.label}
                                </Name>
                            </Box>
                        ))}
                    <IconButton
                        onClick={() => {
                            setCurrentLead(params.row);
                            setOpenLabel(true);
                        }}
                        size="small"
                    >
                        <AddIcon />
                    </IconButton>
                </Box>
            )
        },
        {
            field: "lastAction",
            headerName: "Last Action",
            width: 300,
            valueGetter: (params) =>
                params.value ? `${formatDate(convertDate(params.value.created), true)} - ${params.value.action}` : "",
            renderCell: (params) => <LeadActionsComponent lead={params.row} leadsMode={type} />
        },
        {
            field: "assignments",
            headerName: "Assigned To",
            width: 140,
            valueGetter: (params) => (params.value?.length ? params.value[0].assign.name : "")
        },
        { field: "assignBy", headerName: "Assigned By", width: 140 },
        { field: "pageName", headerName: "Page", width: 140 },
        { field: "campaignName", headerName: "Ad Set", width: 140 },
        { field: "source", headerName: "Source", width: 140 }
    ];

    useEffect(() => {
        const loadPages = async () => {
            const collectionRef = collection(db, "pages");
            const q = query(collectionRef, where("admins", "array-contains", user.id));
            const snapshot = await getDocs(q);
            const pages = mapSnapshot(snapshot);
            setPages([{ name: "[none]", id: "" }, { name: "[ALL]", id: "all" }, ...pages]);
        };
        loadPages();
    }, []);

    useEffect(() => {
        const loadLeads = async () => {
            setLoading(true);
            const leads = await getLeadsByPageId(currentPageId, user, loadAll);
            console.log(leads);
            setSourceLeads(leads);
            setReload(false);
            setLoadAll(false);
            setLoading(false);
        };
        if (reload === false) return;
        loadLeads();
    }, [currentPageId, loadAll, reload]);

    const handleChangePage = (e) => {
        setReload(true);
        setCurrentPageId(e.target.value);
    };

    const handleChangeType = (event, newValue) => {
        setLeadsType(newValue);
    };

    const handleSetLoadAll = () => {
        setLoadAll(true);
        setReload(true);
    };

    const confirmation = useConfirmation();

    const deleteLeads = async () => {
        const response = await confirmation("Delete leads", "Are you sure you want to delete these leads?");
        if (!response) return;

        console.log(rowSelectionModel);
        const collectionRef = collection(db, "leads");
        rowSelectionModel.forEach(async (id) => {
            await deleteDoc(doc(collectionRef, id));
        });

        setSourceLeads((prev) => prev.filter((lead) => !rowSelectionModel.includes(lead.id)));
    };

    const assignLeads = async () => {};

    return (
        <Container maxWidth="none">
            <GridContainer>
                <Loading loading={loading} />
                <GridFlexBox fs md={5} xs={12}>
                    <Tabs value={leadsType} onChange={handleChangeType}>
                        <Tab label="Source Leads" />
                        <Tab label="Assigned Leads" />
                    </Tabs>
                </GridFlexBox>
                <GridFlexBox md={7} xs={12}>
                    <Typography>{`No. of leads: ${
                        sourceLeads?.length ? formatNumber(sourceLeads.length, 0) : 0
                    }`}</Typography>
                    <Button sx={{ marginRight: "8px", marginLeft: "8px" }} onClick={handleSetLoadAll}>
                        Load All
                    </Button>
                    <FormControl fullWidth>
                        <InputLabel size="small">Page</InputLabel>
                        <Select value={currentPageId} label="Page" onChange={handleChangePage} size="small">
                            {pages.map((page) => (
                                <MenuItem value={page.id} key={page.id}>
                                    {page.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </GridFlexBox>
                <GridFlexBox fs>
                    <Paper sx={{ height: "80vh", width: "100%", overflowY: "auto" }}>
                        <DataGrid
                            rows={sourceLeads ? sourceLeads : []}
                            columns={columns}
                            slots={{
                                toolbar: () => (
                                    <CustomToolbar
                                        onDelete={deleteLeads}
                                        onAssign={assignLeads}
                                        disabled={rowSelectionModel.length === 0}
                                    />
                                )
                            }}
                            onRowSelectionModelChange={(newRowSelectionModel) => {
                                setRowSelectionModel(newRowSelectionModel);
                            }}
                            rowSelectionModel={rowSelectionModel}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 100
                                    }
                                }
                            }}
                            rowsPerPageOptions={[5, 10, 20, 100]}
                            checkboxSelection
                            disableRowSelectionOnClick
                            // isRowSelectable={(params) => {
                            //     if (params.row.type === "ASSIGNED_LEAD") {
                            //         return false;
                            //     } else {
                            //         return true;
                            //     }
                            // }}
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                            getRowHeight={() => "auto"}
                        />
                    </Paper>
                </GridFlexBox>
            </GridContainer>
            <FullDialog
                open={openCard}
                handleClose={() => setOpenCard(false)}
                title={"Lead Card"}
                Component={
                    <LeadCardPage lead={currentLead} handleClose={() => setOpenCard(false)} leadsMode={leadsType} />
                }
            />
            <FullDialog
                open={openLabel}
                handleClose={() => setOpenLabel(false)}
                title={"Labels"}
                Component={
                    <ManageLabels
                        lead={currentLead}
                        setLead={setCurrentLead}
                        handleClose={() => setOpenLabel(false)}
                        setLeads={setSourceLeads}
                    />
                }
            />
        </Container>
    );
}

function CustomToolbar({ onDelete, onAssign, disabled }) {
    return (
        <GridToolbarContainer>
            <GridToolbar />
            <Box display="flex" alignItems={"center"} pt="4px">
                <Button startIcon={<DeleteIcon />} color="error" onClick={onDelete} disabled={disabled} size="small">
                    Delete
                </Button>
                <Button
                    startIcon={<AssignmentIndOutlinedIcon />}
                    color="primary"
                    onClick={onAssign}
                    disabled={disabled}
                    size="small"
                >
                    Assign
                </Button>
            </Box>
        </GridToolbarContainer>
    );
}
