import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from "@mui/icons-material/Send";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    AppBar,
    Box,
    Button,
    CardMedia,
    Chip,
    Container,
    Dialog,
    Divider,
    Fab,
    FormControlLabel,
    Grid,
    Icon,
    IconButton,
    InputAdornment,
    LinearProgress,
    ListItem,
    ListItemButton,
    Alert as MuiAlert,
    Paper,
    Slide,
    Snackbar,
    Stack,
    Switch,
    Tab,
    Tabs,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import { blue, grey } from "@mui/material/colors";
import {
    arrayRemove,
    arrayUnion,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    limit,
    orderBy,
    query,
    updateDoc,
    where
} from "firebase/firestore";
import React, { forwardRef, useContext, useEffect, useMemo, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { CodeBlock } from "../../components/CodeBlock";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import useList from "../../context-utils/ListContext";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import mapSnapshot from "../../functions/common-functions/mapSnapshot";
import removeSpecialChars from "../../functions/common-functions/removeSpecialChars";
import useChooseFriend from "../../hooks/useChooseFriend";
import useWindowSize from "../../hooks/useWindowSize";
import { WHATSAPPCLOUD_PRODUCT_ID } from "../../settings/settings";
import { GridContainer, GridDivider, GridFlexBox, Name } from "../../themes/themes";
import { arrayIsEmpty } from "../../utils-functions/arrayIsEmpty";
import mapDocSnapshot from "../../utils-functions/mapDocSnapshot";
import formatDate from "./../../functions/common-functions/formatDate";
import convertDate from "./../../utils-functions/convertDate";
import ConnectWhatsappComponent from "./ConnectWhatsappComponent";
import loadChatRooms from "./loadChatRooms";
import loadMessages from "./loadMessages";
import { sendMessage } from "./sendMessageToNames";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ChatHistoryPage() {
    const [chatRooms, setChatRooms] = React.useState([]);
    const [currentChatRoom, setCurrentChatRoom] = React.useState();
    const [messages, setMessages] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [clientId, setClientId] = React.useState(null);
    const [apiAddress, setApiAddress] = React.useState("");
    const [secret, setSecret] = React.useState("");
    const [whatsappSession, setWhatsappSession] = React.useState("");
    const [whatsappPhone, setWhatsappPhone] = React.useState("");
    const [chatRoomClientIds, setChatRoomClientIds] = React.useState([]);
    const [message, setMessage] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [chatRoomLimit, setChatRoomLimit] = useState(50); //initially load 50 chatroom for the given client id
    const [extraMessage, setExtraMessage] = useState(0);
    const [source, setSource] = useState(0); // 0 = main, 1 = shared

    const { user } = useContext(AuthContext);

    const messagesRef = useRef(null);

    //This reference is to find out the first unread message
    const targetMessageRef = useRef(null);

    //This reference is to find out if the user has scrolls to the last message
    const messagesEndRef = useRef(null);

    //This reference is to find out if the user has scrolls to the top message
    const messagesFirstRef = useRef(null);

    const prevMessageFirstRef = useRef(null);

    useEffect(() => {
        if (!clientId) return;
        const unsubscribe = loadChatRooms(setChatRooms, clientId, chatRoomLimit, source);

        console.log("chatRooms: ", chatRooms);

        return unsubscribe;
    }, [clientId, chatRoomLimit, source]);

    useEffect(() => {
        console.log("user: ", user);
        let clientId = removeSpecialChars(user.id);
        if (user.superUserId) {
            clientId = removeSpecialChars(user.superUserId);
        }
        setClientId(clientId);
    }, [user]);

    useEffect(() => {
        if (!currentChatRoom) return;
        const unsubscribe = loadMessages(user, currentChatRoom, setMessages, setLoading, extraMessage);

        return unsubscribe;
    }, [currentChatRoom, extraMessage]);

    useEffect(() => {
        if (arrayIsEmpty(chatRooms)) return;
        const chatRoomClientIds = chatRooms.reduce((acc, chatRoom) => {
            const clientId = chatRoom.clientId;
            if (acc.includes(clientId)) {
                return acc;
            } else {
                return [...acc, clientId];
            }
        }, []);
        chatRoomClientIds.sort();
        setChatRoomClientIds(chatRoomClientIds);
    }, [chatRooms]);

    //This is the function to scroll to the start of unread message
    useEffect(() => {
        if (targetMessageRef.current) {
            targetMessageRef.current.scrollIntoView();
        } else if (messagesEndRef.current) {
            //If there is no unread message, then staright away go to the last message
            messagesEndRef.current.scrollIntoView();
        }

        //This is to counter when load older message, it will go to the last message once load
        //By having this it will stay at the prev first message scroll position
        if (prevMessageFirstRef.current && messagesFirstRef.current) {
            if (prevMessageFirstRef.current === messagesFirstRef.current) {
                console.log("same");
                prevMessageFirstRef.current.scrollIntoView();
                return;
            } else {
                console.log("going back to previos top");
                prevMessageFirstRef.current.scrollIntoView();
            }
        }
    }, [messages]);

    //This is the function to observe whether the user has scroll to the last message
    //then update the unread inside database to 0
    useEffect(() => {
        const resetUnreadCount = async () => {
            console.log("Setting unread to 0");
            const whatsappMessageRef = doc(db, "whatsappMessages", currentChatRoom.id);
            await updateDoc(whatsappMessageRef, {
                unread: 0,
                unreadAI: 0
            });
        };

        if (messagesEndRef.current) {
            const observer = new IntersectionObserver(
                ([entry]) => {
                    // When the last message is visible in the viewport, update the database
                    if (entry.intersectionRatio >= 0.25 && currentChatRoom.unread !== 0) {
                        resetUnreadCount();
                    }
                },
                { threshold: [0, 0.25, 0.5, 0.75, 1] } // Trigger when the 0.25 element is visible
            );

            observer.observe(messagesEndRef.current);

            // Cleanup
            return () => observer.disconnect();
        }
    }, [messages]);

    //This is the observer to check whether the message has hit the top
    useEffect(() => {
        const fetchMoreMessage = async () => {
            console.log("hit the top already");
            if (prevMessageFirstRef.current === messagesFirstRef.current) {
                console.log("no need to fetch older messages");
            } else {
                prevMessageFirstRef.current = messagesFirstRef.current;
                setExtraMessage((prev) => prev + 50);
            }
        };

        if (messagesFirstRef.current) {
            const observer = new IntersectionObserver(
                ([entry]) => {
                    // When the last message is visible in the viewport, update the database
                    if (entry.intersectionRatio >= 0.25) {
                        fetchMoreMessage();
                    }
                },
                { threshold: [0, 0.25, 0.5, 0.75, 1] } // Trigger when the 0.25 element is visible
            );

            observer.observe(messagesFirstRef.current);

            // Cleanup
            return () => observer.disconnect();
        }
    }, [messages]);

    //Need to reset every time the chatRoom changes, or else it will inherit the prev chatroom one
    useEffect(() => {
        prevMessageFirstRef.current = null;
        console.log("prevMessageFirstRef reset");
    }, [currentChatRoom]);

    const list = useList();

    const windowSize = useWindowSize();

    useEffect(() => {
        console.log(windowSize);
    }, [windowSize]);

    const handleSelect = async (sharedWClientList) => {
        try {
            const response = await list(sharedWClientList, "Choose Client Id", "clientId", "face", false, true);
            if (response) {
                setClientId(response.clientId);
            }
        } catch (err) {
            console.log(err.message);
        }
    };

    const handleSendMessage = async () => {
        if (!currentChatRoom) return;
        const number = currentChatRoom.id.split("-")[1];
        const from = currentChatRoom.clientId;
        const response = await sendMessage(number, message, from, apiAddress, secret, user);
        setMessage("");
        console.log(response);
    };

    const displayChatRooms = useMemo(() => {
        const newChatRooms = chatRooms.filter((chatRoom) => {
            return (
                chatRoom.clientId === clientId || (chatRoom.sharedWithId && chatRoom.sharedWithId.includes(clientId))
            );
        });
        return newChatRooms;
    }, [chatRooms, clientId]);

    const handleClose = () => {
        setOpen(false);
    };

    const chooseFriend = useChooseFriend();

    const handleAddMember = async () => {
        const friend = await chooseFriend();
        if (friend) {
            console.log(friend);
            const docRef = doc(db, "whatsappMessages", currentChatRoom.id);
            await updateDoc(docRef, {
                sharedWithId: arrayUnion(removeSpecialChars(friend.id)),
                sharedWithName: arrayUnion(friend.displayName)
            });
            const newCurrentChatRoom = { ...currentChatRoom };

            // Initialize if undefined
            newCurrentChatRoom.sharedWithId = newCurrentChatRoom.sharedWithId || [];
            newCurrentChatRoom.sharedWithName = newCurrentChatRoom.sharedWithName || [];

            newCurrentChatRoom.sharedWithId.push(removeSpecialChars(friend.id));
            newCurrentChatRoom.sharedWithName.push(friend.displayName);
            setCurrentChatRoom(newCurrentChatRoom);
        }
    };

    const handleDeleteMember = async (member, i) => {
        const docRef = doc(db, "whatsappMessages", currentChatRoom.id);
        await updateDoc(docRef, {
            sharedWithId: arrayRemove(member),
            sharedWithName: arrayRemove(currentChatRoom.sharedWithName[i])
        });
        const newCurrentChatRoom = { ...currentChatRoom };
        newCurrentChatRoom.sharedWithId.splice(i, 1);
        newCurrentChatRoom.sharedWithName.splice(i, 1);
        setCurrentChatRoom(newCurrentChatRoom);
    };

    const checkMedia = (message) => {
        console.log(message);
        if (message.type === "ptt") {
            return (
                <audio controls>
                    <source src={message.downloadUrl} />
                    Your browser does not support the audio element.
                </audio>
            );
        } else if (message.type === "image") {
            return <CardMedia component="img" image={message.downloadUrl} alt="file" sx={{ width: "200px" }} />;
        } else {
            return <Typography>{message.downloadUrl}</Typography>;
        }
    };

    return (
        <Container maxWidth="none" disableGutters>
            {windowSize.width < 600 && (
                <Box position="fixed" left="5px" top="61px" zIndex="1000">
                    <Fab color="warning" size="small">
                        <IconButton onClick={() => setOpen(true)} size="small">
                            <KeyboardArrowUpIcon />
                        </IconButton>
                    </Fab>
                </Box>
            )}

            {/* This is the chat room column (left) */}
            <Grid container display="flex" spacing={1}>
                {/* This is when it is desktop view */}
                {windowSize.width > 600 ? (
                    <Grid item xs={12} md={4}>
                        <ChatRooms
                            clientId={clientId}
                            user={user}
                            handleSelect={handleSelect}
                            displayChatRooms={displayChatRooms}
                            setCurrentChatRoom={setCurrentChatRoom}
                            currentChatRoom={currentChatRoom}
                            setChatRoomLimit={setChatRoomLimit}
                            setExtraMessage={setExtraMessage}
                            source={source}
                            setSource={setSource}
                            chatRoomLimit={chatRoomLimit}
                        />
                    </Grid>
                ) : (
                    // This is when it is mobile view
                    <React.Fragment>
                        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                            <AppBar sx={{ position: "relative" }}>
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                        Chat Rooms
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <ChatRooms
                                clientId={clientId}
                                user={user}
                                handleSelect={handleSelect}
                                displayChatRooms={displayChatRooms}
                                setCurrentChatRoom={setCurrentChatRoom}
                                handleClose={handleClose}
                                currentChatRoom={currentChatRoom}
                                setChatRoomLimit={setChatRoomLimit}
                                setExtraMessage={setExtraMessage}
                                source={source}
                                setSource={setSource}
                                chatRoomLimit={chatRoomLimit}
                            />
                        </Dialog>
                    </React.Fragment>
                )}

                {/* This is the chat message column (middle), will appear on both desktop and mobile view */}
                <Grid item xs={12} md={5}>
                    <Grid container display="flex" spacing="8px">
                        {/* This is the message row, lives inside the chat message column */}
                        <Grid item display="flex" xs={12} flexDirection="column">
                            {/* Message header for displaying chatroom id */}
                            {currentChatRoom && (
                                <Grid
                                    item
                                    sx={{
                                        position: "sticky",
                                        top: 0,
                                        backgroundColor: "primary.main",
                                        color: "white",
                                        height: "max-content",
                                        justifyContent: "center",
                                        alignItems: "flex-end",
                                        display: "flex",
                                        flexDirection: "column",
                                        padding: "1% 2%"
                                    }}
                                >
                                    <Typography variant="h6">{currentChatRoom.name}</Typography>
                                    <Typography>{currentChatRoom.id}</Typography>
                                </Grid>
                            )}

                            {loading && <LinearProgress color="inherit" />}

                            <Paper
                                sx={{
                                    height: ["65vh", "70vh"],
                                    width: "100%",
                                    overflowY: "scroll",
                                    paddingRight: "8px"
                                }}
                                ref={messagesRef}
                                variant="outlined"
                            >
                                <Grid container display="flex">
                                    {/* Start of message */}
                                    {messages.map((message, index) => (
                                        <Grid
                                            item
                                            xs={12}
                                            key={message.id}
                                            ref={
                                                index === messages.length - 1 || currentChatRoom.unread === 1
                                                    ? messagesEndRef
                                                    : index === 0
                                                    ? messagesFirstRef
                                                    : currentChatRoom.unread !== 0 &&
                                                      index === messages.length - currentChatRoom.unread
                                                    ? targetMessageRef
                                                    : null
                                            }
                                        >
                                            {currentChatRoom.unread !== 0 &&
                                            index === messages.length - currentChatRoom.unread ? (
                                                <Stack
                                                    direction="row"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    sx={{
                                                        backgroundColor: "primary.light"
                                                    }}
                                                    my="1%"
                                                >
                                                    <Typography sx={{ color: "white" }}>Unread Messages</Typography>
                                                </Stack>
                                            ) : (
                                                <GridDivider mtb={0} />
                                            )}
                                            <GridItem message={message}>
                                                <Name>{formatDate(convertDate(message.date), true)}</Name>
                                                <Name>{message.name}</Name>
                                            </GridItem>
                                            <GridItem message={message}>
                                                <Grid item xs={12}>
                                                    {message.downloadUrl && checkMedia(message)}
                                                    <ReactMarkdown
                                                        components={{
                                                            code({ node, inline, className, children, ...props }) {
                                                                const match = /language-(\w+)/.exec(className || "");
                                                                return !inline && match ? (
                                                                    <CodeBlock
                                                                        language={match[1]}
                                                                        value={String(children).replace(/\n$/, "")}
                                                                        {...props}
                                                                    />
                                                                ) : (
                                                                    <code className={className} {...props}>
                                                                        {children}
                                                                    </code>
                                                                );
                                                            }
                                                        }}
                                                        sx={{ whiteSpace: "pre-wrap" }}
                                                    >
                                                        {message.body}
                                                    </ReactMarkdown>
                                                </Grid>
                                            </GridItem>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Paper>
                        </Grid>

                        {/* This is the send message row, live inside the chat messages column */}
                        <Grid item display="flex" xs={12}>
                            <TextField
                                sx={{ paddingLeft: ["8px", 0], paddingRight: ["8px", 0] }}
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                multiline
                                fullWidth
                                disabled={!currentChatRoom || !whatsappSession}
                                autoComplete="off"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleSendMessage}>
                                                <SendIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                {/* This is the column for connect whatsapp (right), only appears in desktop view */}
                <Grid item xs={12} md={3} sx={{ display: windowSize.width > 600 ? "flex" : "none" }}>
                    <Grid container display="flex">
                        <Grid item display="flex" xs={12} flexDirection="column">
                            <Box mb={"8px"}>
                                <ConnectWhatsappComponent
                                    whatsappUser={user}
                                    setApiAddress={setApiAddress}
                                    setWhatsappSession={setWhatsappSession}
                                    setWhatsappPhone={setWhatsappPhone}
                                    setSecret={setSecret}
                                />
                            </Box>
                        </Grid>

                        {/* This is the add member component */}
                        <Grid item display="flex" xs={12} flexDirection="column">
                            <Paper sx={{ height: "50vh", width: "100%", padding: "8px" }}>
                                <Grid container display="flex">
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            onClick={handleAddMember}
                                            disabled={!currentChatRoom || currentChatRoom.clientId !== user.clientId}
                                        >
                                            Add Member
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <List dense={true}>
                                            {currentChatRoom?.clientId === user?.clientId &&
                                                currentChatRoom?.sharedWithId?.map((member, i) => (
                                                    <React.Fragment key={member}>
                                                        <ListItem
                                                            disablePadding
                                                            secondaryAction={
                                                                <IconButton
                                                                    edge="end"
                                                                    onClick={() => handleDeleteMember(member, i)}
                                                                >
                                                                    <Icon>delete</Icon>
                                                                </IconButton>
                                                            }
                                                        >
                                                            <ListItemButton disableGutters>
                                                                <ListItemText
                                                                    primary={currentChatRoom?.sharedWithName[i]}
                                                                />
                                                            </ListItemButton>
                                                        </ListItem>
                                                    </React.Fragment>
                                                ))}
                                        </List>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

const GridItem = ({ children, xs = 12, message }) => {
    const justifyContent = () => {
        if (message) {
            const from = removeNonNumeric(message.from);
            const number = message.chatRoomId.split("-")[0];
            if (from === number) {
                return "flex-end";
            } else {
                return "flex-start";
            }
        } else {
            return "flex-start";
        }
    };
    return (
        <Grid item display="flex" sx={{ paddingLeft: "8px" }} justifyContent={justifyContent} xs={xs}>
            <Box display="flex" gap="8px" alignContent={"center"}>
                {children}
            </Box>
        </Grid>
    );
};

function ChatRooms({
    clientId,
    user,
    handleSelect,
    displayChatRooms,
    setCurrentChatRoom,
    handleClose = () => {},
    currentChatRoom,
    setChatRoomLimit = () => {},
    setExtraMessage,
    source,
    setSource,
    chatRoomLimit
}) {
    const [selectedChatRoom, setSelectedChatRoom] = useState(currentChatRoom);
    const [searchText, setSearchText] = useState("");
    const [isSorted, setIsSorted] = useState(false);
    const chatRoomRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [alertContent, setAlertContent] = useState({
        content: "",
        severity: "success"
    });
    const [wClient, setWClient] = useState(null);
    const [companyPriceId, setCompanyPriceId] = useState(null);
    const [sharedWClientList, setSharedWClientList] = useState();
    const [isShared, setIsShared] = useState(false);

    //close handler for the snackbar
    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const Alert = forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    //Function to filter the chatroom by name or phone AND the sorting by number of unread count
    const filteredChatRooms = useMemo(() => {
        let results = displayChatRooms;

        if (searchText.trim() !== "") {
            results = results.filter(
                (chatRoom) =>
                    chatRoom.chatRoomId.toLowerCase().includes(searchText.toLowerCase()) ||
                    (chatRoom.name && chatRoom.name.toLowerCase().includes(searchText.toLowerCase()))
            );
        }

        if (isSorted) {
            results = [...results].sort((a, b) => b.unread - a.unread);
        }

        return results;
    }, [searchText, displayChatRooms, isSorted]);

    useEffect(() => {
        if (!user) return;

        const fetchWhatsappClient = async () => {
            console.log("fetchWhatsappClient init");
            const wClientRef = doc(db, "whatsappClients", removeSpecialChars(user.id));
            console.log("fetchWhatsappClient clientId: ", removeSpecialChars(user.id));
            try {
                const wClientSnapshot = await getDoc(wClientRef);
                const wClientData = mapDocSnapshot(wClientSnapshot);
                console.log("fetchWhatsappClient wClientData: ", wClientData);
                setWClient(wClientData);
            } catch (err) {
                console.log(err);
            }
        };

        fetchWhatsappClient();
    }, [user]);

    // This is to fetch the priceId for account type = company
    useEffect(() => {
        const fetchCompanyPriceId = async () => {
            const whatsappCloudPriceColRef = collection(db, "products", WHATSAPPCLOUD_PRODUCT_ID, "prices");
            const q = query(whatsappCloudPriceColRef, where("accountType", "==", "company"));
            try {
                const snapshot = await getDocs(q);
                const prices = mapSnapshot(snapshot);
                setCompanyPriceId(prices);
            } catch (err) {
                console.log(err.message);
            }
        };

        fetchCompanyPriceId();
    }, []);

    //Listener to the chatroom scroll to see whether hit the bottom
    useEffect(() => {
        const handleScroll = () => {
            const current = chatRoomRef.current;
            if (current) {
                const isBottom =
                    Math.trunc(current.scrollHeight - current.scrollTop) === Math.trunc(current.clientHeight);
                if (isBottom) {
                    console.log("Hit the bottom!");
                    setChatRoomLimit((prev) => prev + 50);
                }
            }
        };

        const currentPaper = chatRoomRef.current;
        currentPaper.addEventListener("scroll", handleScroll);

        return () => {
            // Cleanup
            currentPaper.removeEventListener("scroll", handleScroll);
        };
    });

    useEffect(() => {
        const fetchSharedWClientList = async () => {
            if (!user) return;

            let q;
            let isPersonal = true;

            const collectionRef = collection(db, "whatsappClients");
            if (user.role && user.role === "Super Admin") {
                q = query(collectionRef, orderBy("clientId"));
                isPersonal = false;
            } else {
                q = query(
                    collectionRef,
                    where("sharedWithId", "array-contains", user.clientId),
                    orderBy("date", "desc"),
                    limit(chatRoomLimit)
                );
            }
            try {
                const snapshot = await getDocs(q);
                const clientIdObjs = mapSnapshot(snapshot);
                console.log("clientIdObjs: ", clientIdObjs);
                const myEntry = {
                    clientId: user.clientId,
                    id: user.clientId
                };

                //Check if clientId already exists in the array
                const clientIdExists = clientIdObjs.some((item) => item.clientId === myEntry.clientId);

                //If doesnt exist then I add
                if (!clientIdExists) {
                    clientIdObjs.unshift(myEntry);
                }

                setSharedWClientList(clientIdObjs);

                //This is to find out whether the personal account is being shared with other's whatsapp client
                //If yes then show the select client id button
                if (isPersonal) {
                    if (clientIdObjs.length > 0) {
                        setIsShared(true);
                    }
                }
            } catch (err) {
                console.log(err.message);
            }
        };

        fetchSharedWClientList();
    }, [user, chatRoomLimit]);

    const handleChangeSource = (e, value) => {
        console.log("value: ", value);
        setSource(value);
    };

    const chooseFriend = useChooseFriend();
    const handleWhatsappShareClick = async () => {
        const friend = await chooseFriend();
        if (friend) {
            console.log(friend);
            const whatsappClientDocRef = doc(db, "whatsappClients", clientId);
            const formattedFriendId = removeSpecialChars(friend.id);

            try {
                const newWClient = { ...wClient };

                //If already exist then dont need to update anymore
                if (newWClient.sharedWithId && newWClient.sharedWithId.includes(formattedFriendId)) {
                    console.log("The friend already inside the list, no further action");
                    setAlertContent({
                        content: "This friend already inside the list.",
                        severity: "info"
                    });
                    setOpen(true);
                    return;
                }

                await updateDoc(whatsappClientDocRef, {
                    sharedWithId: arrayUnion(formattedFriendId),
                    sharedWithName: arrayUnion(friend.displayName)
                });

                // Initialize if undefined
                newWClient.sharedWithId = newWClient.sharedWithId || [];
                newWClient.sharedWithName = newWClient.sharedWithName || [];

                newWClient.sharedWithId.push(formattedFriendId);
                newWClient.sharedWithName.push(friend.displayName);
                setWClient(newWClient);

                //Popping up the alert telling user succesfully added
                setAlertContent({
                    content: `Whatsapp client has been shared to ${friend.displayName}.`,
                    severity: "success"
                });
                setOpen(true);
            } catch (err) {
                console.log(err.message);
            }
        }
    };

    const handleDeleteSharer = async (member, i) => {
        const whatsappClientDocRef = doc(db, "whatsappClients", clientId);
        const newWClient = { ...wClient };

        try {
            await updateDoc(whatsappClientDocRef, {
                sharedWithId: arrayRemove(member),
                sharedWithName: arrayRemove(newWClient.sharedWithName[i])
            });

            newWClient.sharedWithId.splice(i, 1);
            newWClient.sharedWithName.splice(i, 1);
            setWClient(newWClient);
        } catch (err) {
            console.log(err.message);
        }
    };

    const confirmation = useConfirmation();

    const handleDeleteChatRoom = async (chatRoom) => {
        const docRef = doc(db, "whatsappMessages", chatRoom.id);
        const response = await confirmation("Delete chatroom?", "Press ok to confirm.");
        if (response) {
            try {
                await deleteDoc(docRef);
                console.log("Chat room deleted");
            } catch (err) {
                console.log(err.message);
            }
        }
    };

    const isAccountTypeCompany = (user) => {
        if (!user.subscriptionPriceIds || user.subscriptionPriceIds.length === 0) return false;

        //Extract only the 'id' attributes from the companyPriceId array
        const companyIds = companyPriceId.map((price) => price.id);

        //Check if any id in user.subscriptionPriceIds matches any id in companyPriceId
        return user.subscriptionPriceIds.some((id) => companyIds.includes(id));
    };

    return (
        <Grid container display="flex" spacing={"8px"} sx={{ height: ["80vh", "83vh"] }}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={alertContent.severity} sx={{ width: "100%" }}>
                    {alertContent.content}
                </Alert>
            </Snackbar>

            <Grid item display="flex" xs={12} flexDirection="column">
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Grid container display="flex">
                            <Grid item xs={12} md={12} display="flex">
                                <Box display="flex" alignContent="center" gap="8px">
                                    <Name>Chat Rooms:</Name>
                                    <Name>{clientId}</Name>
                                </Box>
                            </Grid>

                            <Grid container display="flex" justifyContent={"flex-start"} spacing={1}>
                                {/* Only super admin and companyPlan subscriber can use */}
                                {(user && companyPriceId && isAccountTypeCompany(user)) ||
                                user.role === "Super Admin" ? (
                                    <Grid
                                        item
                                        display="flex"
                                        justifyContent={"flex-start"}
                                        sx={{ height: "min-content" }}
                                    >
                                        <Button
                                            size="small"
                                            variant="contained"
                                            onClick={handleWhatsappShareClick}
                                            color="success"
                                            disabled={!user || clientId !== user.clientId}
                                        >
                                            Share
                                        </Button>
                                    </Grid>
                                ) : (
                                    <Grid></Grid>
                                )}

                                {isShared || user.role === "Super Admin" ? (
                                    <Grid
                                        item
                                        display="flex"
                                        justifyContent={"flex-start"}
                                        sx={{ height: "min-content" }}
                                    >
                                        <Button
                                            size="small"
                                            variant="contained"
                                            onClick={() => handleSelect(sharedWClientList)}
                                        >
                                            Select Client Id
                                        </Button>
                                    </Grid>
                                ) : (
                                    <Grid></Grid>
                                )}
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container xs={12}>
                            <Typography variant="subtitle2" xs={12}>
                                Whatsapp Shared With:
                            </Typography>
                            <List dense={true} xs={12} sx={{ width: "100%" }}>
                                {wClient?.sharedWithId?.map((member, i) => (
                                    <React.Fragment key={member}>
                                        <ListItem
                                            disablePadding
                                            secondaryAction={
                                                <IconButton edge="end" onClick={() => handleDeleteSharer(member, i)}>
                                                    <Icon>delete</Icon>
                                                </IconButton>
                                            }
                                        >
                                            <ListItemButton disableGutters>
                                                <ListItemText primary={wClient?.sharedWithName[i]} />
                                            </ListItemButton>
                                        </ListItem>
                                    </React.Fragment>
                                ))}
                            </List>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item display="flex" xs={12} flexDirection="column">
                <Container sx={{ margin: "1% 0" }}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Search"
                        size="small"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }}
                    />
                    <Box display="flex" justifyContent="space-between">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={isSorted}
                                    onChange={() => setIsSorted((prev) => !prev)}
                                    name="sortSwitch"
                                    color="primary"
                                />
                            }
                            label="Sort by Unread"
                        />
                        <Tabs
                            value={source}
                            onChange={handleChangeSource}
                            variant="scrollable"
                            scrollButtons="auto"
                            allowScrollButtonsMobile
                        >
                            <Tab label="Main" />
                            <Tab label="Shared" />
                        </Tabs>
                    </Box>
                </Container>
            </Grid>
            <Grid item display="flex" xs={12} flexDirection="column">
                <Paper
                    ref={chatRoomRef}
                    sx={{ height: "65vh", width: "100%", padding: "8px", overflowY: "scroll" }}
                    variant="outlined"
                >
                    <Grid container display="flex">
                        <GridDivider />
                        <Grid item xs={12} display={"flex"}>
                            <List sx={{ width: "100%" }} dense={true}>
                                {filteredChatRooms && filteredChatRooms.length > 0 ? (
                                    filteredChatRooms.map((chatRoom) => (
                                        // Start of individual chatroom
                                        <React.Fragment key={chatRoom.id}>
                                            <GridContainer>
                                                <GridFlexBox xs={11}>
                                                    <ListItemButton
                                                        alignItems="flex-start"
                                                        sx={{
                                                            justifyContent: "space-between",
                                                            backgroundColor:
                                                                selectedChatRoom && selectedChatRoom.id === chatRoom.id
                                                                    ? blue[50]
                                                                    : "none"
                                                        }}
                                                        onClick={() => {
                                                            handleClose();
                                                            setSelectedChatRoom(chatRoom);
                                                            setCurrentChatRoom(chatRoom);
                                                            setExtraMessage(0);
                                                        }}
                                                    >
                                                        <ListItem sx={{ width: "50%" }}>
                                                            <ListItemText
                                                                primary={
                                                                    <Typography
                                                                        color={blue[600]}
                                                                        overflow="hidden"
                                                                        textOverflow="ellipsis"
                                                                        sx={{ whiteSpace: "nowrap" }}
                                                                    >
                                                                        {chatRoom.name || chatRoom.id.split("-")[1]}
                                                                    </Typography>
                                                                }
                                                                secondary={
                                                                    <React.Fragment>
                                                                        <Box display="flex">
                                                                            <Typography
                                                                                noWrap
                                                                                overflow="hidden"
                                                                                textOverflow="ellipsis"
                                                                                sx={{ whiteSpace: "nowrap" }}
                                                                            >
                                                                                {chatRoom.lastMessage ||
                                                                                    chatRoom.lastMessageType ||
                                                                                    ""}
                                                                            </Typography>
                                                                        </Box>
                                                                    </React.Fragment>
                                                                }
                                                            />
                                                        </ListItem>
                                                        <ListItem
                                                            sx={{
                                                                justifyContent: "flex-end",
                                                                width: "50%"
                                                            }}
                                                        >
                                                            <ListItemText
                                                                primary={
                                                                    <Typography
                                                                        color={grey[500]}
                                                                        size="small"
                                                                        sx={{
                                                                            justifyContent: "flex-end",
                                                                            display: "flex"
                                                                        }}
                                                                    >
                                                                        {formatDate(convertDate(chatRoom.date), true)}
                                                                    </Typography>
                                                                }
                                                                secondary={
                                                                    <React.Fragment>
                                                                        <Box
                                                                            display="flex"
                                                                            sx={{ justifyContent: "flex-end" }}
                                                                        >
                                                                            {Boolean(chatRoom.unreadAI) && (
                                                                                <Chip
                                                                                    label={chatRoom.unreadAI}
                                                                                    color="error"
                                                                                />
                                                                            )}
                                                                            {Boolean(chatRoom.unreadAI) && (
                                                                                <Chip
                                                                                    label={chatRoom.unread}
                                                                                    color="primary"
                                                                                />
                                                                            )}
                                                                        </Box>
                                                                    </React.Fragment>
                                                                }
                                                            />
                                                        </ListItem>
                                                    </ListItemButton>
                                                </GridFlexBox>
                                                <GridFlexBox xs={1}>
                                                    <IconButton onClick={() => handleDeleteChatRoom(chatRoom)}>
                                                        <Icon>delete</Icon>
                                                    </IconButton>
                                                </GridFlexBox>
                                            </GridContainer>

                                            <Divider />
                                        </React.Fragment>
                                    ))
                                ) : (
                                    <ListItem>
                                        <Typography
                                            variant="h6"
                                            xs={12}
                                            color={grey[500]}
                                            sx={{ textAlign: "center", width: "100%" }}
                                        >
                                            Sorry, no chat found
                                        </Typography>
                                    </ListItem>
                                )}
                            </List>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

function removeNonNumeric(inputString) {
    return inputString.replace(/[^0-9]/g, "");
}
