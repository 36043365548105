import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { toast } from "react-hot-toast";

import { Avatar, Box, Button, Chip, Dialog, DialogTitle, IconButton, TextField, Typography } from "@mui/material";
import { collection, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import convertDate from "../../functions/common-functions/convertDate";
import formatDate from "../../functions/common-functions/formatDate";
import useColors from "../../hooks/useColors";
import { Name } from "../../themes/themes";
import mapSnapshot from "../../utils-functions/mapSnapshot";
import formatWhatsAppStyle from "./formatWhatsappStyle";

export function ChatRoomComponent({ chatRoom = {}, handleClick }) {
    const [typographyWidth, setTypographyWidth] = useState("auto");
    const [openDialog, setOpenDialog] = useState(false);

    const number = chatRoom?.id?.split("-")[0] || "";

    const { user } = useContext(AuthContext);

    const containerRef = useRef(null);

    const colors = useColors();

    useEffect(() => {
        const calculateWidth = () => {
            if (containerRef.current) {
                const containerWidth = containerRef.current.offsetWidth;
                const iconButtonWidth = 52; // Adjust this based on actual IconButton size
                const calculatedWidth = containerWidth - iconButtonWidth - 8 * 2; // Subtract IconButton width and margins
                setTypographyWidth(calculatedWidth);
            }
        };

        // Calculate on mount and window resize
        calculateWidth();
        window.addEventListener("resize", calculateWidth);

        // Cleanup
        return () => window.removeEventListener("resize", calculateWidth);
    });

    const messageToDisplay = () => {
        if (chatRoom?.lastMessageType === "ptt") return "Voice message 🎤";
        if (chatRoom?.lastMessageType === "image") return "Image 📷";

        const lastMessage = chatRoom?.lastMessage;
        if (!lastMessage) return "";

        return lastMessage ? formatWhatsAppStyle(lastMessage) : "";
    };

    const handleClickMore = (event, chatRoom) => {
        console.log("clicked more", chatRoom);
        event.stopPropagation();
        setOpenDialog(true);
    };

    return (
        <Box
            display="flex"
            flexDirection={"column"}
            width={"100%"}
            sx={{
                borderBottom: `1px solid ${colors.topBarLine}`,
                "&:hover": {
                    backgroundColor: colors.boxHighlight // Replace 'primary.main' with your desired color
                },
                cursor: "pointer"
            }}
        >
            <Box
                display={"flex"}
                alignItems={"center"}
                width={"100%"}
                onClick={() => handleClick(chatRoom)}
                justifyContent={"space-between"}
                px="4px"
            >
                <Box p="8px" display="flex" width={"100%"} ref={containerRef}>
                    <Box>
                        <Avatar sx={{ width: "45px", height: "45px" }} />
                    </Box>
                    <Box display="flex" flexDirection={"column"} p="0 8px 0 8px" width={"100%"}>
                        <Box
                            display="flex"
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            width={`${typographyWidth}px`}
                        >
                            <Typography
                                variant="body1"
                                flex={1}
                                noWrap
                                sx={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    wordBreak: "break-word" // Break long words
                                }}
                            >
                                {chatRoom?.name || number}
                            </Typography>
                            <Typography variant="caption" color="#71818a">
                                {chatRoom?.date ? formatDate(convertDate(chatRoom.date), true) : ""}
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            width={`${typographyWidth}px`} // Set width to calculated width because nowrap only works with fixed width
                        >
                            <Typography
                                variant="body2"
                                flex={1}
                                noWrap
                                sx={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    wordBreak: "break-word" // Break long words
                                }}
                            >
                                {messageToDisplay()}
                            </Typography>
                            {Boolean(chatRoom.unreadAI) && <Chip label={chatRoom.unreadAI} color="error" />}
                            {Boolean(chatRoom.unread) && <Chip label={chatRoom.unread} color="primary" />}
                            <IconButton size="small" onClick={(event) => handleClickMore(event, chatRoom)}>
                                <ExpandMoreIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box px={1} pb={1}>
                {chatRoom?.flowName && <Chip label={chatRoom.flowName} color="primary" size="small" />}{" "}
            </Box>
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                PaperProps={{
                    sx: {
                        width: { xs: "99vw", md: "70vw" }, // Adjust breakpoints as needed
                        height: "71vh",
                        maxWidth: "100%" // Ensure the dialog doesn't exceed the screen width
                    }
                }}
            >
                <FlowComponent chatRoom={chatRoom} user={user} />
            </Dialog>
        </Box>
    );
}

const FlowComponent = ({ chatRoom, user }) => {
    const [flows, setFlows] = useState([]);
    const [currentFlow, setCurrentFlow] = useState();
    const [currentFlowId, setCurrentFlowId] = useState();

    const colors = useColors();

    useEffect(() => {
        const collectionRef = collection(db, "users", user.id, "whatsappFlows");
        const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
            const data = mapSnapshot(snapshot);
            setFlows(data);
        });

        return () => {
            unsubscribe();
        };
    });

    useEffect(() => {
        if (!currentFlowId) return;
        const flow = flows.find((flow) => flow.id === currentFlowId);
        setCurrentFlow(flow);
    }, [currentFlowId]);

    const handleClickAssign = async () => {
        const docRef = doc(db, "fbMessages", chatRoom.id);
        await updateDoc(docRef, { flowId: currentFlowId, flowUserId: user.id, flowName: currentFlow.name });
        toast.success("Flow assigned successfully");
    };

    return (
        <>
            <DialogTitle>
                <Box display="flex" gap={3}>
                    <Typography variant="h5">Flows</Typography>
                    <Button variant="contained" disabled={!currentFlowId} onClick={handleClickAssign}>
                        Assign
                    </Button>
                </Box>
            </DialogTitle>
            <Box p={2} display="flex" height={"100%"} gap={1}>
                <Box
                    sx={{ width: "40%", height: "100%", border: `1px solid ${colors.topBarLine}`, overflowY: "auto" }}
                    display="flex"
                    flexDirection={"column"}
                >
                    {flows.map((flow) => (
                        <Box
                            key={flow.id}
                            onClick={() => {
                                console.log("clicked flow", flow);
                                setCurrentFlowId(flow.id);
                            }}
                            sx={{
                                borderBottom: `1px solid ${colors.topBarLine}`,
                                "&:hover": {
                                    backgroundColor: colors.boxHighlight // Replace 'primary.main' with your desired color
                                },
                                cursor: "pointer"
                            }}
                            p={2}
                        >
                            <Name bold={currentFlowId === flow.id}>{flow.name}</Name>
                        </Box>
                    ))}
                </Box>
                <Box flex={1} display="flex" flexDirection={"column"} justifyContent={"flex-start"} gap={1}>
                    <TextField
                        multiline
                        rows={4}
                        value={currentFlow?.system || ""}
                        onKeyDown={(e) => {
                            e.preventDefault();
                        }}
                    />
                    <TextField
                        multiline
                        rows={20}
                        value={currentFlow?.flowDesign || ""}
                        onKeyDown={(e) => {
                            e.preventDefault();
                        }}
                    />
                </Box>
            </Box>
        </>
    );
};
