export const sampleActions = [
    {
        action: "Called",
        remark: "not looking for property ",
        apptm: null,
        files: null,
        created: {
            seconds: 1627546935,
            nanoseconds: 461000000
        },
        user: "janecwy11@gmail.com",
        userName: "Jane Chan",
        id: "RoqUZMD5wl3Ux4L2C2Wc",
        path: "leads/00E3Oz425UUQvCqVauf9/actions/RoqUZMD5wl3Ux4L2C2Wc",
        leadId: "00E3Oz425UUQvCqVauf9"
    },
    {
        apptm: null,
        user: "ngsinyi@hotmail.com",
        remark: "",
        files: null,
        action: "Called",
        created: {
            seconds: 1627651557,
            nanoseconds: 803000000
        },
        userName: "Elsa  Ng",
        id: "YOPTsDHpZZFQMHJItFae",
        path: "leads/02SkLENKVvXU7K3apnKt/actions/YOPTsDHpZZFQMHJItFae",
        leadId: "02SkLENKVvXU7K3apnKt"
    },
    {
        files: null,
        userName: "Elsa  Ng",
        remark: "Signed another project ",
        action: "Called",
        user: "ngsinyi@hotmail.com",
        created: {
            seconds: 1627651609,
            nanoseconds: 576000000
        },
        apptm: null,
        id: "pOQ6Gt5vKCU5uPWQXc5F",
        path: "leads/02SkLENKVvXU7K3apnKt/actions/pOQ6Gt5vKCU5uPWQXc5F",
        leadId: "02SkLENKVvXU7K3apnKt"
    },
    {
        remark: "He on the class right now not familiar to talk tmr call her back ",
        action: "Called",
        user: "jeremytan.iqi@gmail.com",
        files: null,
        apptm: null,
        userName: "Jeremy Tan",
        created: {
            seconds: 1666875233,
            nanoseconds: 626000000
        },
        id: "1NJU1eLEEDKdcioaqrEM",
        path: "leads/02UxA80zYQbZCjjQu4bk/actions/1NJU1eLEEDKdcioaqrEM",
        leadId: "02UxA80zYQbZCjjQu4bk"
    },
    {
        apptm: null,
        user: "iqi.jimmyng@gmail.com",
        userName: "Jimmy Ng ",
        remark: "blast projects ",
        action: "Whatsapped",
        created: {
            seconds: 1665496547,
            nanoseconds: 127000000
        },
        files: null,
        id: "B8uda54ygIXOrHucQBoC",
        path: "leads/02UxA80zYQbZCjjQu4bk/actions/B8uda54ygIXOrHucQBoC",
        leadId: "02UxA80zYQbZCjjQu4bk"
    },
    {
        user: "iqi.jimmyng@gmail.com",
        apptm: null,
        files: null,
        created: {
            seconds: 1668166412,
            nanoseconds: 640000000
        },
        userName: "Jimmy Ng ",
        remark: "blast mossaz",
        action: "Whatsapped",
        id: "IyqoOY2NbMb8kyenpdVb",
        path: "leads/02UxA80zYQbZCjjQu4bk/actions/IyqoOY2NbMb8kyenpdVb",
        leadId: "02UxA80zYQbZCjjQu4bk"
    },
    {
        action: "Called",
        apptm: null,
        files: null,
        user: "zzhenghoong96@gmail.com",
        remark: "Previous want use son name but son dw. Self is foreigner ",
        userName: "Zheng Hoong Tan",
        created: {
            seconds: 1664970763,
            nanoseconds: 91000000
        },
        id: "JvEcJKitktiSaDQVc8kw",
        path: "leads/02UxA80zYQbZCjjQu4bk/actions/JvEcJKitktiSaDQVc8kw",
        leadId: "02UxA80zYQbZCjjQu4bk"
    },
    {
        user: "iqi.jimmyng@gmail.com",
        action: "Whatsapped",
        files: null,
        remark: "asking ownstay or investment",
        userName: "Jimmy Ng ",
        created: {
            seconds: 1665235503,
            nanoseconds: 89000000
        },
        apptm: null,
        id: "KNKpf5E9rWepGbaq4Txg",
        path: "leads/02UxA80zYQbZCjjQu4bk/actions/KNKpf5E9rWepGbaq4Txg",
        leadId: "02UxA80zYQbZCjjQu4bk"
    },
    {
        apptm: null,
        created: {
            seconds: 1668523310,
            nanoseconds: 709000000
        },
        userName: "Zheng Hoong Tan",
        user: "zzhenghoong96@gmail.com",
        action: "Whatsapped",
        remark: "",
        files: null,
        id: "Ot81EWTNSNOHWgjKGD67",
        path: "leads/02UxA80zYQbZCjjQu4bk/actions/Ot81EWTNSNOHWgjKGD67",
        leadId: "02UxA80zYQbZCjjQu4bk"
    },
    {
        remark: "Send her the mossaz details see see first then tmr call back ",
        action: "Whatsapped",
        apptm: null,
        created: {
            seconds: 1666875321,
            nanoseconds: 569000000
        },
        userName: "Jeremy Tan",
        user: "jeremytan.iqi@gmail.com",
        files: null,
        id: "TjyGqsTbfpgr6SVi53fM",
        path: "leads/02UxA80zYQbZCjjQu4bk/actions/TjyGqsTbfpgr6SVi53fM",
        leadId: "02UxA80zYQbZCjjQu4bk"
    },
    {
        created: {
            seconds: 1680240296,
            nanoseconds: 856000000
        },
        leadId: "02UxA80zYQbZCjjQu4bk",
        files: null,
        apptm: null,
        user: "xiiaosheng33096@gmail.com",
        action: "Called",
        userName: "foo fangsheng",
        remark: "",
        id: "ZyhhzIt04qjbhVgmKqT2",
        path: "leads/02UxA80zYQbZCjjQu4bk/actions/ZyhhzIt04qjbhVgmKqT2"
    },
    {
        remark: "",
        created: {
            seconds: 1680240288,
            nanoseconds: 387000000
        },
        action: "Whatsapped",
        user: "xiiaosheng33096@gmail.com",
        files: null,
        leadId: "02UxA80zYQbZCjjQu4bk",
        userName: "foo fangsheng",
        apptm: null,
        id: "loTWCBRo7HEZuXV65an9",
        path: "leads/02UxA80zYQbZCjjQu4bk/actions/loTWCBRo7HEZuXV65an9"
    },
    {
        remark: "UTC",
        action: "Called",
        leadId: "02UxA80zYQbZCjjQu4bk",
        created: {
            seconds: 1680240352,
            nanoseconds: 127000000
        },
        files: null,
        apptm: null,
        userName: "foo fangsheng",
        user: "xiiaosheng33096@gmail.com",
        id: "rTcizKuMAtnl5QaORhEI",
        path: "leads/02UxA80zYQbZCjjQu4bk/actions/rTcizKuMAtnl5QaORhEI"
    },
    {
        files: null,
        action: "Research",
        apptm: null,
        userName: "ST HAN",
        remark: "showroom appt\n",
        created: {
            seconds: 1630997809,
            nanoseconds: 227000000
        },
        user: "hanst9803@gmail.com",
        id: "RlAJg9wKz936SPbuet8X",
        path: "leads/03RRu86cqEM43mJVin74/actions/RlAJg9wKz936SPbuet8X",
        leadId: "03RRu86cqEM43mJVin74"
    },
    {
        apptm: null,
        remark: "Busying, ask me WhatsApp",
        files: null,
        user: "zzhenghoong96@gmail.com",
        created: {
            seconds: 1664799646,
            nanoseconds: 476000000
        },
        userName: "Zheng Hoong Tan",
        action: "Called",
        id: "3aEZ5nkDj5Rh6HYhw3ko",
        path: "leads/04gwgAhaFVtNGSQO3X4v/actions/3aEZ5nkDj5Rh6HYhw3ko",
        leadId: "04gwgAhaFVtNGSQO3X4v"
    },
    {
        action: "Called",
        files: null,
        user: "vinzchau00@gmail.com",
        remark: "He not free now ,ltr call back",
        userName: "Vinz Chau",
        created: {
            seconds: 1664431944,
            nanoseconds: 870000000
        },
        apptm: null,
        id: "CwTzuMs7gCGnQpKXDj2M",
        path: "leads/04gwgAhaFVtNGSQO3X4v/actions/CwTzuMs7gCGnQpKXDj2M",
        leadId: "04gwgAhaFVtNGSQO3X4v"
    },
    {
        files: null,
        userName: "Zheng Hoong Tan",
        apptm: null,
        user: "zzhenghoong96@gmail.com",
        created: {
            seconds: 1669882232,
            nanoseconds: 471000000
        },
        remark: "",
        action: "Whatsapped",
        id: "IkPwFsg99QCp33RubYJ8",
        path: "leads/04gwgAhaFVtNGSQO3X4v/actions/IkPwFsg99QCp33RubYJ8",
        leadId: "04gwgAhaFVtNGSQO3X4v"
    },
    {
        user: "zzhenghoong96@gmail.com",
        files: null,
        remark: "",
        action: "Whatsapped",
        apptm: null,
        created: {
            seconds: 1668522779,
            nanoseconds: 385000000
        },
        userName: "Zheng Hoong Tan",
        id: "KX5PSs7Ti5jdpego3yBW",
        path: "leads/04gwgAhaFVtNGSQO3X4v/actions/KX5PSs7Ti5jdpego3yBW",
        leadId: "04gwgAhaFVtNGSQO3X4v"
    },
    {
        userName: "Zheng Hoong Tan",
        apptm: null,
        user: "zzhenghoong96@gmail.com",
        action: "Whatsapped",
        created: {
            seconds: 1665568654,
            nanoseconds: 619000000
        },
        files: null,
        remark: "Whatsapp follow up",
        id: "ORpu9mESVk2WyA1oet46",
        path: "leads/04gwgAhaFVtNGSQO3X4v/actions/ORpu9mESVk2WyA1oet46",
        leadId: "04gwgAhaFVtNGSQO3X4v"
    },
    {
        userName: "Jimmy Ng ",
        created: {
            seconds: 1669979645,
            nanoseconds: 795000000
        },
        remark: "asking is he still looking for property at pj \n",
        files: null,
        user: "iqi.jimmyng@gmail.com",
        action: "Whatsapped",
        apptm: null,
        id: "bH9zUhPIyzf1fMBXnyDv",
        path: "leads/04gwgAhaFVtNGSQO3X4v/actions/bH9zUhPIyzf1fMBXnyDv",
        leadId: "04gwgAhaFVtNGSQO3X4v"
    },
    {
        userName: "Vinz Chau",
        user: "vinzchau00@gmail.com",
        apptm: null,
        action: "Called",
        remark: "Call bck tmr 1pm",
        created: {
            seconds: 1664458414,
            nanoseconds: 28000000
        },
        files: null,
        id: "jEmvn9f7yi36LoBuWknD",
        path: "leads/04gwgAhaFVtNGSQO3X4v/actions/jEmvn9f7yi36LoBuWknD",
        leadId: "04gwgAhaFVtNGSQO3X4v"
    },
    {
        user: "iqi.jimmyng@gmail.com",
        action: "Called",
        apptm: null,
        remark: "ask to whatsapp ",
        files: null,
        created: {
            seconds: 1669979593,
            nanoseconds: 867000000
        },
        userName: "Jimmy Ng ",
        id: "oK4xJtBSNriHSB3crwno",
        path: "leads/04gwgAhaFVtNGSQO3X4v/actions/oK4xJtBSNriHSB3crwno",
        leadId: "04gwgAhaFVtNGSQO3X4v"
    },
    {
        remark: "No longer looking for property.",
        files: null,
        apptm: null,
        userName: "Jason Mok",
        created: {
            seconds: 1628672972,
            nanoseconds: 964000000
        },
        user: "jasonmch02@gmail.com",
        action: "Whatsapped",
        id: "YuHYOLK3nEISbHFkD4Eh",
        path: "leads/04j46l4WzNkGCgXkt7Es/actions/YuHYOLK3nEISbHFkD4Eh",
        leadId: "04j46l4WzNkGCgXkt7Es"
    },
    {
        remark: "ady bought",
        userName: "Vinz Chau",
        user: "vinzchau00@gmail.com",
        apptm: null,
        created: {
            seconds: 1667996984,
            nanoseconds: 270000000
        },
        files: null,
        action: "Research",
        id: "oVOQ7d0gtVOx4do2pTw9",
        path: "leads/05NEvpKPOpsISz1aE0xJ/actions/oVOQ7d0gtVOx4do2pTw9",
        leadId: "05NEvpKPOpsISz1aE0xJ"
    },
    {
        userName: "CY Yong",
        files: null,
        remark: "cannot reach",
        created: {
            seconds: 1626421944,
            nanoseconds: 269000000
        },
        apptm: null,
        action: "Called",
        user: "charles951121@gmail.com",
        id: "00c8ckBdTEHD3MH6aYic",
        path: "leads/05g4yNzkjPcliW4E6Vj6/actions/00c8ckBdTEHD3MH6aYic",
        leadId: "05g4yNzkjPcliW4E6Vj6"
    },
    {
        action: "Called",
        files: null,
        created: {
            seconds: 1626269032,
            nanoseconds: 41000000
        },
        user: "charles951121@gmail.com",
        apptm: null,
        remark: "npu",
        userName: "CY Yong",
        id: "BpizyNIk5y2lUxgvmrQ8",
        path: "leads/05g4yNzkjPcliW4E6Vj6/actions/BpizyNIk5y2lUxgvmrQ8",
        leadId: "05g4yNzkjPcliW4E6Vj6"
    },
    {
        user: "charles951121@gmail.com",
        created: {
            seconds: 1626269041,
            nanoseconds: 73000000
        },
        action: "Whatsapped",
        apptm: null,
        remark: "",
        userName: "CY Yong",
        files: null,
        id: "VZmchFwITBw2y62TVmyE",
        path: "leads/05g4yNzkjPcliW4E6Vj6/actions/VZmchFwITBw2y62TVmyE",
        leadId: "05g4yNzkjPcliW4E6Vj6"
    },
    {
        remark: "",
        apptm: null,
        files: null,
        created: {
            seconds: 1626421960,
            nanoseconds: 952000000
        },
        userName: "CY Yong",
        user: "charles951121@gmail.com",
        action: "Whatsapped",
        id: "WiPECELMfhs5epx9lRf0",
        path: "leads/05g4yNzkjPcliW4E6Vj6/actions/WiPECELMfhs5epx9lRf0",
        leadId: "05g4yNzkjPcliW4E6Vj6"
    },
    {
        files: null,
        user: "kohpohfen@gmail.com",
        action: "Whatsapped",
        remark: "",
        apptm: null,
        created: {
            seconds: 1658235282,
            nanoseconds: 176000000
        },
        userName: "Summer Koh",
        id: "2mbbaEf8aKQ1Ti5wZd4j",
        path: "leads/05jjPeD7Ol3W2912Jgzd/actions/2mbbaEf8aKQ1Ti5wZd4j",
        leadId: "05jjPeD7Ol3W2912Jgzd"
    },
    {
        action: "Whatsapped",
        remark: "Send D'Terra brochure to customer\n",
        user: "zzhenghoong96@gmail.com",
        files: null,
        created: {
            seconds: 1659772399,
            nanoseconds: 988000000
        },
        userName: "Zheng Hoong Tan",
        apptm: null,
        id: "J8dZ3UUfCkEsVyc27obu",
        path: "leads/05jjPeD7Ol3W2912Jgzd/actions/J8dZ3UUfCkEsVyc27obu",
        leadId: "05jjPeD7Ol3W2912Jgzd"
    },
    {
        apptm: null,
        created: {
            seconds: 1658235396,
            nanoseconds: 27000000
        },
        files: null,
        remark: "吕 fb register",
        action: "Research",
        userName: "Summer Koh",
        user: "kohpohfen@gmail.com",
        id: "M0Jxufg4Mw6eB7wnEzrZ",
        path: "leads/05jjPeD7Ol3W2912Jgzd/actions/M0Jxufg4Mw6eB7wnEzrZ",
        leadId: "05jjPeD7Ol3W2912Jgzd"
    },
    {
        user: "kohpohfen@gmail.com",
        created: {
            seconds: 1658840819,
            nanoseconds: 908000000
        },
        action: "Whatsapped",
        userName: "Summer Koh",
        files: null,
        apptm: null,
        remark: "Duno stay or invest. Wan stay first. If ownstay,wan fast completion, if slow completion, is invest. 500-6xxk he think, he duno sis budget. Wait he go bangkok with sis first, then cn discuss. Next week go cameron, mayb come on Saturday ",
        id: "NDb8GU3KW6GCC8Oo1toa",
        path: "leads/05jjPeD7Ol3W2912Jgzd/actions/NDb8GU3KW6GCC8Oo1toa",
        leadId: "05jjPeD7Ol3W2912Jgzd"
    },
    {
        apptm: null,
        remark: "No pick up",
        userName: "Wilson Tan",
        created: {
            seconds: 1659095824,
            nanoseconds: 7000000
        },
        files: null,
        action: "Called",
        user: "wilsontan00@gmail.com",
        id: "RNdbspSTVfpgbxdrXS8J",
        path: "leads/05jjPeD7Ol3W2912Jgzd/actions/RNdbspSTVfpgbxdrXS8J",
        leadId: "05jjPeD7Ol3W2912Jgzd"
    },
    {
        user: "zzhenghoong96@gmail.com",
        remark: "",
        created: {
            seconds: 1668520855,
            nanoseconds: 24000000
        },
        userName: "Zheng Hoong Tan",
        apptm: null,
        files: null,
        action: "Whatsapped",
        id: "WqZ3MLYRi70phWYAdN9p",
        path: "leads/05jjPeD7Ol3W2912Jgzd/actions/WqZ3MLYRi70phWYAdN9p",
        leadId: "05jjPeD7Ol3W2912Jgzd"
    },
    {
        created: {
            seconds: 1658152699,
            nanoseconds: 851000000
        },
        userName: "Summer Koh",
        remark: "Pj\nInvest\nLike\nStay nearby pj\nStay Setia alam\nOkay \n3rooms\nJoint name with sis\n\nMayb ownstay",
        action: "Research",
        apptm: null,
        user: "kohpohfen@gmail.com",
        files: null,
        id: "Xl4yhl4j31smieU162aC",
        path: "leads/05jjPeD7Ol3W2912Jgzd/actions/Xl4yhl4j31smieU162aC",
        leadId: "05jjPeD7Ol3W2912Jgzd"
    },
    {
        user: "wilsontan00@gmail.com",
        userName: "Wilson Tan",
        action: "Called",
        remark: "Call back 8pm",
        apptm: null,
        files: null,
        created: {
            seconds: 1660298904,
            nanoseconds: 375000000
        },
        id: "d1VSmjYzaU9iuEiGmAhq",
        path: "leads/05jjPeD7Ol3W2912Jgzd/actions/d1VSmjYzaU9iuEiGmAhq",
        leadId: "05jjPeD7Ol3W2912Jgzd"
    },
    {
        remark: "send brochure  . working ",
        userName: "Pang  Yy",
        created: {
            seconds: 1660637588,
            nanoseconds: 539000000
        },
        apptm: null,
        files: null,
        user: "cindy_pang91@hotmail.com",
        action: "Research",
        id: "gLPaoD1wFUlI9AM3hSaC",
        path: "leads/05jjPeD7Ol3W2912Jgzd/actions/gLPaoD1wFUlI9AM3hSaC",
        leadId: "05jjPeD7Ol3W2912Jgzd"
    },
    {
        created: {
            seconds: 1658235107,
            nanoseconds: 800000000
        },
        apptm: null,
        user: "kohpohfen@gmail.com",
        files: null,
        remark: "showroom.",
        action: "Called",
        userName: "Summer Koh",
        id: "hLWT4YQr8v73d464Isgj",
        path: "leads/05jjPeD7Ol3W2912Jgzd/actions/hLWT4YQr8v73d464Isgj",
        leadId: "05jjPeD7Ol3W2912Jgzd"
    },
    {
        files: null,
        action: "Appointment",
        remark: "Date: 2022-07-22 | Time: 22:54 | Venue: showroom | Language: undefined | Budget: time not sure yet | Intention: mandarin",
        apptm: {
            time: "22:54",
            date: {
                seconds: 1658448000,
                nanoseconds: 0
            },
            user: "kohpohfen@gmail.com",
            venue: "showroom",
            intention: "mandarin",
            budget: "time not sure yet"
        },
        user: "kohpohfen@gmail.com",
        created: {
            seconds: 1658235181,
            nanoseconds: 610000000
        },
        userName: "Summer Koh",
        id: "kKHgm9qvELUYoC2ZoJ0Z",
        path: "leads/05jjPeD7Ol3W2912Jgzd/actions/kKHgm9qvELUYoC2ZoJ0Z",
        leadId: "05jjPeD7Ol3W2912Jgzd"
    },
    {
        user: "cindy_pang91@hotmail.com",
        userName: "Pang  Yy",
        apptm: null,
        action: "Research",
        created: {
            seconds: 1667551591,
            nanoseconds: 14000000
        },
        remark: "bought sekinchan landed- hometown. rent then stay",
        files: null,
        id: "mx5l2STweVC7k70FayDG",
        path: "leads/05jjPeD7Ol3W2912Jgzd/actions/mx5l2STweVC7k70FayDG",
        leadId: "05jjPeD7Ol3W2912Jgzd"
    },
    {
        created: {
            seconds: 1669880897,
            nanoseconds: 654000000
        },
        apptm: null,
        remark: "",
        userName: "Zheng Hoong Tan",
        files: null,
        action: "Whatsapped",
        user: "zzhenghoong96@gmail.com",
        id: "p7zMYH3sRsEypf7IHB90",
        path: "leads/05jjPeD7Ol3W2912Jgzd/actions/p7zMYH3sRsEypf7IHB90",
        leadId: "05jjPeD7Ol3W2912Jgzd"
    },
    {
        apptm: null,
        userName: "Zheng Hoong Tan",
        action: "Called",
        user: "zzhenghoong96@gmail.com",
        remark: "Say not free now",
        files: null,
        created: {
            seconds: 1659616707,
            nanoseconds: 750000000
        },
        id: "qqTlZjJ5fugnGoSVwfwV",
        path: "leads/05jjPeD7Ol3W2912Jgzd/actions/qqTlZjJ5fugnGoSVwfwV",
        leadId: "05jjPeD7Ol3W2912Jgzd"
    },
    {
        files: null,
        remark: "",
        user: "kohpohfen@gmail.com",
        apptm: null,
        action: "Called",
        created: {
            seconds: 1658840810,
            nanoseconds: 387000000
        },
        userName: "Summer Koh",
        id: "vv37ShZ3q8HxoDzPMDDa",
        path: "leads/05jjPeD7Ol3W2912Jgzd/actions/vv37ShZ3q8HxoDzPMDDa",
        leadId: "05jjPeD7Ol3W2912Jgzd"
    },
    {
        created: {
            seconds: 1660482264,
            nanoseconds: 654000000
        },
        apptm: null,
        userName: "Zheng Hoong Tan",
        remark: "Send Lavile brochure to customer\n",
        files: null,
        user: "zzhenghoong96@gmail.com",
        action: "Research",
        id: "wXAaVihlIMzN5cQfslhl",
        path: "leads/05jjPeD7Ol3W2912Jgzd/actions/wXAaVihlIMzN5cQfslhl",
        leadId: "05jjPeD7Ol3W2912Jgzd"
    },
    {
        apptm: null,
        userName: "Summer Koh",
        remark: "sis",
        files: null,
        action: "Whatsapped",
        user: "kohpohfen@gmail.com",
        created: {
            seconds: 1658235096,
            nanoseconds: 300000000
        },
        id: "zF2ZxT92BNvQWe1RSYL2",
        path: "leads/05jjPeD7Ol3W2912Jgzd/actions/zF2ZxT92BNvQWe1RSYL2",
        leadId: "05jjPeD7Ol3W2912Jgzd"
    },
    {
        user: "ngsinyi@hotmail.com",
        userName: "Elsa  Ng",
        remark: "No response",
        created: {
            seconds: 1625575794,
            nanoseconds: 806000000
        },
        files: null,
        action: "Called",
        id: "2Q8UEstcYKg77LvWbOpa",
        path: "leads/05lE3r6nceMKX16qrJuw/actions/2Q8UEstcYKg77LvWbOpa",
        leadId: "05lE3r6nceMKX16qrJuw"
    },
    {
        remark: "sent DÍVO POSTER",
        action: "Whatsapped",
        user: "msleow@gmail.com",
        files: null,
        apptm: null,
        userName: "Iris Leow",
        created: {
            seconds: 1630426177,
            nanoseconds: 839000000
        },
        id: "aLKe8ivXUiLc7z3Bi3qM",
        path: "leads/05lE3r6nceMKX16qrJuw/actions/aLKe8ivXUiLc7z3Bi3qM",
        leadId: "05lE3r6nceMKX16qrJuw"
    },
    {
        created: {
            seconds: 1634958456,
            nanoseconds: 730000000
        },
        userName: "Vernice Lee",
        action: "Whatsapped",
        files: null,
        apptm: null,
        remark: "",
        user: "vernicelee2621@gmail.com",
        id: "7Vo9rswKURGht64xZgzG",
        path: "leads/06cQVNlbT69gokwIUgyZ/actions/7Vo9rswKURGht64xZgzG",
        leadId: "06cQVNlbT69gokwIUgyZ"
    },
    {
        action: "Research",
        remark: "send msg",
        created: {
            seconds: 1635486031,
            nanoseconds: 700000000
        },
        user: "irychang20@gmail.com",
        files: null,
        apptm: null,
        userName: "iry chang",
        id: "931MH4XkHI3CnHplQArQ",
        path: "leads/06cQVNlbT69gokwIUgyZ/actions/931MH4XkHI3CnHplQArQ",
        leadId: "06cQVNlbT69gokwIUgyZ"
    },
    {
        created: {
            seconds: 1635313018,
            nanoseconds: 289000000
        },
        apptm: null,
        action: "Research",
        userName: "iry chang",
        files: null,
        remark: "no pick up",
        user: "irychang20@gmail.com",
        id: "GfryqyiJTtFd9F5CGEUX",
        path: "leads/06cQVNlbT69gokwIUgyZ/actions/GfryqyiJTtFd9F5CGEUX",
        leadId: "06cQVNlbT69gokwIUgyZ"
    },
    {
        remark: "repeated",
        userName: "ST HAN",
        files: null,
        created: {
            seconds: 1635336287,
            nanoseconds: 804000000
        },
        apptm: null,
        user: "hanst9803@gmail.com",
        action: "Research",
        id: "HJPiozPSybdLwLMkJSr3",
        path: "leads/06cQVNlbT69gokwIUgyZ/actions/HJPiozPSybdLwLMkJSr3",
        leadId: "06cQVNlbT69gokwIUgyZ"
    },
    {
        userName: "Vernice Lee",
        remark: "no pickup call",
        action: "Research",
        user: "vernicelee2621@gmail.com",
        apptm: null,
        created: {
            seconds: 1634958450,
            nanoseconds: 381000000
        },
        files: null,
        id: "fKLPXcpAQOYUqLn6fyLc",
        path: "leads/06cQVNlbT69gokwIUgyZ/actions/fKLPXcpAQOYUqLn6fyLc",
        leadId: "06cQVNlbT69gokwIUgyZ"
    },
    {
        userName: "Vernice Lee",
        created: {
            seconds: 1634637427,
            nanoseconds: 598000000
        },
        remark: "no pick up call",
        action: "Research",
        apptm: null,
        user: "vernicelee2621@gmail.com",
        files: null,
        id: "gECdQZrfIqAIuI0wGPSf",
        path: "leads/06cQVNlbT69gokwIUgyZ/actions/gECdQZrfIqAIuI0wGPSf",
        leadId: "06cQVNlbT69gokwIUgyZ"
    },
    {
        files: null,
        apptm: null,
        action: "Research",
        user: "irychang20@gmail.com",
        created: {
            seconds: 1635835227,
            nanoseconds: 30000000
        },
        userName: "iry chang",
        remark: 'hm.... dun like central park damansara, feel 5 minutes distance is very far from one utama... prefer something "really near"',
        id: "pRQP4eIYX2748AkZAY1o",
        path: "leads/06cQVNlbT69gokwIUgyZ/actions/pRQP4eIYX2748AkZAY1o",
        leadId: "06cQVNlbT69gokwIUgyZ"
    },
    {
        files: null,
        remark: "Ady bought Melaka unit ,last time want look for PJ area cause want move down .",
        user: "vinzchau00@gmail.com",
        apptm: null,
        userName: "Vinz Chau",
        created: {
            seconds: 1656835853,
            nanoseconds: 620000000
        },
        action: "Called",
        id: "20NyjHdvB57nmJBDANlO",
        path: "leads/08S8uFE7KDf5uGZ2zVVe/actions/20NyjHdvB57nmJBDANlO",
        leadId: "08S8uFE7KDf5uGZ2zVVe"
    },
    {
        remark: "Na",
        action: "Called",
        files: null,
        created: {
            seconds: 1656505988,
            nanoseconds: 783000000
        },
        userName: "Jimmy ong",
        user: "mongny@hotmail.com",
        apptm: null,
        id: "4MhGwLz7eaM3bK06a61E",
        path: "leads/08S8uFE7KDf5uGZ2zVVe/actions/4MhGwLz7eaM3bK06a61E",
        leadId: "08S8uFE7KDf5uGZ2zVVe"
    },
    {
        files: null,
        user: "kohpohfen@gmail.com",
        remark: "",
        action: "Whatsapped",
        userName: "Summer Koh",
        created: {
            seconds: 1656170749,
            nanoseconds: 606000000
        },
        apptm: null,
        id: "8bVNsLLQA1HEqzFH5k2c",
        path: "leads/08S8uFE7KDf5uGZ2zVVe/actions/8bVNsLLQA1HEqzFH5k2c",
        leadId: "08S8uFE7KDf5uGZ2zVVe"
    },
    {
        user: "jeremytan.iqi@gmail.com",
        action: "Research",
        remark: "Pass back ",
        apptm: null,
        created: {
            seconds: 1659012993,
            nanoseconds: 820000000
        },
        userName: "Jeremy Tan",
        files: null,
        id: "AW0TJHqnq6fP7qt9keFg",
        path: "leads/08S8uFE7KDf5uGZ2zVVe/actions/AW0TJHqnq6fP7qt9keFg",
        leadId: "08S8uFE7KDf5uGZ2zVVe"
    },
    {
        created: {
            seconds: 1662463513,
            nanoseconds: 441000000
        },
        files: null,
        user: "caixuanlew@outlook.com",
        remark: "bought melaka",
        apptm: null,
        action: "Called",
        userName: "Chelsea Lew",
        id: "BHhfh1oEJ75iien9qD8E",
        path: "leads/08S8uFE7KDf5uGZ2zVVe/actions/BHhfh1oEJ75iien9qD8E",
        leadId: "08S8uFE7KDf5uGZ2zVVe"
    },
    {
        files: null,
        action: "Called",
        created: {
            seconds: 1661501340,
            nanoseconds: 458000000
        },
        user: "caixuanlew@outlook.com",
        userName: "Chelsea Lew",
        apptm: null,
        remark: "NPU",
        id: "JL4cWs8soozcpVzvp8VZ",
        path: "leads/08S8uFE7KDf5uGZ2zVVe/actions/JL4cWs8soozcpVzvp8VZ",
        leadId: "08S8uFE7KDf5uGZ2zVVe"
    },
    {
        remark: "",
        action: "Called",
        userName: "Mun Hong Yip",
        user: "munhong0909@gmail.com",
        created: {
            seconds: 1656423852,
            nanoseconds: 281000000
        },
        apptm: null,
        files: null,
        id: "LMFh7EH8wvZFt0JnayNh",
        path: "leads/08S8uFE7KDf5uGZ2zVVe/actions/LMFh7EH8wvZFt0JnayNh",
        leadId: "08S8uFE7KDf5uGZ2zVVe"
    },
    {
        files: null,
        remark: "Duplicate number Ms Voon ",
        created: {
            seconds: 1659012953,
            nanoseconds: 545000000
        },
        action: "Called",
        user: "jeremytan.iqi@gmail.com",
        apptm: null,
        userName: "Jeremy Tan",
        id: "PHh4MlUZfQbQi0Ek59UB",
        path: "leads/08S8uFE7KDf5uGZ2zVVe/actions/PHh4MlUZfQbQi0Ek59UB",
        leadId: "08S8uFE7KDf5uGZ2zVVe"
    },
    {
        created: {
            seconds: 1658929257,
            nanoseconds: 787000000
        },
        apptm: null,
        action: "Called",
        userName: "Wilson Tan",
        files: null,
        remark: "Not interested, moved to Melaka",
        user: "wilsontan00@gmail.com",
        id: "dr0Gt0KGC6p5Lhhhknmw",
        path: "leads/08S8uFE7KDf5uGZ2zVVe/actions/dr0Gt0KGC6p5Lhhhknmw",
        leadId: "08S8uFE7KDf5uGZ2zVVe"
    },
    {
        remark: "Npu, called twice ",
        user: "vinzchau00@gmail.com",
        files: null,
        userName: "Vinz Chau",
        action: "Research",
        apptm: null,
        created: {
            seconds: 1656834263,
            nanoseconds: 419000000
        },
        id: "iyx7H996eaDIwp3dUqFv",
        path: "leads/08S8uFE7KDf5uGZ2zVVe/actions/iyx7H996eaDIwp3dUqFv",
        leadId: "08S8uFE7KDf5uGZ2zVVe"
    },
    {
        userName: "Chelsea Lew",
        apptm: null,
        action: "Called",
        user: "caixuanlew@outlook.com",
        files: null,
        created: {
            seconds: 1662122891,
            nanoseconds: 121000000
        },
        remark: "NPU",
        id: "pgfn0kCULnaFiy4Cs1yV",
        path: "leads/08S8uFE7KDf5uGZ2zVVe/actions/pgfn0kCULnaFiy4Cs1yV",
        leadId: "08S8uFE7KDf5uGZ2zVVe"
    },
    {
        remark: "Invest",
        created: {
            seconds: 1656170783,
            nanoseconds: 283000000
        },
        apptm: null,
        action: "Whatsapped",
        files: null,
        userName: "Summer Koh",
        user: "kohpohfen@gmail.com",
        id: "phg2aKtJ5K27rfppFTk5",
        path: "leads/08S8uFE7KDf5uGZ2zVVe/actions/phg2aKtJ5K27rfppFTk5",
        leadId: "08S8uFE7KDf5uGZ2zVVe"
    },
    {
        userName: "Jimmy ong",
        remark: "currently in Melaka, Not looking for any property in PJ or KL",
        files: null,
        apptm: null,
        action: "Called",
        user: "mongny@hotmail.com",
        created: {
            seconds: 1657804292,
            nanoseconds: 951000000
        },
        id: "rq5Kv2HyFnOr7p50wVBq",
        path: "leads/08S8uFE7KDf5uGZ2zVVe/actions/rq5Kv2HyFnOr7p50wVBq",
        leadId: "08S8uFE7KDf5uGZ2zVVe"
    },
    {
        created: {
            seconds: 1660118495,
            nanoseconds: 55000000
        },
        action: "Called",
        userName: "Chelsea Lew",
        remark: "",
        files: null,
        user: "caixuanlew@outlook.com",
        apptm: null,
        id: "x9PB2Br6mSTGZWEifloT",
        path: "leads/08S8uFE7KDf5uGZ2zVVe/actions/x9PB2Br6mSTGZWEifloT",
        leadId: "08S8uFE7KDf5uGZ2zVVe"
    },
    {
        userName: "William Fam",
        created: {
            seconds: 1639302423,
            nanoseconds: 982000000
        },
        user: "williamffk@gmail.com",
        files: null,
        action: "Called",
        remark: "Npu",
        apptm: null,
        id: "6dYfJr2vBKFJKtxQlDe4",
        path: "leads/08wUd9Jrmydl2sOToonx/actions/6dYfJr2vBKFJKtxQlDe4",
        leadId: "08wUd9Jrmydl2sOToonx"
    },
    {
        action: "Called",
        files: null,
        remark: "",
        userName: "Jane Chan",
        created: {
            seconds: 1642159467,
            nanoseconds: 713000000
        },
        user: "janecwy11@gmail.com",
        apptm: null,
        id: "HiFvOZtAjQx9w7LgWSfj",
        path: "leads/08wUd9Jrmydl2sOToonx/actions/HiFvOZtAjQx9w7LgWSfj",
        leadId: "08wUd9Jrmydl2sOToonx"
    },
    {
        files: null,
        userName: "Elsa  Ng",
        remark: "no answer.",
        apptm: null,
        user: "ngsinyi@hotmail.com",
        action: "Called",
        created: {
            seconds: 1632548909,
            nanoseconds: 524000000
        },
        id: "LqEdMleNevA0bhxaMmn0",
        path: "leads/08wUd9Jrmydl2sOToonx/actions/LqEdMleNevA0bhxaMmn0",
        leadId: "08wUd9Jrmydl2sOToonx"
    },
    {
        created: {
            seconds: 1632213450,
            nanoseconds: 473000000
        },
        remark: "for own stay 700sf - 900sf 2 rooms will be ok, know about arcuz and damansara but havent seen yet. send her over the info and follow up over there. tq",
        apptm: null,
        files: null,
        user: "benedict7119@gmail.com",
        userName: "Benedict chan Jin hou",
        action: "Called",
        id: "S6VQGoWFnwX107GFTdF0",
        path: "leads/08wUd9Jrmydl2sOToonx/actions/S6VQGoWFnwX107GFTdF0",
        leadId: "08wUd9Jrmydl2sOToonx"
    },
    {
        remark: "",
        apptm: null,
        action: "Whatsapped",
        userName: "Benedict chan Jin hou",
        files: null,
        user: "benedict7119@gmail.com",
        created: {
            seconds: 1632213926,
            nanoseconds: 956000000
        },
        id: "TOqX154vWnPFka4viXX5",
        path: "leads/08wUd9Jrmydl2sOToonx/actions/TOqX154vWnPFka4viXX5",
        leadId: "08wUd9Jrmydl2sOToonx"
    },
    {
        user: "janecwy11@gmail.com",
        apptm: null,
        userName: "Jane Chan",
        created: {
            seconds: 1630393622,
            nanoseconds: 147000000
        },
        files: null,
        remark: "PJ, 700-900 sf , 2 bedrooms, live in PJ, call back night time",
        action: "Called",
        id: "pf4bbvjcFKT0ivXDzNEA",
        path: "leads/08wUd9Jrmydl2sOToonx/actions/pf4bbvjcFKT0ivXDzNEA",
        leadId: "08wUd9Jrmydl2sOToonx"
    },
    {
        userName: "William Fam",
        files: null,
        apptm: null,
        action: "Called",
        user: "williamffk@gmail.com",
        remark: "Not interested ",
        created: {
            seconds: 1640670394,
            nanoseconds: 241000000
        },
        id: "xg58mni9Mv7hvFnm70Zc",
        path: "leads/08wUd9Jrmydl2sOToonx/actions/xg58mni9Mv7hvFnm70Zc",
        leadId: "08wUd9Jrmydl2sOToonx"
    },
    {
        apptm: null,
        created: {
            seconds: 1653390677,
            nanoseconds: 65000000
        },
        user: "alan_yap1121@hotmail.com",
        files: null,
        action: "Research",
        remark: "no pick up",
        userName: "Alan Yap",
        id: "P2pqX2OBhRMPDds4ezX7",
        path: "leads/096c2oqj87aUDQybTngU/actions/P2pqX2OBhRMPDds4ezX7",
        leadId: "096c2oqj87aUDQybTngU"
    },
    {
        userName: "Junsu Mok",
        action: "Called",
        files: null,
        user: "imjunsu0420@gmail.com",
        created: {
            seconds: 1634188578,
            nanoseconds: 166000000
        },
        remark: "1:17PM no pick up",
        apptm: null,
        id: "4FSVPE8mEltTBk9r9oPe",
        path: "leads/098v71zKoRKj48t1eYAE/actions/4FSVPE8mEltTBk9r9oPe",
        leadId: "098v71zKoRKj48t1eYAE"
    },
    {
        files: null,
        created: {
            seconds: 1637488043,
            nanoseconds: 79000000
        },
        userName: "TAI WAI LOON",
        user: "alextai0517@gmail.com",
        action: "Whatsapped",
        remark: "",
        apptm: null,
        id: "Cb3PQhJFMxh4sTJkwF47",
        path: "leads/098v71zKoRKj48t1eYAE/actions/Cb3PQhJFMxh4sTJkwF47",
        leadId: "098v71zKoRKj48t1eYAE"
    },
    {
        files: null,
        user: "alex07359@gmail.com",
        remark: "npu message greeting \n",
        created: {
            seconds: 1633794672,
            nanoseconds: 5000000
        },
        userName: "dylan  chong",
        apptm: null,
        action: "Research",
        id: "TLJXbvaqvejNfJCFHy6V",
        path: "leads/098v71zKoRKj48t1eYAE/actions/TLJXbvaqvejNfJCFHy6V",
        leadId: "098v71zKoRKj48t1eYAE"
    },
    {
        user: "vernicelee2621@gmail.com",
        action: "Research",
        remark: "number cannot be contacted ",
        created: {
            seconds: 1632978577,
            nanoseconds: 177000000
        },
        apptm: null,
        userName: "Vernice Lee",
        files: null,
        id: "TjlqlQE7fJHi43JxTmEZ",
        path: "leads/09Vo2bqGS8Gs4qbr4oD1/actions/TjlqlQE7fJHi43JxTmEZ",
        leadId: "09Vo2bqGS8Gs4qbr4oD1"
    },
    {
        remark: "",
        created: {
            seconds: 1632985450,
            nanoseconds: 415000000
        },
        files: null,
        user: "vernicelee2621@gmail.com",
        userName: "Vernice Lee",
        action: "Whatsapped",
        apptm: null,
        id: "nzIDtFFYhSHUTCAYmBks",
        path: "leads/09Vo2bqGS8Gs4qbr4oD1/actions/nzIDtFFYhSHUTCAYmBks",
        leadId: "09Vo2bqGS8Gs4qbr4oD1"
    },
    {
        files: null,
        userName: "Junsu Mok",
        apptm: null,
        remark: "4:14PM no pick up",
        action: "Called",
        created: {
            seconds: 1636618448,
            nanoseconds: 38000000
        },
        user: "imjunsu0420@gmail.com",
        id: "kCayN6cFQpHtkqTAG6sg",
        path: "leads/09bNlIYB6ISW6hs45FL4/actions/kCayN6cFQpHtkqTAG6sg",
        leadId: "09bNlIYB6ISW6hs45FL4"
    },
    {
        user: "kaiyegolf@gmail.com",
        remark: "Contact not in service ",
        files: null,
        action: "Research",
        apptm: null,
        userName: "James  On",
        created: {
            seconds: 1646454343,
            nanoseconds: 193000000
        },
        id: "CT6GEpypyfWB9QW5566r",
        path: "leads/09j9CQUkt2XmLrXNkwGp/actions/CT6GEpypyfWB9QW5566r",
        leadId: "09j9CQUkt2XmLrXNkwGp"
    },
    {
        files: null,
        created: {
            seconds: 1664956597,
            nanoseconds: 906000000
        },
        remark: "NPU ",
        user: "alanlaiproperty88@gmail.com",
        userName: "Alan  Lai",
        apptm: null,
        action: "Called",
        id: "JWZlCH8SLBTuzSXiPQxx",
        path: "leads/0AOmygm7XrQNkFNNznHb/actions/JWZlCH8SLBTuzSXiPQxx",
        leadId: "0AOmygm7XrQNkFNNznHb"
    },
    {
        remark: "NPU",
        created: {
            seconds: 1665212668,
            nanoseconds: 103000000
        },
        user: "alanlaiproperty88@gmail.com",
        files: null,
        userName: "Alan  Lai",
        action: "Called",
        apptm: null,
        id: "QFOHtmRLybBfsTgiKvbs",
        path: "leads/0AOmygm7XrQNkFNNznHb/actions/QFOHtmRLybBfsTgiKvbs",
        leadId: "0AOmygm7XrQNkFNNznHb"
    },
    {
        remark: "NPU ",
        created: {
            seconds: 1664887485,
            nanoseconds: 504000000
        },
        files: null,
        user: "alanlaiproperty88@gmail.com",
        userName: "Alan  Lai",
        apptm: null,
        action: "Called",
        id: "QIw1pnGVCJfOGeV2IsMm",
        path: "leads/0AOmygm7XrQNkFNNznHb/actions/QIw1pnGVCJfOGeV2IsMm",
        leadId: "0AOmygm7XrQNkFNNznHb"
    },
    {
        files: null,
        created: {
            seconds: 1664956649,
            nanoseconds: 265000000
        },
        action: "Called",
        remark: "- working and staying at BU \n- looking for own stay \n- seen quite a few project but have not seen The Atera \n- said quite far but told her actually 15 mins to BU from Atera only \n- know and heard CPD, but don't like because too congested \n- working now, send WhatsApp first later call her",
        user: "alanlaiproperty88@gmail.com",
        userName: "Alan  Lai",
        apptm: null,
        id: "pPikBYA4XSWICw3B1FZE",
        path: "leads/0AOmygm7XrQNkFNNznHb/actions/pPikBYA4XSWICw3B1FZE",
        leadId: "0AOmygm7XrQNkFNNznHb"
    },
    {
        created: {
            seconds: 1664631568,
            nanoseconds: 45000000
        },
        apptm: null,
        remark: "",
        userName: "Summer Koh",
        files: null,
        user: "kohpohfen@gmail.com",
        action: "Whatsapped",
        id: "xMl1oovvpVHurwie8UME",
        path: "leads/0AOmygm7XrQNkFNNznHb/actions/xMl1oovvpVHurwie8UME",
        leadId: "0AOmygm7XrQNkFNNznHb"
    },
    {
        remark: "came to my appmt",
        files: null,
        action: "Research",
        created: {
            seconds: 1677126707,
            nanoseconds: 188000000
        },
        apptm: null,
        user: "kohpohfen@gmail.com",
        leadId: "0B1DtPH3DERdCOcjTY27",
        userName: "Summer Koh",
        id: "GJRWJgft43e28Y2f5gnK",
        path: "leads/0B1DtPH3DERdCOcjTY27/actions/GJRWJgft43e28Y2f5gnK"
    },
    {
        apptm: null,
        created: {
            seconds: 1679564086,
            nanoseconds: 611000000
        },
        userName: "Ting Seong Shew",
        user: "seong31993199@gmail.com",
        action: "Whatsapped",
        remark: "",
        files: null,
        leadId: "0B1DtPH3DERdCOcjTY27",
        id: "WepJwg6SEz1pMq5UT9X5",
        path: "leads/0B1DtPH3DERdCOcjTY27/actions/WepJwg6SEz1pMq5UT9X5"
    },
    {
        files: null,
        apptm: null,
        action: "Whatsapped",
        userName: "Jonathan Chin",
        remark: "",
        leadId: "0B1DtPH3DERdCOcjTY27",
        created: {
            seconds: 1679495645,
            nanoseconds: 627000000
        },
        user: "jonathanchin0614@gmail.com",
        id: "WkmCPi0qjQFaI6zcN5V1",
        path: "leads/0B1DtPH3DERdCOcjTY27/actions/WkmCPi0qjQFaI6zcN5V1"
    },
    {
        remark: "",
        user: "kohpohfen@gmail.com",
        created: {
            seconds: 1673962718,
            nanoseconds: 777000000
        },
        action: "Whatsapped",
        apptm: null,
        files: null,
        userName: "Summer Koh",
        id: "XrQ2TwqwnSL2uBRw4GQn",
        path: "leads/0B1DtPH3DERdCOcjTY27/actions/XrQ2TwqwnSL2uBRw4GQn",
        leadId: "0B1DtPH3DERdCOcjTY27"
    },
    {
        apptm: null,
        user: "kohpohfen@gmail.com",
        action: "Whatsapped",
        files: null,
        remark: "",
        created: {
            seconds: 1673963105,
            nanoseconds: 428000000
        },
        userName: "Summer Koh",
        id: "oIljjS2MiNgLUqi0Pxu1",
        path: "leads/0B1DtPH3DERdCOcjTY27/actions/oIljjS2MiNgLUqi0Pxu1",
        leadId: "0B1DtPH3DERdCOcjTY27"
    },
    {
        created: {
            seconds: 1679563715,
            nanoseconds: 253000000
        },
        action: "Called",
        leadId: "0B1DtPH3DERdCOcjTY27",
        userName: "Ting Seong Shew",
        files: null,
        apptm: null,
        user: "seong31993199@gmail.com",
        remark: "",
        id: "rnL7PckPSPPbVjDWEfGU",
        path: "leads/0B1DtPH3DERdCOcjTY27/actions/rnL7PckPSPPbVjDWEfGU"
    },
    {
        action: "Called",
        userName: "Desmond  Chong",
        files: null,
        remark: "",
        user: "desmondchongproperty21@gmail.com",
        apptm: null,
        created: {
            seconds: 1663850612,
            nanoseconds: 810000000
        },
        id: "4F2Q1B9Prdf5TJLLdnJm",
        path: "leads/0ByLfnQQaxB6P9yQenBd/actions/4F2Q1B9Prdf5TJLLdnJm",
        leadId: "0ByLfnQQaxB6P9yQenBd"
    },
    {
        userName: "Chelsea Lew",
        remark: "NPU",
        files: null,
        user: "caixuanlew@outlook.com",
        apptm: null,
        action: "Called",
        created: {
            seconds: 1660916531,
            nanoseconds: 339000000
        },
        id: "56qhg1TrTKS6VsFQlgMQ",
        path: "leads/0ByLfnQQaxB6P9yQenBd/actions/56qhg1TrTKS6VsFQlgMQ",
        leadId: "0ByLfnQQaxB6P9yQenBd"
    },
    {
        files: null,
        created: {
            seconds: 1651986097,
            nanoseconds: 525000000
        },
        action: "Called",
        remark: "Npu",
        userName: "Alexander Chia",
        user: "alexchia320@gmail.com",
        apptm: null,
        id: "EhIDF3XMgmC8HYaoWvK2",
        path: "leads/0ByLfnQQaxB6P9yQenBd/actions/EhIDF3XMgmC8HYaoWvK2",
        leadId: "0ByLfnQQaxB6P9yQenBd"
    },
    {
        apptm: null,
        user: "desmondchongproperty21@gmail.com",
        files: null,
        created: {
            seconds: 1663761471,
            nanoseconds: 111000000
        },
        remark: "",
        action: "Called",
        userName: "Desmond  Chong",
        id: "Hk9Kmo4juQYlyYX5yRyz",
        path: "leads/0ByLfnQQaxB6P9yQenBd/actions/Hk9Kmo4juQYlyYX5yRyz",
        leadId: "0ByLfnQQaxB6P9yQenBd"
    },
    {
        apptm: null,
        remark: "seen",
        action: "Whatsapped",
        userName: "Chelsea Lew",
        user: "caixuanlew@outlook.com",
        files: null,
        created: {
            seconds: 1663224116,
            nanoseconds: 289000000
        },
        id: "KhlE6w9b9O5NS8HxdP9L",
        path: "leads/0ByLfnQQaxB6P9yQenBd/actions/KhlE6w9b9O5NS8HxdP9L",
        leadId: "0ByLfnQQaxB6P9yQenBd"
    },
    {
        files: null,
        apptm: null,
        user: "alan_yap1121@hotmail.com",
        action: "Research",
        userName: "Alan Yap",
        remark: "location",
        created: {
            seconds: 1649849685,
            nanoseconds: 558000000
        },
        id: "M9nGApzbTOn9rrO3SfLk",
        path: "leads/0ByLfnQQaxB6P9yQenBd/actions/M9nGApzbTOn9rrO3SfLk",
        leadId: "0ByLfnQQaxB6P9yQenBd"
    },
    {
        created: {
            seconds: 1662639049,
            nanoseconds: 142000000
        },
        apptm: null,
        action: "Called",
        user: "caixuanlew@outlook.com",
        userName: "Chelsea Lew",
        files: null,
        remark: "NPU",
        id: "Tjxvo1T24nHdfzR6JCVG",
        path: "leads/0ByLfnQQaxB6P9yQenBd/actions/Tjxvo1T24nHdfzR6JCVG",
        leadId: "0ByLfnQQaxB6P9yQenBd"
    },
    {
        created: {
            seconds: 1663242940,
            nanoseconds: 788000000
        },
        user: "caixuanlew@outlook.com",
        apptm: null,
        remark: "NPU",
        userName: "Chelsea Lew",
        files: null,
        action: "Called",
        id: "XdAx5GppLrxzWe6nK2bN",
        path: "leads/0ByLfnQQaxB6P9yQenBd/actions/XdAx5GppLrxzWe6nK2bN",
        leadId: "0ByLfnQQaxB6P9yQenBd"
    },
    {
        remark: "NPU",
        created: {
            seconds: 1663243872,
            nanoseconds: 265000000
        },
        user: "caixuanlew@outlook.com",
        apptm: null,
        userName: "Chelsea Lew",
        action: "Called",
        files: null,
        id: "cYSUBKeYcEElrDsUaFYT",
        path: "leads/0ByLfnQQaxB6P9yQenBd/actions/cYSUBKeYcEElrDsUaFYT",
        leadId: "0ByLfnQQaxB6P9yQenBd"
    },
    {
        files: null,
        user: "alexchia320@gmail.com",
        userName: "Alexander Chia",
        action: "Whatsapped",
        apptm: null,
        created: {
            seconds: 1652271822,
            nanoseconds: 109000000
        },
        remark: "",
        id: "fL2FZFvovgt0sawDU6GU",
        path: "leads/0ByLfnQQaxB6P9yQenBd/actions/fL2FZFvovgt0sawDU6GU",
        leadId: "0ByLfnQQaxB6P9yQenBd"
    },
    {
        created: {
            seconds: 1651131164,
            nanoseconds: 312000000
        },
        user: "alan_yap1121@hotmail.com",
        files: null,
        apptm: null,
        remark: "chit chat",
        action: "Research",
        userName: "Alan Yap",
        id: "fU6p8J42bshUybeooGsv",
        path: "leads/0ByLfnQQaxB6P9yQenBd/actions/fU6p8J42bshUybeooGsv",
        leadId: "0ByLfnQQaxB6P9yQenBd"
    },
    {
        user: "alan_yap1121@hotmail.com",
        userName: "Alan Yap",
        created: {
            seconds: 1649573274,
            nanoseconds: 509000000
        },
        apptm: null,
        files: null,
        action: "Research",
        remark: "no pick up, whatsapp him",
        id: "fs0xuOnkSKGG9dcoAQJl",
        path: "leads/0ByLfnQQaxB6P9yQenBd/actions/fs0xuOnkSKGG9dcoAQJl",
        leadId: "0ByLfnQQaxB6P9yQenBd"
    },
    {
        userName: "Alan Yap",
        created: {
            seconds: 1652777162,
            nanoseconds: 517000000
        },
        remark: "KIV blasting",
        apptm: null,
        user: "alan_yap1121@hotmail.com",
        action: "Research",
        files: null,
        id: "gcWLjc1Icw5jcviHsWmU",
        path: "leads/0ByLfnQQaxB6P9yQenBd/actions/gcWLjc1Icw5jcviHsWmU",
        leadId: "0ByLfnQQaxB6P9yQenBd"
    },
    {
        files: null,
        user: "mongny@hotmail.com",
        apptm: null,
        created: {
            seconds: 1660223716,
            nanoseconds: 208000000
        },
        remark: "Na",
        userName: "Jimmy ong",
        action: "Called",
        id: "hnInobYFREJZgp5Cx2ft",
        path: "leads/0ByLfnQQaxB6P9yQenBd/actions/hnInobYFREJZgp5Cx2ft",
        leadId: "0ByLfnQQaxB6P9yQenBd"
    },
    {
        userName: "Eunice Min",
        apptm: null,
        action: "Called",
        files: null,
        created: {
            seconds: 1651843350,
            nanoseconds: 585000000
        },
        remark: "Npu",
        user: "eunicemin0729@gmail.com",
        id: "q14x9nFR6hp8qFNeuDma",
        path: "leads/0ByLfnQQaxB6P9yQenBd/actions/q14x9nFR6hp8qFNeuDma",
        leadId: "0ByLfnQQaxB6P9yQenBd"
    },
    {
        files: null,
        userName: "Summer Koh",
        remark: "",
        created: {
            seconds: 1649240245,
            nanoseconds: 85000000
        },
        action: "Research",
        user: "kohpohfen@gmail.com",
        apptm: null,
        id: "qJUrcuNhmYovx43PrfEy",
        path: "leads/0ByLfnQQaxB6P9yQenBd/actions/qJUrcuNhmYovx43PrfEy",
        leadId: "0ByLfnQQaxB6P9yQenBd"
    },
    {
        remark: "call also no pick up, send access road to follow up",
        action: "Research",
        userName: "Alan Yap",
        user: "alan_yap1121@hotmail.com",
        files: null,
        created: {
            seconds: 1649748094,
            nanoseconds: 118000000
        },
        apptm: null,
        id: "zCGigaxtb9DZ4zNZ17HC",
        path: "leads/0ByLfnQQaxB6P9yQenBd/actions/zCGigaxtb9DZ4zNZ17HC",
        leadId: "0ByLfnQQaxB6P9yQenBd"
    },
    {
        action: "Called",
        created: {
            seconds: 1679478622,
            nanoseconds: 816000000
        },
        remark: "NPU",
        apptm: null,
        files: null,
        userName: "Ting Seong Shew",
        user: "seong31993199@gmail.com",
        leadId: "0CHVMg9roNBOGqTW2AcM",
        id: "Epbdses6nxw1kljBCzx1",
        path: "leads/0CHVMg9roNBOGqTW2AcM/actions/Epbdses6nxw1kljBCzx1"
    },
    {
        created: {
            seconds: 1670500134,
            nanoseconds: 755000000
        },
        apptm: null,
        files: null,
        user: "vinzchau00@gmail.com",
        userName: "Vinz Chau",
        remark: "npu x2",
        action: "Research",
        id: "UVjyR0DZcbTmrPnZeOk5",
        path: "leads/0CHVMg9roNBOGqTW2AcM/actions/UVjyR0DZcbTmrPnZeOk5",
        leadId: "0CHVMg9roNBOGqTW2AcM"
    },
    {
        remark: "No pick up ",
        files: null,
        apptm: null,
        action: "Called",
        userName: "Wilson Tan",
        created: {
            seconds: 1670240984,
            nanoseconds: 433000000
        },
        user: "wilsontan00@gmail.com",
        id: "cAC11o0IdbmLt018w2uX",
        path: "leads/0CHVMg9roNBOGqTW2AcM/actions/cAC11o0IdbmLt018w2uX",
        leadId: "0CHVMg9roNBOGqTW2AcM"
    },
    {
        userName: "Ting Seong Shew",
        files: null,
        apptm: null,
        user: "seong31993199@gmail.com",
        action: "Called",
        remark: " NPU",
        created: {
            seconds: 1680324548,
            nanoseconds: 177000000
        },
        leadId: "0CHVMg9roNBOGqTW2AcM",
        id: "fH2NDLVzSOr8z3u7k6qR",
        path: "leads/0CHVMg9roNBOGqTW2AcM/actions/fH2NDLVzSOr8z3u7k6qR"
    },
    {
        userName: "Summer Koh",
        action: "Research",
        apptm: null,
        remark: "est 8. die die no pick  up",
        created: {
            seconds: 1670591762,
            nanoseconds: 587000000
        },
        user: "kohpohfen@gmail.com",
        files: null,
        id: "gfn8FxbtOPJqDx9Kyc2n",
        path: "leads/0CHVMg9roNBOGqTW2AcM/actions/gfn8FxbtOPJqDx9Kyc2n",
        leadId: "0CHVMg9roNBOGqTW2AcM"
    },
    {
        files: null,
        user: "kohpohfen@gmail.com",
        created: {
            seconds: 1670150479,
            nanoseconds: 735000000
        },
        remark: "meeting now. ownstay.  dutamas,klcc,bangsar south, bangsar, damansara. at least 900sf 3rooms, prefer freehold. 500k-900kk",
        action: "Research",
        apptm: null,
        userName: "Summer Koh",
        id: "krQVXWPJ7yODN52iycAt",
        path: "leads/0CHVMg9roNBOGqTW2AcM/actions/krQVXWPJ7yODN52iycAt",
        leadId: "0CHVMg9roNBOGqTW2AcM"
    },
    {
        created: {
            seconds: 1679389200,
            nanoseconds: 145000000
        },
        action: "Whatsapped",
        apptm: null,
        userName: "Ting Seong Shew",
        leadId: "0CHVMg9roNBOGqTW2AcM",
        files: null,
        user: "seong31993199@gmail.com",
        remark: "",
        id: "oJOojscjgVNsbjoJIlrv",
        path: "leads/0CHVMg9roNBOGqTW2AcM/actions/oJOojscjgVNsbjoJIlrv"
    },
    {
        files: null,
        apptm: null,
        userName: "Jonathan Chin",
        remark: "",
        created: {
            seconds: 1679493140,
            nanoseconds: 838000000
        },
        user: "jonathanchin0614@gmail.com",
        leadId: "0CHVMg9roNBOGqTW2AcM",
        action: "Whatsapped",
        id: "paWb0pWvA376EdR3UtpX",
        path: "leads/0CHVMg9roNBOGqTW2AcM/actions/paWb0pWvA376EdR3UtpX"
    },
    {
        created: {
            seconds: 1670600348,
            nanoseconds: 436000000
        },
        userName: "Summer Koh",
        files: null,
        action: "Appointment",
        user: "kohpohfen@gmail.com",
        remark: "Date: 2022-12-18 | Time: 21:00 | Venue:  Zoom | Language: undefined | Budget: undefined | Intention: undefined",
        apptm: {
            time: "21:00",
            date: {
                seconds: 1671321600,
                nanoseconds: 0
            },
            user: "kohpohfen@gmail.com",
            venue: " Zoom"
        },
        id: "rvtjpjP0BSfNTqyLQnKQ",
        path: "leads/0CHVMg9roNBOGqTW2AcM/actions/rvtjpjP0BSfNTqyLQnKQ",
        leadId: "0CHVMg9roNBOGqTW2AcM"
    },
    {
        created: {
            seconds: 1635313343,
            nanoseconds: 224000000
        },
        apptm: null,
        user: "irychang20@gmail.com",
        action: "Research",
        files: null,
        remark: "not looking now",
        id: "0kLN3WZadSrZtUmkgw4c",
        path: "leads/0CMadHzJEE9zPFBHaD0O/actions/0kLN3WZadSrZtUmkgw4c",
        leadId: "0CMadHzJEE9zPFBHaD0O"
    },
    {
        created: {
            seconds: 1634804217,
            nanoseconds: 178000000
        },
        apptm: null,
        user: "kaiyegolf@gmail.com",
        remark: "He not interested ",
        action: "Research",
        files: null,
        id: "HxjEA9yxDatzMla47Jsk",
        path: "leads/0CMadHzJEE9zPFBHaD0O/actions/HxjEA9yxDatzMla47Jsk",
        leadId: "0CMadHzJEE9zPFBHaD0O"
    },
    {
        created: {
            seconds: 1635426970,
            nanoseconds: 378000000
        },
        remark: "no ans",
        apptm: null,
        files: null,
        user: "hanst9803@gmail.com",
        action: "Called",
        id: "P1EghgjV8dCdtj8AGAKz",
        path: "leads/0CMadHzJEE9zPFBHaD0O/actions/P1EghgjV8dCdtj8AGAKz",
        leadId: "0CMadHzJEE9zPFBHaD0O"
    },
    {
        user: "vernicelee2621@gmail.com",
        files: null,
        apptm: null,
        action: "Research",
        created: {
            seconds: 1634470855,
            nanoseconds: 680000000
        },
        remark: "just want look for investment property below 400k property /rumawip concept",
        id: "uqvX1YqLB5PFtOLX9KLw",
        path: "leads/0CMadHzJEE9zPFBHaD0O/actions/uqvX1YqLB5PFtOLX9KLw",
        leadId: "0CMadHzJEE9zPFBHaD0O"
    },
    {
        created: {
            seconds: 1665983035,
            nanoseconds: 466000000
        },
        remark: "",
        apptm: null,
        action: "Called",
        user: "kaycee.eliteone@gmail.com",
        files: null,
        userName: "Kaycee Chong",
        id: "LTGWCRt9RS97wc1rVymC",
        path: "leads/0DYrJmmLmvehup5ir6Zf/actions/LTGWCRt9RS97wc1rVymC",
        leadId: "0DYrJmmLmvehup5ir6Zf"
    },
    {
        user: "kaycee.eliteone@gmail.com",
        created: {
            seconds: 1665664169,
            nanoseconds: 582000000
        },
        userName: "Kaycee Chong",
        files: null,
        remark: "",
        apptm: null,
        action: "Called",
        id: "bUX7ZpOsVM3FlaZTS8CX",
        path: "leads/0DYrJmmLmvehup5ir6Zf/actions/bUX7ZpOsVM3FlaZTS8CX",
        leadId: "0DYrJmmLmvehup5ir6Zf"
    },
    {
        created: {
            seconds: 1668328779,
            nanoseconds: 731000000
        },
        remark: "wn chepest",
        action: "Research",
        user: "kohpohfen@gmail.com",
        userName: "Summer Koh",
        apptm: null,
        files: null,
        id: "nkBBhEG3Rfo60FC5Z5l3",
        path: "leads/0DYrJmmLmvehup5ir6Zf/actions/nkBBhEG3Rfo60FC5Z5l3",
        leadId: "0DYrJmmLmvehup5ir6Zf"
    },
    {
        leadId: "0DfAJC57aHB7xQcGS8Sy",
        files: null,
        remark: "NA",
        userName: "henry lee",
        action: "Called",
        apptm: null,
        user: "henryleeproperty12@gmail.com",
        created: {
            seconds: 1677070367,
            nanoseconds: 883000000
        },
        id: "8vTHen56dzvQy7PL2eBc",
        path: "leads/0DfAJC57aHB7xQcGS8Sy/actions/8vTHen56dzvQy7PL2eBc"
    },
    {
        remark: "",
        files: null,
        user: "jonathanchin0614@gmail.com",
        created: {
            seconds: 1676444951,
            nanoseconds: 93000000
        },
        action: "Whatsapped",
        userName: "Jonathan Chin",
        apptm: null,
        id: "FfptbUW6MxT75PEWTppO",
        path: "leads/0DfAJC57aHB7xQcGS8Sy/actions/FfptbUW6MxT75PEWTppO",
        leadId: "0DfAJC57aHB7xQcGS8Sy"
    },
    {
        userName: "Jonathan Chin",
        action: "Called",
        remark: "",
        apptm: null,
        user: "jonathanchin0614@gmail.com",
        files: null,
        created: {
            seconds: 1676444998,
            nanoseconds: 326000000
        },
        id: "Ur2kNv1Uq8Kyjz9EsVN9",
        path: "leads/0DfAJC57aHB7xQcGS8Sy/actions/Ur2kNv1Uq8Kyjz9EsVN9",
        leadId: "0DfAJC57aHB7xQcGS8Sy"
    },
    {
        action: "Research",
        apptm: null,
        files: null,
        created: {
            seconds: 1683360687,
            nanoseconds: 640000000
        },
        leadId: "0DfAJC57aHB7xQcGS8Sy",
        user: "kohpohfen@gmail.com",
        remark: "Klcc",
        userName: "Poh fen Koh",
        id: "oLWuImogeNw7v664MvMK",
        path: "leads/0DfAJC57aHB7xQcGS8Sy/actions/oLWuImogeNw7v664MvMK"
    },
    {
        remark: "",
        userName: "Ting Seong Shew",
        leadId: "0DfAJC57aHB7xQcGS8Sy",
        user: "seong31993199@gmail.com",
        created: {
            seconds: 1679137293,
            nanoseconds: 292000000
        },
        apptm: null,
        action: "Whatsapped",
        files: null,
        id: "p6A4l01mThgi0gXDi3G5",
        path: "leads/0DfAJC57aHB7xQcGS8Sy/actions/p6A4l01mThgi0gXDi3G5"
    },
    {
        created: {
            seconds: 1680867036,
            nanoseconds: 901000000
        },
        userName: "Kelly Wong",
        apptm: null,
        leadId: "0DfAJC57aHB7xQcGS8Sy",
        action: "Called",
        user: "kellywongyikei@gmail.com",
        files: null,
        remark: "WhatsApp-ed",
        id: "qnPiqbTKRDZRNt84owhu",
        path: "leads/0DfAJC57aHB7xQcGS8Sy/actions/qnPiqbTKRDZRNt84owhu"
    },
    {
        created: {
            seconds: 1680942366,
            nanoseconds: 267000000
        },
        action: "Called",
        leadId: "0DfAJC57aHB7xQcGS8Sy",
        remark: "NPU",
        user: "seong31993199@gmail.com",
        userName: "Ting Seong Shew",
        files: null,
        apptm: null,
        id: "swvaBjb2qhNTGzGKo5aZ",
        path: "leads/0DfAJC57aHB7xQcGS8Sy/actions/swvaBjb2qhNTGzGKo5aZ"
    },
    {
        apptm: null,
        created: {
            seconds: 1679041889,
            nanoseconds: 2000000
        },
        userName: "Ferdinand  Poon",
        leadId: "0FfrASEXi89X1Hrju7ZF",
        remark: "",
        files: null,
        user: "fedinandiqi@gmail.com",
        action: "Called",
        id: "J94gYKApn2PzbaW7rHbB",
        path: "leads/0FfrASEXi89X1Hrju7ZF/actions/J94gYKApn2PzbaW7rHbB"
    },
    {
        userName: "Ferdinand  Poon",
        files: null,
        user: "fedinandiqi@gmail.com",
        apptm: null,
        leadId: "0FfrASEXi89X1Hrju7ZF",
        created: {
            seconds: 1679041867,
            nanoseconds: 492000000
        },
        action: "Called",
        remark: "",
        id: "aImbBPTM2euZF7sUljQt",
        path: "leads/0FfrASEXi89X1Hrju7ZF/actions/aImbBPTM2euZF7sUljQt"
    },
    {
        user: "fedinandiqi@gmail.com",
        userName: "Ferdinand  Poon",
        files: null,
        apptm: null,
        created: {
            seconds: 1682411511,
            nanoseconds: 366000000
        },
        remark: "",
        leadId: "0FfrASEXi89X1Hrju7ZF",
        action: "Called",
        id: "itdVXH967nCVtMnyqypN",
        path: "leads/0FfrASEXi89X1Hrju7ZF/actions/itdVXH967nCVtMnyqypN"
    },
    {
        created: {
            seconds: 1679041929,
            nanoseconds: 186000000
        },
        apptm: null,
        remark: "",
        userName: "Ferdinand  Poon",
        action: "Whatsapped",
        files: null,
        leadId: "0FfrASEXi89X1Hrju7ZF",
        user: "fedinandiqi@gmail.com",
        id: "lRPJ1NQsIYsC1BMwi08Z",
        path: "leads/0FfrASEXi89X1Hrju7ZF/actions/lRPJ1NQsIYsC1BMwi08Z"
    },
    {
        apptm: null,
        action: "Called",
        userName: "foo fangsheng",
        created: {
            seconds: 1679810433,
            nanoseconds: 56000000
        },
        remark: "UTC",
        leadId: "0G7OpTMpt99Md0qZIvc8",
        files: null,
        user: "xiiaosheng33096@gmail.com",
        id: "AK76a053F7elrxZ9WH0N",
        path: "leads/0G7OpTMpt99Md0qZIvc8/actions/AK76a053F7elrxZ9WH0N"
    },
    {
        apptm: null,
        remark: "哪里一个广告？",
        action: "Whatsapped",
        user: "huiyingang96@hotmail.com",
        created: {
            seconds: 1663909124,
            nanoseconds: 114000000
        },
        files: null,
        userName: "leona ang",
        id: "EqXfsLtEWXJIINz3iwxR",
        path: "leads/0G7OpTMpt99Md0qZIvc8/actions/EqXfsLtEWXJIINz3iwxR",
        leadId: "0G7OpTMpt99Md0qZIvc8"
    },
    {
        apptm: null,
        user: "huiyingang96@hotmail.com",
        userName: "leona ang",
        action: "Called",
        remark: "find for inv rm1500 ， 买了arcuz 找回之前的agent sun 看hugoz ,send helix2 看看\n",
        created: {
            seconds: 1664455253,
            nanoseconds: 725000000
        },
        files: null,
        id: "GuVqtpumfai0HGC91jPf",
        path: "leads/0G7OpTMpt99Md0qZIvc8/actions/GuVqtpumfai0HGC91jPf",
        leadId: "0G7OpTMpt99Md0qZIvc8"
    },
    {
        userName: "Jeremy Tan",
        apptm: null,
        action: "Called",
        remark: "Busying call back later ",
        created: {
            seconds: 1663852357,
            nanoseconds: 375000000
        },
        files: null,
        user: "jeremytan.iqi@gmail.com",
        id: "Gwyqdkg3pY0FYyCkqkYC",
        path: "leads/0G7OpTMpt99Md0qZIvc8/actions/Gwyqdkg3pY0FYyCkqkYC",
        leadId: "0G7OpTMpt99Md0qZIvc8"
    },
    {
        user: "xiiaosheng33096@gmail.com",
        apptm: null,
        leadId: "0G7OpTMpt99Md0qZIvc8",
        userName: "foo fangsheng",
        remark: "",
        action: "Whatsapped",
        files: null,
        created: {
            seconds: 1679810410,
            nanoseconds: 279000000
        },
        id: "HKeYdAovJG3T6JlKwbYw",
        path: "leads/0G7OpTMpt99Md0qZIvc8/actions/HKeYdAovJG3T6JlKwbYw"
    },
    {
        leadId: "0G7OpTMpt99Md0qZIvc8",
        userName: "Jeremy Tan",
        remark: "Send velada ads video n detail ",
        files: null,
        user: "jeremytan.iqi@gmail.com",
        action: "Research",
        apptm: null,
        created: {
            seconds: 1681025675,
            nanoseconds: 455000000
        },
        id: "JyBgYUZe828f1WWaCC6D",
        path: "leads/0G7OpTMpt99Md0qZIvc8/actions/JyBgYUZe828f1WWaCC6D"
    },
    {
        created: {
            seconds: 1683624034,
            nanoseconds: 924000000
        },
        apptm: null,
        userName: "Jeremy Tan",
        action: "Research",
        user: "jeremytan.iqi@gmail.com",
        files: null,
        remark: "He said not looking property ald ask me not to sent him ald ",
        leadId: "0G7OpTMpt99Md0qZIvc8",
        id: "L48FdNIeW7elAUhVQxA8",
        path: "leads/0G7OpTMpt99Md0qZIvc8/actions/L48FdNIeW7elAUhVQxA8"
    },
    {
        remark: "Update bukit Jalil project details he actually looking for 2 bedroom de appointment okay time will update with me again ",
        userName: "Jeremy Tan",
        apptm: null,
        files: null,
        action: "Whatsapped",
        user: "jeremytan.iqi@gmail.com",
        created: {
            seconds: 1677065776,
            nanoseconds: 638000000
        },
        leadId: "0G7OpTMpt99Md0qZIvc8",
        id: "L8JbkpSiqjwfCQsof1xk",
        path: "leads/0G7OpTMpt99Md0qZIvc8/actions/L8JbkpSiqjwfCQsof1xk"
    },
    {
        created: {
            seconds: 1663937016,
            nanoseconds: 381000000
        },
        user: "jeremytan.iqi@gmail.com",
        files: null,
        userName: "Jeremy Tan",
        apptm: null,
        action: "Called",
        remark: "He view before Mossaz DCP, Atera, Hugoz. all not Ngam him cause he monthly instalment budget only Rm1,500 he looking for investment project ",
        id: "WUgj0NYBUmjr7jImwa0M",
        path: "leads/0G7OpTMpt99Md0qZIvc8/actions/WUgj0NYBUmjr7jImwa0M",
        leadId: "0G7OpTMpt99Md0qZIvc8"
    },
    {
        files: null,
        user: "jeremytan.iqi@gmail.com",
        created: {
            seconds: 1683623203,
            nanoseconds: 251000000
        },
        action: "Research",
        apptm: null,
        leadId: "0G7OpTMpt99Md0qZIvc8",
        remark: "Sent brochure and re arrange appointment with him ",
        userName: "Jeremy Tan",
        id: "XUnLKC4BmX1rIIEc2qlH",
        path: "leads/0G7OpTMpt99Md0qZIvc8/actions/XUnLKC4BmX1rIIEc2qlH"
    },
    {
        files: null,
        action: "Called",
        userName: "leona ang",
        user: "huiyingang96@hotmail.com",
        created: {
            seconds: 1663938604,
            nanoseconds: 883000000
        },
        apptm: null,
        remark: "Npu",
        id: "gB2eOqzx32fYQTBDc6TP",
        path: "leads/0G7OpTMpt99Md0qZIvc8/actions/gB2eOqzx32fYQTBDc6TP",
        leadId: "0G7OpTMpt99Md0qZIvc8"
    },
    {
        user: "iqi.jimmyng@gmail.com",
        action: "Called",
        created: {
            seconds: 1669970024,
            nanoseconds: 597000000
        },
        files: null,
        userName: "Jimmy Ng ",
        apptm: null,
        remark: "no looking for property ",
        id: "nRCg7x8OQaAcfUsRCjgY",
        path: "leads/0G7OpTMpt99Md0qZIvc8/actions/nRCg7x8OQaAcfUsRCjgY",
        leadId: "0G7OpTMpt99Md0qZIvc8"
    },
    {
        files: null,
        action: "Research",
        remark: "Pass back ",
        userName: "Jeremy Tan",
        created: {
            seconds: 1683624077,
            nanoseconds: 542000000
        },
        leadId: "0G7OpTMpt99Md0qZIvc8",
        apptm: null,
        user: "jeremytan.iqi@gmail.com",
        id: "x0sudJjqgq9ijBItXNka",
        path: "leads/0G7OpTMpt99Md0qZIvc8/actions/x0sudJjqgq9ijBItXNka"
    },
    {
        created: {
            seconds: 1679810402,
            nanoseconds: 399000000
        },
        userName: "foo fangsheng",
        remark: "",
        leadId: "0G7OpTMpt99Md0qZIvc8",
        files: null,
        action: "Called",
        apptm: null,
        user: "xiiaosheng33096@gmail.com",
        id: "yEusG7frykhEb2Gs9hUB",
        path: "leads/0G7OpTMpt99Md0qZIvc8/actions/yEusG7frykhEb2Gs9hUB"
    },
    {
        remark: "Drop a message’s ",
        userName: "Jeremy Tan",
        action: "Whatsapped",
        created: {
            seconds: 1663937697,
            nanoseconds: 256000000
        },
        files: null,
        user: "jeremytan.iqi@gmail.com",
        apptm: null,
        id: "yZ95aP94sp70qR1P7DTg",
        path: "leads/0G7OpTMpt99Md0qZIvc8/actions/yZ95aP94sp70qR1P7DTg",
        leadId: "0G7OpTMpt99Md0qZIvc8"
    },
    {
        remark: "Update him how to play with studio investments ",
        action: "Whatsapped",
        apptm: null,
        files: null,
        user: "jeremytan.iqi@gmail.com",
        created: {
            seconds: 1664007330,
            nanoseconds: 863000000
        },
        userName: "Jeremy Tan",
        id: "zjHDqROsAu7hZM8Rw4hP",
        path: "leads/0G7OpTMpt99Md0qZIvc8/actions/zjHDqROsAu7hZM8Rw4hP",
        leadId: "0G7OpTMpt99Md0qZIvc8"
    },
    {
        apptm: null,
        files: null,
        created: {
            seconds: 1650350926,
            nanoseconds: 80000000
        },
        user: "kaiyegolf@gmail.com",
        remark: "Not interested",
        action: "Research",
        userName: "James  On",
        id: "1eGWfYG2ioEY9vIHXPFj",
        path: "leads/0G8HPqPhrumZccLUx540/actions/1eGWfYG2ioEY9vIHXPFj",
        leadId: "0G8HPqPhrumZccLUx540"
    },
    {
        files: null,
        userName: "James  On",
        action: "Research",
        apptm: null,
        remark: "No answer",
        created: {
            seconds: 1650260978,
            nanoseconds: 810000000
        },
        user: "kaiyegolf@gmail.com",
        id: "2MzdEMM6m0swO2yEh7Kr",
        path: "leads/0G8HPqPhrumZccLUx540/actions/2MzdEMM6m0swO2yEh7Kr",
        leadId: "0G8HPqPhrumZccLUx540"
    },
    {
        remark: "She say change mind already. No looking it",
        userName: "Edwin Goh",
        action: "Called",
        files: null,
        user: "edwingoh.kokchai@gmail.com",
        created: {
            seconds: 1648537367,
            nanoseconds: 52000000
        },
        apptm: null,
        id: "mTqiXv7EQjUrgE4qEDQ1",
        path: "leads/0G8HPqPhrumZccLUx540/actions/mTqiXv7EQjUrgE4qEDQ1",
        leadId: "0G8HPqPhrumZccLUx540"
    },
    {
        action: "Called",
        user: "kaycee.eliteone@gmail.com",
        userName: "Kaycee Chong",
        files: null,
        apptm: null,
        remark: "",
        created: {
            seconds: 1665665352,
            nanoseconds: 445000000
        },
        id: "3gtI0gqrnanjNyUMF2up",
        path: "leads/0GrdJbNFdtlnXI2TwpbC/actions/3gtI0gqrnanjNyUMF2up",
        leadId: "0GrdJbNFdtlnXI2TwpbC"
    },
    {
        user: "desmondchongproperty21@gmail.com",
        userName: "Desmond  Chong",
        apptm: null,
        files: null,
        remark: "",
        created: {
            seconds: 1664538860,
            nanoseconds: 411000000
        },
        action: "Called",
        id: "DENlkHWZ8bOQh41GHDlq",
        path: "leads/0GrdJbNFdtlnXI2TwpbC/actions/DENlkHWZ8bOQh41GHDlq",
        leadId: "0GrdJbNFdtlnXI2TwpbC"
    },
    {
        userName: "Kaycee Chong",
        user: "kaycee.eliteone@gmail.com",
        action: "Called",
        files: null,
        remark: "",
        apptm: null,
        created: {
            seconds: 1665983065,
            nanoseconds: 119000000
        },
        id: "XPATD1GbSIizPCUz1FlA",
        path: "leads/0GrdJbNFdtlnXI2TwpbC/actions/XPATD1GbSIizPCUz1FlA",
        leadId: "0GrdJbNFdtlnXI2TwpbC"
    },
    {
        created: {
            seconds: 1664538909,
            nanoseconds: 146000000
        },
        userName: "Desmond  Chong",
        remark: "",
        action: "Called",
        files: null,
        apptm: null,
        user: "desmondchongproperty21@gmail.com",
        id: "hY8SlyZdSnD4QCN6PIZd",
        path: "leads/0GrdJbNFdtlnXI2TwpbC/actions/hY8SlyZdSnD4QCN6PIZd",
        leadId: "0GrdJbNFdtlnXI2TwpbC"
    },
    {
        userName: "Pang  Yy",
        created: {
            seconds: 1664439046,
            nanoseconds: 521000000
        },
        user: "cindy_pang91@hotmail.com",
        apptm: null,
        action: "Research",
        files: null,
        remark: "student . help parents survey . investment . bought southville",
        id: "n1D9E95B72PeW9gpOxMD",
        path: "leads/0GrdJbNFdtlnXI2TwpbC/actions/n1D9E95B72PeW9gpOxMD",
        leadId: "0GrdJbNFdtlnXI2TwpbC"
    },
    {
        files: null,
        userName: "Pang  Yy",
        apptm: null,
        action: "Whatsapped",
        user: "cindy_pang91@hotmail.com",
        remark: "",
        created: {
            seconds: 1664448618,
            nanoseconds: 426000000
        },
        id: "twBCuwvhYRU6iro1tCYb",
        path: "leads/0GrdJbNFdtlnXI2TwpbC/actions/twBCuwvhYRU6iro1tCYb",
        leadId: "0GrdJbNFdtlnXI2TwpbC"
    },
    {
        userName: "Jimmy Ng ",
        files: null,
        user: "iqi.jimmyng@gmail.com",
        created: {
            seconds: 1663075506,
            nanoseconds: 328000000
        },
        remark: "blast divo",
        action: "Whatsapped",
        apptm: null,
        id: "1nQckzL5ttTYSRMmnkEk",
        path: "leads/0Gwuab5g27f8XIJOeqV4/actions/1nQckzL5ttTYSRMmnkEk",
        leadId: "0Gwuab5g27f8XIJOeqV4"
    },
    {
        userName: "Jimmy Ng ",
        apptm: null,
        user: "iqi.jimmyng@gmail.com",
        created: {
            seconds: 1660991948,
            nanoseconds: 825000000
        },
        files: null,
        action: "Whatsapped",
        remark: "Send some d ivo facilities ",
        id: "1q2vVwJxXITsAaA8upLX",
        path: "leads/0Gwuab5g27f8XIJOeqV4/actions/1q2vVwJxXITsAaA8upLX",
        leadId: "0Gwuab5g27f8XIJOeqV4"
    },
    {
        userName: "William Fam",
        files: null,
        action: "Called",
        remark: "Npu",
        apptm: null,
        created: {
            seconds: 1649238480,
            nanoseconds: 747000000
        },
        user: "williamffk@gmail.com",
        id: "8bwONZatO2oPI1GqRp9P",
        path: "leads/0Gwuab5g27f8XIJOeqV4/actions/8bwONZatO2oPI1GqRp9P",
        leadId: "0Gwuab5g27f8XIJOeqV4"
    },
    {
        created: {
            seconds: 1666354781,
            nanoseconds: 476000000
        },
        apptm: null,
        user: "iqi.jimmyng@gmail.com",
        remark: "blast fiddlewoodz",
        files: null,
        userName: "Jimmy Ng ",
        action: "Research",
        id: "8dYn3SVH3znbx7Jvt894",
        path: "leads/0Gwuab5g27f8XIJOeqV4/actions/8dYn3SVH3znbx7Jvt894",
        leadId: "0Gwuab5g27f8XIJOeqV4"
    },
    {
        created: {
            seconds: 1647867262,
            nanoseconds: 228000000
        },
        userName: "Lorraine  Lai",
        remark: "English. Investment. Not sure abt budget - need to check w husband (construction industry)\nInvestment - also as a gift to daughter (studying in college)\nStays in Tmn Desa \nHousewife - but doing some voluntary job \n(Very organized person - she took down notes during 45mins phone conversation) \nProposed Fiddlewoodz, D’Ivo, Est8 ",
        files: null,
        user: "aya_raine@yahoo.com.au",
        action: "Called",
        apptm: null,
        id: "8y4b7fNAqzmZCsYLg2jB",
        path: "leads/0Gwuab5g27f8XIJOeqV4/actions/8y4b7fNAqzmZCsYLg2jB",
        leadId: "0Gwuab5g27f8XIJOeqV4"
    },
    {
        userName: "Jimmy Ng ",
        files: null,
        created: {
            seconds: 1668171734,
            nanoseconds: 470000000
        },
        apptm: null,
        user: "iqi.jimmyng@gmail.com",
        action: "Whatsapped",
        remark: "blast exsim ",
        id: "9bRXvdtlFKDRGPl8OByf",
        path: "leads/0Gwuab5g27f8XIJOeqV4/actions/9bRXvdtlFKDRGPl8OByf",
        leadId: "0Gwuab5g27f8XIJOeqV4"
    },
    {
        user: "williamffk@gmail.com",
        files: null,
        userName: "William Fam",
        created: {
            seconds: 1661432318,
            nanoseconds: 343000000
        },
        apptm: null,
        remark: "Npu",
        action: "Called",
        id: "9s6ItZh8J50Qp1ImC4uv",
        path: "leads/0Gwuab5g27f8XIJOeqV4/actions/9s6ItZh8J50Qp1ImC4uv",
        leadId: "0Gwuab5g27f8XIJOeqV4"
    },
    {
        userName: "Jimmy Ng ",
        user: "iqi.jimmyng@gmail.com",
        remark: "blast atera",
        action: "Whatsapped",
        created: {
            seconds: 1662625547,
            nanoseconds: 49000000
        },
        apptm: null,
        files: null,
        id: "AkuVhF8dryWkyQC6ZmEK",
        path: "leads/0Gwuab5g27f8XIJOeqV4/actions/AkuVhF8dryWkyQC6ZmEK",
        leadId: "0Gwuab5g27f8XIJOeqV4"
    },
    {
        files: null,
        remark: "many agents / feel uncomfortable if any agents bring them viewing ",
        action: "Called",
        created: {
            seconds: 1651580762,
            nanoseconds: 445000000
        },
        apptm: null,
        userName: "Junsu Mok",
        user: "imjunsu0420@gmail.com",
        id: "AwT1S20CwOCUvndLpp60",
        path: "leads/0Gwuab5g27f8XIJOeqV4/actions/AwT1S20CwOCUvndLpp60",
        leadId: "0Gwuab5g27f8XIJOeqV4"
    },
    {
        user: "aya_raine@yahoo.com.au",
        created: {
            seconds: 1648039030,
            nanoseconds: 502000000
        },
        userName: "Lorraine  Lai",
        action: "Research",
        files: null,
        remark: "she keeps saying husband is busy, no time to discuss w him. They are not urgent/ hardcore investors blah blah blah...",
        apptm: null,
        id: "CwB9A2hfA7DNTVwJPaUK",
        path: "leads/0Gwuab5g27f8XIJOeqV4/actions/CwB9A2hfA7DNTVwJPaUK",
        leadId: "0Gwuab5g27f8XIJOeqV4"
    },
    {
        userName: "Jimmy Ng ",
        remark: "send some artist impression and actual design of dcp",
        files: null,
        action: "Whatsapped",
        created: {
            seconds: 1661424345,
            nanoseconds: 764000000
        },
        user: "iqi.jimmyng@gmail.com",
        apptm: null,
        id: "EZfmFRAfekGLVyuRYtz1",
        path: "leads/0Gwuab5g27f8XIJOeqV4/actions/EZfmFRAfekGLVyuRYtz1",
        leadId: "0Gwuab5g27f8XIJOeqV4"
    },
    {
        remark: "no pick up",
        created: {
            seconds: 1664540366,
            nanoseconds: 503000000
        },
        userName: "Jimmy Ng ",
        action: "Called",
        apptm: null,
        files: null,
        user: "iqi.jimmyng@gmail.com",
        id: "NmDbaUN6QlN0A41eDlf3",
        path: "leads/0Gwuab5g27f8XIJOeqV4/actions/NmDbaUN6QlN0A41eDlf3",
        leadId: "0Gwuab5g27f8XIJOeqV4"
    },
    {
        files: null,
        user: "iqi.jimmyng@gmail.com",
        created: {
            seconds: 1662294084,
            nanoseconds: 864000000
        },
        userName: "Jimmy Ng ",
        action: "Whatsapped",
        remark: "blast mossaz",
        apptm: null,
        id: "OcB4Cc2QTZp31kkto6K3",
        path: "leads/0Gwuab5g27f8XIJOeqV4/actions/OcB4Cc2QTZp31kkto6K3",
        leadId: "0Gwuab5g27f8XIJOeqV4"
    },
    {
        files: null,
        action: "Whatsapped",
        user: "iqi.jimmyng@gmail.com",
        apptm: null,
        remark: "blast projects ",
        userName: "Jimmy Ng ",
        created: {
            seconds: 1665560437,
            nanoseconds: 359000000
        },
        id: "RU73aKDaOIbLyH6WD3Ak",
        path: "leads/0Gwuab5g27f8XIJOeqV4/actions/RU73aKDaOIbLyH6WD3Ak",
        leadId: "0Gwuab5g27f8XIJOeqV4"
    },
    {
        user: "iqi.jimmyng@gmail.com",
        apptm: null,
        created: {
            seconds: 1664959027,
            nanoseconds: 974000000
        },
        action: "Whatsapped",
        userName: "Jimmy Ng ",
        remark: "blast dcp ",
        files: null,
        id: "VHNMnQqilgvJ9K51BOEf",
        path: "leads/0Gwuab5g27f8XIJOeqV4/actions/VHNMnQqilgvJ9K51BOEf",
        leadId: "0Gwuab5g27f8XIJOeqV4"
    },
    {
        user: "iqi.jimmyng@gmail.com",
        remark: "blast dcp",
        created: {
            seconds: 1661681257,
            nanoseconds: 307000000
        },
        apptm: null,
        userName: "Jimmy Ng ",
        files: null,
        action: "Whatsapped",
        id: "Vn3SmTrt8AyMk8ljaAT0",
        path: "leads/0Gwuab5g27f8XIJOeqV4/actions/Vn3SmTrt8AyMk8ljaAT0",
        leadId: "0Gwuab5g27f8XIJOeqV4"
    },
    {
        apptm: null,
        user: "iqi.jimmyng@gmail.com",
        userName: "Jimmy Ng ",
        remark: "blast dcp",
        action: "Whatsapped",
        files: null,
        created: {
            seconds: 1662127121,
            nanoseconds: 616000000
        },
        id: "XfdBbLOWVTZxl6SbasKx",
        path: "leads/0Gwuab5g27f8XIJOeqV4/actions/XfdBbLOWVTZxl6SbasKx",
        leadId: "0Gwuab5g27f8XIJOeqV4"
    },
    {
        apptm: null,
        user: "iqi.jimmyng@gmail.com",
        action: "Research",
        created: {
            seconds: 1664371193,
            nanoseconds: 197000000
        },
        files: null,
        userName: "Jimmy Ng ",
        remark: "blast dcp",
        id: "hb3AX3RLhRjR5XhYGTzq",
        path: "leads/0Gwuab5g27f8XIJOeqV4/actions/hb3AX3RLhRjR5XhYGTzq",
        leadId: "0Gwuab5g27f8XIJOeqV4"
    },
    {
        user: "iqi.jimmyng@gmail.com",
        userName: "Jimmy Ng ",
        action: "Whatsapped",
        files: null,
        created: {
            seconds: 1663941401,
            nanoseconds: 313000000
        },
        apptm: null,
        remark: "blast fiddlewoodz",
        id: "j2CzJJ7keHtwt7CFUeW2",
        path: "leads/0Gwuab5g27f8XIJOeqV4/actions/j2CzJJ7keHtwt7CFUeW2",
        leadId: "0Gwuab5g27f8XIJOeqV4"
    },
    {
        action: "Whatsapped",
        files: null,
        userName: "Jimmy Ng ",
        user: "iqi.jimmyng@gmail.com",
        remark: "blast hugoz",
        created: {
            seconds: 1663589960,
            nanoseconds: 227000000
        },
        apptm: null,
        id: "k3djMVdNlS21smC0lEuR",
        path: "leads/0Gwuab5g27f8XIJOeqV4/actions/k3djMVdNlS21smC0lEuR",
        leadId: "0Gwuab5g27f8XIJOeqV4"
    },
    {
        action: "Called",
        apptm: null,
        userName: "Junsu Mok",
        remark: "8:13PM no pick up",
        created: {
            seconds: 1651579978,
            nanoseconds: 54000000
        },
        user: "imjunsu0420@gmail.com",
        files: null,
        id: "n2SS9j0VEEYnGfntVTyG",
        path: "leads/0Gwuab5g27f8XIJOeqV4/actions/n2SS9j0VEEYnGfntVTyG",
        leadId: "0Gwuab5g27f8XIJOeqV4"
    },
    {
        created: {
            seconds: 1662811455,
            nanoseconds: 652000000
        },
        user: "iqi.jimmyng@gmail.com",
        files: null,
        remark: "blast moon festival",
        userName: "Jimmy Ng ",
        apptm: null,
        action: "Research",
        id: "ojKR5HwxeOxOP1Kghb8k",
        path: "leads/0Gwuab5g27f8XIJOeqV4/actions/ojKR5HwxeOxOP1Kghb8k",
        leadId: "0Gwuab5g27f8XIJOeqV4"
    },
    {
        remark: "Greetings ",
        userName: "Jimmy Ng ",
        files: null,
        action: "Whatsapped",
        created: {
            seconds: 1660830921,
            nanoseconds: 463000000
        },
        user: "iqi.jimmyng@gmail.com",
        apptm: null,
        id: "stEwYiD3HqSvhla3cznC",
        path: "leads/0Gwuab5g27f8XIJOeqV4/actions/stEwYiD3HqSvhla3cznC",
        leadId: "0Gwuab5g27f8XIJOeqV4"
    },
    {
        user: "iqi.jimmyng@gmail.com",
        remark: "blast projects",
        created: {
            seconds: 1666870140,
            nanoseconds: 275000000
        },
        apptm: null,
        files: null,
        action: "Whatsapped",
        userName: "Jimmy Ng ",
        id: "uG9ekmJubGDiM3y5iuMz",
        path: "leads/0Gwuab5g27f8XIJOeqV4/actions/uG9ekmJubGDiM3y5iuMz",
        leadId: "0Gwuab5g27f8XIJOeqV4"
    },
    {
        apptm: null,
        files: null,
        action: "Called",
        user: "meijunkapo_1014@hotmail.com",
        userName: "MJ CHEAH",
        created: {
            seconds: 1649133647,
            nanoseconds: 767000000
        },
        remark: "Want buy for their daughter/ investment . can feel like  Husband is decision maker . \nCan feel they want find 500k + de property , but they know so hard to find . \n\nShe tell me she and her husband Don’t like hard push , will feel so 反感，他们不要。 ",
        id: "wWeV7G54y08CzckiYy8X",
        path: "leads/0Gwuab5g27f8XIJOeqV4/actions/wWeV7G54y08CzckiYy8X",
        leadId: "0Gwuab5g27f8XIJOeqV4"
    },
    {
        action: "Called",
        files: null,
        userName: "Jimmy Ng ",
        created: {
            seconds: 1660829808,
            nanoseconds: 940000000
        },
        user: "iqi.jimmyng@gmail.com",
        apptm: null,
        remark: "Survey buyer, stay at old klang road, proposed dcp and old klang road project. Before that planing to investment at old klang road",
        id: "z0kahfSFDQ9AWQAYKEzb",
        path: "leads/0Gwuab5g27f8XIJOeqV4/actions/z0kahfSFDQ9AWQAYKEzb",
        leadId: "0Gwuab5g27f8XIJOeqV4"
    },
    {
        apptm: null,
        created: {
            seconds: 1677652190,
            nanoseconds: 295000000
        },
        leadId: "0HGcFk7VXpDtg4hB5q1W",
        userName: "foo fangsheng",
        files: null,
        action: "Called",
        user: "xiiaosheng33096@gmail.com",
        remark: "UTC",
        id: "ET3ACq5IlNvTia1HdupS",
        path: "leads/0HGcFk7VXpDtg4hB5q1W/actions/ET3ACq5IlNvTia1HdupS"
    },
    {
        action: "Whatsapped",
        userName: "foo fangsheng",
        leadId: "0HGcFk7VXpDtg4hB5q1W",
        user: "xiiaosheng33096@gmail.com",
        created: {
            seconds: 1677652034,
            nanoseconds: 153000000
        },
        apptm: null,
        files: null,
        remark: "",
        id: "zlNKWPWSzA3lMdaKFmKy",
        path: "leads/0HGcFk7VXpDtg4hB5q1W/actions/zlNKWPWSzA3lMdaKFmKy"
    },
    {
        action: "Research",
        userName: "Pang  Yy",
        created: {
            seconds: 1659438850,
            nanoseconds: 673000000
        },
        apptm: null,
        user: "cindy_pang91@hotmail.com",
        remark: "tukkkkkkkkkkkkkkktukkkkkkkkk",
        files: null,
        id: "33FTnQg8bn9Fx5iuKNBu",
        path: "leads/0HKiEC28x1GUctMLR58S/actions/33FTnQg8bn9Fx5iuKNBu",
        leadId: "0HKiEC28x1GUctMLR58S"
    },
    {
        remark: "for children, study monash, buy dy",
        userName: "Chris  Tai ",
        created: {
            seconds: 1649852544,
            nanoseconds: 490000000
        },
        apptm: null,
        action: "Called",
        files: null,
        user: "christai8798@gmail.com",
        id: "HF8LqKBF1MsfVcrAo7rc",
        path: "leads/0HKiEC28x1GUctMLR58S/actions/HF8LqKBF1MsfVcrAo7rc",
        leadId: "0HKiEC28x1GUctMLR58S"
    },
    {
        action: "Whatsapped",
        user: "cindy_pang91@hotmail.com",
        userName: "Pang  Yy",
        remark: "",
        apptm: null,
        created: {
            seconds: 1660117549,
            nanoseconds: 325000000
        },
        files: null,
        id: "N8pdcBPNMPYWCBuPYqZ2",
        path: "leads/0HKiEC28x1GUctMLR58S/actions/N8pdcBPNMPYWCBuPYqZ2",
        leadId: "0HKiEC28x1GUctMLR58S"
    },
    {
        apptm: null,
        files: null,
        action: "Research",
        created: {
            seconds: 1633345045,
            nanoseconds: 606000000
        },
        user: "vernicelee2621@gmail.com",
        remark: "prefer nearby Sunway project as want looks for kids",
        id: "R3UGpL0ZPB0dwgKu5aFA",
        path: "leads/0HKiEC28x1GUctMLR58S/actions/R3UGpL0ZPB0dwgKu5aFA",
        leadId: "0HKiEC28x1GUctMLR58S"
    },
    {
        userName: "Pang  Yy",
        files: null,
        remark: "run resort in sipadan .sabahan . kid study at taylor . he found subsales .but still looking .....",
        user: "cindy_pang91@hotmail.com",
        action: "Research",
        apptm: null,
        created: {
            seconds: 1659605523,
            nanoseconds: 657000000
        },
        id: "TO4q1uD12uU2RJORB0Ib",
        path: "leads/0HKiEC28x1GUctMLR58S/actions/TO4q1uD12uU2RJORB0Ib",
        leadId: "0HKiEC28x1GUctMLR58S"
    },
    {
        remark: "try intro sunway serene",
        files: null,
        created: {
            seconds: 1659606411,
            nanoseconds: 537000000
        },
        action: "Research",
        apptm: null,
        user: "cindy_pang91@hotmail.com",
        userName: "Pang  Yy",
        id: "ipP8q1VlAM4Pf0SnH3gS",
        path: "leads/0HKiEC28x1GUctMLR58S/actions/ipP8q1VlAM4Pf0SnH3gS",
        leadId: "0HKiEC28x1GUctMLR58S"
    },
    {
        apptm: null,
        user: "cindy_pang91@hotmail.com",
        remark: "no sound",
        created: {
            seconds: 1658909071,
            nanoseconds: 745000000
        },
        userName: "Pang  Yy",
        files: null,
        action: "Called",
        id: "tdN1L9mWWbzq8xwg2wBD",
        path: "leads/0HKiEC28x1GUctMLR58S/actions/tdN1L9mWWbzq8xwg2wBD",
        leadId: "0HKiEC28x1GUctMLR58S"
    },
    {
        remark: "",
        files: null,
        created: {
            seconds: 1626759092,
            nanoseconds: 193000000
        },
        user: "ngsinyi@hotmail.com",
        apptm: null,
        action: "Called",
        id: "GSkAR1eoaJgCLZWFwUdE",
        path: "leads/0HtZeXy19FNeHSBKh5EN/actions/GSkAR1eoaJgCLZWFwUdE",
        leadId: "0HtZeXy19FNeHSBKh5EN"
    },
    {
        created: {
            seconds: 1625386312,
            nanoseconds: 94000000
        },
        action: "Called",
        remark: "budget - 1500 - 2000",
        user: "msleow@gmail.com",
        files: null,
        id: "LCRwdsRh7g8M4CuTZV2b",
        path: "leads/0HtZeXy19FNeHSBKh5EN/actions/LCRwdsRh7g8M4CuTZV2b",
        leadId: "0HtZeXy19FNeHSBKh5EN"
    },
    {
        action: "Whatsapped",
        remark: "sent 121 residence info",
        created: {
            seconds: 1625386624,
            nanoseconds: 82000000
        },
        files: null,
        user: "msleow@gmail.com",
        id: "VD6S3cjg0MHQBI4B3IAj",
        path: "leads/0HtZeXy19FNeHSBKh5EN/actions/VD6S3cjg0MHQBI4B3IAj",
        leadId: "0HtZeXy19FNeHSBKh5EN"
    },
    {
        user: "henryleeproperty12@gmail.com",
        files: null,
        created: {
            seconds: 1679561953,
            nanoseconds: 703000000
        },
        userName: "henry lee",
        apptm: null,
        remark: "blocked clash lead",
        action: "Called",
        leadId: "0I1i3rbdkZ3oi2XpEk72",
        id: "dLrBThe0JWt3rSiU4gKE",
        path: "leads/0I1i3rbdkZ3oi2XpEk72/actions/dLrBThe0JWt3rSiU4gKE"
    },
    {
        user: "aidenlim950310@gmail.com",
        leadId: "0Iaa2mftqB1zCcMz8SBb",
        apptm: null,
        remark: "D’Velada",
        action: "Whatsapped",
        created: {
            seconds: 1678780728,
            nanoseconds: 964000000
        },
        files: null,
        userName: "Aiden  Lim",
        id: "2A95BWGMGUcwfoXDXQ0s",
        path: "leads/0Iaa2mftqB1zCcMz8SBb/actions/2A95BWGMGUcwfoXDXQ0s"
    },
    {
        created: {
            seconds: 1678533256,
            nanoseconds: 735000000
        },
        action: "Whatsapped",
        apptm: null,
        files: null,
        user: "seong31993199@gmail.com",
        leadId: "0Iaa2mftqB1zCcMz8SBb",
        remark: "已买",
        userName: "Ting Seong Shew",
        id: "5xNmR5AbmLBi3H70maqX",
        path: "leads/0Iaa2mftqB1zCcMz8SBb/actions/5xNmR5AbmLBi3H70maqX"
    },
    {
        files: null,
        user: "aidenlim950310@gmail.com",
        created: {
            seconds: 1680159233,
            nanoseconds: 766000000
        },
        action: "Called",
        apptm: null,
        leadId: "0Iaa2mftqB1zCcMz8SBb",
        remark: "Still surveying, want condo ,  it so far doesn’t have anything Tat attract him , he also don’t know what he want ",
        userName: "Aiden  Lim",
        id: "SNW72q9BBd1I0bG5iqs8",
        path: "leads/0Iaa2mftqB1zCcMz8SBb/actions/SNW72q9BBd1I0bG5iqs8"
    },
    {
        leadId: "0Iaa2mftqB1zCcMz8SBb",
        apptm: null,
        remark: "",
        created: {
            seconds: 1678092895,
            nanoseconds: 676000000
        },
        userName: "Summer Koh",
        user: "kohpohfen@gmail.com",
        action: "Whatsapped",
        files: null,
        id: "VZLQLkncJvWlw3ymgZxZ",
        path: "leads/0Iaa2mftqB1zCcMz8SBb/actions/VZLQLkncJvWlw3ymgZxZ"
    },
    {
        user: "jonathanchin0614@gmail.com",
        action: "Research",
        userName: "Jonathan Chin",
        files: null,
        created: {
            seconds: 1678352621,
            nanoseconds: 714000000
        },
        remark: "pick up my phone call but no sound, already whatsapp customer",
        apptm: null,
        leadId: "0Iaa2mftqB1zCcMz8SBb",
        id: "fGBfD28Tqrvoi7C0iBZx",
        path: "leads/0Iaa2mftqB1zCcMz8SBb/actions/fGBfD28Tqrvoi7C0iBZx"
    },
    {
        files: null,
        leadId: "0Iaa2mftqB1zCcMz8SBb",
        created: {
            seconds: 1678437407,
            nanoseconds: 69000000
        },
        apptm: null,
        user: "aidenlim950310@gmail.com",
        remark: "Line cut off , staying in kuchai , wanna upgrade ",
        action: "Called",
        userName: "Aiden  Lim",
        id: "qdFT1nt59ULUPyWdQRyo",
        path: "leads/0Iaa2mftqB1zCcMz8SBb/actions/qdFT1nt59ULUPyWdQRyo"
    },
    {
        leadId: "0Iaa2mftqB1zCcMz8SBb",
        files: null,
        apptm: null,
        remark: "",
        action: "Called",
        userName: "Ting Seong Shew",
        user: "seong31993199@gmail.com",
        created: {
            seconds: 1678533087,
            nanoseconds: 764000000
        },
        id: "sDQILwwiuVq7tmlykf2B",
        path: "leads/0Iaa2mftqB1zCcMz8SBb/actions/sDQILwwiuVq7tmlykf2B"
    },
    {
        apptm: null,
        action: "Called",
        created: {
            seconds: 1678438648,
            nanoseconds: 52000000
        },
        user: "williamffk@gmail.com",
        userName: "William Fam",
        remark: "Truecaller is fu hong kong. Npu",
        leadId: "0Iaa2mftqB1zCcMz8SBb",
        files: null,
        id: "uJfPluabWp624huhSnYU",
        path: "leads/0Iaa2mftqB1zCcMz8SBb/actions/uJfPluabWp624huhSnYU"
    },
    {
        userName: "Aiden  Lim",
        apptm: null,
        created: {
            seconds: 1677668522,
            nanoseconds: 227000000
        },
        leadId: "0Iaa2mftqB1zCcMz8SBb",
        user: "aidenlim950310@gmail.com",
        remark: "Bz meeting client . Cb later",
        action: "Called",
        files: null,
        id: "zWcDJL6YknGAGMx6g1Ip",
        path: "leads/0Iaa2mftqB1zCcMz8SBb/actions/zWcDJL6YknGAGMx6g1Ip"
    },
    {
        action: "Called",
        created: {
            seconds: 1633928146,
            nanoseconds: 423000000
        },
        remark: "already book with Alvin - loan processing?",
        user: "imjunsu0420@gmail.com",
        apptm: null,
        files: null,
        id: "RX6jw8wajkxReRUUxjim",
        path: "leads/0JVKYNaAdul1dEuz0OSq/actions/RX6jw8wajkxReRUUxjim",
        leadId: "0JVKYNaAdul1dEuz0OSq"
    },
    {
        files: null,
        action: "Research",
        apptm: null,
        remark: "ady contacted by other agent",
        user: "vernicelee2621@gmail.com",
        created: {
            seconds: 1632488722,
            nanoseconds: 505000000
        },
        id: "xBq4iFMCFrHr9CtwzjID",
        path: "leads/0JVKYNaAdul1dEuz0OSq/actions/xBq4iFMCFrHr9CtwzjID",
        leadId: "0JVKYNaAdul1dEuz0OSq"
    },
    {
        user: "mongny@hotmail.com",
        files: null,
        created: {
            seconds: 1662886624,
            nanoseconds: 869000000
        },
        userName: "Jimmy ong",
        remark: "Na",
        action: "Called",
        apptm: null,
        id: "qImeGPZwhKDC6GcgosOU",
        path: "leads/0KD1cQKI5eM3yvwbAwQ7/actions/qImeGPZwhKDC6GcgosOU",
        leadId: "0KD1cQKI5eM3yvwbAwQ7"
    },
    {
        user: "munhong0909@gmail.com",
        created: {
            seconds: 1664441444,
            nanoseconds: 958000000
        },
        userName: "Mun Hong Yip",
        apptm: null,
        action: "Called",
        files: null,
        remark: "said bought sentul",
        id: "v6FzjV4Rjn7sD38hJQiD",
        path: "leads/0KD1cQKI5eM3yvwbAwQ7/actions/v6FzjV4Rjn7sD38hJQiD",
        leadId: "0KD1cQKI5eM3yvwbAwQ7"
    },
    {
        created: {
            seconds: 1668684491,
            nanoseconds: 469000000
        },
        action: "Whatsapped",
        remark: "",
        apptm: null,
        user: "zzhenghoong96@gmail.com",
        files: null,
        userName: "Zheng Hoong Tan",
        id: "Nbm1nSB90UqqvY32aO0X",
        path: "leads/0Kv7Yx5NKKGmsVKXyuIa/actions/Nbm1nSB90UqqvY32aO0X",
        leadId: "0Kv7Yx5NKKGmsVKXyuIa"
    },
    {
        userName: "Zheng Hoong Tan",
        created: {
            seconds: 1667911939,
            nanoseconds: 116000000
        },
        action: "Whatsapped",
        apptm: null,
        user: "zzhenghoong96@gmail.com",
        files: null,
        remark: "whatsapp send",
        id: "VKbIjdB7vLgfSG5txKQw",
        path: "leads/0Kv7Yx5NKKGmsVKXyuIa/actions/VKbIjdB7vLgfSG5txKQw",
        leadId: "0Kv7Yx5NKKGmsVKXyuIa"
    },
    {
        userName: "Gene Lee",
        files: null,
        action: "Called",
        remark: "Gene following up, fiddlewoodz vs goodwood and EST8",
        user: "gnelol8895@gmail.com",
        apptm: null,
        created: {
            seconds: 1666704240,
            nanoseconds: 326000000
        },
        id: "Z4xa3c236FjCqm03RVIO",
        path: "leads/0Kv7Yx5NKKGmsVKXyuIa/actions/Z4xa3c236FjCqm03RVIO",
        leadId: "0Kv7Yx5NKKGmsVKXyuIa"
    },
    {
        action: "Called",
        files: null,
        user: "zzhenghoong96@gmail.com",
        remark: "NPU",
        apptm: null,
        created: {
            seconds: 1668066167,
            nanoseconds: 393000000
        },
        userName: "Zheng Hoong Tan",
        id: "pneS5l4Dqqt7puTrRpyN",
        path: "leads/0Kv7Yx5NKKGmsVKXyuIa/actions/pneS5l4Dqqt7puTrRpyN",
        leadId: "0Kv7Yx5NKKGmsVKXyuIa"
    },
    {
        userName: "Desmond  Chong",
        user: "desmondchongproperty21@gmail.com",
        action: "Called",
        apptm: null,
        remark: "",
        created: {
            seconds: 1664452277,
            nanoseconds: 106000000
        },
        files: null,
        id: "6tkyutv10DnO1mrPy5Bt",
        path: "leads/0LbpX0upTQQAbTcpT86p/actions/6tkyutv10DnO1mrPy5Bt",
        leadId: "0LbpX0upTQQAbTcpT86p"
    },
    {
        created: {
            seconds: 1665662378,
            nanoseconds: 823000000
        },
        remark: "",
        userName: "Kaycee Chong",
        user: "kaycee.eliteone@gmail.com",
        apptm: null,
        action: "Called",
        files: null,
        id: "O6eonF4A8wHVgNGcwtOl",
        path: "leads/0LbpX0upTQQAbTcpT86p/actions/O6eonF4A8wHVgNGcwtOl",
        leadId: "0LbpX0upTQQAbTcpT86p"
    },
    {
        remark: "",
        userName: "Desmond  Chong",
        apptm: null,
        action: "Called",
        files: null,
        user: "desmondchongproperty21@gmail.com",
        created: {
            seconds: 1664452247,
            nanoseconds: 319000000
        },
        id: "lrBfpi1IHrR8s5aif9Ya",
        path: "leads/0LbpX0upTQQAbTcpT86p/actions/lrBfpi1IHrR8s5aif9Ya",
        leadId: "0LbpX0upTQQAbTcpT86p"
    },
    {
        action: "Called",
        created: {
            seconds: 1665982798,
            nanoseconds: 937000000
        },
        user: "kaycee.eliteone@gmail.com",
        files: null,
        remark: "",
        apptm: null,
        userName: "Kaycee Chong",
        id: "nXbXfMkHxSKg42RnKm2x",
        path: "leads/0LbpX0upTQQAbTcpT86p/actions/nXbXfMkHxSKg42RnKm2x",
        leadId: "0LbpX0upTQQAbTcpT86p"
    },
    {
        files: null,
        action: "Research",
        created: {
            seconds: 1647408234,
            nanoseconds: 382000000
        },
        userName: "James  On",
        apptm: null,
        remark: "No answer ",
        user: "kaiyegolf@gmail.com",
        id: "e6ExlbafveoR1ukJWkfQ",
        path: "leads/0LdlzD65v13hKrWfABz2/actions/e6ExlbafveoR1ukJWkfQ",
        leadId: "0LdlzD65v13hKrWfABz2"
    },
    {
        apptm: null,
        action: "Whatsapped",
        user: "hengch88@yahoo.com.my",
        created: {
            seconds: 1629164047,
            nanoseconds: 570000000
        },
        remark: "Lure him on the KLCC project",
        files: null,
        id: "A29EQXO7rTBU2AScEZnz",
        path: "leads/0LpwGJeZqKiYDYRGjNV6/actions/A29EQXO7rTBU2AScEZnz",
        leadId: "0LpwGJeZqKiYDYRGjNV6"
    },
    {
        remark: "Nouvelle, Directly opposite KLCC, dislike Mew's E&O location, being deeper, aware of the JV with Mitshui, Want something that is completed.",
        user: "hengch88@yahoo.com.my",
        created: {
            seconds: 1627555021,
            nanoseconds: 223000000
        },
        files: null,
        action: "Research",
        apptm: null,
        id: "HEl34OlCLSPjMdWV3psY",
        path: "leads/0LpwGJeZqKiYDYRGjNV6/actions/HEl34OlCLSPjMdWV3psY",
        leadId: "0LpwGJeZqKiYDYRGjNV6"
    },
    {
        created: {
            seconds: 1628649865,
            nanoseconds: 563000000
        },
        apptm: null,
        user: "hengch88@yahoo.com.my",
        action: "Called",
        remark: "",
        files: null,
        id: "h6QDuEoPUKSeFK5PiIsC",
        path: "leads/0LpwGJeZqKiYDYRGjNV6/actions/h6QDuEoPUKSeFK5PiIsC",
        leadId: "0LpwGJeZqKiYDYRGjNV6"
    },
    {
        apptm: null,
        remark: "",
        user: "hengch88@yahoo.com.my",
        created: {
            seconds: 1628135503,
            nanoseconds: 322000000
        },
        action: "Called",
        files: null,
        id: "tQpRXZLYg8QiHKw4nv83",
        path: "leads/0LpwGJeZqKiYDYRGjNV6/actions/tQpRXZLYg8QiHKw4nv83",
        leadId: "0LpwGJeZqKiYDYRGjNV6"
    },
    {
        created: {
            seconds: 1664696895,
            nanoseconds: 222000000
        },
        apptm: null,
        userName: "Jeremy Tan",
        files: null,
        action: "Whatsapped",
        remark: "Intro Hugoz ",
        user: "jeremytan.iqi@gmail.com",
        id: "9nYgs4unuHftBVV1oVyo",
        path: "leads/0NCnYxOP6V2Kvu2r9XHL/actions/9nYgs4unuHftBVV1oVyo",
        leadId: "0NCnYxOP6V2Kvu2r9XHL"
    },
    {
        action: "Whatsapped",
        user: "jeremytan.iqi@gmail.com",
        userName: "Jeremy Tan",
        apptm: null,
        created: {
            seconds: 1661244686,
            nanoseconds: 539000000
        },
        files: null,
        remark: "Send laurel surround view",
        id: "A4FhIaQTaQfFeSvvEufZ",
        path: "leads/0NCnYxOP6V2Kvu2r9XHL/actions/A4FhIaQTaQfFeSvvEufZ",
        leadId: "0NCnYxOP6V2Kvu2r9XHL"
    },
    {
        created: {
            seconds: 1662627847,
            nanoseconds: 250000000
        },
        user: "cindy_pang91@hotmail.com",
        files: null,
        action: "Whatsapped",
        userName: "Pang  Yy",
        apptm: null,
        remark: "",
        id: "BP4nFYTVRxnGHxKNgLMz",
        path: "leads/0NCnYxOP6V2Kvu2r9XHL/actions/BP4nFYTVRxnGHxKNgLMz",
        leadId: "0NCnYxOP6V2Kvu2r9XHL"
    },
    {
        remark: "Low budget",
        userName: "Summer Koh",
        apptm: null,
        user: "kohpohfen@gmail.com",
        files: null,
        created: {
            seconds: 1656681836,
            nanoseconds: 414000000
        },
        action: "Research",
        id: "FHRmi6xOS7OkPoggfcG3",
        path: "leads/0NCnYxOP6V2Kvu2r9XHL/actions/FHRmi6xOS7OkPoggfcG3",
        leadId: "0NCnYxOP6V2Kvu2r9XHL"
    },
    {
        action: "Whatsapped",
        files: null,
        remark: "Auntie Lai de, for own stay or investment, Budget around Rm300k want nearby KLCC area. Paxtonz & mossaz no parking not okay for her. Intro her skyline 1B1B she feel interested but no unit ald if got unit can let her know .Update on 10 July , let me study more KL area project then I’ll introduce to her again ",
        created: {
            seconds: 1656680422,
            nanoseconds: 438000000
        },
        userName: "Jeremy Tan",
        user: "jeremytan.iqi@gmail.com",
        apptm: null,
        id: "HGgwEjEiYLscMkgILGnR",
        path: "leads/0NCnYxOP6V2Kvu2r9XHL/actions/HGgwEjEiYLscMkgILGnR",
        leadId: "0NCnYxOP6V2Kvu2r9XHL"
    },
    {
        files: null,
        action: "Whatsapped",
        apptm: null,
        userName: "Summer Koh",
        created: {
            seconds: 1656680297,
            nanoseconds: 672000000
        },
        user: "kohpohfen@gmail.com",
        remark: "Say dunwan mossaz. Propose 121",
        id: "JIe4tHeD0j8aYcGtJnxN",
        path: "leads/0NCnYxOP6V2Kvu2r9XHL/actions/JIe4tHeD0j8aYcGtJnxN",
        leadId: "0NCnYxOP6V2Kvu2r9XHL"
    },
    {
        action: "Whatsapped",
        user: "kohpohfen@gmail.com",
        userName: "Summer Koh",
        files: null,
        apptm: null,
        remark: "",
        created: {
            seconds: 1656588788,
            nanoseconds: 286000000
        },
        id: "KFq8EqMU2ATtAfzEbRMd",
        path: "leads/0NCnYxOP6V2Kvu2r9XHL/actions/KFq8EqMU2ATtAfzEbRMd",
        leadId: "0NCnYxOP6V2Kvu2r9XHL"
    },
    {
        userName: "Alan Yap",
        created: {
            seconds: 1657018192,
            nanoseconds: 195000000
        },
        files: null,
        user: "alan_yap1121@hotmail.com",
        apptm: null,
        action: "Whatsapped",
        remark: "",
        id: "TCadZ1ikhm2tIV3UjlQ5",
        path: "leads/0NCnYxOP6V2Kvu2r9XHL/actions/TCadZ1ikhm2tIV3UjlQ5",
        leadId: "0NCnYxOP6V2Kvu2r9XHL"
    },
    {
        created: {
            seconds: 1656505727,
            nanoseconds: 862000000
        },
        userName: "Jeremy Tan",
        apptm: null,
        files: null,
        user: "jeremytan.iqi@gmail.com",
        remark: "Not yet pick up x2 ",
        action: "Called",
        id: "UtZAaSfxFQsL9yDXmwBs",
        path: "leads/0NCnYxOP6V2Kvu2r9XHL/actions/UtZAaSfxFQsL9yDXmwBs",
        leadId: "0NCnYxOP6V2Kvu2r9XHL"
    },
    {
        action: "Whatsapped",
        created: {
            seconds: 1661675556,
            nanoseconds: 523000000
        },
        apptm: null,
        user: "jeremytan.iqi@gmail.com",
        remark: "send studio investment knowledge video",
        files: null,
        userName: "Jeremy Tan",
        id: "ZBvGFzmddSnurC9eswR3",
        path: "leads/0NCnYxOP6V2Kvu2r9XHL/actions/ZBvGFzmddSnurC9eswR3",
        leadId: "0NCnYxOP6V2Kvu2r9XHL"
    },
    {
        user: "jeremytan.iqi@gmail.com",
        action: "Called",
        userName: "Jeremy Tan",
        files: null,
        created: {
            seconds: 1656596166,
            nanoseconds: 846000000
        },
        remark: "No pick up today ",
        apptm: null,
        id: "arIL0GSpbKzQi0daZQT6",
        path: "leads/0NCnYxOP6V2Kvu2r9XHL/actions/arIL0GSpbKzQi0daZQT6",
        leadId: "0NCnYxOP6V2Kvu2r9XHL"
    },
    {
        action: "Called",
        userName: "Jeremy Tan",
        remark: "No pick up",
        created: {
            seconds: 1656679446,
            nanoseconds: 988000000
        },
        user: "jeremytan.iqi@gmail.com",
        files: null,
        apptm: null,
        id: "eZviy7EPQR8w346lShW1",
        path: "leads/0NCnYxOP6V2Kvu2r9XHL/actions/eZviy7EPQR8w346lShW1",
        leadId: "0NCnYxOP6V2Kvu2r9XHL"
    },
    {
        action: "Whatsapped",
        remark: "Introduce Laurel to him ",
        files: null,
        user: "jeremytan.iqi@gmail.com",
        created: {
            seconds: 1659682056,
            nanoseconds: 708000000
        },
        userName: "Jeremy Tan",
        apptm: null,
        id: "j0iBklfKhERyE58dvBD6",
        path: "leads/0NCnYxOP6V2Kvu2r9XHL/actions/j0iBklfKhERyE58dvBD6",
        leadId: "0NCnYxOP6V2Kvu2r9XHL"
    },
    {
        user: "cindy_pang91@hotmail.com",
        files: null,
        apptm: null,
        userName: "Pang  Yy",
        action: "Research",
        created: {
            seconds: 1659953860,
            nanoseconds: 766000000
        },
        remark: "no piick up",
        id: "onxZfbgs2kWxk418GdMJ",
        path: "leads/0NCnYxOP6V2Kvu2r9XHL/actions/onxZfbgs2kWxk418GdMJ",
        leadId: "0NCnYxOP6V2Kvu2r9XHL"
    },
    {
        apptm: null,
        action: "Research",
        files: null,
        user: "cindy_pang91@hotmail.com",
        remark: "not reachable",
        userName: "Pang  Yy",
        created: {
            seconds: 1659447854,
            nanoseconds: 211000000
        },
        id: "rkxEyrBNLX4yIShKYkC8",
        path: "leads/0NCnYxOP6V2Kvu2r9XHL/actions/rkxEyrBNLX4yIShKYkC8",
        leadId: "0NCnYxOP6V2Kvu2r9XHL"
    },
    {
        apptm: null,
        user: "jeremytan.iqi@gmail.com",
        files: null,
        userName: "Jeremy Tan",
        remark: "Send Exsim zoo video",
        created: {
            seconds: 1669881996,
            nanoseconds: 475000000
        },
        action: "Whatsapped",
        id: "saeWJ8qjLNyIO3Tl1X8I",
        path: "leads/0NCnYxOP6V2Kvu2r9XHL/actions/saeWJ8qjLNyIO3Tl1X8I",
        leadId: "0NCnYxOP6V2Kvu2r9XHL"
    },
    {
        apptm: null,
        action: "Called",
        remark: "Whatsapp talk, then cut off phone",
        user: "alan_yap1121@hotmail.com",
        created: {
            seconds: 1657018130,
            nanoseconds: 399000000
        },
        userName: "Alan Yap",
        files: null,
        id: "uKhyo3RLtFD5Yc6KcEhc",
        path: "leads/0NCnYxOP6V2Kvu2r9XHL/actions/uKhyo3RLtFD5Yc6KcEhc",
        leadId: "0NCnYxOP6V2Kvu2r9XHL"
    },
    {
        remark: "300k. Call her, she npu, n be busy tone",
        userName: "Summer Koh",
        files: null,
        apptm: null,
        created: {
            seconds: 1656588799,
            nanoseconds: 612000000
        },
        user: "kohpohfen@gmail.com",
        action: "Called",
        id: "yo3nLrBesB0ZW1KEYbPp",
        path: "leads/0NCnYxOP6V2Kvu2r9XHL/actions/yo3nLrBesB0ZW1KEYbPp",
        leadId: "0NCnYxOP6V2Kvu2r9XHL"
    },
    {
        user: "aidenlim950310@gmail.com",
        action: "Called",
        userName: "Aiden  Lim",
        leadId: "0NNFLU46N8I2Q79q447C",
        files: null,
        remark: "No need Ty ",
        created: {
            seconds: 1678181551,
            nanoseconds: 75000000
        },
        apptm: null,
        id: "AeeXEeakNRgmQcpUp0VB",
        path: "leads/0NNFLU46N8I2Q79q447C/actions/AeeXEeakNRgmQcpUp0VB"
    },
    {
        user: "williamffk@gmail.com",
        action: "Called",
        leadId: "0NNFLU46N8I2Q79q447C",
        files: null,
        created: {
            seconds: 1678367197,
            nanoseconds: 939000000
        },
        userName: "William Fam",
        apptm: null,
        remark: "Bought at klcc. Dont know which project. Didnt tell me",
        id: "BpvI5nXxKW7QJCsTXXs3",
        path: "leads/0NNFLU46N8I2Q79q447C/actions/BpvI5nXxKW7QJCsTXXs3"
    },
    {
        action: "Whatsapped",
        user: "jonathanchin0614@gmail.com",
        userName: "Jonathan Chin",
        created: {
            seconds: 1677857892,
            nanoseconds: 320000000
        },
        files: null,
        leadId: "0NNFLU46N8I2Q79q447C",
        remark: "Customer bought hugoz on tuesday 02/03",
        apptm: null,
        id: "Uh7bgRnrhi4awPjKbwE2",
        path: "leads/0NNFLU46N8I2Q79q447C/actions/Uh7bgRnrhi4awPjKbwE2"
    },
    {
        action: "Research",
        leadId: "0NNFLU46N8I2Q79q447C",
        files: null,
        apptm: null,
        created: {
            seconds: 1678605911,
            nanoseconds: 284000000
        },
        remark: "adi bought",
        userName: "Smun Lee",
        user: "sookmun770@gmail.com",
        id: "V6apk7YlBNkhAIb7kMYa",
        path: "leads/0NNFLU46N8I2Q79q447C/actions/V6apk7YlBNkhAIb7kMYa"
    },
    {
        remark: "买了",
        apptm: null,
        created: {
            seconds: 1678530937,
            nanoseconds: 978000000
        },
        leadId: "0NNFLU46N8I2Q79q447C",
        files: null,
        action: "Called",
        user: "seong31993199@gmail.com",
        userName: "Ting Seong Shew",
        id: "XrJ6JeTZnMX5A01WtY4o",
        path: "leads/0NNFLU46N8I2Q79q447C/actions/XrJ6JeTZnMX5A01WtY4o"
    },
    {
        files: null,
        action: "Research",
        remark: "call back tmr",
        leadId: "0NNFLU46N8I2Q79q447C",
        userName: "Smun Lee",
        apptm: null,
        created: {
            seconds: 1678001364,
            nanoseconds: 519000000
        },
        user: "sookmun770@gmail.com",
        id: "ckSH2rEOLqxileTVIR2w",
        path: "leads/0NNFLU46N8I2Q79q447C/actions/ckSH2rEOLqxileTVIR2w"
    },
    {
        user: "kohpohfen@gmail.com",
        leadId: "0NNFLU46N8I2Q79q447C",
        remark: "",
        userName: "Summer Koh",
        apptm: null,
        created: {
            seconds: 1677916347,
            nanoseconds: 609000000
        },
        action: "Whatsapped",
        files: null,
        id: "eVwwcks0s30fMzpkSMxx",
        path: "leads/0NNFLU46N8I2Q79q447C/actions/eVwwcks0s30fMzpkSMxx"
    },
    {
        files: null,
        action: "Whatsapped",
        user: "iqi.jimmyng@gmail.com",
        userName: "Jimmy Ng ",
        apptm: null,
        remark: "blast fiddlewoodz",
        created: {
            seconds: 1663936380,
            nanoseconds: 328000000
        },
        id: "2j0jolszBcND5KVq2Sos",
        path: "leads/0NRicE29adugZN6VkQ1g/actions/2j0jolszBcND5KVq2Sos",
        leadId: "0NRicE29adugZN6VkQ1g"
    },
    {
        user: "iqi.jimmyng@gmail.com",
        action: "Research",
        files: null,
        remark: "blast fiddlewoodz",
        userName: "Jimmy Ng ",
        created: {
            seconds: 1666355174,
            nanoseconds: 596000000
        },
        apptm: null,
        id: "9X02dQpTBrQB64SwAcIG",
        path: "leads/0NRicE29adugZN6VkQ1g/actions/9X02dQpTBrQB64SwAcIG",
        leadId: "0NRicE29adugZN6VkQ1g"
    },
    {
        user: "iqi.jimmyng@gmail.com",
        apptm: null,
        files: null,
        created: {
            seconds: 1661521196,
            nanoseconds: 355000000
        },
        remark: "asking for investment or ownstay",
        userName: "Jimmy Ng ",
        action: "Whatsapped",
        id: "AQLEkxtIEUoyHcOuJfbc",
        path: "leads/0NRicE29adugZN6VkQ1g/actions/AQLEkxtIEUoyHcOuJfbc",
        leadId: "0NRicE29adugZN6VkQ1g"
    },
    {
        userName: "Alan  Lai",
        files: null,
        action: "Called",
        remark: "NPU",
        user: "alanlaiproperty88@gmail.com",
        apptm: null,
        created: {
            seconds: 1661521242,
            nanoseconds: 764000000
        },
        id: "CAFbulBmIOoV3rfVOdY4",
        path: "leads/0NRicE29adugZN6VkQ1g/actions/CAFbulBmIOoV3rfVOdY4",
        leadId: "0NRicE29adugZN6VkQ1g"
    },
    {
        created: {
            seconds: 1661851941,
            nanoseconds: 684000000
        },
        userName: "Jimmy Ng ",
        action: "Called",
        apptm: null,
        files: null,
        remark: "looking ownstay for her daughter around PJ and her daughter 21 years old. next year graduate and start building profile. planning to use her daughter name or joint name. Stay around PJ,  have one investment property at setia alam and already 7 years, rental 800 but monthly installment  1.3k. ",
        user: "iqi.jimmyng@gmail.com",
        id: "EYDY79lbBjER99ErPeHZ",
        path: "leads/0NRicE29adugZN6VkQ1g/actions/EYDY79lbBjER99ErPeHZ",
        leadId: "0NRicE29adugZN6VkQ1g"
    },
    {
        files: null,
        created: {
            seconds: 1662103650,
            nanoseconds: 3000000
        },
        remark: "call later",
        userName: "Alan  Lai",
        action: "Called",
        apptm: null,
        user: "alanlaiproperty88@gmail.com",
        id: "Ft8X9XACbewN24g5RT52",
        path: "leads/0NRicE29adugZN6VkQ1g/actions/Ft8X9XACbewN24g5RT52",
        leadId: "0NRicE29adugZN6VkQ1g"
    },
    {
        userName: "Jimmy Ng ",
        remark: "blast hugoz",
        files: null,
        apptm: null,
        created: {
            seconds: 1663584297,
            nanoseconds: 836000000
        },
        action: "Whatsapped",
        user: "iqi.jimmyng@gmail.com",
        id: "KGBRwYnlYIriyDkZLJeq",
        path: "leads/0NRicE29adugZN6VkQ1g/actions/KGBRwYnlYIriyDkZLJeq",
        leadId: "0NRicE29adugZN6VkQ1g"
    },
    {
        remark: "NPU",
        userName: "Alan  Lai",
        files: null,
        apptm: null,
        created: {
            seconds: 1662471841,
            nanoseconds: 640000000
        },
        user: "alanlaiproperty88@gmail.com",
        action: "Called",
        id: "McnJk0nQTIBervf942av",
        path: "leads/0NRicE29adugZN6VkQ1g/actions/McnJk0nQTIBervf942av",
        leadId: "0NRicE29adugZN6VkQ1g"
    },
    {
        action: "Whatsapped",
        userName: "Jimmy Ng ",
        user: "iqi.jimmyng@gmail.com",
        files: null,
        remark: "blast projects ",
        apptm: null,
        created: {
            seconds: 1665560017,
            nanoseconds: 646000000
        },
        id: "NrkA5TW6EqQhhNZuy7gB",
        path: "leads/0NRicE29adugZN6VkQ1g/actions/NrkA5TW6EqQhhNZuy7gB",
        leadId: "0NRicE29adugZN6VkQ1g"
    },
    {
        apptm: null,
        created: {
            seconds: 1661513761,
            nanoseconds: 739000000
        },
        action: "Called",
        files: null,
        user: "desmondchongproperty21@gmail.com",
        userName: "Desmond  Chong",
        remark: "- Ms. Ana\n- Survey property for daughter, final year studying\n- daughter first home buyer \n- Stay nearby PJ ",
        id: "OErEhfGEjjEshMVVPMtP",
        path: "leads/0NRicE29adugZN6VkQ1g/actions/OErEhfGEjjEshMVVPMtP",
        leadId: "0NRicE29adugZN6VkQ1g"
    },
    {
        userName: "Alan  Lai",
        remark: "",
        user: "alanlaiproperty88@gmail.com",
        created: {
            seconds: 1665336622,
            nanoseconds: 168000000
        },
        action: "Whatsapped",
        files: null,
        apptm: null,
        id: "P8DJGTeJGaoRspmApLIT",
        path: "leads/0NRicE29adugZN6VkQ1g/actions/P8DJGTeJGaoRspmApLIT",
        leadId: "0NRicE29adugZN6VkQ1g"
    },
    {
        userName: "Jimmy Ng ",
        action: "Whatsapped",
        user: "iqi.jimmyng@gmail.com",
        files: null,
        apptm: null,
        created: {
            seconds: 1664957588,
            nanoseconds: 641000000
        },
        remark: "blast dcp ",
        id: "QD4ZjoxXaLP8RszRrS4D",
        path: "leads/0NRicE29adugZN6VkQ1g/actions/QD4ZjoxXaLP8RszRrS4D",
        leadId: "0NRicE29adugZN6VkQ1g"
    },
    {
        apptm: null,
        userName: "Jimmy Ng ",
        files: null,
        user: "iqi.jimmyng@gmail.com",
        created: {
            seconds: 1664370252,
            nanoseconds: 67000000
        },
        action: "Research",
        remark: "blast dcp",
        id: "RIST2wYb3v9PZyXgCXcL",
        path: "leads/0NRicE29adugZN6VkQ1g/actions/RIST2wYb3v9PZyXgCXcL",
        leadId: "0NRicE29adugZN6VkQ1g"
    },
    {
        created: {
            seconds: 1662286404,
            nanoseconds: 440000000
        },
        files: null,
        user: "iqi.jimmyng@gmail.com",
        userName: "Jimmy Ng ",
        action: "Whatsapped",
        remark: "blast mossaz",
        apptm: null,
        id: "SS0qLA35kSI42LEKizVB",
        path: "leads/0NRicE29adugZN6VkQ1g/actions/SS0qLA35kSI42LEKizVB",
        leadId: "0NRicE29adugZN6VkQ1g"
    },
    {
        user: "iqi.jimmyng@gmail.com",
        userName: "Jimmy Ng ",
        action: "Whatsapped",
        created: {
            seconds: 1662041989,
            nanoseconds: 885000000
        },
        apptm: null,
        remark: "blast dcp",
        files: null,
        id: "TzVB03J3wgSLkxY80DpX",
        path: "leads/0NRicE29adugZN6VkQ1g/actions/TzVB03J3wgSLkxY80DpX",
        leadId: "0NRicE29adugZN6VkQ1g"
    },
    {
        action: "Called",
        userName: "Alan  Lai",
        apptm: null,
        files: null,
        user: "alanlaiproperty88@gmail.com",
        remark: "NPU",
        created: {
            seconds: 1661516262,
            nanoseconds: 916000000
        },
        id: "UZ5uFg6sEm5hCih2iqXe",
        path: "leads/0NRicE29adugZN6VkQ1g/actions/UZ5uFg6sEm5hCih2iqXe",
        leadId: "0NRicE29adugZN6VkQ1g"
    },
    {
        userName: "Jimmy Ng ",
        action: "Called",
        created: {
            seconds: 1663935474,
            nanoseconds: 673000000
        },
        files: null,
        user: "iqi.jimmyng@gmail.com",
        apptm: null,
        remark: "she owned tailor factory business but planning to retired because not enough worker. she and her daughter busy. when she free will contact me for viewing",
        id: "V0rEqi9ZoC2Da6SjaCbh",
        path: "leads/0NRicE29adugZN6VkQ1g/actions/V0rEqi9ZoC2Da6SjaCbh",
        leadId: "0NRicE29adugZN6VkQ1g"
    },
    {
        apptm: null,
        user: "alanlaiproperty88@gmail.com",
        created: {
            seconds: 1662722959,
            nanoseconds: 341000000
        },
        action: "Called",
        userName: "Alan  Lai",
        files: null,
        remark: "this week not free, 20 多号才得空",
        id: "XP5gpJzCeMWphnPr2IGN",
        path: "leads/0NRicE29adugZN6VkQ1g/actions/XP5gpJzCeMWphnPr2IGN",
        leadId: "0NRicE29adugZN6VkQ1g"
    },
    {
        files: null,
        user: "cindy_pang91@hotmail.com",
        action: "Research",
        apptm: null,
        userName: "Pang  Yy",
        remark: "",
        created: {
            seconds: 1663153368,
            nanoseconds: 859000000
        },
        id: "XbctxvMtUwITXM39So29",
        path: "leads/0NRicE29adugZN6VkQ1g/actions/XbctxvMtUwITXM39So29",
        leadId: "0NRicE29adugZN6VkQ1g"
    },
    {
        files: null,
        user: "iqi.jimmyng@gmail.com",
        action: "Whatsapped",
        apptm: null,
        userName: "Jimmy Ng ",
        remark: "blast projects",
        created: {
            seconds: 1666793908,
            nanoseconds: 56000000
        },
        id: "dADHRiZa5uukf0HcDFWw",
        path: "leads/0NRicE29adugZN6VkQ1g/actions/dADHRiZa5uukf0HcDFWw",
        leadId: "0NRicE29adugZN6VkQ1g"
    },
    {
        apptm: null,
        action: "Whatsapped",
        remark: "NPU",
        files: null,
        created: {
            seconds: 1662786930,
            nanoseconds: 230000000
        },
        userName: "Alan  Lai",
        user: "alanlaiproperty88@gmail.com",
        id: "fGwMaNIS1q7NqlfgaSMI",
        path: "leads/0NRicE29adugZN6VkQ1g/actions/fGwMaNIS1q7NqlfgaSMI",
        leadId: "0NRicE29adugZN6VkQ1g"
    },
    {
        user: "alanlaiproperty88@gmail.com",
        files: null,
        remark: "",
        created: {
            seconds: 1661185226,
            nanoseconds: 336000000
        },
        apptm: null,
        action: "Whatsapped",
        userName: "Alan  Lai",
        id: "fwLEqQWHoab5qalFr6hM",
        path: "leads/0NRicE29adugZN6VkQ1g/actions/fwLEqQWHoab5qalFr6hM",
        leadId: "0NRicE29adugZN6VkQ1g"
    },
    {
        action: "Called",
        apptm: null,
        remark: "Anna, kepong ren, buy for daughter 21 years old, almost graduate, survey liao lake city, also got park, also township, study at sunway, ",
        userName: "Alan  Lai",
        user: "alanlaiproperty88@gmail.com",
        created: {
            seconds: 1661177707,
            nanoseconds: 345000000
        },
        files: null,
        id: "iqUDWY7vjLrwnNYSp8ZP",
        path: "leads/0NRicE29adugZN6VkQ1g/actions/iqUDWY7vjLrwnNYSp8ZP",
        leadId: "0NRicE29adugZN6VkQ1g"
    },
    {
        apptm: null,
        created: {
            seconds: 1662624488,
            nanoseconds: 867000000
        },
        files: null,
        user: "iqi.jimmyng@gmail.com",
        userName: "Jimmy Ng ",
        remark: "blast fiddlewoodz",
        action: "Whatsapped",
        id: "krQxwveZQNAVecZ2Hxy2",
        path: "leads/0NRicE29adugZN6VkQ1g/actions/krQxwveZQNAVecZ2Hxy2",
        leadId: "0NRicE29adugZN6VkQ1g"
    },
    {
        apptm: null,
        user: "iqi.jimmyng@gmail.com",
        remark: "blast divo",
        action: "Whatsapped",
        created: {
            seconds: 1663075175,
            nanoseconds: 890000000
        },
        userName: "Jimmy Ng ",
        files: null,
        id: "paeJLjpb0sQ2q6XA1DOT",
        path: "leads/0NRicE29adugZN6VkQ1g/actions/paeJLjpb0sQ2q6XA1DOT",
        leadId: "0NRicE29adugZN6VkQ1g"
    },
    {
        remark: "",
        action: "Whatsapped",
        apptm: null,
        userName: "Summer Koh",
        files: null,
        user: "kohpohfen@gmail.com",
        created: {
            seconds: 1661160462,
            nanoseconds: 945000000
        },
        id: "puMUNalRjM7xv7IQXFXQ",
        path: "leads/0NRicE29adugZN6VkQ1g/actions/puMUNalRjM7xv7IQXFXQ",
        leadId: "0NRicE29adugZN6VkQ1g"
    },
    {
        userName: "Jimmy Ng ",
        action: "Research",
        created: {
            seconds: 1662811536,
            nanoseconds: 661000000
        },
        user: "iqi.jimmyng@gmail.com",
        apptm: null,
        remark: "blast moon festival\n",
        files: null,
        id: "rLLngUq2cDSGwqtLypFB",
        path: "leads/0NRicE29adugZN6VkQ1g/actions/rLLngUq2cDSGwqtLypFB",
        leadId: "0NRicE29adugZN6VkQ1g"
    },
    {
        files: null,
        user: "iqi.jimmyng@gmail.com",
        apptm: null,
        created: {
            seconds: 1661521146,
            nanoseconds: 620000000
        },
        remark: "no pick up",
        action: "Called",
        userName: "Jimmy Ng ",
        id: "tm9OK5i2yu6Wwl3P4zaB",
        path: "leads/0NRicE29adugZN6VkQ1g/actions/tm9OK5i2yu6Wwl3P4zaB",
        leadId: "0NRicE29adugZN6VkQ1g"
    },
    {
        userName: "Jimmy Ng ",
        user: "iqi.jimmyng@gmail.com",
        apptm: null,
        created: {
            seconds: 1668170646,
            nanoseconds: 821000000
        },
        files: null,
        action: "Whatsapped",
        remark: "blast exsim ",
        id: "xzwttlgxhpr5wmEGjZbj",
        path: "leads/0NRicE29adugZN6VkQ1g/actions/xzwttlgxhpr5wmEGjZbj",
        leadId: "0NRicE29adugZN6VkQ1g"
    },
    {
        action: "Whatsapped",
        apptm: null,
        created: {
            seconds: 1662546543,
            nanoseconds: 687000000
        },
        remark: "及时得空可以带你的女儿来PJ showroom走一走啊",
        user: "iqi.jimmyng@gmail.com",
        files: null,
        userName: "Jimmy Ng ",
        id: "yyIMckNoLyjAjyvnm8wP",
        path: "leads/0NRicE29adugZN6VkQ1g/actions/yyIMckNoLyjAjyvnm8wP",
        leadId: "0NRicE29adugZN6VkQ1g"
    },
    {
        files: null,
        user: "hanst9803@gmail.com",
        action: "Called",
        created: {
            seconds: 1636980432,
            nanoseconds: 822000000
        },
        remark: "Not interested",
        apptm: null,
        id: "CAi1bmHlffJ2Rj7vOASP",
        path: "leads/0NdHIWcYhjHtw1BKR2U9/actions/CAi1bmHlffJ2Rj7vOASP",
        leadId: "0NdHIWcYhjHtw1BKR2U9"
    },
    {
        userName: "Summer Koh",
        created: {
            seconds: 1675745957,
            nanoseconds: 814000000
        },
        user: "kohpohfen@gmail.com",
        remark: "2 properties landed in johor, 1 under his name\nLoan ,2 loan under ur name. 3rd property. With wife. Weekend stay, old want come in kl. Ownstay long term in retirement\n\nFreehold, easy rent out as air bnb. Wife buy in ampang. 32 years old. Retire 65 years. Leasehold okay. Want easy go bukit bintang. Sgd 10k for both husband and wife\n\nHe aim sometimes stay when retire at age of 60/65. \n\nOur current commitment is\nRm 2100\nRm 1600\n\nWife like to go bukit bintang by mrt\n\nGt children, they cn stay in their johor property\n\nWife buy property ampang 2xxk cheap , easy rent",
        action: "Research",
        files: null,
        apptm: null,
        id: "8ng4M9tNo8lDnFjB3ijo",
        path: "leads/0OLIFCTlonhhAhaFShco/actions/8ng4M9tNo8lDnFjB3ijo",
        leadId: "0OLIFCTlonhhAhaFShco"
    },
    {
        files: null,
        remark: "Date: 2023-02-09 | Time: 10:00 | Venue: Zoom arte | Language: undefined | Budget: undefined | Intention: undefined",
        userName: "Summer Koh",
        action: "Appointment",
        user: "kohpohfen@gmail.com",
        created: {
            seconds: 1675745955,
            nanoseconds: 649000000
        },
        apptm: {
            venue: "Zoom arte",
            time: "10:00",
            user: "kohpohfen@gmail.com",
            date: {
                seconds: 1675900800,
                nanoseconds: 0
            }
        },
        id: "IpLQacWpDdJhfqnRud3X",
        path: "leads/0OLIFCTlonhhAhaFShco/actions/IpLQacWpDdJhfqnRud3X",
        leadId: "0OLIFCTlonhhAhaFShco"
    },
    {
        user: "kellywongyikei@gmail.com",
        userName: "Kelly Wong",
        remark: "WhatsApp-ed",
        action: "Called",
        files: null,
        apptm: null,
        created: {
            seconds: 1680866995,
            nanoseconds: 317000000
        },
        leadId: "0OLIFCTlonhhAhaFShco",
        id: "R5YA5XzJUig3vBACG15q",
        path: "leads/0OLIFCTlonhhAhaFShco/actions/R5YA5XzJUig3vBACG15q"
    },
    {
        created: {
            seconds: 1677388113,
            nanoseconds: 208000000
        },
        remark: "npu",
        userName: "Smun Lee",
        action: "Research",
        apptm: null,
        files: null,
        leadId: "0OLIFCTlonhhAhaFShco",
        user: "sookmun770@gmail.com",
        id: "R7vWARINO99FVs07B1Lx",
        path: "leads/0OLIFCTlonhhAhaFShco/actions/R7vWARINO99FVs07B1Lx"
    },
    {
        user: "kohpohfen@gmail.com",
        files: null,
        userName: "Summer Koh",
        remark: "",
        action: "Whatsapped",
        apptm: null,
        created: {
            seconds: 1658233761,
            nanoseconds: 395000000
        },
        id: "ZnG0bEc1AtAllJdArwvf",
        path: "leads/0ONam4cYp2ryNSaDy10c/actions/ZnG0bEc1AtAllJdArwvf",
        leadId: "0ONam4cYp2ryNSaDy10c"
    },
    {
        apptm: null,
        userName: "Summer Koh",
        user: "kohpohfen@gmail.com",
        files: null,
        created: {
            seconds: 1658233746,
            nanoseconds: 362000000
        },
        action: "Called",
        remark: "",
        id: "kAN19u2Ckzb6t8tIDAs4",
        path: "leads/0ONam4cYp2ryNSaDy10c/actions/kAN19u2Ckzb6t8tIDAs4",
        leadId: "0ONam4cYp2ryNSaDy10c"
    },
    {
        action: "Research",
        remark: "",
        apptm: null,
        created: {
            seconds: 1658233745,
            nanoseconds: 290000000
        },
        user: "kohpohfen@gmail.com",
        userName: "Summer Koh",
        files: null,
        id: "puq94RdmAZj1YRzfr7zy",
        path: "leads/0ONam4cYp2ryNSaDy10c/actions/puq94RdmAZj1YRzfr7zy",
        leadId: "0ONam4cYp2ryNSaDy10c"
    },
    {
        apptm: null,
        action: "Called",
        user: "ckkey08181@gmail.com",
        remark: "",
        files: null,
        created: {
            seconds: 1638969827,
            nanoseconds: 18000000
        },
        id: "6HrPWjT9y3cSvPEhLkOW",
        path: "leads/0ONpfBiQrwuBq3F6yMiw/actions/6HrPWjT9y3cSvPEhLkOW",
        leadId: "0ONpfBiQrwuBq3F6yMiw"
    },
    {
        apptm: null,
        user: "ckkey08181@gmail.com",
        remark: "No plan",
        action: "Called",
        files: null,
        created: {
            seconds: 1638970255,
            nanoseconds: 136000000
        },
        id: "HqtAHaATADEniqlQZr4u",
        path: "leads/0ONpfBiQrwuBq3F6yMiw/actions/HqtAHaATADEniqlQZr4u",
        leadId: "0ONpfBiQrwuBq3F6yMiw"
    },
    {
        user: "ckkey08181@gmail.com",
        files: null,
        remark: "NPU",
        created: {
            seconds: 1638969903,
            nanoseconds: 47000000
        },
        apptm: null,
        action: "Called",
        id: "VgwjKk91UsAisWv8JKLp",
        path: "leads/0ONpfBiQrwuBq3F6yMiw/actions/VgwjKk91UsAisWv8JKLp",
        leadId: "0ONpfBiQrwuBq3F6yMiw"
    },
    {
        remark: "",
        apptm: null,
        action: "Whatsapped",
        user: "ckkey08181@gmail.com",
        created: {
            seconds: 1638969823,
            nanoseconds: 692000000
        },
        files: null,
        id: "Wl2TZiALfkUJGzXxq5Cc",
        path: "leads/0ONpfBiQrwuBq3F6yMiw/actions/Wl2TZiALfkUJGzXxq5Cc",
        leadId: "0ONpfBiQrwuBq3F6yMiw"
    },
    {
        action: "Research",
        files: null,
        apptm: null,
        user: "vernicelee2621@gmail.com",
        remark: "Ady bought Puchong project",
        created: {
            seconds: 1637759091,
            nanoseconds: 744000000
        },
        id: "fPvsj0JgHS36V3GtGFnp",
        path: "leads/0ONpfBiQrwuBq3F6yMiw/actions/fPvsj0JgHS36V3GtGFnp",
        leadId: "0ONpfBiQrwuBq3F6yMiw"
    },
    {
        action: "Whatsapped",
        remark: "",
        user: "alextai0517@gmail.com",
        apptm: null,
        created: {
            seconds: 1636364541,
            nanoseconds: 81000000
        },
        files: null,
        id: "sVzTgCw2TGJQsVjcuQpn",
        path: "leads/0ONpfBiQrwuBq3F6yMiw/actions/sVzTgCw2TGJQsVjcuQpn",
        leadId: "0ONpfBiQrwuBq3F6yMiw"
    },
    {
        apptm: null,
        user: "vernicelee2621@gmail.com",
        created: {
            seconds: 1637759041,
            nanoseconds: 357000000
        },
        action: "Called",
        remark: "",
        files: null,
        id: "zLerp0XxddEKxIPU9ez5",
        path: "leads/0ONpfBiQrwuBq3F6yMiw/actions/zLerp0XxddEKxIPU9ez5",
        leadId: "0ONpfBiQrwuBq3F6yMiw"
    },
    {
        action: "Research",
        apptm: null,
        user: "kohpohfen@gmail.com",
        created: {
            seconds: 1652966829,
            nanoseconds: 219000000
        },
        userName: "Summer Koh",
        files: null,
        remark: "Saw tropika himself by walkin showroom. Know bloomsvale say jam.     Fren at subang. ",
        id: "7LNvo7S1dhni059AjRms",
        path: "leads/0ORPzcjXXS1H8Vgp9KkK/actions/7LNvo7S1dhni059AjRms",
        leadId: "0ORPzcjXXS1H8Vgp9KkK"
    },
    {
        apptm: null,
        remark: "alira metroprk, fren at subang. stayed exsim before.. ",
        userName: "Summer Koh",
        files: null,
        action: "Research",
        created: {
            seconds: 1652965242,
            nanoseconds: 751000000
        },
        user: "kohpohfen@gmail.com",
        id: "GFGNsd38wS5C0EROAsMB",
        path: "leads/0ORPzcjXXS1H8Vgp9KkK/actions/GFGNsd38wS5C0EROAsMB",
        leadId: "0ORPzcjXXS1H8Vgp9KkK"
    },
    {
        userName: "Summer Koh",
        created: {
            seconds: 1652965373,
            nanoseconds: 87000000
        },
        remark: "Date: 2022-05-21 | Time: 18:00 | Venue: actual | Language: chinese | Budget: undefined | Intention: millerz",
        files: null,
        user: "kohpohfen@gmail.com",
        action: "Appointment",
        apptm: {
            intention: "millerz",
            time: "18:00",
            language: "chinese",
            date: {
                seconds: 1653091200,
                nanoseconds: 0
            },
            venue: "actual",
            user: "kohpohfen@gmail.com"
        },
        id: "Ixcx920o4atzbqBYc1Ab",
        path: "leads/0ORPzcjXXS1H8Vgp9KkK/actions/Ixcx920o4atzbqBYc1Ab",
        leadId: "0ORPzcjXXS1H8Vgp9KkK"
    },
    {
        user: "alexchia320@gmail.com",
        action: "Whatsapped",
        userName: "Alexander Chia",
        remark: "",
        created: {
            seconds: 1652669849,
            nanoseconds: 411000000
        },
        files: null,
        apptm: null,
        id: "VQyYZU4oF0MwAtaXsD0T",
        path: "leads/0ORPzcjXXS1H8Vgp9KkK/actions/VQyYZU4oF0MwAtaXsD0T",
        leadId: "0ORPzcjXXS1H8Vgp9KkK"
    },
    {
        apptm: null,
        remark: "Npu",
        user: "alexchia320@gmail.com",
        action: "Called",
        created: {
            seconds: 1652506479,
            nanoseconds: 148000000
        },
        files: null,
        userName: "Alexander Chia",
        id: "VqmkIdfd3TFhaj0aY6lG",
        path: "leads/0ORPzcjXXS1H8Vgp9KkK/actions/VqmkIdfd3TFhaj0aY6lG",
        leadId: "0ORPzcjXXS1H8Vgp9KkK"
    },
    {
        user: "zzhenghoong96@gmail.com",
        files: null,
        apptm: null,
        action: "Whatsapped",
        created: {
            seconds: 1669868507,
            nanoseconds: 778000000
        },
        remark: "",
        userName: "Zheng Hoong Tan",
        id: "WDeXQWrn5lVvjc13m6yO",
        path: "leads/0ORPzcjXXS1H8Vgp9KkK/actions/WDeXQWrn5lVvjc13m6yO",
        leadId: "0ORPzcjXXS1H8Vgp9KkK"
    },
    {
        action: "Appointment",
        user: "kohpohfen@gmail.com",
        userName: "Summer Koh",
        remark: "Date: 2022-05-21 | Time: 18:00 | Venue: actual | Language: chinese | Budget: undefined | Intention: millerz",
        files: null,
        created: {
            seconds: 1652965368,
            nanoseconds: 835000000
        },
        apptm: {
            time: "18:00",
            date: {
                seconds: 1653091200,
                nanoseconds: 0
            },
            venue: "actual",
            language: "chinese",
            user: "kohpohfen@gmail.com",
            intention: "millerz"
        },
        id: "dxvlwPfEIWCwkiLfQAqL",
        path: "leads/0ORPzcjXXS1H8Vgp9KkK/actions/dxvlwPfEIWCwkiLfQAqL",
        leadId: "0ORPzcjXXS1H8Vgp9KkK"
    },
    {
        apptm: null,
        created: {
            seconds: 1652256869,
            nanoseconds: 906000000
        },
        user: "alan_yap1121@hotmail.com",
        files: null,
        action: "Whatsapped",
        userName: "Alan Yap",
        remark: "",
        id: "jBfrf3ePOdHWMoVwhwv8",
        path: "leads/0ORPzcjXXS1H8Vgp9KkK/actions/jBfrf3ePOdHWMoVwhwv8",
        leadId: "0ORPzcjXXS1H8Vgp9KkK"
    },
    {
        user: "kohpohfen@gmail.com",
        created: {
            seconds: 1652169529,
            nanoseconds: 457000000
        },
        files: null,
        remark: "Want move in july. Ask me damai residence. Ownstay + invest. Say millerz small. Enterprise no do tax",
        action: "Research",
        apptm: null,
        userName: "Summer Koh",
        id: "t2m7KCI8FQJrwxgeYYVg",
        path: "leads/0ORPzcjXXS1H8Vgp9KkK/actions/t2m7KCI8FQJrwxgeYYVg",
        leadId: "0ORPzcjXXS1H8Vgp9KkK"
    },
    {
        created: {
            seconds: 1652962059,
            nanoseconds: 737000000
        },
        user: "kohpohfen@gmail.com",
        remark: "no like dcp.  wn ner eco city. interested millerz",
        files: null,
        userName: "Summer Koh",
        action: "Research",
        apptm: null,
        id: "woSkj0sUqWV9m2CCV3t7",
        path: "leads/0ORPzcjXXS1H8Vgp9KkK/actions/woSkj0sUqWV9m2CCV3t7",
        leadId: "0ORPzcjXXS1H8Vgp9KkK"
    },
    {
        files: null,
        remark: "Booked and processing loan for a Damai area unit property ",
        action: "Called",
        created: {
            seconds: 1655474277,
            nanoseconds: 564000000
        },
        apptm: null,
        userName: "Zheng Hoong Tan",
        user: "zzhenghoong96@gmail.com",
        id: "wqqZh31hZzlvuomu1h1y",
        path: "leads/0ORPzcjXXS1H8Vgp9KkK/actions/wqqZh31hZzlvuomu1h1y",
        leadId: "0ORPzcjXXS1H8Vgp9KkK"
    },
    {
        remark: "Pass Back",
        action: "Research",
        userName: "Zheng Hoong Tan",
        files: null,
        apptm: null,
        created: {
            seconds: 1655805916,
            nanoseconds: 832000000
        },
        user: "zzhenghoong96@gmail.com",
        id: "xtatNFKRjU53eQQqyVe1",
        path: "leads/0ORPzcjXXS1H8Vgp9KkK/actions/xtatNFKRjU53eQQqyVe1",
        leadId: "0ORPzcjXXS1H8Vgp9KkK"
    },
    {
        files: null,
        action: "Whatsapped",
        remark: "",
        user: "gnelol8895@gmail.com",
        apptm: null,
        userName: "Gene Lee",
        created: {
            seconds: 1673441186,
            nanoseconds: 847000000
        },
        id: "3BdAU0QYzJjo1w5mlup4",
        path: "leads/0PNpbo11K6yjKJXZZV3T/actions/3BdAU0QYzJjo1w5mlup4",
        leadId: "0PNpbo11K6yjKJXZZV3T"
    },
    {
        apptm: null,
        user: "gnelol8895@gmail.com",
        remark: "在驾车，粗人，挂电话",
        files: null,
        created: {
            seconds: 1673422163,
            nanoseconds: 465000000
        },
        userName: "Gene Lee",
        action: "Called",
        id: "N8MnQaGHY0zIkB2IomBf",
        path: "leads/0PNpbo11K6yjKJXZZV3T/actions/N8MnQaGHY0zIkB2IomBf",
        leadId: "0PNpbo11K6yjKJXZZV3T"
    },
    {
        apptm: null,
        files: null,
        user: "kohpohfen@gmail.com",
        userName: "Summer Koh",
        action: "Whatsapped",
        created: {
            seconds: 1675235323,
            nanoseconds: 535000000
        },
        remark: "",
        id: "Ulgx6qdLTAzQjql89gci",
        path: "leads/0PNpbo11K6yjKJXZZV3T/actions/Ulgx6qdLTAzQjql89gci",
        leadId: "0PNpbo11K6yjKJXZZV3T"
    },
    {
        created: {
            seconds: 1673441202,
            nanoseconds: 886000000
        },
        action: "Whatsapped",
        remark: "",
        apptm: null,
        userName: "Gene Lee",
        user: "gnelol8895@gmail.com",
        files: null,
        id: "irK7GAEYfg4mNStFXClf",
        path: "leads/0PNpbo11K6yjKJXZZV3T/actions/irK7GAEYfg4mNStFXClf",
        leadId: "0PNpbo11K6yjKJXZZV3T"
    },
    {
        userName: "Jeremy Tan",
        created: {
            seconds: 1676986897,
            nanoseconds: 342000000
        },
        remark: "He said at outside call back later ",
        apptm: null,
        files: null,
        action: "Called",
        user: "jeremytan.iqi@gmail.com",
        leadId: "0PNpbo11K6yjKJXZZV3T",
        id: "ldqjf3U4GnI6fV9Jlsjq",
        path: "leads/0PNpbo11K6yjKJXZZV3T/actions/ldqjf3U4GnI6fV9Jlsjq"
    },
    {
        files: null,
        remark: "bought genting project",
        action: "Research",
        apptm: null,
        leadId: "0PNpbo11K6yjKJXZZV3T",
        userName: "Smun Lee",
        created: {
            seconds: 1678610446,
            nanoseconds: 857000000
        },
        user: "sookmun770@gmail.com",
        id: "olsFJrm1J582px5Pz2Fx",
        path: "leads/0PNpbo11K6yjKJXZZV3T/actions/olsFJrm1J582px5Pz2Fx"
    },
    {
        action: "Called",
        remark: "He said he bought Genting for Airbnb de purpose try xia now driving later call back ",
        created: {
            seconds: 1676448359,
            nanoseconds: 73000000
        },
        userName: "Jeremy Tan",
        user: "jeremytan.iqi@gmail.com",
        apptm: null,
        files: null,
        id: "wM9EPgTQoKT07dyIBGrF",
        path: "leads/0PNpbo11K6yjKJXZZV3T/actions/wM9EPgTQoKT07dyIBGrF",
        leadId: "0PNpbo11K6yjKJXZZV3T"
    },
    {
        created: {
            seconds: 1665662437,
            nanoseconds: 319000000
        },
        files: null,
        remark: "",
        userName: "Kaycee Chong",
        user: "kaycee.eliteone@gmail.com",
        apptm: null,
        action: "Called",
        id: "Q73RUK8NhRj0slqQV47Q",
        path: "leads/0PR9RJ4hGiC4Lg6HihDE/actions/Q73RUK8NhRj0slqQV47Q",
        leadId: "0PR9RJ4hGiC4Lg6HihDE"
    },
    {
        files: null,
        created: {
            seconds: 1670500601,
            nanoseconds: 880000000
        },
        action: "Whatsapped",
        apptm: null,
        user: "vinzchau00@gmail.com",
        remark: "client ask me drop whatsapp dulu ,she busy now",
        userName: "Vinz Chau",
        id: "1IVSbMH1hAz8B06UJYfq",
        path: "leads/0QXqpkyKAnmnLxx36C4R/actions/1IVSbMH1hAz8B06UJYfq",
        leadId: "0QXqpkyKAnmnLxx36C4R"
    },
    {
        userName: "Smun Lee",
        apptm: null,
        action: "Called",
        files: null,
        user: "sookmun770@gmail.com",
        remark: "Busy ",
        created: {
            seconds: 1670136135,
            nanoseconds: 894000000
        },
        id: "FLHUl4G5RXHZsgVL1EfE",
        path: "leads/0QXqpkyKAnmnLxx36C4R/actions/FLHUl4G5RXHZsgVL1EfE",
        leadId: "0QXqpkyKAnmnLxx36C4R"
    },
    {
        created: {
            seconds: 1679478749,
            nanoseconds: 153000000
        },
        action: "Called",
        user: "seong31993199@gmail.com",
        apptm: null,
        remark: "NPU",
        leadId: "0QXqpkyKAnmnLxx36C4R",
        files: null,
        userName: "Ting Seong Shew",
        id: "LefSXzKLVojMNhA9dYj3",
        path: "leads/0QXqpkyKAnmnLxx36C4R/actions/LefSXzKLVojMNhA9dYj3"
    },
    {
        created: {
            seconds: 1680324722,
            nanoseconds: 902000000
        },
        apptm: null,
        remark: "",
        action: "Whatsapped",
        files: null,
        userName: "Ting Seong Shew",
        leadId: "0QXqpkyKAnmnLxx36C4R",
        user: "seong31993199@gmail.com",
        id: "Suj9LQjf1jDLerZ2vCZn",
        path: "leads/0QXqpkyKAnmnLxx36C4R/actions/Suj9LQjf1jDLerZ2vCZn"
    },
    {
        files: null,
        created: {
            seconds: 1670244353,
            nanoseconds: 912000000
        },
        action: "Called",
        userName: "Wilson Tan",
        user: "wilsontan00@gmail.com",
        apptm: null,
        remark: "",
        id: "gyhaTul0ILzUWNjt9z5w",
        path: "leads/0QXqpkyKAnmnLxx36C4R/actions/gyhaTul0ILzUWNjt9z5w",
        leadId: "0QXqpkyKAnmnLxx36C4R"
    },
    {
        files: null,
        apptm: null,
        leadId: "0QXqpkyKAnmnLxx36C4R",
        user: "seong31993199@gmail.com",
        created: {
            seconds: 1680324675,
            nanoseconds: 857000000
        },
        userName: "Ting Seong Shew",
        action: "Called",
        remark: "NPU",
        id: "niT0cIw1IWLMclsYeE4Q",
        path: "leads/0QXqpkyKAnmnLxx36C4R/actions/niT0cIw1IWLMclsYeE4Q"
    },
    {
        files: null,
        user: "jonathanchin0614@gmail.com",
        userName: "Jonathan Chin",
        remark: "",
        leadId: "0QXqpkyKAnmnLxx36C4R",
        apptm: null,
        created: {
            seconds: 1679493233,
            nanoseconds: 336000000
        },
        action: "Whatsapped",
        id: "pFfanq1hlQpdCOP89dKp",
        path: "leads/0QXqpkyKAnmnLxx36C4R/actions/pFfanq1hlQpdCOP89dKp"
    },
    {
        apptm: null,
        leadId: "0QXqpkyKAnmnLxx36C4R",
        action: "Whatsapped",
        remark: "",
        files: null,
        user: "seong31993199@gmail.com",
        userName: "Ting Seong Shew",
        created: {
            seconds: 1679389211,
            nanoseconds: 853000000
        },
        id: "t2DIwd83LWTTbECLwTYE",
        path: "leads/0QXqpkyKAnmnLxx36C4R/actions/t2DIwd83LWTTbECLwTYE"
    },
    {
        apptm: null,
        created: {
            seconds: 1648703133,
            nanoseconds: 355000000
        },
        remark: "Called ",
        userName: "James  On",
        action: "Research",
        files: null,
        user: "kaiyegolf@gmail.com",
        id: "KfV0FTzVuQPohc3KDkS5",
        path: "leads/0QphxShX23h0s3DtBaO6/actions/KfV0FTzVuQPohc3KDkS5",
        leadId: "0QphxShX23h0s3DtBaO6"
    },
    {
        userName: "Mun Hong Yip",
        apptm: null,
        user: "munhong0909@gmail.com",
        remark: "np",
        files: null,
        action: "Emailed",
        created: {
            seconds: 1650613396,
            nanoseconds: 250000000
        },
        id: "aa9kdl1aov9ptibrQUBx",
        path: "leads/0RAEf8mx4DlLLGB9uNpd/actions/aa9kdl1aov9ptibrQUBx",
        leadId: "0RAEf8mx4DlLLGB9uNpd"
    },
    {
        apptm: null,
        action: "Called",
        leadId: "0T3et7BnaRj7M7VOnudJ",
        userName: "Aiden  Lim",
        user: "aidenlim950310@gmail.com",
        files: null,
        created: {
            seconds: 1683108292,
            nanoseconds: 602000000
        },
        remark: "Na",
        id: "7ctBE0Y4icK9MATh7REU",
        path: "leads/0T3et7BnaRj7M7VOnudJ/actions/7ctBE0Y4icK9MATh7REU"
    },
    {
        remark: "Na",
        user: "aidenlim950310@gmail.com",
        apptm: null,
        files: null,
        action: "Called",
        leadId: "0T3et7BnaRj7M7VOnudJ",
        created: {
            seconds: 1682686915,
            nanoseconds: 735000000
        },
        userName: "Aiden  Lim",
        id: "LPBnLyuHWnueUJQf44mL",
        path: "leads/0T3et7BnaRj7M7VOnudJ/actions/LPBnLyuHWnueUJQf44mL"
    },
    {
        files: null,
        remark: "npu",
        leadId: "0T3et7BnaRj7M7VOnudJ",
        apptm: null,
        user: "sookmun770@gmail.com",
        action: "Called",
        userName: "Smun Lee",
        created: {
            seconds: 1683290258,
            nanoseconds: 860000000
        },
        id: "mFbs9PZWcGwpxGeknQEM",
        path: "leads/0T3et7BnaRj7M7VOnudJ/actions/mFbs9PZWcGwpxGeknQEM"
    },
    {
        action: "Research",
        files: null,
        userName: "Pang  Yy",
        created: {
            seconds: 1663578232,
            nanoseconds: 945000000
        },
        apptm: null,
        remark: "npu",
        user: "cindy_pang91@hotmail.com",
        id: "0uWtAFZ50eLjPkUEQMsE",
        path: "leads/0T6vASWjyZGPUg3GzYvU/actions/0uWtAFZ50eLjPkUEQMsE",
        leadId: "0T6vASWjyZGPUg3GzYvU"
    },
    {
        user: "cindy_pang91@hotmail.com",
        action: "Research",
        apptm: null,
        userName: "Pang  Yy",
        created: {
            seconds: 1663158438,
            nanoseconds: 859000000
        },
        remark: "NPU",
        files: null,
        id: "20wNwvRDDKd0gN0OjXwm",
        path: "leads/0T6vASWjyZGPUg3GzYvU/actions/20wNwvRDDKd0gN0OjXwm",
        leadId: "0T6vASWjyZGPUg3GzYvU"
    },
    {
        user: "cindy_pang91@hotmail.com",
        files: null,
        apptm: null,
        userName: "Pang  Yy",
        action: "Research",
        created: {
            seconds: 1665576077,
            nanoseconds: 695000000
        },
        remark: "blast",
        id: "BvQXW61V6ogqzCIJ7uYE",
        path: "leads/0T6vASWjyZGPUg3GzYvU/actions/BvQXW61V6ogqzCIJ7uYE",
        leadId: "0T6vASWjyZGPUg3GzYvU"
    },
    {
        created: {
            seconds: 1665656499,
            nanoseconds: 138000000
        },
        userName: "Alan  Lai",
        files: null,
        remark: "NPU X2",
        apptm: null,
        action: "Called",
        user: "alanlaiproperty88@gmail.com",
        id: "DWpSQ07s0hGJcICHLHdV",
        path: "leads/0T6vASWjyZGPUg3GzYvU/actions/DWpSQ07s0hGJcICHLHdV",
        leadId: "0T6vASWjyZGPUg3GzYvU"
    },
    {
        created: {
            seconds: 1665669188,
            nanoseconds: 250000000
        },
        apptm: null,
        files: null,
        action: "Called",
        userName: "Alan  Lai",
        remark: "",
        user: "alanlaiproperty88@gmail.com",
        id: "aBPdBfiSs4HDGgOaU92t",
        path: "leads/0T6vASWjyZGPUg3GzYvU/actions/aBPdBfiSs4HDGgOaU92t",
        leadId: "0T6vASWjyZGPUg3GzYvU"
    },
    {
        apptm: null,
        userName: "Pang  Yy",
        created: {
            seconds: 1666356041,
            nanoseconds: 58000000
        },
        files: null,
        remark: "npu",
        user: "cindy_pang91@hotmail.com",
        action: "Research",
        id: "g6n83T5nc3OPEM9myunM",
        path: "leads/0T6vASWjyZGPUg3GzYvU/actions/g6n83T5nc3OPEM9myunM",
        leadId: "0T6vASWjyZGPUg3GzYvU"
    },
    {
        files: null,
        user: "mongny@hotmail.com",
        remark: "Na",
        action: "Called",
        userName: "Jimmy ong",
        apptm: null,
        created: {
            seconds: 1663416627,
            nanoseconds: 283000000
        },
        id: "mup8VfAytORX0GpKwMqm",
        path: "leads/0T6vASWjyZGPUg3GzYvU/actions/mup8VfAytORX0GpKwMqm",
        leadId: "0T6vASWjyZGPUg3GzYvU"
    },
    {
        action: "Called",
        remark: "",
        created: {
            seconds: 1637673986,
            nanoseconds: 314000000
        },
        files: null,
        apptm: null,
        user: "vernicelee2621@gmail.com",
        id: "2kv5tcqgF9peV5GoAZTm",
        path: "leads/0TR1Um2FyscOJZ0Aqdb1/actions/2kv5tcqgF9peV5GoAZTm",
        leadId: "0TR1Um2FyscOJZ0Aqdb1"
    },
    {
        user: "ckkey08181@gmail.com",
        files: null,
        remark: "",
        apptm: null,
        action: "Whatsapped",
        created: {
            seconds: 1639367090,
            nanoseconds: 315000000
        },
        id: "5UKkarphhwF2u6wb4bmW",
        path: "leads/0TR1Um2FyscOJZ0Aqdb1/actions/5UKkarphhwF2u6wb4bmW",
        leadId: "0TR1Um2FyscOJZ0Aqdb1"
    },
    {
        remark: "7:24PM no pick up",
        user: "imjunsu0420@gmail.com",
        files: null,
        action: "Called",
        created: {
            seconds: 1636629840,
            nanoseconds: 490000000
        },
        apptm: null,
        id: "8Dca5vHhmELTZxKLj5sD",
        path: "leads/0TR1Um2FyscOJZ0Aqdb1/actions/8Dca5vHhmELTZxKLj5sD",
        leadId: "0TR1Um2FyscOJZ0Aqdb1"
    },
    {
        apptm: null,
        files: null,
        user: "ckkey08181@gmail.com",
        action: "Whatsapped",
        remark: "",
        created: {
            seconds: 1639910724,
            nanoseconds: 317000000
        },
        id: "8WLJ2NWH25133NOMKT6h",
        path: "leads/0TR1Um2FyscOJZ0Aqdb1/actions/8WLJ2NWH25133NOMKT6h",
        leadId: "0TR1Um2FyscOJZ0Aqdb1"
    },
    {
        remark: "",
        files: null,
        apptm: null,
        action: "Whatsapped",
        created: {
            seconds: 1639478178,
            nanoseconds: 352000000
        },
        user: "ckkey08181@gmail.com",
        id: "K0o56yo3YpysoVsWnXPK",
        path: "leads/0TR1Um2FyscOJZ0Aqdb1/actions/K0o56yo3YpysoVsWnXPK",
        leadId: "0TR1Um2FyscOJZ0Aqdb1"
    },
    {
        action: "Whatsapped",
        created: {
            seconds: 1639561622,
            nanoseconds: 843000000
        },
        files: null,
        user: "ckkey08181@gmail.com",
        remark: "",
        apptm: null,
        id: "LEnjIQu39SyXzWrIYqDp",
        path: "leads/0TR1Um2FyscOJZ0Aqdb1/actions/LEnjIQu39SyXzWrIYqDp",
        leadId: "0TR1Um2FyscOJZ0Aqdb1"
    },
    {
        created: {
            seconds: 1640956899,
            nanoseconds: 137000000
        },
        user: "ckkey08181@gmail.com",
        apptm: null,
        action: "Whatsapped",
        remark: "",
        files: null,
        id: "PontIYuxj4i3QsxqXO4m",
        path: "leads/0TR1Um2FyscOJZ0Aqdb1/actions/PontIYuxj4i3QsxqXO4m",
        leadId: "0TR1Um2FyscOJZ0Aqdb1"
    },
    {
        apptm: null,
        user: "ckkey08181@gmail.com",
        action: "Called",
        remark: "",
        created: {
            seconds: 1639310986,
            nanoseconds: 738000000
        },
        files: null,
        id: "dK9d4ThujVaRfhXbxcv9",
        path: "leads/0TR1Um2FyscOJZ0Aqdb1/actions/dK9d4ThujVaRfhXbxcv9",
        leadId: "0TR1Um2FyscOJZ0Aqdb1"
    },
    {
        remark: "",
        apptm: null,
        created: {
            seconds: 1637673984,
            nanoseconds: 481000000
        },
        user: "vernicelee2621@gmail.com",
        files: null,
        action: "Research",
        id: "f0e8IfpA5AJf6L2mVuVa",
        path: "leads/0TR1Um2FyscOJZ0Aqdb1/actions/f0e8IfpA5AJf6L2mVuVa",
        leadId: "0TR1Um2FyscOJZ0Aqdb1"
    },
    {
        apptm: null,
        user: "hanst9803@gmail.com",
        created: {
            seconds: 1636979586,
            nanoseconds: 379000000
        },
        action: "Called",
        files: null,
        remark: "No ans ",
        id: "kbmPwqLDtzojf3wPzU9Q",
        path: "leads/0TR1Um2FyscOJZ0Aqdb1/actions/kbmPwqLDtzojf3wPzU9Q",
        leadId: "0TR1Um2FyscOJZ0Aqdb1"
    },
    {
        files: null,
        user: "ckkey08181@gmail.com",
        action: "Called",
        created: {
            seconds: 1639311017,
            nanoseconds: 616000000
        },
        remark: "NPU",
        apptm: null,
        id: "t9qJ1mIh1lswDfG2xRSc",
        path: "leads/0TR1Um2FyscOJZ0Aqdb1/actions/t9qJ1mIh1lswDfG2xRSc",
        leadId: "0TR1Um2FyscOJZ0Aqdb1"
    },
    {
        apptm: null,
        created: {
            seconds: 1645786220,
            nanoseconds: 513000000
        },
        user: "alan_0219@hotmail.com",
        remark: "no pick up. ",
        action: "Research",
        files: null,
        userName: "Chia  Wei lun",
        id: "RRIVub07pSRlOVNS863F",
        path: "leads/0UPtFUQaOhz1jv9qBP8q/actions/RRIVub07pSRlOVNS863F",
        leadId: "0UPtFUQaOhz1jv9qBP8q"
    },
    {
        apptm: null,
        files: null,
        created: {
            seconds: 1645601902,
            nanoseconds: 689000000
        },
        action: "Whatsapped",
        remark: "sent mini brochure gene version",
        userName: "Gene Lee",
        user: "gnelol8895@gmail.com",
        id: "Sxr42MqzgXs5741eQjdS",
        path: "leads/0UPtFUQaOhz1jv9qBP8q/actions/Sxr42MqzgXs5741eQjdS",
        leadId: "0UPtFUQaOhz1jv9qBP8q"
    },
    {
        apptm: null,
        remark: "currently plan on hold, ownstay 2 - 3 rooms, know 1 utama, dont know PJ Trade Centre, everything on hold , will whatsapp brochure for her for reference",
        files: null,
        action: "Called",
        created: {
            seconds: 1645601496,
            nanoseconds: 117000000
        },
        userName: "Gene Lee",
        user: "gnelol8895@gmail.com",
        id: "aBSYmMo5GwGgIbVLU7rs",
        path: "leads/0UPtFUQaOhz1jv9qBP8q/actions/aBSYmMo5GwGgIbVLU7rs",
        leadId: "0UPtFUQaOhz1jv9qBP8q"
    },
    {
        userName: "Eunice Min",
        apptm: null,
        created: {
            seconds: 1642326461,
            nanoseconds: 544000000
        },
        user: "eunicemin0729@gmail.com",
        action: "Research",
        files: null,
        remark: "For her daughter, taylors university, budget below 400k, completion year asap",
        id: "ubncWQwU8ZFJZvq5lzBU",
        path: "leads/0UPtFUQaOhz1jv9qBP8q/actions/ubncWQwU8ZFJZvq5lzBU",
        leadId: "0UPtFUQaOhz1jv9qBP8q"
    },
    {
        files: null,
        remark: "Seremban people, daughter already studying taylor, about five years, say she still saving money for it, second follow up client, will send the mossaz details for her ",
        action: "Research",
        apptm: null,
        user: "eunicemin0729@gmail.com",
        userName: "Eunice Min",
        created: {
            seconds: 1647438295,
            nanoseconds: 64000000
        },
        id: "ury9ZrIUZGCQxy0reQeM",
        path: "leads/0UPtFUQaOhz1jv9qBP8q/actions/ury9ZrIUZGCQxy0reQeM",
        leadId: "0UPtFUQaOhz1jv9qBP8q"
    },
    {
        files: null,
        action: "Research",
        apptm: null,
        remark: "npu",
        user: "alan_0219@hotmail.com",
        userName: "Chia  Wei lun",
        created: {
            seconds: 1648042622,
            nanoseconds: 406000000
        },
        id: "AkXPFEBUHpeRQ2JO5Ekx",
        path: "leads/0UR5BtCeTMHQMh30Yi9b/actions/AkXPFEBUHpeRQ2JO5Ekx",
        leadId: "0UR5BtCeTMHQMh30Yi9b"
    },
    {
        apptm: null,
        created: {
            seconds: 1648647056,
            nanoseconds: 906000000
        },
        user: "sookmun770@gmail.com",
        action: "Called",
        files: null,
        remark: "NPU",
        userName: "Smun Lee",
        id: "CrJDHbVHruwXFyLL6sNS",
        path: "leads/0UR5BtCeTMHQMh30Yi9b/actions/CrJDHbVHruwXFyLL6sNS",
        leadId: "0UR5BtCeTMHQMh30Yi9b"
    },
    {
        files: null,
        apptm: null,
        userName: "Chia  Wei lun",
        created: {
            seconds: 1648539182,
            nanoseconds: 738000000
        },
        user: "alan_0219@hotmail.com",
        remark: "npu",
        action: "Research",
        id: "HvxdIr2V22DpQvxh5F15",
        path: "leads/0UR5BtCeTMHQMh30Yi9b/actions/HvxdIr2V22DpQvxh5F15",
        leadId: "0UR5BtCeTMHQMh30Yi9b"
    },
    {
        user: "eunicemin0729@gmail.com",
        created: {
            seconds: 1650548577,
            nanoseconds: 469000000
        },
        remark: "Npu",
        action: "Called",
        apptm: null,
        files: null,
        userName: "Eunice Min",
        id: "Lg347Q8ZngiMIThiqHdT",
        path: "leads/0UR5BtCeTMHQMh30Yi9b/actions/Lg347Q8ZngiMIThiqHdT",
        leadId: "0UR5BtCeTMHQMh30Yi9b"
    },
    {
        user: "alexchia320@gmail.com",
        action: "Called",
        remark: "Npu",
        created: {
            seconds: 1652276092,
            nanoseconds: 344000000
        },
        userName: "Alexander Chia",
        files: null,
        apptm: null,
        id: "LlCf0HFxhrfUfOw1Oixx",
        path: "leads/0UR5BtCeTMHQMh30Yi9b/actions/LlCf0HFxhrfUfOw1Oixx",
        leadId: "0UR5BtCeTMHQMh30Yi9b"
    },
    {
        apptm: null,
        files: null,
        remark: "NPU",
        userName: "Smun Lee",
        created: {
            seconds: 1648288887,
            nanoseconds: 403000000
        },
        action: "Called",
        user: "sookmun770@gmail.com",
        id: "cUOHswsoAZPHdLP1ZdYF",
        path: "leads/0UR5BtCeTMHQMh30Yi9b/actions/cUOHswsoAZPHdLP1ZdYF",
        leadId: "0UR5BtCeTMHQMh30Yi9b"
    },
    {
        action: "Called",
        apptm: null,
        remark: "NPU",
        userName: "Smun Lee",
        created: {
            seconds: 1647953702,
            nanoseconds: 438000000
        },
        user: "sookmun770@gmail.com",
        files: null,
        id: "fKwYDChhaweG7t2Gmpoy",
        path: "leads/0UR5BtCeTMHQMh30Yi9b/actions/fKwYDChhaweG7t2Gmpoy",
        leadId: "0UR5BtCeTMHQMh30Yi9b"
    },
    {
        created: {
            seconds: 1648128228,
            nanoseconds: 381000000
        },
        apptm: null,
        user: "eunicemin0729@gmail.com",
        userName: "Eunice Min",
        remark: "",
        action: "Research",
        files: null,
        id: "zpo2CvNCVq5ljSoqJe65",
        path: "leads/0UR5BtCeTMHQMh30Yi9b/actions/zpo2CvNCVq5ljSoqJe65",
        leadId: "0UR5BtCeTMHQMh30Yi9b"
    },
    {
        created: {
            seconds: 1663926011,
            nanoseconds: 305000000
        },
        files: null,
        remark: "",
        user: "charles951121@gmail.com",
        action: "Whatsapped",
        userName: "CY Yong",
        apptm: null,
        id: "07lvufOv6a5fdrlvtP8H",
        path: "leads/0WNI4pjjx6bTIXNzKsSN/actions/07lvufOv6a5fdrlvtP8H",
        leadId: "0WNI4pjjx6bTIXNzKsSN"
    },
    {
        files: null,
        remark: "",
        apptm: null,
        userName: "CY Yong",
        user: "charles951121@gmail.com",
        action: "Whatsapped",
        created: {
            seconds: 1666656915,
            nanoseconds: 218000000
        },
        id: "7hUE5MtveFro06ZSlFJT",
        path: "leads/0WNI4pjjx6bTIXNzKsSN/actions/7hUE5MtveFro06ZSlFJT",
        leadId: "0WNI4pjjx6bTIXNzKsSN"
    },
    {
        created: {
            seconds: 1669966792,
            nanoseconds: 626000000
        },
        user: "iqi.jimmyng@gmail.com",
        remark: "no pick up ",
        userName: "Jimmy Ng ",
        action: "Called",
        files: null,
        apptm: null,
        id: "EzAaWfngm6vZ8I3t982Q",
        path: "leads/0WNI4pjjx6bTIXNzKsSN/actions/EzAaWfngm6vZ8I3t982Q",
        leadId: "0WNI4pjjx6bTIXNzKsSN"
    },
    {
        userName: "CY Yong",
        remark: "kenal covid, now at hospital, will on hold until recover\n",
        files: null,
        apptm: null,
        created: {
            seconds: 1664412839,
            nanoseconds: 687000000
        },
        user: "charles951121@gmail.com",
        action: "Research",
        id: "GOcT2F9FpBEIEwe31urr",
        path: "leads/0WNI4pjjx6bTIXNzKsSN/actions/GOcT2F9FpBEIEwe31urr",
        leadId: "0WNI4pjjx6bTIXNzKsSN"
    },
    {
        created: {
            seconds: 1662886569,
            nanoseconds: 162000000
        },
        userName: "leona ang",
        apptm: null,
        action: "Whatsapped",
        remark: "send dcp",
        files: null,
        user: "huiyingang96@hotmail.com",
        id: "PR7xVogljOxZgxOsdZLE",
        path: "leads/0WNI4pjjx6bTIXNzKsSN/actions/PR7xVogljOxZgxOsdZLE",
        leadId: "0WNI4pjjx6bTIXNzKsSN"
    },
    {
        created: {
            seconds: 1663242036,
            nanoseconds: 241000000
        },
        user: "charles951121@gmail.com",
        action: "Whatsapped",
        files: null,
        apptm: null,
        userName: "CY Yong",
        remark: "Mother from pj, father from raub pahang, but she like PJ the most, but not so familiar with SECTION 14 ",
        id: "VbBbMkZWNaJrdFbrsH4H",
        path: "leads/0WNI4pjjx6bTIXNzKsSN/actions/VbBbMkZWNaJrdFbrsH4H",
        leadId: "0WNI4pjjx6bTIXNzKsSN"
    },
    {
        created: {
            seconds: 1663074056,
            nanoseconds: 94000000
        },
        files: null,
        action: "Whatsapped",
        apptm: null,
        remark: "",
        user: "charles951121@gmail.com",
        userName: "CY Yong",
        id: "Ypqa8G9EciMWEIDtn4y7",
        path: "leads/0WNI4pjjx6bTIXNzKsSN/actions/Ypqa8G9EciMWEIDtn4y7",
        leadId: "0WNI4pjjx6bTIXNzKsSN"
    },
    {
        files: null,
        userName: "CY Yong",
        remark: "Date: 2022-09-18 | Time: 12:00 | Venue: Atera Residence  | Language: Chinese | Budget: 600k | Intention: Both for 2-3 rooms unit",
        user: "charles951121@gmail.com",
        apptm: {
            user: "charles951121@gmail.com",
            intention: "Both for 2-3 rooms unit",
            time: "12:00",
            venue: "Atera Residence ",
            budget: "600k",
            language: "Chinese",
            date: {
                seconds: 1663459200,
                nanoseconds: 0
            }
        },
        created: {
            seconds: 1663073988,
            nanoseconds: 131000000
        },
        action: "Appointment",
        id: "id2HkkasuBOyjjnFheTu",
        path: "leads/0WNI4pjjx6bTIXNzKsSN/actions/id2HkkasuBOyjjnFheTu",
        leadId: "0WNI4pjjx6bTIXNzKsSN"
    },
    {
        userName: "CY Yong",
        user: "charles951121@gmail.com",
        files: null,
        remark: "From PJ, looking PJ only, for both purpose, can accept late completion year project as long as got nice offer, heard exsim project then skip, coz she survey property from 2019, the first project she heard is D’Quince, but near to mosque she dislike, and after 1 year she heard D’Erica she interested but when she know that time already too late all sold out, and then this year she got heard dterra sold out also she feel sien, don’t want consider there, heard verando before feel zap, and dont like that place and heard helix before, but not yet visit showroom,\n\nWhen I say Atera she very interested with section14 that location, direct date me this Sunday 12pm visit showroom\nHer budget below 600k, looking for good developer and 2-3 rooms unit\n",
        apptm: null,
        action: "Called",
        created: {
            seconds: 1663072910,
            nanoseconds: 916000000
        },
        id: "qT9Y92lfrf7suWoNfPkJ",
        path: "leads/0WNI4pjjx6bTIXNzKsSN/actions/qT9Y92lfrf7suWoNfPkJ",
        leadId: "0WNI4pjjx6bTIXNzKsSN"
    },
    {
        apptm: null,
        files: null,
        created: {
            seconds: 1662887049,
            nanoseconds: 354000000
        },
        userName: "leona ang",
        user: "huiyingang96@hotmail.com",
        action: "Called",
        remark: "call back 9pm , call back no reply ",
        id: "vwIifIVVolD8VEqlGdvV",
        path: "leads/0WNI4pjjx6bTIXNzKsSN/actions/vwIifIVVolD8VEqlGdvV",
        leadId: "0WNI4pjjx6bTIXNzKsSN"
    },
    {
        apptm: null,
        userName: "Pang  Yy",
        action: "Whatsapped",
        created: {
            seconds: 1659512879,
            nanoseconds: 648000000
        },
        remark: "",
        files: null,
        user: "cindy_pang91@hotmail.com",
        id: "BaRp9gwlBIoWSqSYMgck",
        path: "leads/0X1Zizhf5A5MGOpV7gNu/actions/BaRp9gwlBIoWSqSYMgck",
        leadId: "0X1Zizhf5A5MGOpV7gNu"
    },
    {
        userName: "Desmond  Chong",
        files: null,
        apptm: null,
        user: "desmondchongproperty21@gmail.com",
        action: "Called",
        remark: "- NPU",
        created: {
            seconds: 1657197787,
            nanoseconds: 485000000
        },
        id: "CSD00U2uZcmvfGbY4RnJ",
        path: "leads/0X1Zizhf5A5MGOpV7gNu/actions/CSD00U2uZcmvfGbY4RnJ",
        leadId: "0X1Zizhf5A5MGOpV7gNu"
    },
    {
        action: "Research",
        files: null,
        user: "jeremytan.iqi@gmail.com",
        remark: "Pass back, too low budget ",
        apptm: null,
        userName: "Jeremy Tan",
        created: {
            seconds: 1657114995,
            nanoseconds: 415000000
        },
        id: "HwivI9puS1kEAkMIYcL6",
        path: "leads/0X1Zizhf5A5MGOpV7gNu/actions/HwivI9puS1kEAkMIYcL6",
        leadId: "0X1Zizhf5A5MGOpV7gNu"
    },
    {
        action: "Called",
        files: null,
        created: {
            seconds: 1657021025,
            nanoseconds: 255000000
        },
        userName: "Desmond  Chong",
        apptm: null,
        user: "desmondchongproperty21@gmail.com",
        remark: "- NPU",
        id: "Mw761BGqcfOWN6hUn39W",
        path: "leads/0X1Zizhf5A5MGOpV7gNu/actions/Mw761BGqcfOWN6hUn39W",
        leadId: "0X1Zizhf5A5MGOpV7gNu"
    },
    {
        remark: "Indian uncle , Mr “padini” looking Sunway area or pj area and his and family working and staying in pj area . Buying property for his daughter. Her daughter just married and got a child. Budget below Rm300k. Don’t want studio need around 1-2 bedrooms ",
        apptm: null,
        action: "Called",
        created: {
            seconds: 1656506033,
            nanoseconds: 480000000
        },
        userName: "Jeremy Tan",
        user: "jeremytan.iqi@gmail.com",
        files: null,
        id: "TPEntZ7NxysQf8NmfRSP",
        path: "leads/0X1Zizhf5A5MGOpV7gNu/actions/TPEntZ7NxysQf8NmfRSP",
        leadId: "0X1Zizhf5A5MGOpV7gNu"
    },
    {
        action: "Research",
        user: "cindy_pang91@hotmail.com",
        userName: "Pang  Yy",
        files: null,
        remark: "fatimi- budget 350k . pj area",
        created: {
            seconds: 1659430130,
            nanoseconds: 420000000
        },
        apptm: null,
        id: "ZQLQJdKvpmTHj0khKAXB",
        path: "leads/0X1Zizhf5A5MGOpV7gNu/actions/ZQLQJdKvpmTHj0khKAXB",
        leadId: "0X1Zizhf5A5MGOpV7gNu"
    },
    {
        action: "Research",
        user: "cindy_pang91@hotmail.com",
        files: null,
        apptm: null,
        userName: "Pang  Yy",
        remark: "fuyan me . ",
        created: {
            seconds: 1660615659,
            nanoseconds: 16000000
        },
        id: "khSSIl7tD7LdBpZBbeR7",
        path: "leads/0X1Zizhf5A5MGOpV7gNu/actions/khSSIl7tD7LdBpZBbeR7",
        leadId: "0X1Zizhf5A5MGOpV7gNu"
    },
    {
        created: {
            seconds: 1657262546,
            nanoseconds: 878000000
        },
        action: "Called",
        remark: "",
        files: null,
        apptm: null,
        userName: "Desmond  Chong",
        user: "desmondchongproperty21@gmail.com",
        id: "w9b1W8hssRAayz3AXhhS",
        path: "leads/0X1Zizhf5A5MGOpV7gNu/actions/w9b1W8hssRAayz3AXhhS",
        leadId: "0X1Zizhf5A5MGOpV7gNu"
    },
    {
        user: "sookmun770@gmail.com",
        userName: "Smun Lee",
        created: {
            seconds: 1648284647,
            nanoseconds: 143000000
        },
        apptm: null,
        remark: "NPU",
        action: "Called",
        files: null,
        id: "KaN0RT3dbSFn7eFxk9Dc",
        path: "leads/0XDzeiOvrmIbqH3uhMvX/actions/KaN0RT3dbSFn7eFxk9Dc",
        leadId: "0XDzeiOvrmIbqH3uhMvX"
    },
    {
        remark: "buy for ownstay. from other area. monthly installment is RM1400. only can push mossaz. ",
        created: {
            seconds: 1648126451,
            nanoseconds: 740000000
        },
        user: "alan_0219@hotmail.com",
        apptm: null,
        action: "Research",
        files: null,
        userName: "Chia  Wei lun",
        id: "Q7fgxj0u35TiADTGrcmM",
        path: "leads/0XDzeiOvrmIbqH3uhMvX/actions/Q7fgxj0u35TiADTGrcmM",
        leadId: "0XDzeiOvrmIbqH3uhMvX"
    },
    {
        action: "Research",
        remark: "Already bought M Luna ",
        apptm: null,
        user: "alan_0219@hotmail.com",
        userName: "Chia  Wei lun",
        files: null,
        created: {
            seconds: 1648541220,
            nanoseconds: 663000000
        },
        id: "lQemhGmktrrgZw120lbM",
        path: "leads/0XDzeiOvrmIbqH3uhMvX/actions/lQemhGmktrrgZw120lbM",
        leadId: "0XDzeiOvrmIbqH3uhMvX"
    },
    {
        remark: "Already signed m luna kepong",
        files: null,
        created: {
            seconds: 1648127495,
            nanoseconds: 143000000
        },
        user: "eunicemin0729@gmail.com",
        userName: "Eunice Min",
        action: "Research",
        apptm: null,
        id: "liTQdsqpe1rt2BplpSgG",
        path: "leads/0XDzeiOvrmIbqH3uhMvX/actions/liTQdsqpe1rt2BplpSgG",
        leadId: "0XDzeiOvrmIbqH3uhMvX"
    },
    {
        action: "Called",
        apptm: null,
        userName: "Jimmy ong",
        remark: "call back at tomorrow 1 pm",
        files: null,
        user: "mongny@hotmail.com",
        created: {
            seconds: 1659010432,
            nanoseconds: 103000000
        },
        id: "QQuq7BASk7QzpdsSX5Sr",
        path: "leads/0XqMV3laTYpiu1rm9eCa/actions/QQuq7BASk7QzpdsSX5Sr",
        leadId: "0XqMV3laTYpiu1rm9eCa"
    },
    {
        created: {
            seconds: 1663065453,
            nanoseconds: 231000000
        },
        apptm: null,
        userName: "Junsu Mok",
        action: "Called",
        remark: "6:37PM already buy IOI project / puchong / pass back ",
        user: "imjunsu0420@gmail.com",
        files: null,
        id: "WRRiZhIKY9YySb3J3iZ8",
        path: "leads/0XqMV3laTYpiu1rm9eCa/actions/WRRiZhIKY9YySb3J3iZ8",
        leadId: "0XqMV3laTYpiu1rm9eCa"
    },
    {
        action: "Appointment",
        files: null,
        userName: "Summer Koh",
        remark: "Date: 2022-06-18 | Time: 17:00 | Venue: Dmsr showroom | Language: undefined | Budget: undefined | Intention: undefined",
        created: {
            seconds: 1655194422,
            nanoseconds: 912000000
        },
        user: "kohpohfen@gmail.com",
        apptm: {
            time: "17:00",
            date: {
                seconds: 1655510400,
                nanoseconds: 0
            },
            user: "kohpohfen@gmail.com",
            venue: "Dmsr showroom"
        },
        id: "cTURwz7HXfd2qAAGnp7O",
        path: "leads/0XqMV3laTYpiu1rm9eCa/actions/cTURwz7HXfd2qAAGnp7O",
        leadId: "0XqMV3laTYpiu1rm9eCa"
    },
    {
        user: "kohpohfen@gmail.com",
        files: null,
        userName: "Summer Koh",
        action: "Research",
        remark: "Book with vanness and me",
        created: {
            seconds: 1655891414,
            nanoseconds: 499000000
        },
        apptm: null,
        id: "x2VjQMPASl8B5poCXjv8",
        path: "leads/0XqMV3laTYpiu1rm9eCa/actions/x2VjQMPASl8B5poCXjv8",
        leadId: "0XqMV3laTYpiu1rm9eCa"
    },
    {
        files: null,
        apptm: null,
        user: "mongny@hotmail.com",
        action: "Called",
        userName: "Jimmy ong",
        remark: "budget 700k, Low density, currently stay in kepong, surveying, no rush to buy and move in, already see DCP there, dont like the access rpad,",
        created: {
            seconds: 1659071722,
            nanoseconds: 321000000
        },
        id: "ztSGow4nESCZXViAjbLI",
        path: "leads/0XqMV3laTYpiu1rm9eCa/actions/ztSGow4nESCZXViAjbLI",
        leadId: "0XqMV3laTYpiu1rm9eCa"
    },
    {
        action: "Called",
        user: "munhong0909@gmail.com",
        files: null,
        created: {
            seconds: 1681305413,
            nanoseconds: 420000000
        },
        remark: "",
        apptm: null,
        userName: "Mun Hong Yip",
        leadId: "0Y3YiRN8Mwl7crrgHzKq",
        id: "2zSyr05dyJYjJvtF3qHQ",
        path: "leads/0Y3YiRN8Mwl7crrgHzKq/actions/2zSyr05dyJYjJvtF3qHQ"
    },
    {
        remark: "",
        created: {
            seconds: 1678972324,
            nanoseconds: 2000000
        },
        action: "Whatsapped",
        userName: "henry lee",
        leadId: "0Y3YiRN8Mwl7crrgHzKq",
        apptm: null,
        user: "henryleeproperty12@gmail.com",
        files: null,
        id: "AAiH3E2Ly18bh0goj0YE",
        path: "leads/0Y3YiRN8Mwl7crrgHzKq/actions/AAiH3E2Ly18bh0goj0YE"
    },
    {
        user: "jonathanchin0614@gmail.com",
        leadId: "0Y3YiRN8Mwl7crrgHzKq",
        apptm: null,
        files: null,
        userName: "Jonathan Chin",
        action: "Whatsapped",
        remark: "",
        created: {
            seconds: 1679490961,
            nanoseconds: 342000000
        },
        id: "AXNQdCCn4FZSnTVKBgl7",
        path: "leads/0Y3YiRN8Mwl7crrgHzKq/actions/AXNQdCCn4FZSnTVKBgl7"
    },
    {
        action: "Called",
        leadId: "0Y3YiRN8Mwl7crrgHzKq",
        created: {
            seconds: 1680270326,
            nanoseconds: 456000000
        },
        user: "kellywongyikei@gmail.com",
        userName: "Kelly Wong",
        remark: "Looking for newly launched projects which near to MRT",
        apptm: null,
        files: null,
        id: "SxiVwoouBQoBRxKeb0rI",
        path: "leads/0Y3YiRN8Mwl7crrgHzKq/actions/SxiVwoouBQoBRxKeb0rI"
    },
    {
        created: {
            seconds: 1681220127,
            nanoseconds: 467000000
        },
        apptm: null,
        user: "sookmun770@gmail.com",
        files: null,
        action: "Research",
        leadId: "0Y3YiRN8Mwl7crrgHzKq",
        userName: "Smun Lee",
        remark: "invest, 1st property, aim long term rental, prefer 2-3 rooms, prefer ,maluri kl area cux convenient to trx, budget 700k, not so prefer bukit jalil. live in old klang road",
        id: "TVSPRFm5y44FLvoISW6m",
        path: "leads/0Y3YiRN8Mwl7crrgHzKq/actions/TVSPRFm5y44FLvoISW6m"
    },
    {
        user: "kohpohfen@gmail.com",
        action: "Research",
        apptm: null,
        remark: "Investment",
        leadId: "0Y3YiRN8Mwl7crrgHzKq",
        files: null,
        created: {
            seconds: 1680370797,
            nanoseconds: 493000000
        },
        userName: "Poh fen Koh",
        id: "YPJ62d9EWyStaBrcnurS",
        path: "leads/0Y3YiRN8Mwl7crrgHzKq/actions/YPJ62d9EWyStaBrcnurS"
    },
    {
        action: "Whatsapped",
        created: {
            seconds: 1682411380,
            nanoseconds: 911000000
        },
        files: null,
        leadId: "0Y3YiRN8Mwl7crrgHzKq",
        remark: "",
        apptm: null,
        userName: "Ting Seong Shew",
        user: "seong31993199@gmail.com",
        id: "nSGjPvIuWP3pHhoP5hax",
        path: "leads/0Y3YiRN8Mwl7crrgHzKq/actions/nSGjPvIuWP3pHhoP5hax"
    },
    {
        leadId: "0Y3YiRN8Mwl7crrgHzKq",
        userName: "henry lee",
        files: null,
        action: "Whatsapped",
        user: "henryleeproperty12@gmail.com",
        created: {
            seconds: 1678972343,
            nanoseconds: 775000000
        },
        apptm: null,
        remark: "",
        id: "qgfM91Qtmnn0y2JNyyP6",
        path: "leads/0Y3YiRN8Mwl7crrgHzKq/actions/qgfM91Qtmnn0y2JNyyP6"
    },
    {
        created: {
            seconds: 1678949110,
            nanoseconds: 309000000
        },
        files: null,
        userName: "henry lee",
        user: "henryleeproperty12@gmail.com",
        remark: "NA",
        leadId: "0Y3YiRN8Mwl7crrgHzKq",
        action: "Called",
        apptm: null,
        id: "sSkAysv6L91Cme1Q8iVS",
        path: "leads/0Y3YiRN8Mwl7crrgHzKq/actions/sSkAysv6L91Cme1Q8iVS"
    },
    {
        created: {
            seconds: 1678972277,
            nanoseconds: 972000000
        },
        apptm: null,
        action: "Called",
        userName: "henry lee",
        leadId: "0Y3YiRN8Mwl7crrgHzKq",
        user: "henryleeproperty12@gmail.com",
        remark: "NA",
        files: null,
        id: "xOedYuEYw8CWfU96CyQB",
        path: "leads/0Y3YiRN8Mwl7crrgHzKq/actions/xOedYuEYw8CWfU96CyQB"
    },
    {
        leadId: "0Y3YiRN8Mwl7crrgHzKq",
        apptm: null,
        files: null,
        user: "henryleeproperty12@gmail.com",
        created: {
            seconds: 1680614346,
            nanoseconds: 411000000
        },
        remark: "",
        userName: "henry lee",
        action: "Whatsapped",
        id: "z3s8XYf1icJPUBE6jxD4",
        path: "leads/0Y3YiRN8Mwl7crrgHzKq/actions/z3s8XYf1icJPUBE6jxD4"
    }
];
