import React, { useContext, useState } from "react";
import { GridDivider, GridFlexBox, IconC, Name } from "../themes/themes";
import { useEffect } from "react";
import { InputContext, useInput } from "../context-utils/InputContext";
import editField from "../functions/editField";
import { AuthContext } from "../context/AuthContext";
import isAdmin from "../utils-functions/isAdmin";
import { Icon, IconButton } from "@mui/material";
import { useConfirmation } from "../context-utils/ConfirmationContext";
import { type } from "@testing-library/user-event/dist/type";

const KEYS = {
    displayName: "Display Name",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    phone: "Phone",
    chatId: "Chat ID",
    referralCode: "Referral Code"
};

export default function DataFields({ object, collectionId, keys_ = KEYS, handleUpdate, state }) {
    const [keys, setKeys] = useState(keys_);
    const { user } = useContext(AuthContext);

    const { openInputDialog } = useContext(InputContext);

    const input = useInput();
    const confirmation = useConfirmation();

    useEffect(() => {
        if (object) {
            let newKeys = {};
            Object.keys(keys_).forEach((key) => {
                newKeys[key] = keys[key];
            });
            let extraKeys = {};

            if (isAdmin(user, state)) {
                Object.keys(object).forEach((key) => {
                    if (checkString(object, key)) {
                        if (!newKeys[key]) {
                            extraKeys[key] = key;
                        }
                    }
                });
            }

            setKeys({ ...newKeys, ...extraKeys });
        }
    }, [object]);

    const handleClick = async (key) => {
        const response = await openInputDialog("Edit field", "Edit " + key, "", checkString(object, key));
        await editField(object, key, response, collectionId);
        handleUpdate(object, key, response);
    };

    const handleAddField = async () => {
        const fieldName = await input("Add Field", "Add a custom field name", "field name", "");
        const fieldValue = await input("Add Field", "Add a custom field value", "field value", "");
        console.log(fieldName, fieldValue);
        const response = await confirmation("Confirmed?", `${fieldName}: ${fieldValue}`);
        if (response) {
            await editField(user, fieldName, fieldValue, "users");
            handleUpdate(object, fieldName, fieldValue);
        }
    };

    const canEdit = (user, state, key) => {
        if (isAdmin(user, state)) return true;
        if (key === "displayName" || key === "lastName" || key === "firstName" || key === "phone") return true;
        return false;
    };

    return (
        <React.Fragment>
            {object &&
                Object.keys(keys).map((key, i) => (
                    <React.Fragment key={key}>
                        <GridFlexBox w="30%" fs>
                            <Name>{keys[key]}:</Name>
                        </GridFlexBox>
                        <GridFlexBox w="60%" fs>
                            <Name whiteSpace="nowrap">{checkString(object, key) || ""}</Name>
                        </GridFlexBox>
                        <GridFlexBox w="10%" fe>
                            {canEdit(user, state, key) && <IconC onClick={() => handleClick(key)}>edit</IconC>}
                        </GridFlexBox>
                        {i === Object.keys(KEYS).length - 1 && <GridDivider />}
                    </React.Fragment>
                ))}
            {isAdmin(user, state) && (
                <GridFlexBox w="100%" fs>
                    <IconButton onClick={handleAddField}>
                        <Icon>add</Icon>
                    </IconButton>
                </GridFlexBox>
            )}
            <GridDivider />
        </React.Fragment>
    );
}

const checkString = (object, key) => {
    if (typeof object[key] === "string" || typeof object[key] === "number") {
        return object[key];
    } else {
        return "";
    }
};
