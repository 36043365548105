import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, Grid, Icon, IconButton, Paper, TextField } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { green, red, yellow } from "@mui/material/colors";
import { Container } from "@mui/system";
import { arrayRemove, arrayUnion, doc, updateDoc } from "firebase/firestore";
import { encode } from "gpt-tokenizer";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import FullDialog from "../../components/FullDialog";
import ImageComponent from "../../components/Image/ImageComponent";
import UploadFilesButton from "../../components/UploadFile/UploadFilesButton";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import { useInput } from "../../context-utils/InputContext";
import { db } from "../../firebase/firebase-utils";
import removeSpecialChars from "../../functions/common-functions/removeSpecialChars";
import useChooseFriend from "../../hooks/useChooseFriend";
import { GridContainer, GridDivider, GridFlexBox, Loading, Title, addAdornment } from "../../themes/themes";
import { arrayIsEmpty } from "../../utils-functions/arrayIsEmpty";
import { notification } from "../../utils-functions/notification";
import OverviewFlowPage from "../ReactFlowPage/ReactFlowPage";
import ConnectWhatsappComponent from "../WhatsappWebPage/ConnectWhatsappComponent";
import { ExcelLikeInput } from "../WhatsappWebPage/WhatsappBroadcastPage";
import { AuthContext } from "./../../context/AuthContext";
import { Name } from "./../../themes/themes";
import FollowUpDesign from "./FollowUpDesign";
import SettingsDialog from "./SettingsDialog";
import WhatsappLinkGenerator from "./WhatsappLinkGenerator";
import addAssignedNumber from "./addAssignedNumber";
import addFlow from "./addFlow";
import addKeyword from "./addKeyword";
import deleteAssignedNumber from "./deleteAssignedNumber";
import deleteFlow from "./deleteFlow";
import loadAssignedNumbers from "./loadAssignedNumbers";
import loadFlows from "./loadFlows";
import shareFlowDesign from "./shareFlowDesign";
import updateDisableAI from "./updateDisableAI";
import updateFlowDesign from "./updateFlowDesign";
import updateFlowStatus from "./updateFlowStatus";
import updateImages from "./updateImages";
import updateKeywords from "./updateKeywords";
import updateModel from "./updateModel";
import updateSaveLead from "./updateSaveLead";

export default function WhatsappFlowPage() {
    const [loading, setLoading] = React.useState(false);
    const [keyword, setKeyword] = React.useState("");
    const [flows, setFlows] = React.useState([]);
    const [flow, setFlow] = React.useState("");
    const [currentFlow, setCurrentFlow] = React.useState({});
    const [flowDesign, setFlowDesign] = React.useState("");
    const [assignedNumbers, setAssignedNumbers] = React.useState([]);
    const [model, setModel] = React.useState("gpt-3.5-turbo");
    const [whatsappPhone, setWhatsappPhone] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [system, setSystem] = useState(
        "You are a real estate agent. Your goal is to secure appointment to view the sales galery. Do not mention you are an AI."
    );
    const [edited, setEdited] = useState(false);
    const [openFollowUp, setOpenFollowUp] = useState(false);
    const [openFollowUpReactFlow, setOpenFollowUpReactFlow] = useState(false); //added by wayne 12-1-24 : temporary add a button for development purpose.
    const [tokens, setTokens] = useState(0);
    const [openSettings, setOpenSettings] = useState(false);

    const { user } = useContext(AuthContext);

    const confirmation = useConfirmation();

    const REQUIRED_FIELDS = [
        { label: "Name", id: "name" },
        { label: "Phone", id: "phone" }
    ];

    useEffect(() => {
        const unsubscribe = loadFlows(user, setFlows, setLoading);
        return unsubscribe;
    }, []);

    useEffect(() => {
        if (currentFlow.id) {
            const newCurrentFlow = flows.find((flow) => {
                if (flow.id) {
                    return flow.id === currentFlow.id;
                } else {
                    return false;
                }
            });
            setCurrentFlow(newCurrentFlow || {});
            console.log(newCurrentFlow);
            const model = newCurrentFlow?.model || "gpt-3.5-turbo";
            setModel(model);
        }
    }, [flows]);

    useEffect(() => {
        const unsubscribe = loadAssignedNumbers(user, setAssignedNumbers, setLoading);
        return unsubscribe;
    }, []);

    useEffect(() => {
        if (currentFlow.id) {
            updateModel(user, currentFlow, model);
        }
    }, [model]);

    useEffect(() => {
        if (!flowDesign) {
            setTokens(0);
            return;
        }
        const tokens = encode(flowDesign);
        setTokens(tokens.length);
    }, [flowDesign]);

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleAddKeyword = async () => {
        if (!currentFlow.id) {
            notification("Error", "Please select a flow or create one", "danger");
            return;
        }

        if (keyword && !arrayIsEmpty(flows)) {
            for (let i = 0; i < flows.length; i++) {
                let found = false;
                if (flows[i].keywords) {
                    flows[i].keywords.every((existingKeyword) => {
                        if (keyword === existingKeyword) {
                            notification("Error", "Keyword already exists in " + flows[i]["name"], "danger");
                            found = true;
                            return false;
                        }
                        return true;
                    });
                }
                if (found) return;
            }

            await addKeyword(keyword.trim(), user, currentFlow);
            setKeyword("");
        }
    };

    const handleDeleteKeyword = async (index) => {
        const response = await confirmation("Delete keyword?", "Press ok to confirm");
        if (response) {
            const newKeywords = currentFlow.keywords.filter((keyword, i) => i !== index);
            await updateKeywords(user, currentFlow, newKeywords);
        }
    };

    const handleClickAddFlow = async () => {
        if (!flow) return;
        const clientId = removeSpecialChars(user.id);
        const flowId = await addFlow(flow, user, clientId);
        setCurrentFlow({ id: flowId, name: flow });
        setFlow("");
        setFlowDesign("");
    };

    const handleDeleteFlow = async (flow) => {
        const response = await confirmation("Delete flow?", "Press ok to confirm");
        if (response) {
            await deleteFlow(flow, user);
        }
    };

    const handleClickFlow = (flow) => {
        setCurrentFlow(flow);
        setFlowDesign(flow.flowDesign || "");
        setModel(flow.model || "gpt-3.5-turbo");
        setSystem(flow.system || "");
        console.log(flow);
    };

    const handleSaveFlowDesign = async () => {
        if (!currentFlow.id) {
            notification("Error", "Please select a flow or create one", "danger");
            return;
        }
        if (flowDesign && edited) {
            await updateFlowDesign(user, currentFlow, flowDesign.trim(), system);
            setEdited(false);
            notification("Success", "Flow design saved", "success");
        }
    };

    const handleDeleteAssignedNumber = async (number) => {
        console.log(number);
        await deleteAssignedNumber(number);
        const docRef = doc(db, "users", currentFlow.userId, "whatsappFlows", currentFlow.id);
        const client = number.chatRoomId.split("-")[1];
        await updateDoc(docRef, { blockedUsers: arrayUnion(client) });
        const newCurrentFlow = { ...currentFlow };
        newCurrentFlow.blockedUsers = [...newCurrentFlow.blockedUsers, client];
        setCurrentFlow(newCurrentFlow);
    };

    const handleClickStatus = async (flow) => {
        const newStatus = flow.status === "active" ? "inactive" : "active";
        await updateFlowStatus(user, flow, newStatus);
    };

    const displayAssignedNumbers = useMemo(() => {
        const numbers = assignedNumbers.filter((number) => number.whatsappFlowId === currentFlow?.id);
        return numbers;
    });

    const handleFile = async (files) => {
        if (currentFlow.images) {
            let newImages = [...currentFlow.images, ...files];
            let uniqueImages = Array.from(new Set(newImages));
            await updateImages(uniqueImages, user, currentFlow);
        } else {
            const newImages = [...files];
            await updateImages(newImages, user, currentFlow);
        }
    };

    const handleDeleteImage = async (i) => {
        let images = [...currentFlow.images];
        images.splice(i, 1);
        await updateImages(images, user, currentFlow);
    };

    const chooseFriend = useChooseFriend();

    const handleShareFlowDesign = async (flow) => {
        const friend = await chooseFriend();
        if (friend) {
            await shareFlowDesign(user, flow, friend);
        }
    };

    const input = useInput();

    const handleClickTag = async (i) => {
        console.log(i);
        const response = await input("Tag", "Enter a tag for the file", "tag", currentFlow?.images[i]?.tag || "");
        if (response) {
            const images = [...currentFlow.images];
            images[i].tag = response.trim().toLowerCase();
            await updateImages(images, user, currentFlow);
        }
    };

    const handleChangeFlowDesign = (e) => {
        const newContent = e.target.value;
        const tokens = encode(newContent).length;
        console.log(tokens.length);

        if (tokens <= 1400) {
            setFlowDesign(newContent);
            setEdited(true);
            setErrorMessage("");
        } else {
            setErrorMessage("Flow design cannot exceed 1400 words");
        }
    };

    const handleChangeSystem = (e) => {
        const newContent = e.target.value;
        const tokens = encode(newContent).length;

        if (tokens <= 500) {
            setSystem(newContent);
            setEdited(true);
            setErrorMessage("");
        } else {
            setErrorMessage("System message cannot exceed 500 tokens");
        }
    };

    const handleChangeSaveLead = async (e) => {
        const checked = e.target.checked;
        await updateSaveLead(user, currentFlow, checked);
    };

    const handleChangeDisableAI = async (e) => {
        const checked = e.target.checked;
        await updateDisableAI(user, currentFlow, !checked);
    };

    const handleChangeAppointment = async (e) => {
        const checked = e.target.checked;
        const docRef = doc(db, "users", user.id, "whatsappFlows", currentFlow.id);
        updateDoc(docRef, { appointment: checked });
        console.log("updated appointment", checked);
    };

    const handleChangeSendAudio = async (e) => {
        const checked = e.target.checked;
        const docRef = doc(db, "users", user.id, "whatsappFlows", currentFlow.id);
        updateDoc(docRef, { sendAudio: checked });
        console.log("updated send audio", checked);
    };

    const handleDeleteBlockedUser = async (item) => {
        const docRef = doc(db, "users", currentFlow.userId, "whatsappFlows", currentFlow.id);
        await updateDoc(docRef, { blockedUsers: arrayRemove(item) });
        const newCurrentFlow = { ...currentFlow };
        newCurrentFlow.blockedUsers = newCurrentFlow.blockedUsers.filter((user) => user !== item);
        setCurrentFlow(newCurrentFlow);
    };

    const initialState = {
        name: "",
        phone: "",
        countrycode: "60"
    };

    const [userInput, setUserInput] = useState(initialState);

    const handleChange = (event) => {
        const { id, value } = event.target;
        setUserInput((prevInput) => ({
            ...prevInput,
            [id]: value
        }));
    };

    const handleAddSingle = async () => {
        console.log("addsingle");

        // if (whatsappPhone === '') {
        //     alert(`Please fill in the following field(s): whatsapp phone`);
        // }
        // else
        if (userInput.name === "" || userInput.phone === "" || userInput.countrycode === "") {
            alert(`Please fill in the following field(s): name,phone,country code`);
        } else {
            let userPhone = userInput.countrycode.replace(/\D/g, "") + userInput.phone.replace(/\D/g, "");
            let inputobj = { ...userInput, phone: userPhone };
            await addAssignedNumber(whatsappPhone, user, inputobj, currentFlow);
            setUserInput(initialState);
        }
    };

    const handlePasteData = async (data) => {
        try {
            let names = data.map((item) => {
                return { name: item[0], phone: item[1], status: "" };
            });
            if (!names[names.length - 1].name) names.pop();

            await Promise.all(
                names.map(async (contact) => {
                    await addAssignedNumber(whatsappPhone, user, contact, currentFlow, contact);
                })
            );
        } catch (err) {
            console.log("Error handlePasteData.");
        }
    };

    const handleClickIndex = async () => {
        if (!currentFlow.id) return;
        const docRef = doc(db, "users", user.id, "whatsappFlows", currentFlow.id);
        await updateDoc(docRef, { runVectorIndexing: true });
        console.log("updated runVectorIndexing", true);
        toast.success("Indexing set to true");
    };

    const handleEditName = async (flow) => {
        if (!flow.id) return;

        const response = await input("Edit Flow Name", "Enter a new name for the flow", "name", flow.name);
        if (response) {
            const docRef = doc(db, "users", user.id, "whatsappFlows", flow.id);
            await updateDoc(docRef, { name: response });
            toast.success("Flow name updated");
        }
    };
    
    return (
        <Container maxWidth="none">
            <Grid container display="flex" spacing={1}>
                <Loading loading={loading} />
                <Grid item display="flex" xs={12} md={4}>
                    <Grid container display="flex" spacing={1} alignContent={"flex-start"}>
                        <Grid item display="flex" xs={12} md={12} flexDirection={"column"}>
                            <Paper sx={{ width: "100%", padding: "8px" }}>
                                <Name color={currentFlow?.name ? green[500] : yellow[800]}>
                                    Current flow: {currentFlow?.name || "Please select a flow"}
                                </Name>
                            </Paper>
                        </Grid>
                        <Grid item display="flex" xs={12} md={12} flexDirection={"column"}>
                            <Paper sx={{ width: "100%", padding: "8px" }}>
                                <Box display="flex" width="100%" gap={"8px"} justifyContent={"space-between"}>
                                    <Name>Whatsapp Flows:</Name>
                                    <TextField
                                        placeholder="Flow name"
                                        size="small"
                                        InputProps={addAdornment(handleClickAddFlow)}
                                        value={flow}
                                        onChange={(e) => setFlow(e.currentTarget.value)}
                                    />
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item display="flex" xs={12} md={12} flexDirection={"column"}>
                            <Paper
                                sx={{
                                    width: "100%",
                                    padding: "8px",
                                    height: "30vh",
                                    overflowY: "auto",
                                    overflowX: "hidden"
                                }}
                            >
                                {flows.map((flow) => (
                                    <Box display={"flex"} width={"100%"} key={flow.id}>
                                        <Grid
                                            item
                                            xs={7}
                                            alignSelf={"center"}
                                            onClick={() => handleClickFlow(flow)}
                                            sx={{
                                                cursor: "pointer"
                                            }}
                                        >
                                            <Name bold={flow.id === currentFlow?.id ? true : false}>{flow.name}</Name>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={3}
                                            alignSelf={"center"}
                                            onClick={() => handleClickStatus(flow)}
                                            sx={{
                                                cursor: "pointer"
                                            }}
                                        >
                                            <Button sx={{ color: flow.status === "active" ? green[500] : red[500] }}>
                                                {flow.status}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton onClick={() => handleEditName(flow)}>
                                                <Icon>edit</Icon>
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton onClick={() => handleShareFlowDesign(flow)}>
                                                <Icon>share</Icon>
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton onClick={() => handleDeleteFlow(flow)}>
                                                <Icon>delete</Icon>
                                            </IconButton>
                                        </Grid>
                                    </Box>
                                ))}
                            </Paper>
                        </Grid>
                        <Grid item display="flex" xs={12} md={12} flexDirection={"column"}>
                            <Paper sx={{ width: "100%", padding: "8px" }}>
                                <Box display="flex" width="100%" gap={"8px"} justifyContent={"space-between"}>
                                    <Name>Trigger keywords: </Name>
                                    <TextField
                                        disabled={!currentFlow.id}
                                        placeholder="Trigger keyword"
                                        value={keyword}
                                        multiline
                                        size="small"
                                        onChange={(e) => setKeyword(e.currentTarget.value)}
                                        InputProps={addAdornment(handleAddKeyword)}
                                    />
                                </Box>
                                <GridDivider />
                                {currentFlow?.keywords?.map((keyword, i) => (
                                    <Box display={"flex"} width={"100%"} key={i}>
                                        <Grid item xs={11} alignSelf={"center"}>
                                            <Name>{keyword}</Name>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton onClick={() => handleDeleteKeyword(i)}>
                                                <Icon>delete</Icon>
                                            </IconButton>
                                        </Grid>
                                    </Box>
                                ))}
                                <Box display={"flex"} width={"100%"}></Box>
                            </Paper>
                        </Grid>
                        <Grid item display="flex" xs={12} md={12} flexDirection={"column"}>
                            <Paper sx={{ width: "100%", padding: "8px" }}>
                                <WhatsappLinkGenerator whatsappPhone={whatsappPhone} />
                            </Paper>
                        </Grid>
                        <Grid item display="flex" xs={12} md={12} flexDirection={"column"}>
                            <Paper sx={{ width: "100%", padding: "8px" }}>
                                <ConnectWhatsappComponent whatsappUser={user} setWhatsappPhone={setWhatsappPhone} />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item display="flex" xs={12} md={5}>
                    <Grid container display="flex" spacing={1} alignContent={"flex-start"}>
                        <Grid item display="flex" flexDirection={"column"} xs={12}>
                            <Paper sx={{ width: "100%", padding: "8px" }}>
                                <Box display={"flex"} gap="8px">
                                    <Name>Flow design:</Name>
                                    <SettingsDialog
                                        currentFlow={currentFlow}
                                        handleChangeAppointment={handleChangeAppointment}
                                        handleChangeDisableAI={handleChangeDisableAI}
                                        handleChangeSaveLead={handleChangeSaveLead}
                                        handleChangeSendAudio={handleChangeSendAudio}
                                        model={model}
                                        open={openSettings}
                                        setModel={setModel}
                                        setOpen={setOpenSettings}
                                        user={user}
                                    />
                                </Box>

                                <GridDivider />
                                <TextField
                                    multiline
                                    disabled={!currentFlow.id}
                                    rows={6}
                                    fullWidth
                                    placeholder="System message"
                                    value={system}
                                    onBlur={handleSaveFlowDesign}
                                    onChange={handleChangeSystem}
                                    sx={{ marginBottom: "8px" }}
                                />
                                <TextField
                                    multiline
                                    rows={15}
                                    fullWidth
                                    disabled={!currentFlow.id}
                                    placeholder="Design your flow here"
                                    value={flowDesign}
                                    onBlur={handleSaveFlowDesign}
                                    onChange={handleChangeFlowDesign}
                                />
                                <Box display="flex" gap="8px" justifyContent={"space-between"}>
                                    <Box display="flex" gap="8px">
                                        <Button
                                            variant="contained"
                                            sx={{ marginTop: "8px" }}
                                            onClick={handleSaveFlowDesign}
                                        >
                                            Save
                                        </Button>
                                        <Name color={red[500]}>{errorMessage}</Name>
                                        <Name>{`${tokens} / 1400 tokens`} </Name>
                                    </Box>
                                    <Box display="flex" m={"8px"}>
                                        <Typography sx={{ alignSelf: "center", marginRight: "8px" }}>
                                            Create Vector Index
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            onClick={handleClickIndex}
                                            disabled={user.role !== "Admin"}
                                        >
                                            Index
                                        </Button>
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item display="flex" flexDirection={"column"} xs={12}>
                            <Paper sx={{ width: "100%", padding: "8px" }}>
                                <Grid item xs={12}>
                                    <Name>Files: </Name>
                                </Grid>
                                <GridDivider />
                                {currentFlow.images?.map((image, i) => (
                                    <GridContainer key={image.downloadURL}>
                                        <Grid item display="flex" xs={4}>
                                            <Box display="flex" gap="8px">
                                                <ImageComponent image={image} width={["100px", "150px"]} />
                                            </Box>
                                        </Grid>
                                        <Grid item display="flex" xs={4}>
                                            <Name>{image.name}</Name>
                                        </Grid>
                                        <Grid item display="flex" xs={3}>
                                            <Button
                                                variant="outlined"
                                                onClick={() => {
                                                    handleClickTag(i);
                                                }}
                                            >
                                                {image.tag || "tag"}
                                            </Button>
                                        </Grid>
                                        <Grid item display="flex" xs={1}>
                                            <IconButton onClick={() => handleDeleteImage(i)}>
                                                <Icon>delete</Icon>
                                            </IconButton>
                                        </Grid>
                                        <GridDivider />
                                    </GridContainer>
                                ))}
                                {currentFlow.id && (
                                    <Box sx={{ marginTop: "8px" }}>
                                        <UploadFilesButton handleFile={handleFile} label="Add" />
                                    </Box>
                                )}
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item display="flex" xs={12} md={3}>
                    <Grid container display="flex" spacing={1} alignContent={"flex-start"}>
                        {currentFlow.id && (
                            <Grid item xs={12} display="flex" flexDirection={"column"}>
                                <Paper sx={{ width: "100%", padding: "8px" }}>
                                    <Grid container display="flex" spacing={1}>
                                        <Grid item display="flex" xs={12}>
                                            <Name>Follow up sequence design:</Name>
                                        </Grid>
                                        <Grid item display="flex">
                                            {/* <Button onClick={() => setOpenFollowUp(true)} variant="contained">
                                                Start Design
                                            </Button> */}
                                            {/*added by wayne : 12-1-24 temporary add button for development purpose */}
                                            <Button
                                                onClick={() => setOpenFollowUpReactFlow(true)}
                                                variant="contained"
                                                sx={{ marginLeft: "10px" }}
                                            >
                                                Flow Design
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        )}
                        <Grid item display="flex" flexDirection={"column"} xs={12}>
                            <Paper
                                sx={{
                                    width: "100%",
                                    padding: "8px",
                                    height: "40vh",
                                    overflowY: "auto",
                                    overflowX: "hidden"
                                }}
                            >
                                <Grid container alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        <Name>Assigned Numbers:</Name>
                                    </Grid>
                                    {currentFlow.id ? (
                                        <Grid item>
                                            <Button variant="contained" onClick={handleClickOpen}>
                                                Add
                                            </Button>
                                        </Grid>
                                    ) : (
                                        <></>
                                    )}
                                </Grid>

                                <GridDivider />
                                {displayAssignedNumbers.map((item) => (
                                    <Box display={"flex"} width={"100%"} key={item.id}>
                                        <Grid item xs={6} alignSelf={"center"}>
                                            {item.name}
                                        </Grid>
                                        <Grid item xs={6} alignSelf={"center"}>
                                            {item.from}
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton onClick={() => handleDeleteAssignedNumber(item)}>
                                                <Icon>delete</Icon>
                                            </IconButton>
                                        </Grid>
                                    </Box>
                                ))}
                            </Paper>
                        </Grid>
                        <Grid item display="flex" flexDirection={"column"} xs={12}>
                            <Paper
                                sx={{
                                    width: "100%",
                                    padding: "8px",
                                    height: "40vh",
                                    overflowY: "auto",
                                    overflowX: "hidden"
                                }}
                            >
                                <Name>Blocked Numbers:</Name>
                                <GridDivider />
                                {currentFlow.blockedUsers?.map((item) => (
                                    <Box display={"flex"} width={"100%"} key={item.id}>
                                        <Grid item xs={6} alignSelf={"center"}>
                                            {item}
                                        </Grid>

                                        <Grid item xs={1}>
                                            <IconButton onClick={() => handleDeleteBlockedUser(item)}>
                                                <Icon>delete</Icon>
                                            </IconButton>
                                        </Grid>
                                    </Box>
                                ))}
                            </Paper>
                        </Grid>
                        <GridDivider />
                    </Grid>
                </Grid>
            </Grid>
            <FullDialog
                handleClose={() => setOpenFollowUp(false)}
                open={openFollowUp}
                title="Follow up Design"
                Component={<FollowUpDesign flow={currentFlow} />}
            />
            {/*added by wayne 12-1-2024
            temporay add button for development purpose.*/}
            <FullDialog
                handleClose={() => setOpenFollowUpReactFlow(false)}
                open={openFollowUpReactFlow}
                title="Follow up Sequences Design"
                Component={<OverviewFlowPage flow={currentFlow} />}
            />
            {/*added by wayne 9-11-2023
            additional dialog for user to manual add in single phone num or batch upload*/}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add Phone Number</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ marginBottom: "16px" }}>
                        Choose to either add single phone number or batch add phone numbers.
                    </DialogContentText>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Add Single Phone Number</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                            <TextField
                                id="name"
                                label="Name"
                                variant="outlined"
                                type="text"
                                value={userInput.name}
                                onChange={handleChange}
                            />
                            <div style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
                                <TextField
                                    id="countrycode"
                                    label="Code"
                                    variant="outlined"
                                    type="text"
                                    value={userInput.countrycode}
                                    onChange={handleChange}
                                    style={{ width: "60px" }} // Set a fixed width for the country code field
                                />
                                <TextField
                                    id="phone"
                                    label="Phone Number"
                                    variant="outlined"
                                    type="tel"
                                    value={userInput.phone}
                                    onChange={handleChange}
                                    style={{ flex: 1 }} // Let the phone number field take the remaining width
                                />
                            </div>
                            <Button onClick={handleAddSingle} variant="contained">
                                SAVE
                            </Button>
                            <div style={{ fontSize: "9px" }}>
                                *Phone format should be country code and your phone number.
                            </div>
                            <div style={{ fontSize: "9px" }}>Example: Malaysia phone number 012 8765 4321 </div>
                            <div style={{ fontSize: "9px" }}>Code : 60 Phone number : 1287654321</div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography>Batch Add Phone Numbers</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
                            <ExcelLikeInput handlePasteData={handlePasteData} />
                        </AccordionDetails>
                    </Accordion>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

function removeNonNumeric(input) {
    if (!input) return "";
    let result = input.replace(/[^0-9]/g, "");
    return result;
}
